import React, { memo, useState } from "react";
import { useField } from "formik";
import cx from "classnames";
import Label from "../Label";
import Hint from "../Hint";
import ValidationFeedback from "../ValidationFeedback";
import { useIntl } from "react-intl";
import Link from "@components/Link";

const Checkbox = ({
  label = "",
  hint = "",
  className,
  name,
  onChange,
  onFocus,
  onBlur,
  onKeyUp,
  validate,
  showFeedbackIcon = true,
  showFeedbackText = true,
  typeError = "warning",
  isTermsAndConditions = false,
  ...props
}) => {
  const [field, meta, helpers] = useField({
    name,
    onChange,
    onFocus,
    onBlur,
    onKeyUp,
    validate,
  });
  const [checked, setChecked] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const intl = useIntl();

  const toggle = () => {
    setChecked(!checked);
    helpers.setValue(checked, true);
    // field.onChange(value);
    onChange(checked);
  };
  return (
    <>
      <div className="flex flex-row items-center gap-3">
        <div className={`${isTermsAndConditions ? "" : ""}`}>
          <input
            id={field.name}
            name={field.name}
            type="checkbox"
            checked={!!meta.value}
            onChange={toggle}
            {...field}
            {...props}
            className={cx(
              "outline-none focus:outline-none transition-all ease-in-out duration-500 relative inline-flex items-center h-6 w-6 rounded acc bg-gray text-gold ",
              { "border-brand-500": !meta?.error || !meta?.touched },
              {
                "border-warning":
                  !!meta?.error && meta?.touched && typeError === "warning",
              },
              {
                "border-red":
                  !!meta?.error && meta?.touched && typeError === "error",
              }
            )}
          />
        </div>
        <div
          className={cx("text-sm", {
            "flex items-center justify-center": !isTermsAndConditions,
          })}
        >
          {" "}
          {isTermsAndConditions ? (
            <>
              {intl.formatMessage(
                {
                  defaultMessage:
                    "I am 18 years old and I accept the {terms} and {policy}.",
                  description: "Label T&C consent",
                },
                {
                  terms: (
                    <Link
                      trackName="Terms and Conditions"
                      trackParams={{ component: "RegForm" }}
                      to="termsAndConditions"
                      target="_blank"
                      className="underline text-purple-dark"
                    >
                      {intl.formatMessage({
                        defaultMessage: "Terms and Conditions",
                        description: "Label T&C consent",
                      })}
                    </Link>
                  ),
                  policy: (
                    <Link
                      trackName="Privacy Policy"
                      trackParams={{ component: "RegForm" }}
                      to="privacyPolicy"
                      target="_blank"
                      className="underline text-purple-dark"
                    >
                      {intl.formatMessage({
                        defaultMessage: "Privacy Policy",
                        description: "privacy policy link",
                      })}
                    </Link>
                  ),
                }
              )}
            </>
          ) : (
            <span>
              {intl.formatMessage(
                {
                  defaultMessage: "{message}",
                  description: "Label Checkbox",
                },
                { message: label }
              )}
            </span>
          )}
        </div>
      </div>
      <ValidationFeedback
        meta={meta}
        name={name}
        showFeedbackIcon={showFeedbackIcon}
        showFeedbackText={showFeedbackText && !isFocused}
        typeError={typeError}
      />

      <Hint show={!!hint && !meta?.error}>{hint}</Hint>
    </>
  );
};
export default memo(Checkbox);
