/**
 * WalletTable Component
 *
 * This component is responsible for showing overview of your wallet
 *
 * @category   WalletTable
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */

import { FormattedNumber, useIntl } from 'react-intl';
import useSWR from 'swr';
import { useWallet } from '../../../Provide/WalletContext';
import { getGrantedBonuses } from '../../../../data/model/bonus';

const WalletTable = ({ setMenuItem }) => {
  const intl = useIntl();
  const {
    total, totalBonusWallet, currency, totalWithdraw, lockedAmount,
  } = useWallet();

  const { error, data, mutate } = useSWR('getGrantedBonuses', getGrantedBonuses);

  const activeBonuses = data?.filter((item) => item.stage === 'active');

  let bonusTotal = 0;
  activeBonuses?.map((item) => { bonusTotal += item.amount_cents; });
  // const total = useSelector(state => state.wallet.total);
  // const totalBonusWallet = useSelector(state => state.wallet.totalBonusWallet);
  // const lockedAmount = useSelector(state => state.wallet.lockedAmount);

  return (
    <div className="flex flex-col w-full mb-2">
      <h4 className="mb-10 text-2xl font-light leading-5 text-center text-white   sticky top-3.5 sm:top-4.5 bg-[#13161d] max-w-full ms-auto me-auto pt-1 sm:pt-0 pb-[11px] z-[9] ml-0 sm:mt-2 w-full">
        {intl.formatMessage({
          defaultMessage: "My Account",
          description: "My Account Section",
        })}
      </h4>
      <div className="w-full m-auto mb-8 text-center">
        <h2 className="font-light">
          {intl.formatMessage({
            defaultMessage: "Balance",
            description: "Balance overview labels",
          })}
        </h2>
        <h1 className="mt-1">
          {/* <FormattedNumber
            value={total}
            style="currency"
            currency={currency}
          /> */}

          {total}
        </h1>
      </div>
      <div className="flex flex-row justify-center w-full font-light">
        <div className="flex flex-col w-1/2 text-center">
          <p className="text-sm button-medium">
            {intl.formatMessage({
              defaultMessage: "Locked by Bonus",
              description: "Balance overview labels",
            })}
          </p>
          <p className="mt-0 text-2xl button-medium">
            {/* <FormattedNumber
              value={lockedAmount}
              style="currency"
              currency={currency}
            /> */}
            {lockedAmount}
          </p>
        </div>
        <div className="flex flex-col w-1/2 text-center">
          <p className="text-sm button-medium">
            {intl.formatMessage({
              defaultMessage: "Withdrawable balance",
              description: "Balance overview labels",
            })}
          </p>
          <p className="mt-0 text-2xl button-medium">
            {/* <FormattedNumber
              value={totalWithdraw}
              style="currency"
              currency={currency}
            /> */}
            {totalWithdraw}
          </p>
        </div>
      </div>

      {/* <table className="w-full">
        <tbody>
          <tr className="h-8">
            <td className="text-purple-dark">
              {intl.formatMessage({
                defaultMessage: 'Total Balance',
                description: 'Balance overview labels',
              })}
              :
            </td>
            <td className="text-lg font-bold text-right text-purple-dark">
              <FormattedNumber
                value={total}
                style="currency"
                currency={currency}
              />
            </td>
          </tr> */}
      {/* <tr className="h-8"> */}
      {/*  <td className="button-medium"> */}
      {/*    {intl.formatMessage({ */}
      {/*      defaultMessage: 'Bonus balance', */}
      {/*      description: 'Balance overview labels', */}
      {/*    })} */}
      {/*    : */}
      {/*  </td> */}
      {/*  <td className="font-bold text-right button-medium"> */}
      {/*    <FormattedNumber */}
      {/*      value={totalBonusWallet} */}
      {/*      style="currency" */}
      {/*      currency={currency} */}
      {/*    /> */}
      {/*  </td> */}
      {/* </tr> */}
      {/* <tr className="h-8">
            <td className="button-medium">
              {intl.formatMessage({
                defaultMessage: 'Locked by Bonus',
                description: 'Balance overview labels',
              })}
              :
            </td>
            <td className="font-bold text-right button-medium">
              <FormattedNumber
                value={0}
                style="currency"
                currency={currency}
              />
            </td>
          </tr>
          <tr className="h-8">
            <td className="button-medium">
              {intl.formatMessage({
                defaultMessage: 'Withdrawable balance',
                description: 'Balance overview labels',
              })}
              :
            </td>
            <td className="font-bold text-right button-medium">
              <FormattedNumber
                value={totalWithdraw}
                style="currency"
                currency={currency}
              />
            </td>
          </tr>
        </tbody>
      </table> */}
    </div>
  );
};

export default WalletTable;
