import React, { useEffect, useState } from "react";
import Icon from "../../../../Icon/Icon";
import cx from "classnames";
import { useIntl } from "react-intl";
import { DOC_TYPE, DOCUMENT_STATUS } from "../../../../../data/constant";
import useSWR from "swr";
import { getDocuments } from "../../../../../data/model/documents";
import DocumentCard from "../DocumentCard/DocumentCard";

const VerificationFromV1 = () => {
  const intl = useIntl();

  const [documents, setDocuments] = useState([]);

  const { error, data, mutate } = useSWR("getDocuments", getDocuments);

  useEffect(() => {
    if (data?.length > 0) {
      setDocuments(data?.filter((x) => x.document_type === null));
    }
  }, [data]);

  if (!documents?.length) {
    return null;
  }

  return (
    <div className="border-1 border-brand-600 py-3.5 px-3 rounded  bg-[#1e2229]">
      <div className="flex items-center w-full gap-3 mb-2.5">
        <div className="flex items-center justify-center w-4 h-4 rounded-full bg-gold ring-2 ring-gold ring-opacity-50">
          <Icon icon="paymentCard" className={"w-2 h-2 text-brand"} />
        </div>
        <div className="flex items-center justify-between w-full">
          <h5 className="text-base font-normal text-white">
            {intl.formatMessage({
              defaultMessage: "Other Uploaded Document",
              description: "label",
            })}
          </h5>
          {/* <span
              className={cx(
                `capitalize px-2.5 py-1 rounded border text-10 sm:text-[8px] font-bold bg-[#0091ff] border-[#0091ff] text-[#102739]`
                // {
                //   "bg-[#0091ff] border-[#0091ff] text-[#102739]":
                //     "pending" === "pending",
                //   "bg-[#30d158] border-[#30d158] text-[#17311e]":
                //     "status" === "approved",
                //   "bg-[#d63232] border-[#d63232] text-[#321818]":
                //     "status" === "declined",
                // }
              )}
            >
              Requested
            </span> */}
        </div>
      </div>

      <div className="pl-7">
        {/* <div className="text-[#979797]  text-xs font-normal">
            <div>
              <p className="mb-5">
                {intl.formatMessage({
                  defaultMessage:
                    "We need to verify all payment methods used on the account in the past 6 months.",
                  description: "Label",
                })}
              </p>
              <p className="mb-5">
                {intl.formatMessage({
                  defaultMessage:
                    "What verification you need to upload depends on the payment methods you have used.",
                  description: "Label",
                })}
              </p>
            </div>
            <div>
              <div className="flex w-full gap-5 mb-5 overflow-auto">
                {paymentTypeTabs.map((_tab) => (
                  <button
                    onClick={() => setActiveTab(_tab)}
                    className={cx("text-xs text-gold whitespace-nowrap", {
                      "font-bold border-b-1 border-gold": activeTab === _tab,
                      "font-normal": activeTab !== _tab,
                    })}
                    type="button"
                  >
                    {_tab}
                  </button>
                ))}
              </div>
              <div className="flex flex-col gap-5">
                {identityRules.map((_item, i) => (
                  <div
                    className="flex gap-2"
                    key={`identityRules__${i}__payment`}
                  >
                    <div className="flex items-center justify-center w-4 h-4 rounded-full">
                      <Icon
                        icon="checkOutline"
                        className={"w-3 h-3 text-gold"}
                      />
                    </div>
                    <span>{_item}</span>
                  </div>
                ))}
              </div>
            </div>
          </div> */}

        {documents && documents?.length > 0 ? (
          <div className="flex flex-col gap-3 mt-3.5">
            {documents
              ?.sort(
                (a, b) => new Date(b?.updated_at) - new Date(a?.updated_at)
              )
              ?.map((_item, index) => (
                <DocumentCard
                  document={_item}
                  key={`other_documents__${index}`}
                  icon={"file"}
                />
              ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default VerificationFromV1;
