import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { useIntl } from "react-intl";
import cx from "classnames";
import Button from "../../../Button";
import { SelectBox, TextField } from "../../../Formik";
import { useWallet } from "../../../Provide/WalletContext";
import selectors from "./selectors.json";
import { getCurrencies } from "../../../../data/model/wallet";
import { useFormikContext } from "formik";
import TabbedView from "../../../Tabs";
import Tab from "../../../Tabs/Tab";
import { useCentrifuge } from "../../../Provide/CentrifugeContext";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  formatCardNumber,
  getCardType,
  isStringContainAlpha,
  isValidCardholderName,
  isValidCardNumber,
  removeDiacriticsWithUpperCase,
} from "../../../../data/constant";
import visaLogo from "./cardLogo/visa.png";
import mastercardLogo from "./cardLogo/mastercard.png";
import maestroLogo from "./cardLogo/maestro.png";
import amexLogo from "./cardLogo/amex.png";

const type = {
  tel: TextField,
};
const DynamicFields = ({ paymentPlan, method, setStatus }) => {
  const intl = useIntl();
  const { subscribe } = useCentrifuge();
  const [copied, setCopied] = useState(false);
  const [showAlert, setShowAlert] = useState(null);
  const [cardType, setCardType] = useState(null);
  // const [infoMsg, setShowInfoMessage] = useState(null);
  // const [successMsg, setShowSuccessMessage] = useState(null);
  // console.log("showAlert--showAlert", showAlert);
  const { currency, totalCasinoWallet } = useWallet();
  console.log("paymentPlan", paymentPlan);
  const { data: currencies } = useSWR("getCurrencies", getCurrencies);
  let currentWallet = currencies?.filter((cur) => cur?.code === currency);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 4000);
    }
  }, [copied]);

  useEffect(() => {
    if (showAlert) {
      // setTimeout(() => {
      //   setShowAlert(null);
      //   setShowInfoMessage(null);
      //   setStatus({ step: "success" });
      // }, [30000]);
      subscribe("payments_changes", (message) => {
        if (message?.data?.success) {
          setShowAlert(null);
          // setShowInfoMessage(null);
          setStatus({ step: "success" });
          // setShowSuccessMessage(true);
        }
        // console.log("message--message--balance", message);
      });
      // console.log("start--timeout");
      setTimeout(() => {
        // console.log("timeout--timeout");
        setShowAlert(null);
        // setShowInfoMessage(showAlert);
      }, [300000]);
    }
  }, [showAlert]);
  //Get Formik properties
  const { setFieldValue, touched, setFieldTouched, errors, values } =
    useFormikContext();
  // console.log("values--values", values);

  if (!paymentPlan) {
    return null;
  }

  useEffect(() => {
    if (
      Object.keys(touched).includes("expiryMonth") &&
      Object.keys(touched).includes("expiryYear")
    ) {
      setFieldTouched("fields.expiry_date");
    }
    setFieldValue(
      "fields.expiry_date",
      `20${`0${values?.fields?.expiryYear}`.slice(
        -2
      )}-${`0${values?.fields?.expiryMonth}`.slice(-2)}`,
      true
    );
  }, [
    `${`0${values?.fields?.expiryYear}`?.slice(
      -4
    )}-${`0${values?.fields?.expiryMonth}`?.slice(-2)}`,
  ]);

  const cardLogos = {
    visa: visaLogo,
    mastercard: mastercardLogo,
    maestro: maestroLogo,
    amex: amexLogo,
  };

  const handleCardNumberChange = (value) => {
    try {
      const input = value;
      const sanitizedInput = input?.replace(/\D/g, ""); // Remove non-numeric characters
      const rawValue = value?.replace(/\s/g, "");
      const formattedInput = formatCardNumber(sanitizedInput);
      if (
        rawValue?.length >
          values?.fields?.encCreditcardNumber?.replace(/\s/g, "").length ||
        rawValue?.length !== 0
      ) {
        setFieldValue("fields[encCreditcardNumber]", formattedInput, false);
      } else {
        setFieldValue("fields[encCreditcardNumber]", value, false);
      }
      // console.log("formattedInput--formattedInput", formattedInput);
      const detectedCardType = getCardType(sanitizedInput);
      // console.log("detectedCardType", detectedCardType);
      //6703 4444 4444 4449 -masestro
      //5555500830030331 -master
      //4111111111111111 -visa
      //378282246310005 -amex
      setCardType(detectedCardType);
      return detectedCardType;
    } catch (error) {
      // console.log("error--error", error);
    }
  };

  const messageType = (type) => {
    // console.log("this is the type being passed", type);
    switch (type) {
      case "protocol":
        return intl.formatMessage({
          defaultMessage: "Protocol",
          description: "Label",
        });
      case "credit_card_number":
        return intl.formatMessage({
          defaultMessage: "Credit Card number",
          description: "Label",
        });
      case "encCreditcardNumber":
        return intl.formatMessage({
          defaultMessage: "Card number",
          description: "Label",
        });
      case "card_number":
        return intl.formatMessage({
          defaultMessage: "Card number",
          description: "Label",
        });
      case "cardNumber":
        return intl.formatMessage({
          defaultMessage: "Card number",
          description: "Label",
        });
      case "card_holder":
        return intl.formatMessage({
          defaultMessage: "Cardholder name",
          description: "Label",
        });
      case "bankAccountName":
        return intl.formatMessage({
          defaultMessage: "Bank account name",
          description: "Label",
        });
      case "bankCardNo":
        return intl.formatMessage({
          defaultMessage: "Bank card number",
          description: "Label",
        });
      case "province":
        return intl.formatMessage({
          defaultMessage: "Province",
          description: "Label",
        });
      case "area":
        return intl.formatMessage({
          defaultMessage: "Area",
          description: "Label",
        });
      case "beneficiaryAddress":
        return intl.formatMessage({
          defaultMessage: "Beneficiary address",
          description: "Label",
        });
      case "bankName":
        return intl.formatMessage({
          defaultMessage: "Bank name",
          description: "Label",
        });
      case "bank_name":
        return intl.formatMessage({
          defaultMessage: "Bank name",
          description: "Label",
        });
      case "bank_address":
        return intl.formatMessage({
          defaultMessage: "Bank address",
          description: "Label",
        });
      case "account_owner_name":
        return intl.formatMessage({
          defaultMessage: "Account owner name",
          description: "Label",
        });
      case "branchAddress":
        return intl.formatMessage({
          defaultMessage: "Branch address",
          description: "Label",
        });
      case "secureId":
        return intl.formatMessage({
          defaultMessage: "Secure ID",
          description: "Label",
        });
      case "cardHolder":
        return intl.formatMessage({
          defaultMessage: "Cardholder name",
          description: "Label",
        });
      case "countryCode":
        return intl.formatMessage({
          defaultMessage: "Country code (2 chars)",
          description: "Label",
        });
      case "cvc":
        return intl.formatMessage({
          defaultMessage: "Card security code",
          description: "Label",
        });
      case "card_csc":
        return intl.formatMessage({
          defaultMessage: "Security code",
          description: "Label",
        });
      case "destinationAccount":
        return intl.formatMessage({
          defaultMessage: "Destination Account",
          description: "Label",
        });
      case "beneficiaryName":
        return intl.formatMessage({
          defaultMessage: "Beneficiary Name",
          description: "Label",
        });
      case "accountNumber":
        return intl.formatMessage({
          defaultMessage: "Account Number",
          description: "Label",
        });
      case "bic":
        return intl.formatMessage({
          defaultMessage: "BIC/SWIFT",
          description: "Label",
        });
      case "swift":
        return intl.formatMessage({
          defaultMessage: "BIC/SWIFT",
          description: "Label",
        });
      case "display_swift":
        return intl.formatMessage({
          defaultMessage: "BIC/SWIFT",
          description: "Label",
        });
      case "accountNumber":
        return intl.formatMessage({
          defaultMessage: "IBAN/Account number",
          description: "Label",
        });
      case "account_identifier":
        return intl.formatMessage({
          defaultMessage: "IBAN/Account number",
          description: "Label",
        });
      case "account_type":
        return intl.formatMessage({
          defaultMessage: "Account type",
          description: "Label",
        });
      case "wallet_address":
        return intl.formatMessage({
          defaultMessage: "Wallet Address",
          description: "Label",
        });
      case "tag":
        return intl.formatMessage({
          defaultMessage: "Tag",
          description: "Label",
        });
      case "address":
        return intl.formatMessage({
          defaultMessage: "Address",
          description: "Label",
        });
      case "seamless_expiry_date":
        return intl.formatMessage({
          defaultMessage: "Expiry date",
          description: "Label",
        });
      case "tokenize":
        return intl.formatMessage({
          defaultMessage: "Save account for future payments",
          description: "Label",
        });
      default:
        return type;
    }
  };

  const getLoadingItem = () => (
    <div className="flex items-start gap-2 p-2 my-3 text-black rounded-lg bg-gold">
      <svg
        aria-hidden="true"
        className="inline w-4 h-4 animate-spin"
        viewBox="0 0 100 101"
        fill="none"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="black"
          opacity={"30%"}
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="black"
        />
      </svg>
      <div>
        <p>
          {intl.formatMessage({
            defaultMessage:
              "We are currently monitoring the network for your payment",
            description: "payment monitoring msg",
          })}
        </p>
      </div>
    </div>
  );

  const handleShowAlert = (address) => {
    setShowAlert(address);
    // setShowInfoMessage(null);
  };

  const getField = (item) => {
    const Input = type[item.type] ?? TextField;
    switch (item.type) {
      case "banner":
      case "brand_description":
        return (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: item?.htmlContent }}
              className="mt-4"
            />
          </>
        );
    }
    switch (item.fieldName) {
      case "terms_acceptance":
        return null;
      case "cardHolder":
      case "card_Holder":
        return (
          <div className="mt-4">
            {/* <span className="block mb-1 ml-3 text-xs text-gray">
              {intl.formatMessage({
                defaultMessage: 'Expiry date',
                description: 'Label',
              })}
            </span> */}
            <div className="flex flex-row">
              {/* <Input type="text" name="fields[expiry_date]" label="expiry_date" className="w-1/2 mr-4" /> */}
              {/* {' '} */}
              <TextField
                type="text"
                className="w-full"
                name={`fields[${item.fieldName}]`}
                label={messageType(item.fieldName)}
                validate={(value) => validate(value, item)}
              />
            </div>
          </div>
        );
      case "encCreditcardNumber":
        return (
          <div className="mt-4">
            {/* <span className="block mb-1 ml-3 text-xs text-gray">
              {intl.formatMessage({
                defaultMessage: 'Expiry date',
                description: 'Label',
              })}
            </span> */}
            <div className="relative flex flex-row">
              {/* <Input type="text" name="fields[expiry_date]" label="expiry_date" className="w-1/2 mr-4" /> */}
              {/* {' '} */}
              <TextField
                type="text"
                className="w-full"
                maxLength={19}
                name={`fields[${item.fieldName}]`}
                label={messageType(item.fieldName)}
                validate={(value) => validate(value, item)}
              />
              {cardType && (
                <div className="absolute flex w-12 h-12 gap-3 right-3 top-1">
                  <img src={cardLogos[cardType]} alt={`${cardType} logo`} />
                </div>
              )}
            </div>
          </div>
        );
      case "expiry_date":
        return (
          <div className="mt-4">
            <div className="flex flex-row w-full">
              <TextField
                type="text"
                maxlength="2"
                name="fields[expiryMonth]"
                label={intl.formatMessage({
                  defaultMessage: "Expiry month",
                  description: "Label",
                })}
                className="w-1/2 ltr:mr-4 rtl:ml-4"
                placeholder="MM"
                validate={(value) =>
                  validate(value, { fieldName: "expiryMonth" })
                }
              />
              <TextField
                type="text"
                maxlength="2"
                className="w-1/2"
                name="fields[expiryYear]"
                label={intl.formatMessage({
                  defaultMessage: "Expiry year",
                  description: "Label",
                })}
                placeholder="YY"
                validate={(value) =>
                  validate(value, { fieldName: "expiryYear" })
                }
              />
              <TextField
                name={`fields[${item.fieldName}]`}
                // label={messageType(item.fieldName)}
                type="hidden"
                //validate={(value) => validate(value, fieldName)}
              />
            </div>
          </div>
        );
      case "encCvv":
        return (
          <div className="w-full mt-4 mb-2">
            {/* <div style={{marginTop:`-4.5rem`, marginLeft:`13.5rem`}}> */}
            {/* <Input type="text" name="fields[expiry_date]" label="expiry_date" className="w-1/2 mr-4" /> */}
            {/* {' '} */}
            <TextField
              type="text"
              maxLength={3}
              name={`fields[${item.fieldName}]`}
              label={intl.formatMessage({
                defaultMessage: "Security code",
                description: "Label",
              })}
              placeholder={intl.formatMessage({
                defaultMessage: "123",
                description: "Placeholder",
              })}
              className="w-full"
              hint={intl.formatMessage({
                defaultMessage: "Three digits from the back",
                description: "Label",
              })}
              validate={(value) => validate(value, item)}
            />
            {/* </div> */}
          </div>
        );
      case "address":
      case "deposit_crypto_address":
        return (
          <div>
            <div className="mt-4 ">
              {intl.formatMessage({
                defaultMessage: "This is your private depositing address.",
                description: "private deposit address Label",
              })}
            </div>
            <a href={`${item?.href}`} target="_blank" className="block py-4">
              <img
                src={`https://quickchart.io/chart?cht=qr&chl=${item?.address}&chs=160x160&chld=L|0`}
                className="ml-0"
                alt=""
              />
            </a>
            {/*<div class="profile-crypto__info" translate="casino.bitcoin.send_text" translate-values="{currency: data.currency}"></div>*/}
            {item.fieldName === "address" && (
              <div className="mt-4">
                {intl.formatMessage({
                  defaultMessage: "Send any amount, ",
                  description: "send amount label",
                })}{" "}
                {item.currency}{" "}
                {intl.formatMessage({
                  defaultMessage: "(plus miner fee) to",
                  description: "miner fees Label",
                })}{" "}
              </div>
            )}
            <div className="inline-flex items-center max-w-full gap-2 ">
              <div
                class="profile-crypto__code"
                className="flex-1 p-2 py-3 overflow-hidden break-words rounded bg-brand-600 md:overflow-auto"
              >
                {item.address}
              </div>
              <div className="mt-0 ">
                <CopyToClipboard
                  text={item.address}
                  onCopy={() => setCopied(true)}
                >
                  <Button.Action
                    trackName="Copy"
                    trackParams={{ component: "DynamicFields" }}
                  >
                    {copied
                      ? intl.formatMessage({
                          defaultMessage: "copied",
                          description: "copied Label",
                        })
                      : intl.formatMessage({
                          defaultMessage: "Copy address",
                          description: "Copy address Label",
                        })}
                  </Button.Action>
                </CopyToClipboard>
              </div>
            </div>
            {(showAlert !== item.address || !showAlert) && (
              <div className="flex mt-5">
                <Button.Action
                  trackName="IhaveTransferClick"
                  trackParams={{ component: "DynamicFields" }}
                  onClick={() => handleShowAlert(item.address)}
                  // className="w-full"
                  // arrow="right"
                >
                  {intl.formatMessage({
                    defaultMessage: "I've Made The Transfer",
                    description: "I transfered label",
                  })}
                </Button.Action>
              </div>
            )}
            {showAlert === item.address && getLoadingItem()}
            {/* {infoMsg === item.address && ( */}
            <p className="py-2 mt-2 ">
              {intl.formatMessage({
                defaultMessage:
                  "Usually cryptocurrency deposits are credited to your account within a couple of minutes, but sometimes it might take longer. If your deposit is not credited to your account in 30 minutes, please contact our support.",
                description: "cryptocurrency info msg",
              })}
            </p>
            {/* )} */}
            {/* {successMsg && (
              <div className="flex flex-wrap items-center justify-center gap-4 mt-4 mb-4 md:mb-0">
                <div className="px-4 py-3 font-medium rounded-lg text-brand bg-green">
                  deposit successful
                </div>
                <Link
                  to="home"
                  className={
                    "py-3 px-4 rounded-lg text-center items-center justify-between rtl:justify-end inline-flex transition-all bg-gold hover:bg-opacity-70 whitespace-nowrap overflow-hidden font-medium text-brand rtl:flex-row-reverse"
                  }
                >
                  {intl.formatMessage({
                    defaultMessage: "We are waiting for you at the game!",
                    description: "We are waiting for you at the game! label",
                  })}
                  <Icon
                    icon="arrow"
                    className={cx(
                      "ml-4 h-4 w-4 inline-block transform -rotate-90"
                    )}
                  />
                </Link>
              </div>
            )} */}
          </div>
        );
      case "multi_protocol_crypto_address":
        return (
          <>
            {/* <div>
              <h4 className="my-4 text-xl font-bold text-center text-white uppercase">
                SEND PAYMENT
              </h4>
            </div> */}
            <TabbedView buttonClassName="text-lg font-bold">
              {item?.protocols?.map((protocol, index) => (
                <Tab
                  name={protocol}
                  key={`multi_protocol_crypto_address__${index}`}
                >
                  <div>
                    {item?.bannersByProtocol?.[protocol]?.map(
                      (bannerItem, index) => {
                        return (
                          <div key={`bannerItem__${index}`}>
                            {/* <div className="text-lg font-bold">
                              {bannerItem?.identifier}
                            </div> */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: bannerItem?.htmlContent,
                              }}
                              // className="mt-4"
                            />
                          </div>
                        );
                      }
                    )}
                    {getField(item?.addressByProtocol?.[protocol])}
                  </div>
                </Tab>
              ))}
            </TabbedView>
          </>
        );
      default:
        if (item?.options?.length) {
          const list = item?.options?.map((item, i) => ({
            name: item.value,
            text: item.label,
          }));

          return (
            <SelectBox
              name={`fields[${item.fieldName}]`}
              // label={intl.formatMessage({
              //   defaultMessage: 'Currency',
              //   description: 'Label of inputfield',
              // })}
              // autoLabel={currentCountrySelected?.name}
              initialValue={item?.proposedValue}
              options={list}
              label={messageType(item.fieldName)}
              className="w-full mt-5"
              showFeedbackIcon={false}
              showFeedbackText={true}
              validate={(value) => validate(value, item)}
            />
          );
        }
        return (
          <div className="mt-4">
            <div className="flex flex-row">
              <TextField
                type="text"
                className="w-full"
                name={`fields[${item.fieldName}]`}
                label={messageType(item.fieldName)}
                validate={(value) => validate(value, item)}
              />
            </div>
          </div>
        );
    }
  };
  const validate = (value, item) => {
    let errorMessage;

    switch (item.fieldName) {
      case "email":
        if (!value || value.trim() === "") {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        } else {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(value)) {
            errorMessage = intl.formatMessage({
              defaultMessage: `Please enter a valid email address`,
              description: "Email Validation",
            });
          }
        }
        break;
      case "cardHolder":
      case "card_Holder":
        const processedInput = removeDiacriticsWithUpperCase(value); // Remove diacritics
        if (processedInput) {
          setFieldValue(`fields.${item.fieldName}`, processedInput, false);
        }
        if (
          processedInput === "" ||
          processedInput === undefined ||
          processedInput === null
        ) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        } else {
          const isValid = isValidCardholderName(processedInput);
          if (isValid?.error === "length") {
            errorMessage = intl.formatMessage({
              defaultMessage: `Incorrect name on the card. You should use 3 to 256 character`,
              description: "valid card holder length error",
            });
          } else if (isValid?.error === "specialChars") {
            errorMessage = intl.formatMessage({
              defaultMessage: `Cardholder name must not contain numbers or special characters`,
              description: "valid card holder length error",
            });
          } else if (isValid?.error === "ascii") {
            errorMessage = intl.formatMessage({
              defaultMessage: `Cardholder name must contain latin characters only`,
              description: "valid card holder length error",
            });
          }
        }
        break;
      case "encCreditcardNumber":
        const cardType = handleCardNumberChange(value);
        if (value === "" || value === undefined || value === null) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        } else if (!isValidCardNumber(value)) {
          errorMessage = intl.formatMessage({
            defaultMessage: `Please enter a valid card number`,
            description: "valid card number error",
          });
        } else if (cardType === "amex") {
          errorMessage = intl.formatMessage({
            defaultMessage: `Amex is not a supported card type. Please use a Visa, Mastercard or Maestro`,
            description: "valid card number error",
          });
        }
        break;
      case "encCvv":
        if (value === "" || value === undefined || value === null) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        } else if (
          value?.toString()?.length !== 3 ||
          !isStringContainAlpha(value)
        ) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This is your CVV, a 3 digit number`,
            description: "cvv Validation",
          });
        }
        break;
      case "expiryYear":
        if (value === "" || value === undefined || value === null) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        } else if (!isStringContainAlpha(value)) {
          errorMessage = intl.formatMessage({
            defaultMessage: `Year must not contain alphabetic characters`,
            description: "year Validation",
          });
        } else {
          let expiryYear = parseInt(value);
          const currentYearFull = new Date().getFullYear();
          const currentYearShort = parseInt(
            currentYearFull.toString().slice(-2)
          );
          const currentMonth = new Date().getMonth() + 1;

          if (expiryYear < 100) {
            const century = Math.floor(currentYearFull / 100) * 100;
            expiryYear += century;
          }
          if (expiryYear < currentYearFull) {
            errorMessage = intl.formatMessage({
              defaultMessage: `Credit card date expired, please correct`,
              description: "Error",
            });
          }
          const expiryMonthValue = values.fields.expiryMonth;
          if (
            expiryMonthValue &&
            expiryYear === currentYearFull &&
            parseInt(expiryMonthValue) < currentMonth
          ) {
            errorMessage = intl.formatMessage({
              defaultMessage: `Credit card date expired, please correct`,
              description: "Error",
            });
          }
        }
        break;
      case "expiryMonth":
        if (value === "" || value === undefined || value === null) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        } else if (!isStringContainAlpha(value)) {
          errorMessage = intl.formatMessage({
            defaultMessage: `Month must not contain alphabetic characters`,
            description: "month Validation",
          });
        } else if (parseInt(value) == 0 || parseInt(value) > 12) {
          errorMessage = intl.formatMessage({
            defaultMessage: `Invalid month, please correct`,
            description: "Error",
          });
        }
        break;
      case "amount":
        if (value === "" || value === undefined || value === null) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        } else if (!isStringContainAlpha(value)) {
          errorMessage = intl.formatMessage({
            defaultMessage: `Amount must not contain alphabetic characters`,
            description: "Amount Validation for alphabetic characters",
          });
        } else {
          if (typeof value === "string") {
            // value = value.replace(/\D/g, '');
            // value /= 100;
            value = parseFloat(value); // parseInt(value);
          }

          if (typeof value === "string") {
            value = value.replace(/\D/g, "");
            // value /= 100;
            // value = parseFloat(value).toFixed(2); //parseInt(value);
          }

          if (
            value <
            parseFloat(method?.termsOfService?.restrictions?.minAmountValue)
          ) {
            errorMessage = `${intl.formatMessage({
              defaultMessage: `The minimum deposit amount is`,
              description: "Validation",
            })} ${
              (intl
                .formatNumber(0, {
                  style: "currency",
                  currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
                .replace(/\d/g, "")
                .trim(),
              Number.parseFloat(
                method?.termsOfService?.restrictions?.minAmountValue
              ))
            }`;
          } else if (
            value >
            parseFloat(method?.termsOfService?.restrictions?.maxAmountValue)
          ) {
            errorMessage = `${intl.formatMessage({
              defaultMessage: `The maximum deposit amount is`,
              description: "Validation",
            })} ${
              (intl
                .formatNumber(0, {
                  style: "currency",
                  currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
                .replace(/\d/g, "")
                .trim(),
              Number.parseFloat(
                method?.termsOfService?.restrictions?.maxAmountValue
              ))
            }`;
          }
        }
        break;
      default:
        if (value === "" || value === undefined || value === null) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        }
        break;
    }

    return errorMessage;
  };

  const getAmountField = (item) => {
    return (
      <>
        <div className="mt-4">
          <span className="text-lg">
            {intl.formatMessage({
              defaultMessage: "Choose how much to deposit",
              description: "Label",
            })}
          </span>
          <div className="w-full mb-4">
            <div
              className="flex flex-row mt-4 mb-2 "
              style={{ width: `fit-content` }}
            >
              {selectors?.currencies[currency]?.map((option, index) => (
                <Button.Action
                  trackName="Deposit Selector"
                  trackParams={{ component: "DynamicFields" }}
                  type="button"
                  color="darkgray"
                  className={cx(
                    "justify-center px-4",
                    {
                      "mr-0":
                        index === selectors.currencies[currency].length - 1,
                    },
                    {
                      "ltr:mr-4 rtl:ml-4":
                        index !== selectors.currencies[currency].length - 1,
                    }
                  )}
                  onClick={() =>
                    setFieldValue(
                      `fields[${item.fieldName}]`,
                      String(option),
                      true
                    )
                  }
                >
                  {intl.formatNumber(option, {
                    style: "currency",
                    currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </Button.Action>
              ))}
            </div>
          </div>
          <span className="mt-4 text-base">
            {intl.formatMessage({
              defaultMessage: "Or, deposit a different amount",
              description: "Label",
            })}
          </span>
        </div>
        <div className="flex flex-col items-stretch w-full mt-4">
          <TextField
            name={`fields[${item.fieldName}]`}
            placeholder={intl.formatMessage({
              defaultMessage: `Amount`,
              description: "Label of inputfield",
            })}
            type="tel"
            validate={(value) => validate(value, item)}
            className="w-full m-0 appearance-none"
            hint={`${intl.formatNumber(
              Number(method?.termsOfService?.restrictions?.minAmountValue),
              {
                style: "currency",
                currency,
                minimumFractionDigits:
                  currentWallet?.[0]?.subunits_to_unit?.toString()?.length -
                    1 ?? 2,
                maximumFractionDigits:
                  currentWallet?.[0]?.subunits_to_unit?.toString()?.length -
                    1 ?? 2,
              }
            )}
                   - 
                  ${intl.formatNumber(
                    Number(
                      method?.termsOfService?.restrictions?.maxAmountValue
                    ),
                    {
                      style: "currency",
                      currency,
                      minimumFractionDigits:
                        currentWallet?.[0]?.subunits_to_unit?.toString()
                          ?.length - 1 ?? 2,
                      maximumFractionDigits:
                        currentWallet?.[0]?.subunits_to_unit?.toString()
                          ?.length - 1 ?? 2,
                    }
                  )}
                  `}
            label={intl.formatMessage(
              {
                defaultMessage: `Amount ({amount})`,
                description: "Label",
              },
              {
                amount: intl
                  .formatNumber(0, {
                    style: "currency",
                    currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                  .replace(/\d/g, "")
                  .trim(),
              }
            )}
          />
        </div>
      </>
    );
  };

  return (
    <>
      {paymentPlan?.amountField !== null &&
        getAmountField(paymentPlan?.amountField)}

      {paymentPlan?.methodFields?.map((item, i) => {
        return (
          (!values.savedProfileId ||
            method?.title === "Jeton" ||
            item?.type === "cvv") && (
            <div className={cx("mt-0")}>{getField(item)}</div>
          )
        );
      })}
    </>
  );
};

export default DynamicFields;
