import history from "../core/history";

export const is_cash_bonus = (item) => {
  try {
    if (
      item.amount_wager_cents === 0 &&
      item.amount_wager_requirement_cents === 0 &&
      item.cancelable === false &&
      item.activatable === false
    ) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const checkif10DaysOld = (item) => {
  try {
    const dateString = item?.created_at;
    const date = new Date(dateString);
    const currentDate = new Date();
    const timeDifference = currentDate - date;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    if (daysDifference >= 10) {
      console.log("The date is 10 days or older.", item?.created_at);
      return false;
    } else {
      console.log("The date is less than 10 days old.", item?.created_at);
      return true;
    }
  } catch (error) {}
};

export const redirectWithReturnURL = (name, locale) => {
  if (locale) {
    let returnURL = `/${
      locale === "en-us" ? "en-row" : locale
    }/login?return=${name}`;
    history.push(returnURL);
    return null;
  }
};

export const createCustomTitle = (template, vars) => {
  return template?.replace(/\{(.*?)\}/g, (_, key) => vars[key] || `{${key}}`);
};

export const countDecimalDigits = (number) => {
  if (!number) return;
  const decimalPart = (number.toString().split(".")[1] || "").length;
  return decimalPart;
};

export const stringToInt = (str) => {
  var hash = 0;
  if (str.length == 0) return hash;
  for (var i = 0; i < str.length; i++) {
    var char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
};
export const DOC_TYPE = {
  IDENTITY: "identity",
  ADDRESS: "address",
  DEPOSIT: "deposit",
  PAYMENT_METHOD: "payment_method",
};
export const DOCUMENT_STATUS = {
  REJECTED: "not_approved",
  PENDING: "pending",
  APPROVED: "approved",
};
export const BLOCKED_COUNTRY = [
  "RS", //Serbia
  "US", // United States of America
  "GB", // United Kingdom
  "ES", // Spain
  "FR", // France
  "GP", // Guadeloupe
  "MQ", // Martinique
  "GF", // French Guiana
  "RE", // Réunion
  "YT", // Mayotte
  "MF", // St. Martin
  "PF", // French Polynesia
  "WF", // Wallis and Futuna
  "NC", // New Caledonia
  "BE", // Belgium
  "NL", // Netherlands
  "IL", // Israel
  "LT", // Lithuania
  "CW", // Curacao (Dutch West Indies)
  "GE", // Georgia
  "GI", // Gibraltar
  "JE", // Jersey
  "GR", // Greece
  "AO", // Angola
  "AL", // Albania
  "IQ", // Iraq
  "JM", // Jamaica
  "UG", // Uganda
  "PK", // Pakistan
  "IR", // Islamic Republic of Iran
  "PA", // Panama
  "LB", // Lebanon
  "ZW", // Zimbabwe
  "MU", // Mauritius
  "NI", // Nicaragua
  "YE", // Yemen
  "CF", // Central African Republic
  "CI", // Côte d'Ivoire
  "SD", // Sudan
  "LR", // Liberia
  "SY", // Syrian Arab Republic
  "KY", // Cayman Islands
  "SO", // Somalia
  "CG", // Congo (Brazzaville)
  "KP", // North Korea
  "ER", // Eritrea
  "HT", // Haiti
  "SL", // Sierra Leone
  "ET", // Ethiopia
  "MM", // Myanmar
  "SS", // South Sudan
  "BF", // Burkina Faso
  "LY", // Libya
  "ML", // Mali
  "BB", // Barbados
  "RW", // Rwanda
  "CU", // Cuba
  "PT", // Portugal
  "UA", // Ukraine
  "AX", // Åland Islands
  "NF", // Norfolk Island
  "LV", // Latvia
  "BH", // Bahrain
  "MN", // Mongolia
  "HR", // Croatia
  "TH", // Thailand
  "RO", // Romania
  "HK", // Hong Kong
  "BD", // Bangladesh
  "BG", // Bulgaria
  "NG", // Nigeria
  "MY", // Malaysia
  "AQ", // Antarctica
  "TZ", // Tanzania
  "TN", // Tunisia
  "TD", // Chad
  "HN", // Honduras
  "MK", // North Macedonia
  "AD", // Andorra
  "TT", // Trinidad and Tobago
  "JO", // Jordan
  "EG", // Egypt
  "GU", // Guam
  "BS", // Bahamas
  "EH", // Western Sahara
  "BA", // Bosnia and Herzegovina
  "MC", // Monaco
  "SG", // Singapore
  "KH", // Cambodia
  "SV", // El Salvador
  "LI", // Liechtenstein
  "IO", // British Indian Ocean Territory
  "FJ", // Fiji
  "CN", // China
  "CX", // Christmas Island
  "BO", // Bolivia
  "GL", // Greenland
  "FO", // Faroe Islands
  "PS", // Palestine
  "ID", // Indonesia
  "AW", // Aruba
  "BJ", // Benin
  "BM", // Bermuda
  "PG", // Papua New Guinea
  "SC", // Seychelles
  "GS", // South Georgia and the South Sandwich Islands
  "VN", // Vietnam
  "TW", // Taiwan
  "EC", // Ecuador
  "SM", // San Marino
  "WS", // Samoa
  "GT", // Guatemala
  "VA", // Holy See (Vatican City State)
  "LA", // Lao People's Democratic Republic
  "DO", // Dominican Republic
  "CK", // Cook Islands
  "TO", // Tonga
  "VU", // Vanuatu
  "VI", // Virgin Islands U.S.
  "BZ", // Belize
  "ME", // Montenegro
  "OM", // Oman
  "KN", // Saint Kitts and Nevis
  "NE", // Niger
  "GA", // Gabon
  "BY", // Belarus
  "SZ", // Eswatini (Swaziland)
  "GH", // Ghana
  "DM", // Dominica
  "BI", // Burundi
  "BV", // Bouvet Island
  "TG", // Togo
  "MP", // Northern Mariana Islands
  "TK", // Tokelau
  "SR", // Suriname
  "LC", // Saint Lucia
  "LK", // Sri Lanka
  "ZM", // Zambia
  "PY", // Paraguay
  "BW", // Botswana
  "MF", // Saint Martin (French part)
  "DJ", // Djibouti
  "IM", // Isle of Man
  "PM", // Saint Pierre and Miquelon
  "SB", // Solomon Islands
  "FK", // Falkland Islands (Malvinas)
  "SN", // Senegal
  "CM", // Cameroon
  "CV", // Cabo Verde
  "KE", // Kenya
  "KG", // Kyrgyzstan
  "AI", // Anguilla
  "TV", // Tuvalu
  "CC", // Cocos (Keeling) Islands
  "AG", // Antigua and Barbuda
  "GN", // Guinea
  "BN", // Brunei Darussalam
  "BQ", // Bonaire
  "TJ", // Tajikistan
  "ST", // Sao Tome and Principe
  "MG", // Madagascar
  "HM", // Heard Island and McDonald Islands
  "MS", // Montserrat
  "MZ", // Mozambique
  "NP", // Nepal
  "GY", // Guyana
  "GW", // Guinea-Bissau
  "NR", // Nauru
  "TC", // Turks and Caicos Islands
  "BT", // Bhutan
  "NA", // Namibia
  "VG", // Virgin Islands British
  "KI", // Kiribati
  "MW", // Malawi
  "GQ", // Equatorial Guinea
  "SH", // Saint Helena
  "BL", // Saint Barthélemy
  "PN", // Pitcairn
  "UM", // United States Minor Outlying Islands
  "PW", // Palau
  "NC", // New Caledonia (listed again for clarity)
  "GM", // Gambia
  "NU", // Niue
  "MV", // Maldives
  "PF", // French Polynesia (listed again for clarity)
  "MO", // Macao
  "TM", // Turkmenistan
  "GD", // Grenada
  "FM", // Micronesia
  "TL", // Timor-Leste
  "SX", // Sint Maarten (Dutch part)
  "MR", // Mauritania
  "MH", // Marshall Islands
  "KM", // Comoros
  "AZ", // Azerbaijan
  "DZ", // Algeria
  "SK", // Slovakia
  "AM", // Armenia
  "PH", // Philippines
  "SJ", // Svalbard and Jan Mayen
  "AS", // American Samoa
  "MX", // Mexico
  "SA", // Saudi Arabia
  "IN", // India
  "PL", // Poland
  "AF", // Afghanistan
  "SE", // Sweden
  "MD", // Moldova
  "IS", // Iceland
  "KR", // South Korea
  "MA", // Morocco
  "CR", // Costa Rica
  "UY", // Uruguay
  "UZ", // Uzbekistan
  "CL", // Chile
  "VE", // Venezuela
  "TR", // Turkey
  "CO", // Colombia
  "PR", // Puerto Rico
  "PE", // Peru
];

export const isValidCardNumber = (rawCardNumber = "") => {
  const cardNo = rawCardNumber?.replace(/\D/g, "");
  let nDigits = cardNo?.length;

  let nSum = 0;
  let isSecond = false;
  for (let i = nDigits - 1; i >= 0; i--) {
    let d = cardNo[i].charCodeAt() - "0".charCodeAt();

    if (isSecond == true) d = d * 2;

    // We add two digits to handle
    // cases that make two digits
    // after doubling
    nSum += parseInt(d / 10, 10);
    nSum += d % 10;

    isSecond = !isSecond;
  }
  return nSum % 10 == 0;
};
export const getCardType = (cardNumber) => {
  const patterns = {
    visa: /^4/,
    mastercard: /^5[1-5]/,
    maestro: /^(5018|5020|5038|56|58|63|67)/,
    amex: /^3[47]/,
  };

  for (const [cardType, pattern] of Object.entries(patterns)) {
    if (pattern.test(cardNumber)) {
      return cardType;
    }
  }
  return null;
};

export const getMaxCardLength = (cardType) => {
  const lengths = {
    visa: 16,
    mastercard: 16,
    maestro: 19,
    amex: 15,
  };

  return lengths[cardType] || 19; // Default max length if card type is unknown
};

export const isValidCardholderName = (name) => {
  const asciiRegex = /^[\x00-\x7F]*$/;
  const specialCharsRegex = /^[A-Za-z\s'`~.-]*$/;
  const minLength = 3;
  const maxLength = 256;

  if (!asciiRegex.test(name)) {
    return { error: "ascii" };
  }

  if (!specialCharsRegex.test(name)) {
    return { error: "specialChars" };
  }

  if (name.length < minLength || name.length > maxLength) {
    return { error: "length" };
  }

  return true;
};

// Utility function to remove diacritics from accented characters
export const removeDiacriticsWithUpperCase = (str) => {
  if (!str) return;
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toUpperCase();
};

export const isStringContainAlpha = (n) => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
};

export const formatCardNumber = (number) => {
  try {
    return number
      ?.replace(/\s?/g, "")
      .replace(/(\d{4})/g, "$1 ")
      .trim();
  } catch (error) {
    console.log("error--error", error);
    return number;
  }
};
