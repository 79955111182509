import React, { memo, useState } from "react";
import { useField } from "formik";
import cx from "classnames";
import MaskedInput from "react-text-mask";
import Label from "../Label";
import Hint from "../Hint";
import ValidationFeedback from "../ValidationFeedback";

const MaskField = ({
  mask = "",
  overlayedHint = false,
  deposit = false,
  label = "",
  hint = "",
  className,
  name,
  placeholder = null,
  onChange = () => {},
  showFeedbackIcon = true,
  showFeedbackText = true,
  onFocus = null,
  onBlur = null,
  inputClassName = "",
  typeError = "warning",
  autocomplete,
  hasExternalLabel,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const change = (e) => {
    field.onChange(e);
    onChange(e, field, meta, helpers);
  };

  const focus = (e) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const blur = (e) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  const keyUp = (e) => {
    helpers.setTouched(true);
  };

  return (
    <div className={cx("relative", className, { "mb-4": !!hint })}>
      <Label
        name={field.name}
        hasValue={!!meta?.value}
        hasPlaceholder={!!placeholder}
        isFocused={isFocused}
      >
        {label}
      </Label>
      <MaskedInput
        mask={mask}
        type="text"
        id={field.name}
        autoComplete={autocomplete}
        placeholder={placeholder}
        {...field}
        {...props}
        onChange={change}
        onFocus={focus}
        onBlur={blur}
        onKeyUp={keyUp}
        className={cx(
          "w-full appearance-none m-0 px-3  text-white border rounded-lg bg-brand-600  rtl:text-right  focus:outline-none focus:border-gold focus:ring-0  focus:ring-offset-0 focus:ring-transparent",
          { "h-10": hasExternalLabel },
          { "h-14 pt-5 pb-1": !hasExternalLabel },
          { "border-brand-500": !meta?.error || !meta?.touched },
          {
            "border-warning":
              !!meta?.error && meta?.touched && typeError === "warning",
          },
          {
            "border-red":
              !!meta?.error && meta?.touched && typeError === "error",
          },
          inputClassName
        )}
      />
      <ValidationFeedback
        meta={meta}
        name={name}
        showFeedbackIcon={showFeedbackIcon}
        showFeedbackText={meta?.touched}
        typeError={typeError}
      />
      <Hint show={!!hint}>{hint}</Hint>
    </div>
  );
};

export default memo(MaskField);
