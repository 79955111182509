import AutobahnReact from "../../core/websocket";
import { FIELDS } from "../../constants/Gamebrowser";
import { getCmsSessionID } from "./session";
import { getCall } from "../../core/softswiss";
import { isBrowser } from "browser-or-node";
import { getDomainName } from "../../core/utils";

const ENDPOINT = isBrowser
  ? `https://backend.${getDomainName(window.location.hostname)}`
  : "https://backend.theclubhousecasino.com";
export const getGames = () => getCall("/api/games");
export const getTotalJackpots = () => getCall("/api/games/total_jackpots");
export const getJackpots = () => getCall("/api/games/jackpots");

function memoize(fn) {
  const cache = {};
  return async function () {
    const args = JSON.stringify(arguments);

    console.log("arguments passed to memoize fn: ", args);
    console.log("cache data: ", cache);

    cache[args] = cache[args] || fn.apply(undefined, arguments);
    return cache[args];
  };
}

function getAllowedGamesCall(isMobile) {
  return getCall(
    `/api/games${isMobile ? "/allowed_mobile" : "/allowed_desktop"}`
  );
}
function getOrderedGamesCall(isMobile) {
  return getCall("/api/games/order");
}
const memoizedAllowedGamesCall = memoize(getAllowedGamesCall);
const memoizedOrderedGamesCall = memoize(getOrderedGamesCall);

export const getAllowedGames = async (url, isMobile) => {
  const resultTmp = await memoizedAllowedGamesCall(isMobile);
  const gamesNew = Object.keys(resultTmp)?.map((slug) => {
    const { title, demo, real, collections, ...others } = resultTmp[slug];

    return {
      name: title,
      id: slug,
      slug,
      demo,
      real,
      collections: Object.keys(collections),
      thumbnails: {
        s1: `https://cdn.softswiss.net/i/s1/${slug}.webp`,
        s2: `https://cdn.softswiss.net/i/s2/${slug}.webp`,
        s3: `https://cdn.softswiss.net/i/s3/${slug}.webp`,
        s4: `https://cdn.softswiss.net/i/s4/${slug}.webp`,
      },
      thumbnail: `https://cdn.softswiss.net/i/s3/${slug}.webp`,
      backgroundImageUrl: `https://cdn2.softswiss.net/i/b/${slug}.jpg`,
      ...others,
    };
  });
  return gamesNew;
};

export const getBackendOrderedGames = async (url, isMobile, subcategory) => {
  //var category = "live";
  //var subcategory = "gameshows";
  console.log("CACHING", subcategory);
  //=================================================================================
  // Get the list of Games and transform into custom games object.

  var catPropertyConcat = subcategory;

  const gamesList = await memoizedAllowedGamesCall(isMobile);
  const gamesNew = [];

  console.log("subcategory.originalId", subcategory.originalId);
  console.log("subcategory", subcategory);
  console.log("gamesList", gamesList);
  Object.keys(gamesList)?.map((slug) => {
    const { title, demo, real, collections, ...others } = gamesList[slug];

    const newCollections = [];
    if (
      collections?.hasOwnProperty(subcategory.fullId) ||
      !subcategory ||
      subcategory.id == "all"
    ) {
      gamesNew.push({
        name: title,
        id: slug,
        slug,
        demo,
        real,
        collections: Object.keys(collections),
        order: collections?.[subcategory.fullId],
        thumbnails: {
          s1: `https://cdn.softswiss.net/i/s1/${slug}.webp`,
          s2: `https://cdn.softswiss.net/i/s2/${slug}.webp`,
          s3: `https://cdn.softswiss.net/i/s3/${slug}.webp`,
          s4: `https://cdn.softswiss.net/i/s4/${slug}.webp`,
        },
        thumbnail: `https://cdn.softswiss.net/i/s3/${slug}.webp`,
        backgroundImageUrl: `https://cdn2.softswiss.net/i/b/${slug}.jpg`,
        ...others,
      });
    }
  });

  if (subcategory.fullId.indexOf("recommended") > -1) {
    console.log("gamesNew", gamesNew);
  }
  return gamesNew.sort(function (a, b) {
    return a.order - b.order;
  });

  //console.log("Step 1", gamesNew);

  //=================================================================================
  //Get ordering list for all games, then filter by the collection property, map to custom object and sort.
  if (!subcategory) {
    return gamesNew;
  }
  const orderedGamesList = await memoizedOrderedGamesCall();

  const orderedGamesLNew = Object.keys(orderedGamesList)
    ?.map((slug) => {
      for (const [key, value] of Object.entries(orderedGamesList[slug])) {
        if (key.indexOf(catPropertyConcat) > -1) {
          return {
            id: slug,
            ordernumber: orderedGamesList[slug][catPropertyConcat],
          };
        }
      }
    })
    .filter(function (x) {
      return x !== undefined;
    })
    .sort(function (a, b) {
      return a.ordernumber - b.ordernumber;
    });

  console.log("orderedGamesLNew", orderedGamesLNew);
  //console.log("Step 2", orderedGamesLNew);

  //=================================================================================
  //Re-ordering of retrieved Games to match ordering list.
  var orderedResult = [];
  const orderedCollection = Object.keys(orderedGamesLNew)?.map((slug) => {
    //console.log("Step", orderedGamesLNew[slug].id);

    const gameIndex = gamesNew.filter(
      (f) => f.id == orderedGamesLNew[slug].id
    )[0];
    orderedResult.push(gameIndex);
    //console.log("Step", gameIndex);
  });

  return orderedResult;
};

export const getRecentGames = async () =>
  await getCall(`/api/player/played_games`);

export const getOrderedGames = async () =>
  await getCall(`/api/player/played_games`);

export const getCollectionsSWR = () => getCall("/api/games/collections");

export const getProviders = () =>
  getCall("/api/games/providers?category_identifier=all");

export const lastPlayedGames = () =>
  AutobahnReact("everymatrix").call("/casino#lastPlayedGames", {
    expectedGameFields: FIELDS.LOBBYCASINO,
  });

export const compileLaunchUrl = (
  item,
  realMode,
  currency,
  cmsSessionID = ""
) => {
  const url = `${ENDPOINT}${
    !realMode ? item?.demo : `/games/${item?.id}/${item?.real[currency].id}`
  }`;
  return url;
};

export const getRealLaunchUrl = async (path, launchUrl) => {
  const result = await getCmsSessionID();
  const url = `${launchUrl}?funMode=False&_sid=${result.cmsSessionID}`;

  return url;
};

export const getLaunchUrl = (slug, tableID, realMoney) =>
  AutobahnReact("everymatrix").call("/casino#getLaunchUrl", {
    slug,
    tableID,
    realMoney,
  });

export const getRecommendedGamesBySlug = (key, slug) =>
  AutobahnReact("everymatrix").call("/casino#getRecommendedGames", {
    recommendedType: "game",
    slug: [slug],
    expectedGameFields: FIELDS.LOBBYCASINO,
  });

export const searchVendorGames = (gameList = []) =>
  AutobahnReact("everymatrix").call("/casino#getGames", {
    filterByGameCode: gameList,
    expectedFields: FIELDS.LOBBYCASINO,
    specificExportFields: ["gameID", "gameCode"],
  });
