import cx from "classnames";
import logoImg from "./logo-vertical-color.svg";
import logoImgLeft from "./logo-vertical-left-color.svg";
import iconImg from "./icon.svg";
import mainFullSanta from "./main-full-santa.svg";
import mainMobileSanta from "./main-mobile-santa.svg";
import mainFullLeftSanta from "./main-full-left.svg";
import sublineImg from "./subline.svg";
// import Sparkles from "../Sparkles";

const Logo = ({
  classNameSubline,
  classNameLogo,
  alt = "",
  align = "center",
  direction = "horizontal",
  icon = false,
}) => {
  const currentYear = new Date().getFullYear();
  const startDate = new Date(`December 1, ${currentYear}`);
  const endDate = new Date(`January 6, ${currentYear + 1}`);
  const currentDate = new Date();

  if (currentDate >= startDate && currentDate <= endDate) {
    if (icon) {
      return <img src={mainMobileSanta} className={classNameLogo} alt={alt} />;
    }
    return align === "left" ? (
      <img src={mainFullLeftSanta} className={classNameLogo} alt={alt} />
    ) : (
      <img src={mainFullSanta} className={classNameLogo} alt={alt} />
    );
  }
  if (icon) {
    return <img src={iconImg} className={classNameLogo} alt={alt} />;
  }
  return align === "left" ? (
    <img src={logoImgLeft} className={classNameLogo} alt={alt} />
  ) : (
    <img src={logoImg} className={classNameLogo} alt={alt} />
  );
};

export default Logo;
