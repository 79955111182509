import React from "react";
import { getCurrencies } from "../../../../data/model/wallet";
import useSWR from "swr";
import { useIntl } from "react-intl";
import Button from "../../../Button";
import { SelectBox, TextField } from "../../../Formik";
import { useWallet } from "../../../Provide/WalletContext";
import cx from "classnames";
import selectors from "../../../Selectors/selectors.json";

const type = {
  tel: TextField,
};
const DynamicFields = ({
  setAmountValue,
  setFieldValue,
  setFieldError,
  paymentPlan,
  values,
  method,
  initialRequiredInputFields = null,
}) => {
  const intl = useIntl();
  const { currency, totalWithdraw } = useWallet();

  const { data: currencies } = useSWR("getCurrencies", getCurrencies);
  if (!paymentPlan) {
    return null;
  }
  let currentWallet = currencies?.filter((cur) => cur?.code === currency);

  const messageType = (type) => {
    console.log("this is the type being passed", type);
    switch (type) {
      case "protocol":
        return intl.formatMessage({
          defaultMessage: "Protocol",
          description: "Label",
        });
      case "credit_card_number":
        return intl.formatMessage({
          defaultMessage: "Credit Card number",
          description: "Label",
        });
      case "encCreditcardNumber":
        return intl.formatMessage({
          defaultMessage: "Card number",
          description: "Label",
        });
      case "card_number":
        return intl.formatMessage({
          defaultMessage: "Card number",
          description: "Label",
        });
      case "cardNumber":
        return intl.formatMessage({
          defaultMessage: "Card number",
          description: "Label",
        });
      case "card_holder":
        return intl.formatMessage({
          defaultMessage: "Card holder",
          description: "Label",
        });
      case "bankAccountName":
        return intl.formatMessage({
          defaultMessage: "Bank account name",
          description: "Label",
        });
      case "bankCardNo":
        return intl.formatMessage({
          defaultMessage: "Bank card number",
          description: "Label",
        });
      case "province":
        return intl.formatMessage({
          defaultMessage: "Province",
          description: "Label",
        });
      case "area":
        return intl.formatMessage({
          defaultMessage: "Area",
          description: "Label",
        });
      case "beneficiaryAddress":
        return intl.formatMessage({
          defaultMessage: "Beneficiary address",
          description: "Label",
        });
      case "bankName":
        return intl.formatMessage({
          defaultMessage: "Bank name",
          description: "Label",
        });
      case "bank_name":
        return intl.formatMessage({
          defaultMessage: "Bank name",
          description: "Label",
        });
      case "bank_address":
        return intl.formatMessage({
          defaultMessage: "Bank address",
          description: "Label",
        });
      case "account_owner_name":
        return intl.formatMessage({
          defaultMessage: "Account owner name",
          description: "Label",
        });
      case "branchAddress":
        return intl.formatMessage({
          defaultMessage: "Branch address",
          description: "Label",
        });
      case "secureId":
        return intl.formatMessage({
          defaultMessage: "Secure ID",
          description: "Label",
        });
      case "cardHolder":
        return intl.formatMessage({
          defaultMessage: "Card holder",
          description: "Label",
        });
      case "countryCode":
        return intl.formatMessage({
          defaultMessage: "Country code (2 chars)",
          description: "Label",
        });
      case "cvc":
        return intl.formatMessage({
          defaultMessage: "Card security code",
          description: "Label",
        });
      case "card_csc":
        return intl.formatMessage({
          defaultMessage: "Security code",
          description: "Label",
        });
      case "destinationAccount":
        return intl.formatMessage({
          defaultMessage: "Destination Account",
          description: "Label",
        });
      case "beneficiaryName":
        return intl.formatMessage({
          defaultMessage: "Beneficiary Name",
          description: "Label",
        });
      case "accountNumber":
        return intl.formatMessage({
          defaultMessage: "Account Number",
          description: "Label",
        });
      case "bic":
        return intl.formatMessage({
          defaultMessage: "BIC/SWIFT",
          description: "Label",
        });
      case "swift":
        return intl.formatMessage({
          defaultMessage: "BIC/SWIFT",
          description: "Label",
        });
      case "display_swift":
        return intl.formatMessage({
          defaultMessage: "BIC/SWIFT",
          description: "Label",
        });
      case "accountNumber":
        return intl.formatMessage({
          defaultMessage: "IBAN/Account number",
          description: "Label",
        });
      case "account_identifier":
        return intl.formatMessage({
          defaultMessage: "IBAN/Account number",
          description: "Label",
        });
      case "account_type":
        return intl.formatMessage({
          defaultMessage: "Account type",
          description: "Label",
        });
      case "wallet_address":
        return intl.formatMessage({
          defaultMessage: "Wallet Address",
          description: "Label",
        });
      case "tag":
        return intl.formatMessage({
          defaultMessage: "Tag",
          description: "Label",
        });
      case "protocol":
        return intl.formatMessage({
          defaultMessage: "Protocol",
          description: "protocol Label",
        });
      case "address":
        return intl.formatMessage({
          defaultMessage: "Address",
          description: "Label",
        });
      case "seamless_expiry_date":
        return intl.formatMessage({
          defaultMessage: "Expiry date",
          description: "Label",
        });
      case "tokenize":
        return intl.formatMessage({
          defaultMessage: "Save account for future payments",
          description: "Label",
        });
      default:
        return type;
    }
  };

  console.log("withdrawal payment plan", paymentPlan);
  const getField = (item) => {
    const Input = type[item.type] ?? TextField;
    switch (item.type) {
      case "banner":
      case "brand_description":
        return <div dangerouslySetInnerHTML={{ __html: item?.htmlContent }} />;
      case "select":
        const options = item.options.map((option) => {
          return {
            text: option?.label,
            name: option?.value,
          };
        });
        console.log("values withdraw", options);
        return (
          <div className="w-full mt-8">
            <SelectBox
              hasLabel
              initialValue={options[0]}
              name={`fields[${item.fieldName}]`}
              label={messageType(item.fieldName)}
              options={options}
            />
          </div>
        );
    }

    switch (item.fieldName) {
      case "cardHolder":
        return (
          <>
            <TextField
              className="mt-4"
              type={item.type}
              name={`fields[${item.fieldName}]`}
              label={messageType(item.fieldName)}
              validate={(value) => validate(value, item)}
              value={item.proposedValue}
            />
          </>
        );
      case "encCreditcardNumber":
        return (
          <div className="mt-4">
            {/* <span className="block mb-1 ml-3 text-xs text-gray">
              {intl.formatMessage({
                defaultMessage: 'Expiry date',
                description: 'Label',
              })}
            </span> */}
            <div className="flex flex-row">
              {/* <Input type="text" name="fields[expiry_date]" label="expiry_date" className="w-1/2 mr-4" /> */}
              {/* {' '} */}
              <TextField
                type="text"
                className="w-full"
                name={`fields[${item.fieldName}]`}
                label={messageType(item.fieldName)}
                value={item.proposedValue}
                // validate={(value) => validate(value, item)}
              />
            </div>
          </div>
        );
      case "expiry_date":
        return (
          <div className="mt-4">
            <div className="flex flex-row w-full">
              <TextField
                type="text"
                name="fields[expiryMonth]"
                label={intl.formatMessage({
                  defaultMessage: "Expiry month",
                  description: "Label",
                })}
                className="w-1/2 mr-4"
                placeholder="MM"
                // validate={(value) => validate(value, item)}
              />
              <TextField
                type="text"
                className="w-1/2"
                name="fields[expiryYear]"
                label={intl.formatMessage({
                  defaultMessage: "Expiry year",
                  description: "Label",
                })}
                placeholder="YYYY"
                // validate={(value) => validate(value, item)}
              />
            </div>
          </div>
        );
      case "encCvv":
        return (
          <div className="w-full mt-4 mb-2">
            {/* <div style={{marginTop:`-4.5rem`, marginLeft:`13.5rem`}}> */}
            {/* <Input type="text" name="fields[expiry_date]" label="expiry_date" className="w-1/2 mr-4" /> */}
            {/* {' '} */}
            <TextField
              type="text"
              name={`fields[${item.fieldName}]`}
              label={intl.formatMessage({
                defaultMessage: "Security code",
                description: "Label",
              })}
              placeholder={intl.formatMessage({
                defaultMessage: "Cvv",
                description: "Placeholder",
              })}
              className="w-full"
              hint={intl.formatMessage({
                defaultMessage: "Three digits from the back",
                description: "Label",
              })}
            />
            {/* </div> */}
          </div>
        );
      case "protocol":
        const options = item?.protocols?.map((option) => {
          return {
            text: option,
            name: option,
          };
        });
        return (
          <div>
            <SelectBox
              hasLabel
              initialValue={options[0]}
              name={`fields[${item.fieldName}]`}
              label={messageType(item.fieldName)}
              options={options}
            />
            {values?.fields?.protocol?.name ? (
              <div>
                {values && values?.fields?.protocol?.name && (
                  <div>
                    {item &&
                      item?.bannersByProtocol &&
                      item?.bannersByProtocol[values.fields.protocol.name] &&
                      item?.bannersByProtocol[values.fields.protocol.name].map(
                        (bannerItem, index) => (
                          <div key={`bannerItem__${index}`} className="mt-4">
                            <div className="text-lg font-bold">
                              {bannerItem?.identifier}
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: bannerItem?.htmlContent,
                              }}
                              className="mt-4"
                            />
                          </div>
                        )
                      )}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        );
      default:
        if (item.type === "select") {
        } else {
          return (
            <>
              <TextField
                className="mt-4"
                type={item.type}
                name={`fields[${item.fieldName}]`}
                label={messageType(item.fieldName)}
                hint={item.hint}
                // validate={(value) => validate(value, item)}
              />
            </>
          );
        }
    }
  };

  // const minMax = (e, field, meta, helpers) => {

  //   if (field.value < paymentPlan?.method?.cashout?.min) {
  //     return setFieldError('amount', intl.formatMessage({
  //       defaultMessage: `Please enter an amount higher then {amount}`,
  //       description: 'Error',
  //     }, {amount: (intl.formatNumber(0, {
  //       style: 'currency',
  //       currency,
  //       minimumFractionDigits: 0,
  //       maximumFractionDigits: 0 }).replace(/\d/g, '')
  //       .trim(), Number.parseFloat(paymentPlan?.method?.cashout?.min))
  //     }));

  //   } else if (field.value > totalWithdraw) {
  //     return setFieldError('amount', intl.formatMessage({
  //       defaultMessage: `You don't have enough funds on your account`,
  //       description: 'Error',
  //     }));

  //   } else if (field.value > paymentPlan?.method?.cashout?.max) {

  //     return setFieldError('amount', intl.formatMessage({
  //       defaultMessage: `Please enter an amount lower then {amount}`,
  //       description: 'Error',
  //     }, {amount: (intl.formatNumber(0, {
  //       style: 'currency',
  //       currency,
  //       minimumFractionDigits: 0,
  //       maximumFractionDigits: 0 }).replace(/\d/g, '')
  //       .trim(), Number.parseFloat(paymentPlan?.method?.cashout?.max))
  //       }));
  //   }
  //   // else if (value > (totalCasinoWallet - wallet.lockedAmount)) {
  //   //   return this.props.intl.formatMessage(messages.lockedAmount);
  //   // }
  // }

  const validate = (value, item) => {
    let errorMessage;
    setAmountValue(value);
    if (typeof value === "string") {
      value = parseFloat(value); //.toFixed(2); // parseInt(value);
      // value = value.replace(/\D/g, '');
      // value /= 100;
      // value = parseFloat(value).toFixed(2); //parseInt(value);
    }

    //get user amount and check if value is between
    if (value > totalWithdraw) {
      errorMessage = intl.formatMessage(
        {
          defaultMessage: `This maximum amount you can withdraw is {amount}`,
          description: "Error",
        },
        {
          amount:
            (intl
              .formatNumber(0, {
                style: "currency",
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
              .replace(/\d/g, "")
              .trim(),
            Number.parseFloat(totalWithdraw)),
        }
      );
    }

    if (
      value > parseFloat(method?.termsOfService?.restrictions?.maxAmountValue)
    ) {
      errorMessage = `Please enter an amount lower than ${
        (intl
          .formatNumber(0, {
            style: "currency",
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
          .replace(/\d/g, "")
          .trim(),
        Number.parseFloat(method?.termsOfService?.restrictions?.maxAmountValue))
      }`;
    }

    if (
      value < parseFloat(method?.termsOfService?.restrictions?.minAmountValue)
    ) {
      errorMessage = `Please enter an amount higher than ${
        (intl
          .formatNumber(0, {
            style: "currency",
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
          .replace(/\d/g, "")
          .trim(),
        Number.parseFloat(method?.termsOfService?.restrictions?.minAmountValue))
      }`;
    }

    return errorMessage;
  };

  const getAmountField = (item) => {
    return (
      <>
        {item?.options?.length > 0 && (
          <div className="mt-4">
            <span className="text-lg">
              {intl.formatMessage({
                defaultMessage: "Choose how much to deposit",
                description: "Label",
              })}
            </span>
            <div className="w-full mb-4 overflow-auto">
              <div
                className="flex flex-row mt-4 mb-2 "
                style={{ width: `fit-content` }}
              >
                {selectors.currencies[currency].map((option, index) => (
                  <Button.Action
                    trackName="Withdrawal Selector"
                    trackParams={{ component: "DynamicFields" }}
                    color="darkgray"
                    className={cx(
                      "justify-center",
                      {
                        "mr-0":
                          index === selectors.currencies[currency].length - 1,
                      },
                      {
                        "mr-4":
                          index !== selectors.currencies[currency].length - 1,
                      }
                    )}
                    onClick={() =>
                      setFieldValue("fields[amount]", String(option), true)
                    }
                  >
                    {intl.formatNumber(option, {
                      style: "currency",
                      currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </Button.Action>
                ))}
              </div>
            </div>
            <span className="mt-4 text-base">
              {intl.formatMessage({
                defaultMessage: "Or, deposit a different amount",
                description: "Label",
              })}
            </span>
          </div>
        )}
        <div className="flex flex-col items-stretch w-full my-4">
          <TextField
            name={`fields[${item.fieldName}]`}
            placeholder={intl.formatMessage({
              defaultMessage: `Amount`,
              description: "Label of inputfield",
            })}
            type="tel"
            validate={(value) => validate(value, item)}
            className="w-full m-0 appearance-none"
            hint={`${intl.formatNumber(
              Number(method?.termsOfService?.restrictions?.minAmountValue),
              {
                style: "currency",
                currency,
                minimumFractionDigits:
                  currentWallet?.[0]?.subunits_to_unit?.toString()?.length -
                    1 ?? 2,
                maximumFractionDigits:
                  currentWallet?.[0]?.subunits_to_unit?.toString()?.length -
                    1 ?? 2,
              }
            )}
                   - 
                  ${intl.formatNumber(
                    Number(
                      method?.termsOfService?.restrictions?.maxAmountValue
                    ),
                    {
                      style: "currency",
                      currency,
                      minimumFractionDigits:
                        currentWallet?.[0]?.subunits_to_unit?.toString()
                          ?.length - 1 ?? 2,
                      maximumFractionDigits:
                        currentWallet?.[0]?.subunits_to_unit?.toString()
                          ?.length - 1 ?? 2,
                    }
                  )}
                  `}
            label={intl.formatMessage(
              {
                defaultMessage: `Amount ({amount})`,
                description: "Label",
              },
              {
                amount: intl
                  .formatNumber(0, {
                    style: "currency",
                    currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                  .replace(/\d/g, "")
                  .trim(),
              }
            )}
          />
        </div>
      </>
    );
  };

  return (
    <>
      {paymentPlan?.amountField !== null &&
        getAmountField(paymentPlan?.amountField)}

      {initialRequiredInputFields?.map((item, i) => {
        console.log("item test", paymentPlan?.methodFields);
        return <div className={cx("mt-0")}>{getField(item)}</div>;
      })}
    </>
  );
};

export default DynamicFields;
