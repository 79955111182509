import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import _ from "lodash";
import routes from "../../routes/routes";
import history from "../../core/history";
import { localeUrl } from "../../core/url";
import { useTracking } from "../../hooks/useTracking";
import DepositModal from "@components/DepositModal";
import { useModal } from "@components/Modal/ModalContext";

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

const handleClick = (
  event,
  target,
  href,
  to,
  targetHash,
  locale,
  params,
  onClick,
  openRegisterModal,
  openDepositModal,
  ignoreTo,
  transition,
  trackName = "Navigate",
  trackParams
) => {
  const { click } = useTracking();

  if (onClick) {
    onClick(event);
  }

  // if (trackName) {
  //   page(trackName, trackParams);
  // }

  if (trackName) {
    click(trackName, { ...trackParams, to });
  }

  if (to) {
    switch (to) {
      case "deposit":
        openDepositModal();
        break;
      case "register":
        openRegisterModal();
        break;

      default:
        if (!routes.hasOwnProperty(to)) {
          return;
        }

        if (!_.isUndefined(to) && target) {
          const win = window.open(
            localeUrl(to, locale, {
              ...params,
              locale,
              path: routes?.[to]?.[locale],
            }),
            target
          );
          return win.focus();
        }
        if (!_.isUndefined(to) && !ignoreTo) {
          history.push(
            localeUrl(to, locale, {
              ...params,
              locale,
              path: routes?.[to]?.[locale],
            }) + (!_.isUndefined(targetHash) ? `#${targetHash}` : ""),
            { noTransition: !transition }
          );
        }
        break;
    }
  }

  if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
    return;
  }

  if (event.defaultPrevented === true) {
  }
};

const getHref = (href, to, targetHash, locale, params) => {
  if (href) return href;
  if (!_.isUndefined(to) && routes[to]) {
    return (
      localeUrl(to, locale, {
        ...params,
        locale,
        path: routes?.[to]?.[locale],
      }) + (!_.isUndefined(targetHash) ? `#${targetHash}` : "")
    );
  }
  return "";
};

const Link = ({
  children,
  href,
  className,
  target,
  to,
  targetHash,
  params,
  "data-id": dataId,
  onClick,
  ignoreTo = false,
  transition = true,
  ref,
  style,
  trackName,
  trackParams,
  id,
}) => {
  const { click } = useTracking();
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const openRegisterModal = useCallback(
    () => console.log("@toto, open registration"),
    [dispatch]
  );

  const openDepositModal = useCallback(
    () => showModal(DepositModal),
    [dispatch]
  );

  const onClickEvent = useCallback(
    (event) => {
      handleClick(
        event,
        target,
        href,
        to,
        targetHash,
        locale,
        params,
        onClick,
        openRegisterModal,
        openDepositModal,
        ignoreTo,
        transition,
        trackName,
        trackParams
      );
      if (!href) {
        event.preventDefault();
      }
    },
    [
      target,
      href,
      to,
      targetHash,
      locale,
      params,
      onClick,
      ignoreTo,
      transition,
    ]
  );

  const url = useMemo(
    () => getHref(href, to, targetHash, locale, params),
    [href, to, targetHash, locale, params]
  );

  return (
    <a
      href={url}
      onClick={onClickEvent}
      className={className}
      target={target}
      data-id={dataId}
      ref={ref}
      style={style}
      id={id}
    >
      {children}
    </a>
  );
};

export default Link;
