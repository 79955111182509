import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import Button from '@components/Button';
import { rollbackWithdraw } from '@data/model/withdraw';
import { useRouter } from '@components/Provide/RouterContext';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

const messages = defineMessages({
  amount: {
    id: 'label.amount',
    defaultMessage: 'Amount',
    description: 'A label for the amount field',
  },
  date: {
    id: 'label.requestDate',
    defaultMessage: 'Request date',
    description: 'A label for the request date field',
  },
  labelTransactionId: {
    id: 'label.transactionId',
    defaultMessage: 'Transaction ID',
    description: 'Transaction ID label',
  },
});

const AllHistory = ({
  transactions, transactionsGambling, endTime, startTime, period, mutate, currencies, setMenuItem,
}) => {
  const [transactionsCombined, setTransactionsCombined] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const intl = useIntl();
  const itemsPerLoad = 10;
  const [itemsPeriod, setItemsPeriod] = useState(filteredItemPeriod?.sort((a, b) => -a.created_at.localeCompare(b.created_at)));
  const router = useRouter();
  const pendingTransactions = transactions?.filter((item) => item.action === 'cashout' && !item.finished_at)

  useEffect(() => {
    if (typeof (transactions) !== 'undefined' && typeof (transactionsGambling) !== 'undefined') {
      setTransactionsCombined([].concat(transactions, transactionsGambling, pendingTransactions));
      setItemsPeriod(filteredItemPeriod?.sort((a, b) => -a.created_at.localeCompare(b.created_at))?.slice(0, itemsPerLoad));
      setIsLoading(false);
    }
  }, [transactions, transactionsGambling, isLoading, period, filteredItemPeriod]);

  const loadMore = () => {
    setItemsPeriod(filteredItemPeriod?.sort((a, b) => -a.created_at.localeCompare(b.created_at))?.slice(0, itemsPeriod.length + itemsPerLoad));
  };
 
  const getDividedCurrency = (curr) => {
    let wallet = currencies?.filter(cur => cur?.code === curr);
    return wallet[0]?.subunits_to_unit;
  }

  let filteredItemPeriod = [];
  let transactionDate;
  const filterItemsByDuration = (
    (typeof (transactions) !== 'undefined' && typeof (transactionsGambling) !== 'undefined'  && typeof (pendingTransactions) !== 'undefined') && 
      transactionsCombined?.map((item) => {
        transactionDate = new Date(item?.created_at ? item?.created_at?.slice(0, 10) : item?.finished_at?.slice(0, 10));
        if (startTime < transactionDate) {
          filteredItemPeriod.push(item);
        }
      })
  );

  const calculateDecimalPlaces = (curr) => {
    let wallet = currencies?.filter(cur => cur?.code === curr);
    return ((wallet?.[0]?.subunits_to_unit?.toString()?.length - 1) ?? 2);
  }
  return (
    <div className="space-y-4 divide-y divide-brand-400 divide-solid px-2.5">
      {itemsPeriod
        ?.sort((a, b) => -a.created_at.localeCompare(b.created_at))
        ?.map((item, key) => (
          <div className="w-full">
            <div className="flex flex-row items-center justify-between ">
              <span className="text-xs text-brand-400 mt-4">{format(parseISO(item.created_at), 'dd MMMM yyyy, HH:mm:ss')}</span>
            </div>
            {item.action === 'deposit' || item.action === 'cashout' ? (
              <>
                <div className="flex flex-row items-center justify-between">
                  <span className="text-white text-lg font-book">{item.payment_system}</span>
                  <span className={cx('text-lg font-book', { 'text-green': item.success && !item.recallable, 'text-red': !item.success && !item.recallable })}>
                    {intl.formatNumber((item.amount_cents / getDividedCurrency(item.currency)), {
                      style: 'currency',
                      currency: item.currency,
                      minimumFractionDigits: calculateDecimalPlaces(item.currency),
                      maximumFractionDigits: calculateDecimalPlaces(item.currency),
                    })
                    }
                  </span>
                </div>
                {item.recallable && (
                  <Button.Action 
                    trackName="Cancel withdrawal"
                    trackParams={{ component: 'AllHistory' }} onClick={() => { rollbackWithdraw(item.id).then(() => {mutate(), setMenuItem('Root')}); }} arrow="right" className="w-full mt-2">
                    {intl.formatMessage({
                      defaultMessage: 'Cancel withdrawal',
                      description: 'Label',
                    })}
                  </Button.Action>
                )}
              </>
            ) : (
              <>
                <div className="flex flex-row items-center justify-between">
                  <span className="text-white text-lg font-book">{item.title}</span>
                  <span className={cx('text-lg font-book', { 'text-green': item.total_wins > item.total_bets, 'text-red': item.total_wins < item.total_bets })}>
                    {intl.formatNumber(((item.total_wins - item.total_bets) / getDividedCurrency(item.currency)), {
                      style: 'currency',
                      currency: item.currency,
                      minimumFractionDigits: calculateDecimalPlaces(item.currency),
                      maximumFractionDigits: calculateDecimalPlaces(item.currency),
                    })
                    }
                  </span>
                </div>
              </>
            )}
          </div>
        ))}

      {itemsPeriod?.length === 0 && (
      <div className="mt-2">
        {' '}
        {intl.formatMessage({
          defaultMessage: 'No transactions found within the last {period} days.',
          description: 'Label',
        }, { period })}
      </div>
      )}
      {filteredItemPeriod?.length > itemsPeriod?.length && (
        <Button.Action 
          trackName="Load more"
          trackParams={{ component: 'AllHistory' }} type="button" onClick={loadMore}>
          {intl.formatMessage({
            defaultMessage: 'Load more',
            description: 'Label',
          })}
        </Button.Action>
      )}
    </div>
  );
};

export default AllHistory;