import React, { useMemo } from "react";
import Icon from "../../../../Icon/Icon";
import cx from "classnames";
import { useIntl } from "react-intl";
import Button from "../../../../Button";
import { useAuth } from "../../../../Provide/AuthContext";
import { getSessionInfo } from "../../../../../data/model/session";
import { player_fields } from "../../../../../data/model/account";
import useSWR from "swr";
import { openNavigation } from "../../../../../actions/navigation";
import { useDispatch } from "react-redux";

const requiredFields = [
  "first_name",
  "last_name",
  "date_of_birth",
  "city",
  "address",
  "postal_code",
];

const CompleteProfile = () => {
  const intl = useIntl();

  const { isAuthenticated } = useAuth();
  const open = (section = "Root") =>
    dispatch(openNavigation(section, { fromModal: true }));

  const dispatch = useDispatch();

  const { data: userProfile = {} } = useSWR(
    isAuthenticated ? "getSessionInfo" : null,
    getSessionInfo
  );

  const { data: profileFields } = useSWR(
    isAuthenticated ? "player_fields" : null,
    player_fields
  );

  const isProfileUpdateNeeded = useMemo(() => {
    if (userProfile) {
      for (const field of requiredFields) {
        if (!userProfile[field]) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  }, [userProfile]);

  return (
    <div className="border-1 border-brand-600 py-3.5 px-3 rounded flex bg-[#1e2229]">
      <div
        className={cx("flex w-full gap-3", {
          "items-center": !isProfileUpdateNeeded,
        })}
      >
        <div
          className={cx(
            "flex items-center justify-center w-full h-4 rounded-full max-w-4 ring-2 ring-opacity-50",
            {
              "bg-gold ring-gold": isProfileUpdateNeeded,
              "bg-[#30d158] ring-[#30d158]": !isProfileUpdateNeeded,
            }
          )}
        >
          <Icon icon="document" className={"w-2 h-2 text-brand"} />
        </div>
        <div className="w-full">
          <div
            className={cx("flex items-center justify-between", {
              "mb-2.5": isProfileUpdateNeeded,
            })}
          >
            <h5 className="text-base font-normal text-white">
              {intl.formatMessage({
                defaultMessage: "Complete your profile",
                description: "label",
              })}
            </h5>
            <span
              className={cx(
                `capitalize px-2.5 py-1 rounded border text-10 font-bold`,
                {
                  "bg-[#0091ff] border-[#0091ff] text-[#102739]":
                    isProfileUpdateNeeded === true,
                  "bg-[#30d158] border-[#30d158] text-[#17311e]":
                    isProfileUpdateNeeded === false,
                  // "bg-[#d63232] border-[#d63232] text-[#321818]":
                  //   "status" === "declined",
                }
              )}
            >
              {isProfileUpdateNeeded
                ? intl.formatMessage({
                    defaultMessage: "Requested",
                    description: "label",
                  })
                : intl.formatMessage({
                    defaultMessage: "Done",
                    description: "label",
                  })}
            </span>
          </div>
          {isProfileUpdateNeeded && (
            <>
              <p className="text-sm font-normal text-[#979797] mb-5">
                {intl.formatMessage({
                  defaultMessage: "Full name, DOB, Address etc",
                  description: "Label",
                })}
              </p>
              <Button.Action
                trackName="Action"
                arrow={"right"}
                onClick={() => {
                  open("Profile");
                }}
                className={
                  "p-0 text-gold bg-transparent h-auto px-0 text-sm font-normal leading-tight underline"
                }
                arrowClassName={"!ml-1.5 !w-3 !h-3"}
              >
                {intl.formatMessage({
                  defaultMessage: "Personal Details",
                  description: "Label",
                })}
              </Button.Action>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompleteProfile;
