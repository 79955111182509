import React, { useEffect, useState } from "react";
import countryIsoList from "i18n-iso-countries";

import Cookies from "universal-cookie";
import { useTracking } from "@hooks/useTracking";
import useSWR from "swr";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { openNavigation } from "@actions/navigation";
import { useDispatch, useSelector } from "react-redux";
import { logUserIn } from "../../data/model/account";

import RegForm from "./RegForm";
//
// import NemIDStep from './FormSteps/NemID';
//
// import ConsentStep from './FormSteps/Consent';
// import ConsentValidation from './FormSteps/Consent/consent.validation';
import WizardStep from "./WizardStep";
import { useAuth } from "../Provide/AuthContext";
import { getCmsSessionID, getIpInfo } from "../../data/model/session";
import history from "../../core/history";
import { localeUrl } from "../../core/url";
import { postCall } from "../../core/softswiss";
import getCurrenciesQuery from "../LandingOffer/get-currencies.graphql";
import getCountriesQuery from "../LandingOffer/get-countries.graphql";
// import { registerAffiliate } from "../../data/model/affiliate";
import feedback from "./feedback.json";
import { useWallet } from "../Provide/WalletContext";
import DynamicFields from "../ProfileDynamicFields/DynamicFields";
import { ANALYTICS_REGISTER_URL } from "../../constants/Analytics";
import { useScript } from "../UseScript";

countryIsoList.registerLocale(require("i18n-iso-countries/langs/en.json"));
countryIsoList.registerLocale(require("i18n-iso-countries/langs/de.json"));
countryIsoList.registerLocale(require("i18n-iso-countries/langs/et.json"));
countryIsoList.registerLocale(require("i18n-iso-countries/langs/ar.json"));

const Registration = ({ hideModal, params, setMenuItem }) => {
  const { track } = useTracking();
  const dispatch = useDispatch();
  const stag = useSelector((state) => state.affiliate.stag);
  const [stepNumber, setStepNumber] = useState(0);
  const intl = useIntl();
  const [lang] = intl.locale.split("-");
  const { setAuthenticationStatus } = useAuth();
  const { data: session } = useSWR("isActiveUserSession", getCmsSessionID);
  const { data: ipInfo } = useSWR("getIpInfo", getIpInfo);
  const { error: currencyError, data: currencies } =
    useQuery(getCurrenciesQuery);
  const { error: countryError, data: allowedCountries } =
    useQuery(getCountriesQuery);

  const countries = {
    countries: allowedCountries?.countries?.map((item) => ({
      ...item,
      name: countryIsoList.getName(item.code, lang),
    })),
  };
  const countryInfo = countries?.countries?.find(
    (item) => item?.code === ipInfo?.country_code
  );
  const currencyInfo = currencies?.currencies?.find(
    (item) => item?.code === countryInfo?.currency
  );

  const { currency } = useWallet();

  const localeInfo = {
    ...countryInfo,
    ...currencyInfo,
    currency:
      countryInfo?.code === "LU"
        ? "EUR"
        : countryInfo?.code === "AE"
        ? "USD"
        : currencyInfo?.code,
    code: countryInfo?.code,
  };

  const submit = async (values, formikBag) => {
    console.log("Registration submit", values);
    track("Registration submit", values);
    const { setStatus, setFieldError, setFieldTouched, setSubmitting } =
      formikBag;

    const cookies = new Cookies();
    const registerParams = {
      user: {
        email: values?.email,
        password: values?.password,
        password_confirmation: values?.confirmPassword,
        profile_attributes: {
          first_name: values?.firstname,
          last_name: values?.surname,
          address: values?.address1,
          age_acceptance: true,
          terms_acceptance: true,
          receive_sms_promos: values?.marketing,
          receive_promos: values?.marketing,
          city: values?.city,
          mobile_phone: values?.mobile_phone,
          country: values?.country?.name,
          date_of_birth: values?.birthDate,
          gender: values?.gender,
          currency: values?.currency?.name,
          postal_code: values?.postalCode,
        },
      },
    };

    console.log("registerParams", registerParams);
    return (
      postCall("/api/users", registerParams)
        // .then(() =>
        //   postCall("/api/auth_providers/update_details", {
        //     user: { profile_attributes: { terms_acceptance: true } },
        //   })
        // )
        .then(() => logUserIn(values.email, values.password))
        .then(() => {
          // registerAffiliate(stag);
          cookies.set("registred", true);

          useScript(ANALYTICS_REGISTER_URL);
          track("Registration success");
          dispatch(openNavigation("Registration", { status: "success" }));
          setAuthenticationStatus(true);
          // setStatus('success');
          setSubmitting(false);
          // window.setTimeout(() => {
          //
          // }, 1000);

          // history.push(localeUrl('home', 'en-row'));
        })
        .catch((res) => {
          const { password, ...registerParamsAnalytics } = registerParams.user;
          track("Registration error", { error: res, registerParamsAnalytics });
          console.log("Registration Error Last", res);
          setSubmitting(false);
          // feedback
          const messages = [];
          if (res?.reason) {
            messages.push(feedback?.restriction?.[res?.reason] ?? res?.reason);
          }
          if (res?.errors) {
            for (const [field, value] of Object.entries(res.errors)) {
              if (typeof value !== "object") {
                messages.push(`${feedback?.labels?.[field]} ${value}`);
              } else {
                for (const [type, error] of Object.entries(value)) {
                  if (typeof error === "object") {
                    for (const [typesub, suberror] of Object.entries(error)) {
                      messages.push(`${feedback?.labels?.[type]} ${suberror}`);
                    }
                  } else {
                    messages.push(`${feedback?.labels?.[field]} ${error}`);
                  }
                }
              }
            }
          }

          console.log("formState", messages);
          setFieldError("formState", messages);

          // const res = {
          //   errors: {
          //     email: {
          //       invalid: 'is invalid',
          //     },
          //     password_confirmation: {
          //       confirmation: "doesn't match Password",
          //     },
          //     profile: {
          //       first_name: {
          //         blank: "can't be blank",
          //       },
          //     },
          //   },
          // };
          // const messages = [];
          // if (res?.reason) {
          //   messages.push(res?.reason);
          // }
          // for (const [field, value] of Object.entries(res.errors)) {
          //   for (const [type, error] of Object.entries(value)) {
          //     messages.push(value);
          //   }
          // }

          setSubmitting(false);
        })
    );
  };

  useEffect(() => {
    track("Registration loaded");
    history.push(localeUrl("register", intl.locale), { noTransition: true });

    return () =>
      history.push(localeUrl("home", intl.locale), { noTransition: true });
  }, []);

  console.log("localeInfo registration", localeInfo);
  if (!currencies || !countries || !ipInfo) {
    return null;
  }

  console.log("ipInfo", ipInfo);
  const initialValues = {
    email: params?.email ?? "",
    currency: params?.currency
      ? params?.currency
      : !Object.keys(localeInfo).length > 0
      ? { name: "", text: "Please select" }
      : {
          name:
            localeInfo?.currency === undefined
              ? currency
              : localeInfo?.currency,
          text: intl.formatMessage(
            {
              defaultMessage: "{curr} ({amount})",
              description: "Label",
            },
            {
              curr:
                localeInfo?.currency === undefined
                  ? currency
                  : localeInfo?.currency,
              amount: intl
                .formatNumber(0, {
                  style: "currency",
                  currency:
                    localeInfo?.currency === undefined
                      ? currency
                      : localeInfo?.currency,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
                .replace(/\d/g, "")
                .trim(),
            }
          ),
        },
    country:
      !Object.keys(localeInfo).length > 0
        ? { name: "", text: "Please select" }
        : { name: localeInfo?.code, text: localeInfo?.name },
    language: "en",
    password: params?.password ?? "",
    // confirmPassword: '',
    // username: '',
    mobile_phone: "",
    // title: '',
    gender: "",
    firstname: params?.firstname ?? "",
    surname: params?.surname ?? "",
    address1: "",
    city: "",
    postalCode: "",
    // region: '',
    intendedVolume: "",
    personalID: "",
    referrerID: "",
    birthDate: "",
    birthdateDay: "",
    birthdateMonth: "",
    birthdateYear: "",
    termsandcondition: false,
    marketing: false,
    "marketing-yes": false,
    marketingOption: false,
    "marketing-no": false,
    affiliateMarker: "",
    limit: { name: "daily", text: "Limit per day" },
    limitAmount: "",
    formState: "",
  };
  return (
    <RegForm
      enableReinitialize
      initialValues={initialValues}
      initialTouched={{
        formState: true,
        marketing: true,
        termsandcondition: true,
        mobile_phone_error: true,
      }}
      onSubmit={submit}
      setStepNumber={setStepNumber}
      stepNumber={stepNumber}
      hideModal={hideModal}
    >
      {/* <WizardStep validationSchema={AccountValidation(intl)}> */}
      {/*  <Success /> */}
      {/* </WizardStep> */}

      <WizardStep>
        <DynamicFields
          profileFieldsType="registration"
          countries={countries}
          currencies={currencies}
          initialValues={initialValues}
        />
        {/* <PersonalStep initialValues={initialValues} countries={countries} currencies={currencies}/> */}
      </WizardStep>
      {/* <WizardStep validationSchema={AccountValidation(intl)}>
        <SsrCompaetibleSuspense fallback={<div>loading</div>}>
          <Account initialValues={initialValues} localeInfo={localeInfo} ipInfo={ipInfo} countries={countries} currencies={currencies} />
        </SsrCompatibleSuspense>
      </WizardStep> */}
      {/* <WizardStep validationSchema={ConsentValidation(intl)}> */}
      {/*  <SsrCompatibleSuspense fallback={<div>loading</div>}> */}
      {/*    <ConsentStep /> */}
      {/*  </SsrCompatibleSuspense> */}
      {/* </WizardStep> */}

      {/* <WizardStep> */}
      {/*  <LimitsStep /> */}
      {/* </WizardStep> */}
    </RegForm>
  );
};

export default Registration;
