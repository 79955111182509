/**
 * TopBar Component
 *
 * This component is responsible for The top menu like hamburger, logo & CTA's
 *
 * @category   TopBar
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */
import cx from "classnames";
import Button from "../Button";

const Default = ({
  children,
  className,
  onClick = undefined,
  isSubmitting = false,
  submittingLabel
}) => {
  return (
    <Button
      href=""
      onClick={onClick}
      className={cx(`text-white bg-orange font-bold`, className)}
      isSubmitting={isSubmitting}
      submittingLabel={submittingLabel}
    >
      {children}
    </Button>
  );
};

export default Default;
