import React, { useEffect, useMemo, useState } from "react";
import Icon from "../../../../Icon/Icon";
import cx from "classnames";
import { useIntl } from "react-intl";
import { DOC_TYPE, DOCUMENT_STATUS } from "../../../../../data/constant";
import useSWR from "swr";
import {
  getDocuments,
  saveDocument,
} from "../../../../../data/model/documents";
import { useQuery } from "@apollo/client";
import GET_PAYMENT_IDENTITY_CONFIG from "./get-payment-identity-config.graphql";
import Button from "../../../../Button";
import DocumentInformationRulesList from "../DocumentInformationRulesList/DocumentInformationRulesList";
import DocumentCard from "../DocumentCard/DocumentCard";
import DocumentUploadInput from "../DocumentUploadInput/DocumentUploadInput";

const ProofOfPayment = () => {
  const intl = useIntl();

  const [documents, setDocuments] = useState([]);
  const [attachment, setAttachment] = useState();
  const [activeTab, setActiveTab] = useState();
  const [progress, setProgress] = useState(0);
  const [showProofOfPaymentRulesList, setShowProofOfPaymentRulesList] =
    useState(false);
  const [newUploadName, setNeWUploadName] = useState();

  const {
    loading: isLoadingPaymentIdentity,
    error: paymentIdentityError,
    data: paymentIdentity,
  } = useQuery(GET_PAYMENT_IDENTITY_CONFIG, {
    variables: {
      locale: intl.locale,
    },
  });

  const paymentTypeTabs = useMemo(() => {
    if (paymentIdentity) {
      const options = paymentIdentity?.getPaymentIdentityConfig?.map(
        (item) => item?.paymentName
      );
      setActiveTab(options[0]);
      return options;
    } else {
      return [];
    }
  }, [paymentIdentity]);

  const paymentDescriptionList = [
    intl.formatMessage({
      defaultMessage:
        "We need to verify all payment methods used on the account in the past 6 months.",
      description: "Label",
    }),
    intl.formatMessage({
      defaultMessage:
        "What verification you need to upload depends on the payment methods you have used.",
      description: "Label",
    }),
  ];

  const identityRules = useMemo(() => {
    if (paymentIdentity && activeTab) {
      const options =
        paymentIdentity?.getPaymentIdentityConfig?.find(
          (x) => x.paymentName === activeTab
        )?.rules || [];
      return options.map((item) => item?.value);
    } else {
      return [];
    }
  }, [activeTab, paymentIdentity]);

  const { error, data, mutate } = useSWR("getDocuments", getDocuments);

  const isProofOfIdentityApproved = useMemo(() => {
    if (documents) {
      return !!(
        documents?.length > 0 &&
        documents?.some((doc) => doc.status === DOCUMENT_STATUS.APPROVED)
      );
    } else {
      return false;
    }
  }, [documents]);

  useEffect(() => {
    if (data?.length > 0) {
      setDocuments(
        data?.filter((x) => x.document_type === DOC_TYPE.PAYMENT_METHOD)
      );
    }
  }, [data]);

  const handleSelectFile = (file) => {
    setNeWUploadName(activeTab + " " + file?.name?.toString()?.substr(0, 30));
    setAttachment(file);
  };

  const handleSuccess = () => {
    mutate();
    setNeWUploadName(null);
    setAttachment(null);
  };

  const handleErr = (err) => {
    console.log("err--err", err);
    //   {
    //     "errors": [
    //         "Attachment content type should be image or pdf",
    //         "Attachment file size must be less than 10 MB"
    //     ]
    // }
    setNeWUploadName(null);
    setAttachment(null);
  };

  const handleSubmit = () => {
    saveDocument(attachment, newUploadName, DOC_TYPE.PAYMENT_METHOD)
      .then(() => {
        handleSuccess();
        // track("Document Verification submit success", values);
        // setStatus("success");
        // setSubmitting(false);
      })
      .catch((err) => {
        handleErr(err);
        // track("Document Verification submit error", { error: err });
        // setSubmitting(false);
        // setFieldError("formState", err.desc);
        // setStatus("error");
      });
  };

  useEffect(() => {
    if (newUploadName && attachment) {
      handleSubmit();
    }
  }, [newUploadName, attachment]);

  return (
    <div className="border-1 border-brand-600 py-3.5 px-3 rounded bg-[#1e2229]">
      <div className="flex items-center w-full gap-3 mb-2.5">
        <div
          className={cx(
            "flex items-center justify-center w-full h-4 rounded-full max-w-4 ring-2 ring-opacity-50",
            {
              "bg-gold ring-gold": !isProofOfIdentityApproved,
              "bg-[#30d158] ring-[#30d158]": isProofOfIdentityApproved,
            }
          )}
        >
          <Icon icon="paymentCard" className={"w-2 h-2 text-brand"} />
        </div>
        <div className="flex items-center justify-between w-full">
          <h5 className="text-base font-normal text-white">
            {intl.formatMessage({
              defaultMessage: "Proof of payment",
              description: "label",
            })}
          </h5>
          {!isProofOfIdentityApproved ? (
            <span
              className={cx(
                `capitalize px-2.5 py-1 rounded border text-10 sm:text-[8px] font-bold`,
                {
                  "bg-[#0091ff] border-[#0091ff] text-[#102739]":
                    !isProofOfIdentityApproved,
                  "bg-[#30d158] border-[#30d158] text-[#17311e]":
                    isProofOfIdentityApproved,
                }
              )}
            >
              {isProofOfIdentityApproved
                ? intl?.formatMessage({
                    defaultMessage: "Approved",
                    description: "The label for identity Approved",
                  })
                : intl?.formatMessage({
                    defaultMessage: "Requested",
                    description: "The label for identity Requested",
                  })}
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="pl-7">
        {isProofOfIdentityApproved && (
          <Button.Action
            trackName="Action"
            arrow={showProofOfPaymentRulesList ? "up" : "down"}
            onClick={() =>
              setShowProofOfPaymentRulesList(!showProofOfPaymentRulesList)
            }
            className={cx(
              "p-0 text-gold bg-transparent h-auto px-0 text-sm font-normal leading-tight",
              {
                "mb-2": showProofOfPaymentRulesList,
              }
            )}
            arrowClassName={"!ml-1.5 !h-3 transition-all"}
          >
            {intl.formatMessage({
              defaultMessage: "Show what’s needed and upload more files",
              description: "Label",
            })}
          </Button.Action>
        )}
        <div
          className={cx("transition flex flex-col gap-3.5 overflow-hidden", {
            "h-0": isProofOfIdentityApproved && !showProofOfPaymentRulesList,
            "h-max": showProofOfPaymentRulesList,
          })}
        >
          <div className="text-[#979797]  text-sm font-normal">
            <div className="flex flex-col gap-3 mb-3">
              {paymentDescriptionList.map(
                (_item, index) =>
                  _item && (
                    <p key={index} className="m-0">
                      {_item}
                    </p>
                  )
              )}
            </div>
            <div>
              <div className="flex w-full gap-5 mb-5 overflow-auto">
                {paymentTypeTabs?.map((_tab, index) => (
                  <button
                    onClick={() => setActiveTab(_tab)}
                    className={cx("text-sm text-gold whitespace-nowrap", {
                      "font-bold border-b-1 border-gold": activeTab === _tab,
                      "font-normal": activeTab !== _tab,
                    })}
                    type="button"
                    key={`paymentTypeTabs__${index}`}
                  >
                    {_tab}
                  </button>
                ))}
              </div>
              <DocumentInformationRulesList rulesListData={identityRules} />
            </div>
          </div>

          <DocumentUploadInput
            handleSelectFile={handleSelectFile}
            setProgress={setProgress}
            newUploadName={newUploadName}
            name={DOC_TYPE.PAYMENT_METHOD}
            id={DOC_TYPE.PAYMENT_METHOD}
          />
        </div>

        {documents && documents?.length > 0 ? (
          <div className="flex flex-col gap-3 mt-3.5">
            {newUploadName ? (
              <div className="p-3 border rounded bg-brand-600 border-brrounded-md">
                <div className="flex items-center justify-between mb-1.5 gap-2.5">
                  <div className="flex gap-2.5 items-center">
                    <div className="flex items-center justify-center w-3 h-4 rounded-full">
                      <Icon
                        icon="userDocument"
                        className={"w-3 h-4 text-white"}
                      />
                    </div>
                    <h5 className="text-sm font-normal text-white">
                      {newUploadName}
                    </h5>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3">
                  <div className="w-full rounded-full h-2 bg-[#1e2229] relative overflow-hidden">
                    <div
                      className="absolute top-0 left-0 h-full transition duration-300 ease-in-out rounded-full eas bg-gold"
                      style={{
                        width: progress + "%",
                      }}
                    />
                  </div>
                  <span className="text-[8px] font-normal text-gray">
                    {progress}%
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
            {documents
              ?.sort(
                (a, b) => new Date(b?.updated_at) - new Date(a?.updated_at)
              )
              ?.map((_item, index) => (
                <DocumentCard
                  document={_item}
                  key={`payment_documents__${index}`}
                  icon={"file"}
                />
              ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ProofOfPayment;
