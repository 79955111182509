import { oneOf, string } from 'prop-types';
import * as Yup from 'yup';

export default (intl) => Yup.object().shape({
  password: Yup.string()
    .min(2, intl.formatMessage({
      defaultMessage: 'This password is too short',
      description: 'The feedback for password input verification - too short',
    }))
    .max(70, intl.formatMessage({
      defaultMessage: 'This password is too long',
      description: 'The feedback for password input verification - too long',
    }))
    .required(intl.formatMessage({
      defaultMessage: 'A valid password is required',
      description: 'The feedback for password input verification - required',
    })),
  // captcha: Yup.string().required("Required")
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], intl.formatMessage({
      defaultMessage: 'Passwords don\'t match',
      description: 'The feedback for password input verification - required',
    }))
    .required(intl.formatMessage({
      defaultMessage: 'A valid password is required',
      description: 'The feedback for password input verification - required',
    })),
});
