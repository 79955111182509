import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IntlTelInput from "react-intl-tel-input";
import { Field, useField } from "formik";
import cx from "classnames";
import { useTracking } from "@hooks/useTracking";
import Label from "../Label";
import ValidationFeedback from "../ValidationFeedback";
import Hint from "../Hint";

const TelephoneInput = ({
  label = "",
  hint = "",
  className,
  name,
  placeholder = null,
  onChange,
  onFocus,
  onBlur,
  onKeyUp,
  validate,
  showFeedbackIcon = true,
  showFeedbackText = true,
  hasExternalLabel,
  setDefaultFlag = "",
  typeError = "warning",
  ...props
}) => {
  const { track } = useTracking();

  const [field, meta, helpers] = useField({
    name,
    onChange,
    onFocus,
    onBlur,
    onKeyUp,
    validate,
  });
  // const [telephoneValid, setTelephoneValid] = useState(true);
  // const setValidity = (valid) => {
  //   console.log('field', field);
  //   console.log('meta', meta);
  //   console.log('helpers', helpers);
  //   console.log('valid', valid);
  //
  //
  //   // setTelephoneValid(valid);
  // };
  // process number into string with area code for submission
  const processNumber = (isValid, phone, country) => {
    if (phone?.indexOf("+") > -1) {
      return `${phone}`;
    }
    return `+${country.dialCode} ${phone}`;
  };

  // useEffect(() => {
  //   document.getElementById(name).addEventListener('input', function (e) {
  //   e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
  // });
  // }, [])

  console.log("TelephoneInput", props);
  return (
    <div className={cx("relative", className)}>
      <Label
        name={field.name}
        hasValue={!!meta?.value}
        hasPlaceholder={!!placeholder}
        className="-mt-2"
      >
        {label}
      </Label>
      <Field name={name}>
        {({
          field: { value },
          form: {
            isSubmitting,
            setFieldTouched,
            setFieldValue,
            setFieldError,
            getFieldValue,
          },
        }) => (
          <IntlTelInput
            {...props}
            containerClassName={cx(
              "intl-tel-input w-full",
              "",
              className,
              { "h-10": hasExternalLabel },
              { "h-14 pt-5 pb-1": !hasExternalLabel }
            )}
            inputClassName={cx(
              "w-full appearance-none m-0 px-3  text-white border rounded-lg bg-brand-600 rtl:text-right focus:outline-none focus:border-gold focus:ring-0 focus:ring-offset-0 focus:ring-transparent",
              { "h-10": hasExternalLabel },
              { "h-14 pt-5 pb-1": !hasExternalLabel },
              { "border-brand-500": !meta?.error || !meta?.touched },
              { "border-warning": !!meta?.error && meta?.touched }
            )}
            label="telephone"
            defaultValue={value}
            disabled={isSubmitting}
            fieldId={name}
            fieldName={name}
            onSelectFlag={(data, t1, t2) => {
              // if (t1.iso2 !== undefined && setDefaultFlag !== '') {
              //   setDefaultFlag(t1.iso2);
              // }
            }}
            onPhoneNumberBlur={(isValid) => {
              setFieldTouched(name, true);
            }}
            onPhoneNumberChange={(isValid, phone, country) => {
              console.log(
                "testing phone",
                processNumber(isValid, phone, country)
              );
              setFieldValue(name, processNumber(isValid, phone, country));
              setFieldTouched("mobile_phone_error");
              if (country?.iso2 === "au") {
                const isValidAUNumber = phone?.replace(/\D/g, "").length === 10;
                if (!isValidAUNumber) {
                  setFieldValue(
                    "mobile_phone_error",
                    "invalid_phone_number",
                    true
                  );
                } else if (isValidAUNumber) {
                  setFieldValue(
                    "mobile_phone_error",
                    processNumber(isValid, phone, country),
                    true
                  );
                }
              } else {
                if (!isValid) {
                  track("Invalid phone number", {
                    error: { isValid, phone, country },
                  });

                  setFieldValue(
                    "mobile_phone_error",
                    "invalid_phone_number",
                    true
                  );
                }
                if (isValid) {
                  setFieldValue(
                    "mobile_phone_error",
                    processNumber(isValid, phone, country),
                    true
                  );
                }
              }
            }}
          />
        )}
      </Field>
      <ValidationFeedback
        meta={meta}
        name={name}
        showFeedbackIcon={showFeedbackIcon}
        showFeedbackText={showFeedbackText}
        typeError={typeError}
      />

      <Hint show={!!hint && !meta?.error}>{hint}</Hint>
    </div>
  );
};

TelephoneInput.propTypes = {
  name: PropTypes.string.isRequired,
};
export default TelephoneInput;
