import React from "react";
import Icon from "../../../../Icon/Icon";

const DocumentInformationRulesList = ({ heading = "", rulesListData = [] }) => {
  return (
    <div className={"text-[#979797] text-xs font-normal"}>
      {heading && <p className="mb-3">{heading}</p>}
      {rulesListData && rulesListData.length > 0 ? (
        <div className="flex flex-col gap-2">
          {rulesListData.map(
            (_item, i) =>
              _item && (
                <div
                  className="flex items-start gap-2"
                  key={`identityRules__${i}`}
                >
                  <div className="flex items-center justify-center w-4 h-4 rounded-full">
                    <Icon icon="checkOutline" className={"w-3 h-3 text-gold"} />
                  </div>
                  <span>{_item}</span>
                </div>
              )
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DocumentInformationRulesList;
