/**
 * TopBar Component
 *
 * This component is responsible for The top menu like hamburger, logo & CTA's
 *
 * @category   TopBar
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */
import cx from "classnames";
import Button from "../Button";

const Ghost = ({
  children,
  className,
  onClick = undefined,
  isSubmitting = false,
  submittingLabel,
  color = "white"
}) => {
  return (
    <Button
      href=""
      onClick={onClick}
      className={cx(
        `border-1`,
        {
          "border-purple-dark bg-white": color === "white"
        },
        {
          "text-yellow border-yellow": color === "yellow"
        },
        className
      )}
      isSubmitting={isSubmitting}
      submittingLabel={submittingLabel}
    >
      {children}
    </Button>
  );
};

export default Ghost;
