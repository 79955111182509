/**
 * Layout Component
 *
 * This component is responsible for the whole page markup from the root. The wireframe for the site.
 *
 * @category   Layout
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MM
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */

import useStyles from "isomorphic-style-loader/useStyles";
import { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { openNavigation } from "@actions/navigation";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useLazyQuery, useQuery } from "@apollo/client";
import s from "./Layout.scss";
import { useRouter } from "../Provide/RouterContext";
// import { getIpInfo } from "../../data/model/session";
import { useAuth } from "../Provide/AuthContext";
import { getPlayerDetails } from "../../data/model/profile";
import { getCurrencies, getGamingAccounts } from "../../data/model/wallet";
import depositLimits from "../NotificationModals/assets/depositLimits.json";
import DepositModal from "../NotificationModals/Deposit";
// import LoyaltyUpgradeModal from "../NotificationModals/LoyaltyUpgrade";
import { useModal } from "../Modal/ModalContext";
import {
  getLoyaltyGroups,
  getLoyaltyUserPoints,
} from "../../data/model/loyalty";
import MarketingModal from "../NotificationModals/Marketing";
import {
  getDepositBonuses,
  getGrantedBonuses,
  getGrantedFreespins,
} from "../../data/model/bonus";
// import BonusAwardModal from "../NotificationModals/BonusAward";
// import FreespinAwardModal from "../NotificationModals/FreespinAward";
import { stats, getPlayerInfo } from "../../data/model/account";
import CustomToast from "../CustomToast";
import { useCentrifuge } from "../Provide/CentrifugeContext";
import { toggleCheckMark } from "../../actions/app";
import { useWallet } from "../Provide/WalletContext";
import {
  checkif10DaysOld,
  is_cash_bonus,
  stringToInt,
} from "../../data/constant.js";
import GET_MC_VISITED_DETAILS from "./get-mc-visited-details.graphql";
import UPDATE_MC_VISITED_DETAILS from "./update-mc-visited-details.graphql";
import getOfferBlockForYouQuery from "./get-offer-block-for-you.graphql";

const Config = ({
  children,
  footer = true,
  livechat = true,
  topbar = true,
  topbarLogo = true,
  topbarPosition = "fixed",
  initialTriggerMarketing = true,
}) => {
  useStyles(s);

  const intl = useIntl();

  const [correctLocaleRouter, setCorrectLocaleRouter] = useState("");
  const [needsToReloadDeposit, setNeedsToReloadDeposit] = useState(true);
  const [needsToReloadLoyalty, setNeedsToReloadLoyalty] = useState(true);
  const [needsToReloadMarketing, setNeedsToReloadMarketing] = useState(
    initialTriggerMarketing
  );

  // const block = ["BE", "NL", "UK", "GB", "US", "GR", "RS", "CZ"];
  // const { data: ipInfo } = useSWR("getIpInfo", getIpInfo);
  // const ipInfo = {country_code : "erwer"};
  const router = useRouter();
  // const countryInfo = countries?.countries?.find((item) => item?.code === ipInfo?.country_code);
  // const country = countryInfo?.code;

  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const cookies = new Cookies();
  const isNavigationOpen = useSelector(
    (state) => state.navigation.isNavigationOpen
  );
  const isShowCheckMark = useSelector((state) => state.app.isShowCheckMark);
  const latestIsShowCheckMark = useRef();
  const currentRouteName = useRef();

  // console.log("isShowCheckMark123", isShowCheckMark);
  const { data: playerDetails } = useSWR(
    isAuthenticated ? "/api/player" : null,
    getPlayerDetails,
    { refreshInterval: 10000 }
  );
  const { data: playerInfo, isValidating: playerInfoLoading } = useSWR(
    isAuthenticated ? "#/api/player" : "#/api/player/nonauth",
    getPlayerInfo
  );

  // const { data: playerSettings } = useSWR("/api/player/settings", settings);
  // if (playerSettings?.cent) {
  //   CentrifugeReact("razorbill").initialize(playerSettings?.cent);
  // }
  const { data } = useSWR(
    isAuthenticated ? "/api/player/accounts" : null,
    getGamingAccounts,
    { refreshInterval: 10000 }
  );

  const { data: loyaltyGroups } = useSWR(
    isAuthenticated ? "/api/player/status_groups" : null,
    getLoyaltyGroups
  );

  const { data: loyaltyUserPoints } = useSWR(
    isAuthenticated ? "/api/player/comp_points" : null,
    getLoyaltyUserPoints
  );

  const { data: userDepositDetails } = useSWR(
    isAuthenticated ? "/api/player/stats" : null,
    stats
  );

  const activeWallet = data?.filter(
    (item) => item.currency === playerDetails?.currency
  );

  const {
    error,
    data: grantedBonusesData,
    isValidating: grantedBonusesLoading,
    mutate,
  } = useSWR(isAuthenticated ? "getGrantedBonuses" : null, getGrantedBonuses);

  const {
    data: depositBonuses,
    mutate: refetchBonuses,
    isValidating: depositBonusesLoading,
  } = useSWR(
    isAuthenticated ? "/user/bonus#getApplicableBonuses" : null,
    getDepositBonuses
  );

  const {
    error: freespinsError,
    data: freespinsData,
    isValidating: freespinsLoading,
    mutate: freespinsMutate,
  } = useSWR(
    isAuthenticated ? "getGrantedFreespins" : null,
    getGrantedFreespins
  );

  const [updateUnvisitedBonusDetails] = useLazyQuery(
    UPDATE_MC_VISITED_DETAILS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        dispatch(toggleCheckMark(false));
        console.log("data--updateUnvisitedBonusDetails", data);
      },
    }
  );

  const {
    loading: offerBlockForYouLoading,
    error: offerBlockForYouError,
    data: offerBlockForYouData,
  } = useQuery(getOfferBlockForYouQuery, {
    variables: {
      locale: intl.locale,
      statuses:
        playerDetails && playerDetails?.statuses
          ? playerDetails?.statuses.map((x) => x?.id)
          : [],
    },
  });
  // console.log(
  //   "manualcard--manualcard--layout",
  //   offerBlockForYouData?.getOfferBlockForYou
  // );

  const [getMcVisitedDetails] = useLazyQuery(GET_MC_VISITED_DETAILS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      // console.log("data--getMcVisitedDetails", data);
      const lastVisitedTime =
        data?.getMcVisitedDetails?.data?.[0]?.lastVisitedTime;
      let visitedFreeSpinsArray;
      try {
        visitedFreeSpinsArray =
          data?.getMcVisitedDetails?.data &&
          JSON.parse(data?.getMcVisitedDetails?.data?.[0]?.visitedFreeSpins);
      } catch (error) {
        visitedFreeSpinsArray = [];
      }
      // const visitedFreeSpins =
      //   data?.getMcVisitedDetails?.data?.[0]?.visitedFreeSpins;
      // console.log("data--visitedFreeSpins", visitedFreeSpinsArray);
      // console.log(
      //   "data--offerBlockForYouData",
      //   offerBlockForYouData?.getOfferBlockForYou
      // );m
      const manualBonuses = offerBlockForYouData?.getOfferBlockForYou?.map(
        (item) => {
          // const idInInt = item?._id?.replace(/\D/g, "")?.slice(0, 7);
          const idInInt = stringToInt(item?._id);
          return Number(idInInt);
        }
      );
      // console.log("firstArrayIds", firstArrayIds);
      // console.log("visitedFreeSpinsArray", visitedFreeSpinsArray);
      const manualBonusCount =
        manualBonuses?.filter((id) => !visitedFreeSpinsArray?.includes(id))
          .length || 0;

      const depositBonusIds = depositBonuses?.map((item) => {
        const ids = stringToInt(item?.id);
        return Number(ids);
      });
      const depositBonusCount =
        depositBonusIds?.filter((id) => !visitedFreeSpinsArray?.includes(id))
          .length || 0;
      // console.log("Count of items not included in the second array:", count);
      // console.log("data--freespinsData", freespinsData);
      // console.log("depositBonusCount--depositBonusCount", depositBonusCount);

      const cashBonus = grantedBonusesData?.filter?.(
        (item) => is_cash_bonus(item) && checkif10DaysOld(item)
      );
      // console.log("cashBonus--cashBonus--main", cashBonus);

      const freeSpinBonuses = freespinsData?.filter((x) =>
        ["handle_bets", "wait", "issued", "activated"].includes(x.stage)
      );

      const bonuses = grantedBonusesData?.filter?.((item) =>
        ["handle_bets", "wait", "issued"].includes(item.stage)
      );
      // console.log("bonuses--bonuses", bonuses);
      const allBonuses = [...freeSpinBonuses, ...cashBonus, ...bonuses];
      // console.log("allBonuses--allBonuses", allBonuses);

      const filterUnvisitedBonuses = allBonuses?.filter(
        (x) => new Date(x.created_at) > new Date(lastVisitedTime)
      );
      const unvisitedBonusesLength =
        filterUnvisitedBonuses?.length + manualBonusCount + depositBonusCount;
      if (
        unvisitedBonusesLength > 0 &&
        currentRouteName.current !== "myClubhouse"
      ) {
        dispatch(toggleCheckMark(unvisitedBonusesLength));
      } else if (currentRouteName.current === "myClubhouse") {
        updateUnvisitedBonusDetails({
          variables: {
            userId: playerInfo?.id?.toString(),
            lastVisitedTime: new Date(),
          },
        });
      }
      // console.log(
      //   "filterUnvisitedBonuses--filterUnvisitedBonuses123",
      //   filterUnvisitedBonuses
      // );
    },
  });

  useEffect(() => {
    if (
      !playerInfoLoading &&
      playerInfo &&
      !freespinsLoading &&
      freespinsData &&
      !grantedBonusesLoading &&
      grantedBonusesData &&
      !depositBonusesLoading &&
      depositBonuses &&
      !offerBlockForYouLoading &&
      offerBlockForYouData
    ) {
      // console.log("getMcVisitedDetails--call");
      getMcVisitedDetails({
        variables: {
          userId: playerInfo?.id?.toString(),
        },
      });
    }
  }, [
    playerInfo,
    playerInfoLoading,
    freespinsData,
    freespinsLoading,
    grantedBonusesData,
    grantedBonusesLoading,
    depositBonuses,
    depositBonusesLoading,
    offerBlockForYouLoading,
    offerBlockForYouData,
  ]);

  // Pixie analytics
  const addPageViewEvent = () => {
    if (window?.pixie) {
      window?.pixie("event", "PageView");
    }
  };

  useEffect(() => {
    latestIsShowCheckMark.current = isShowCheckMark;
  }, [isShowCheckMark]);

  useEffect(() => {
    addPageViewEvent();
    currentRouteName.current = router?.currentRoute?.name;
  }, [router?.currentRoute?.name]);

  const updateUnvisitedBonusCount = () => {
    if (currentRouteName.current !== "myClubhouse") {
      const updatedCount = latestIsShowCheckMark?.current
        ? latestIsShowCheckMark?.current + 1
        : 1;
      // console.log("updatedCount--mian", updatedCount);
      dispatch(toggleCheckMark(updatedCount));
    } else if (currentRouteName.current === "myClubhouse") {
      updateUnvisitedBonusDetails({
        variables: {
          userId: playerInfo?.id?.toString(),
          lastVisitedTime: new Date(),
        },
      });
    }
  };
  // console.log("freespinsData", freespinsData);
  // const bonuses = bonusesData?.filter?.((item) =>
  //   ["handle_bets", "wait", "issued"].includes(item.stage)
  // );
  // const freespins = freespinsData?.filter?.((item) =>
  //   ["handle_bets", "wait", "issued", "activated"].includes(item.stage)
  // );

  // console.log("freespins--freespins", freespins);
  // console.log("bonuses--bonuses", bonuses);
  const totalDeposits = userDepositDetails?.deposits_count;

  const [userCurrentLevel, setUserCurrentLevel] = useState("");
  const [checkUserLevel, setCheckUserLevel] = useState("");

  const { showModal } = useModal();
  const { currency } = useWallet();
  const { data: currencies } = useSWR("getCurrencies", getCurrencies);

  const { subscribe } = useCentrifuge();

  const currentWallet = currencies?.filter((cur) => cur?.code === currency);
  const currentWalletCurrency = currentWallet?.[0]?.code;
  const depositLimit = depositLimits[currentWalletCurrency];
  const depositToastDescription = intl.formatMessage(
    {
      defaultMessage:
        "Your balance is under {amount}. To keep enjoying The Clubhouse Casino deposit now.",
      description:
        "Paragraph for Deposit Notification Modal -- depositToastDescription",
    },
    {
      amount: intl.formatNumber(
        depositLimit / currentWallet?.[0]?.subunits_to_unit,
        {
          style: "currency",
          currency,
          minimumFractionDigits:
            currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
          maximumFractionDigits:
            currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
        }
      ),
    }
  );

  const depositToastBtnText = intl.formatMessage({
    defaultMessage: "Deposit now",
    description: "Deposit Notification Modal button -- depositToastBtnText",
  });
  const depositToastTitle = intl.formatMessage({
    defaultMessage: "Deposit now to keep playing",
    description: "Header for Deposit Notification Modal -- depositToastTitle",
  });
  const freeSpinToastTitle = intl.formatMessage({
    defaultMessage: "Congratulations!",
    description: "Header for Freespin Notification Modal -- freeSpinToastTitle",
  });
  const freeSpinToastBtnText = intl.formatMessage({
    defaultMessage: "View Free Spins",
    description: "Freespin Notification Modal button -- freeSpinToastBtnText",
  });
  const bonusToastBtnText = intl.formatMessage({
    defaultMessage: "View bonus",
    description: "Bonus Notification Modal button -- bonusToastBtnText",
  });
  useEffect(() => {
    const runBonusSubscribeCheck = async () => {
      // const privateChannels = ["$balance","$stats","game_limits", "comps_award", "bonuses_changes", "freespins_changes", "payments_changes"];
      const currencies = await getCurrencies();
      window.toasts = [];
      subscribe("bonuses_changes", (message) => {
        if (["handle_bets", "wait", "issued"].includes(message?.data?.stage)) {
          // cookies.set("isShowCheckMark", true, {
          //   path: "/",
          //   secure: true,
          //   sameSite: "Lax",
          // });
          // dispatch(toggleCheckMark(true));

          const isCashBonus = is_cash_bonus(message?.data);
          const currentWallet = currencies?.filter(
            (cur) => cur?.code === message?.data?.currency
          );
          console.log("message--message", message, currentWallet);
          // console.log("amount--amount", amount);
          const bonusToastDescription = intl.formatMessage(
            {
              defaultMessage: "You’ve been awarded a {amount} bonus!",
              description:
                "Paragraph for Bonus Notification Modal -- bonusToastDescription",
            },
            {
              amount: intl.formatNumber(
                message?.data?.amount_cents /
                  currentWallet?.[0]?.subunits_to_unit,
                {
                  style: "currency",
                  currency: message?.data?.currency,
                  minimumFractionDigits:
                    currentWallet?.[0]?.subunits_to_unit?.toString()?.length -
                      1 ?? 2,
                  maximumFractionDigits:
                    currentWallet?.[0]?.subunits_to_unit?.toString()?.length -
                      1 ?? 2,
                }
              ),
            }
          );
          const cashBonusToastDescription = intl.formatMessage(
            {
              defaultMessage: "You’ve been awarded a {amount} cash bonus!",
              description:
                "Paragraph for Bonus Notification Modal -- cashBonusToastDescription",
            },
            {
              amount: intl.formatNumber(
                message?.data?.amount_cents /
                  currentWallet?.[0]?.subunits_to_unit,
                {
                  style: "currency",
                  currency: message?.data?.currency,
                  minimumFractionDigits:
                    currentWallet?.[0]?.subunits_to_unit?.toString()?.length -
                      1 ?? 2,
                  maximumFractionDigits:
                    currentWallet?.[0]?.subunits_to_unit?.toString()?.length -
                      1 ?? 2,
                }
              ),
            }
          );
          const mainBonusDescription = isCashBonus
            ? cashBonusToastDescription
            : bonusToastDescription;

          // const isSameBonusToastThere = document.getElementById(
          //   `bonus-notification-${message?.data?.id}`
          // );
          // console.log("isSameBonusToastThere", isSameBonusToastThere);
          // if (isSameBonusToastThere === null) {
          if (window.toasts.indexOf(message?.data?.id) === -1) {
            window.toasts.push(message?.data?.id);
            updateUnvisitedBonusCount();
            showToastNotification(
              freeSpinToastTitle,
              mainBonusDescription,
              bonusToastBtnText,
              { isCashBonus, bonusId: message?.data?.id }
              // { bonusTitle: message?.data?.title }
            );
          }
        }
      });

      subscribe("freespins_changes", (message) => {
        if (["handle_bets", "wait", "issued"].includes(message?.data?.stage)) {
          // "activated" removed coz on active freespin, getting toaster
          // cookies.set("isShowCheckMark", true, {
          //   path: "/",
          //   secure: true,
          //   sameSite: "Lax",
          // });
          // dispatch(toggleCheckMark(true));
          updateUnvisitedBonusCount();
          const freeSpinToastDescription = intl.formatMessage(
            {
              defaultMessage: "You’ve been awarded {freeSpinCount} Free Spins!",
              description:
                "Paragraph for Freespin Notification Modal -- freeSpinToastDescription",
            },
            {
              freeSpinCount: message?.data?.freespins_total,
            }
          );
          showToastNotification(
            freeSpinToastTitle,
            freeSpinToastDescription,
            freeSpinToastBtnText,
            { isFreeSpinBonus: true }
          );
        }
      });
    };
    if (isAuthenticated) {
      runBonusSubscribeCheck();
    }
  }, [isAuthenticated]);
  // redirect user if URL consists of 'reset_password_token
  useEffect(() => {
    if (router?.queryParams?.reset_password_token) {
      const resetToken = router.queryParams.reset_password_token;
      dispatch(
        openNavigation("Auth", {
          initialAction: "resetPasswordForm",
          resetToken,
        })
      );
    }
  }, [router]);

  const showToastNotification = (
    title,
    description,
    btnText,
    extraProps = {}
  ) => {
    toast(
      <CustomToast
        title={title}
        description={description}
        btnText={btnText}
        extraProps={{
          ...extraProps,
          isBonusToast:
            !extraProps?.isDepositToast && !extraProps?.isLoyaltyUpgrade,
        }}
      />,
      {
        // icon: StarIcon,
        closeOnClick: false,
        autoClose: 10000,
        hideProgressBar: true,
        position: toast.POSITION.BOTTOM_LEFT,
        className: "toast-message bg-gold",
      }
    );
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     showToastNotification(
  //       freeSpinToastTitle,
  //       bonusToastDescription,
  //       bonusToastBtnText
  //     );
  //     showToastNotification(
  //       freeSpinToastTitle,
  //       freeSpinToastDescription,
  //       freeSpinToastBtnText
  //     );
  //   }, [2000]);
  // }, []);

  // to trigger bonuses / freespin modal

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     const bonusModal = document.getElementById("bonus-award-notification");
  //     const freespinModal = document.getElementById(
  //       "freespin-award-notification"
  //     );
  //     // check if session storage already has inital bonuses and initial freespins
  //     const initialBonuses = JSON.parse(localStorage.getItem("initialBonuses"));
  //     const initialFreespins = JSON.parse(
  //       localStorage.getItem("initialFreespins")
  //     );
  //     // console.log("bonusModal--bonusModal", bonusModal);
  //     // console.log("freespinModal--freespinModal", freespinModal);
  //     // console.log("bonuses?.length--bonuses?.length", bonuses?.length);
  //     // console.log(
  //     //   "initialBonuses?.length--initialBonuses?.length",
  //     //   initialBonuses?.length
  //     // );
  //     // console.log(
  //     //   "JSON.stringify(initialBonuses)--JSON.stringify(initialBonuses)",
  //     //   JSON.stringify(initialBonuses)
  //     // );
  //     // console.log(
  //     //   "JSON.stringify(bonuses)--JSON.stringify(bonuses)",
  //     //   JSON.stringify(bonuses)
  //     // );
  //     // console.log(
  //     //   "JSON.stringify(initialFreespins)--JSON.stringify(initialFreespins)",
  //     //   JSON.stringify(initialFreespins)
  //     // );
  //     // console.log(
  //     //   "JSON.stringify(freespins)--JSON.stringify(freespins)",
  //     //   JSON.stringify(freespins)
  //     // );
  //     // console.log(
  //     //   "check--check",
  //     //   JSON.stringify(initialFreespins) !== JSON.stringify(freespins) &&
  //     //     bonusModal === null &&
  //     //     freespinModal === null
  //     // );

  //     // console.log(
  //     //   "check--checkfreespin",
  //     //   freespins?.length >= initialFreespins?.length
  //     // );

  //     // console.log(
  //     //   "final--check",
  //     //   initialFreespins.filter?.((item) =>
  //     //     ["handle_bets", "wait", "issued", "activated"].includes(item.stage)
  //     //   )?.length,
  //     //   initialBonuses.filter?.((item) =>
  //     //     ["handle_bets", "wait", "issued"].includes(item.stage)
  //     //   )?.length
  //     // );
  //     // console.log(
  //     //   "awards details local storage",
  //     //   initialBonuses,
  //     //   initialFreespins
  //     // );
  //     if (
  //       initialBonuses !== undefined &&
  //       initialFreespins !== undefined &&
  //       (bonuses?.length > 0 || freespins?.length > 0)
  //     ) {
  //       // check initial data and see if it matches with the fetched data
  //       if (
  //         JSON.stringify(initialBonuses) !== JSON.stringify(bonuses) &&
  //         bonusModal === null &&
  //         freespinModal === null
  //       ) {
  //         // check if it has cancelled, else dont show
  //         if (
  //           bonuses?.length >=
  //           initialBonuses?.filter?.((item) =>
  //             ["handle_bets", "wait", "issued"].includes(item.stage)
  //           )?.length
  //         ) {
  //           // showModal(BonusAwardModal);
  //           // showToastNotification(
  //           //   freeSpinToastTitle,
  //           //   bonusToastDescription,
  //           //   bonusToastBtnText
  //           // );
  //           localStorage.setItem("initialBonuses", JSON.stringify(bonuses));
  //         }
  //       }
  //       // showToastNotification(
  //       //   freeSpinToastTitle,
  //       //   bonusToastDescription,
  //       //   bonusToastBtnText
  //       // );
  //       if (
  //         JSON.stringify(initialFreespins) !== JSON.stringify(freespins) &&
  //         bonusModal === null &&
  //         freespinModal === null
  //       ) {
  //         if (
  //           freespins?.length >=
  //           initialFreespins?.filter?.((item) =>
  //             ["handle_bets", "wait", "issued", "activated"].includes(
  //               item.stage
  //             )
  //           )?.length
  //         ) {
  //           // showModal(FreespinAwardModal);
  //           // showToastNotification(
  //           //   freeSpinToastTitle,
  //           //   freeSpinToastDescription,
  //           //   freeSpinToastBtnText
  //           // );
  //           localStorage.setItem("initialFreespins", JSON.stringify(freespins));
  //         }
  //       }
  //     } else if (bonuses !== undefined && freespins !== undefined) {
  //       localStorage.setItem("initialBonuses", JSON.stringify([]));
  //       localStorage.setItem("initialFreespins", JSON.stringify([]));
  //     }
  //   }
  // }, [bonuses?.length, freespins?.length, isAuthenticated]);

  // to trigger opt-in marketing
  const marketing = cookies?.get("marketingTrigger");
  useEffect(() => {
    if (isAuthenticated) {
      triggerMarketing();
    }
  }, [isAuthenticated, marketing]);

  const triggerMarketing = async () => {
    if (isAuthenticated) {
      const playerDet = await getPlayerDetails();
      // check if other 2 modals are not visible
      const depositModal = document.getElementById("deposit-notification");
      const loyaltyModal = document.getElementById("loyalty-notification");
      const bonusModal = document.getElementById("bonus-award-notification");
      const freespinModal = document.getElementById(
        "freespin-award-notification"
      );

      if (
        depositModal === null &&
        loyaltyModal === null &&
        bonusModal === null &&
        freespinModal === null
      ) {
        // check if user is authenticated
        if (String(marketing) === "true" && isAuthenticated) {
          if (playerDet !== undefined) {
            if (needsToReloadMarketing) {
              // check if receive_sms_promos and receive_promos are false
              if (!playerDet.receive_promos && !playerDet.receive_sms_promos) {
                // enable modal
                showModal(MarketingModal);
                setNeedsToReloadMarketing(false);
              }
            }
          }
        }
      }
    }
  };

  // to trigger loyalty upgrade modal
  const checkForLoyaltyLevel = (checkForModal = false) => {
    if (isAuthenticated) {
      // check if other modal isnt visiblemarketing-notification
      const depositModal = document.getElementById("deposit-notification");
      const marketingModal = document.getElementById("marketing-notification");
      const bonusModal = document.getElementById("bonus-award-notification");
      const freespinModal = document.getElementById(
        "freespin-award-notification"
      );

      if (
        depositModal === null &&
        marketingModal === null &&
        bonusModal === null &&
        freespinModal === null
      ) {
        const loyaltyModal = document.getElementById("loyalty-notification");

        if (loyaltyModal?.length > 0) {
          setNeedsToReloadLoyalty(false);
        } else {
          setNeedsToReloadLoyalty(true);
        }

        let userpnts = 0;
        if (loyaltyUserPoints?.persistent?.points) {
          userpnts = loyaltyUserPoints?.persistent?.points;
        }

        loyaltyGroups?.forEach((element) => {
          if (
            userpnts >= element?.conditions[0]?.persistent_comp_points?.min &&
            element.id === "platinum"
          ) {
            if (!checkForModal) {
              setUserCurrentLevel(element.id);
              setNeedsToReloadLoyalty(true);
            } else {
              setCheckUserLevel(element.id);
            }
          } else if (
            userpnts >= element?.conditions[0]?.persistent_comp_points?.min &&
            userpnts <= element?.conditions[0]?.persistent_comp_points?.max
          ) {
            if (!checkForModal) {
              setUserCurrentLevel(element.id);
              setNeedsToReloadLoyalty(true);
            } else {
              setCheckUserLevel(element.id);
            }
          }
        });

        // showModal(LoyaltyUpgradeModal);
        if (
          checkForModal &&
          checkUserLevel !== "none" &&
          checkUserLevel.length > 0 &&
          needsToReloadLoyalty &&
          userCurrentLevel.length > 0
        ) {
          if (userCurrentLevel !== checkUserLevel) {
            // showModal(LoyaltyUpgradeModal);
            showToastNotification(
              freeSpinToastTitle,
              intl.formatMessage({
                defaultMessage:
                  "You’ve reached the next level of our loyalty programme!",
                description: "Paragraph for Loyalty Notification Modal",
              }),
              intl.formatMessage({
                defaultMessage: "Play now",
                description: "Loyalty Notification Modal button",
              }),
              { isLoyaltyUpgrade: true }
            );
          }
        }
      }
    }
  };

  // initial load to set initial current user level
  useEffect(() => {
    if (isAuthenticated) {
      checkForLoyaltyLevel(false);
    }
  }, [isAuthenticated, userCurrentLevel, loyaltyGroups, loyaltyUserPoints]);

  // need to trigger modal only if loyalty user points change
  useEffect(() => {
    if (isAuthenticated) {
      checkForLoyaltyLevel(true);
    }
  }, [
    isAuthenticated,
    loyaltyUserPoints,
    needsToReloadLoyalty,
    checkUserLevel,
  ]);

  // to trigger deposit notification modal
  const depositTriggerCookie = cookies?.get("depositTrigger");
  useEffect(() => {
    let interval = null;
    if (isAuthenticated) {
      // call the method every second to check if 5 minutes have passed
      interval = setInterval(() => {
        // check if other modal is visible
        const loyaltyModal = document.getElementById("loyalty-notification");
        const marketingModal = document.getElementById(
          "marketing-notification"
        );

        if (loyaltyModal === null && marketingModal === null) {
          // check if user has deposited at least once
          if (
            totalDeposits > 0 &&
            totalDeposits !== undefined &&
            totalDeposits !== null
          ) {
            // check if modal is visible, if yes set needs to reload to false else true
            const depositModal = document.getElementById(
              "deposit-notification"
            );
            if (depositModal !== null) {
              setNeedsToReloadDeposit(false);
            } else {
              setNeedsToReloadDeposit(true);
            }

            const minutesToPass = 5;
            const currentTime = new Date();
            const currentDateTime = new Date(currentTime.getTime());
            const currentWalletCurrency = activeWallet?.[0]?.currency;
            const currentWalletAmount = activeWallet?.[0]?.amount_cents;
            if (needsToReloadDeposit) {
              if (depositTriggerCookie?.length > 0) {
                // check if user is logged in and if wallet has loaded
                if (isAuthenticated && currentWalletCurrency?.length > 0) {
                  // check amount of current wallet deposit and if user has more than the minimum limit
                  const depositLimit = depositLimits[currentWalletCurrency];
                  if (currentWalletAmount <= depositLimit) {
                    const cookiedate = new Date(depositTriggerCookie);
                    const cookiedateWithMinutes = new Date(
                      cookiedate.getTime() + minutesToPass * 60000
                    );
                    // check if time has passed 5 minues
                    // console.log("cookiedate",cookiedate);
                    // console.log("cookiedateWithMinutes",cookiedateWithMinutes);
                    // console.log("currentDateTime",currentDateTime);
                    if (
                      cookiedateWithMinutes.toISOString() <=
                      currentDateTime.toISOString()
                    ) {
                      // enable deposit limit modal
                      // showModal(DepositModal);
                      cookies.set("depositTrigger", currentDateTime, {
                        path: "/",
                      });
                      showToastNotification(
                        depositToastTitle,
                        depositToastDescription,
                        depositToastBtnText,
                        { isDepositToast: true }
                      );
                      setNeedsToReloadDeposit(false);
                    }
                  }
                }
              } else {
                cookies.set("depositTrigger", currentDateTime, { path: "/" });
              }
            }
          }
        }
      }, 20000);
    }

    return () => clearInterval(interval);
  }, [
    isAuthenticated,
    activeWallet,
    depositTriggerCookie,
    needsToReloadDeposit,
  ]);

  // const getLocale = () => {
  //   console.log("router params country", country)
  //   const map = {
  //     CA: 'en-ca',
  //     IN: 'en-in',
  //     IE: 'en-ie',
  //     DE: 'de-de',
  //     NZ: 'en-nz',
  //   };

  //   let localeWord = router?.pathname?.split("/")[1]
  //   let param = router?.pathname?.slice(localeWord.length + 1, router?.pathname?.length + 1)

  //   const eu_countries = ['BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'EL', 'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU', 'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE'];
  //   if(country !== undefined ){
  //     let string = country in map ? map[country] : eu_countries.includes(country) ? 'en-eu' : 'en-row';

  //     if(localeWord.toLowerCase() !== string.toLowerCase())
  //     {
  //       console.log("router params do NOT match", "/"+"https://theclubhousecasino.com/"+string +param)
  //       window.location.href = "https://theclubhousecasino.com/"+string+param;
  //     }else{
  //       console.log("router params do match", "/"+string)
  //     }
  //   }else return;
  // };
  console.log(topbar, "topbartopbartopbar");
  useEffect(() => {
    console.log("NAVBAR IS OPEN");
    isNavigationOpen
      ? (document.getElementById("root").style.overflowY = "hidden")
      : (document.getElementById("root").style.overflowY = "auto");
  }, [isNavigationOpen]);

  return null;
};
Config.whyDidYouRender = true;
export default Config;
