import useSWR from "swr";
import React from "react";
import { useIntl } from "react-intl";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { useDispatch } from "react-redux";
import { closeNavigation } from "@actions/navigation";
import Button from "../../Button";
import { useWallet } from "../../Provide/WalletContext";
import { titleize } from "../../../core/utils";

const WithdrawLocked = ({ setMenuItem }) => {
  const intl = useIntl();
  const {} = useWallet();
  const dispatch = useDispatch();
  const close = () => dispatch(closeNavigation());
  return (
    <div className="w-full flex flex-col pb-14">
      <h4 className="text-center text-2xl leading-5 font-light text-white mb-16">
        {intl.formatMessage({
          defaultMessage: "Withdraw",
          description: "header",
        })}
      </h4>
      <div className="mx-auto mt-5.5 flex-col flex w-full items-stretch">
        {intl.formatMessage({
          defaultMessage:
            "Unfortunately you can't withdraw as you have an active bonus",
          description: "The feedback on withdraw",
        })}
      </div>
      <div className="pt-8  w-full">
        <Button.Action
          trackName="View Bonuses"
          trackParams={{ component: "WithdrawLocked" }}
          onClick={() => setMenuItem("ActiveBonuses")}
          className="w-full"
          arrow="right"
        >
          {intl.formatMessage({
            defaultMessage: "Go to bonus overview",
            description: "Label",
          })}
        </Button.Action>
      </div>
    </div>
  );
};

export default WithdrawLocked;
