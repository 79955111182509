import useSWR from "swr";
import React from "react";
import { useIntl } from "react-intl";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { useDispatch } from "react-redux";
import { closeNavigation } from "@actions/navigation";
import SubmitFeedback from "@components/Formik/SubmitFeedback";
import Button from "../../Button";
import { useWallet } from "../../Provide/WalletContext";

const WithdrawSuccess = ({ pid, hideModal }) => {
  const intl = useIntl();
  const { currency } = useWallet();
  const dispatch = useDispatch();
  const close = () => dispatch(closeNavigation());
  // credit: {currency: "DKK", amount: 100, name: ""}
  // debit: {currency: "DKK", amount: 100, name: "MoneyMatrix_PaySafeCard"}
  // fees: []
  // isFirstDeposit: false
  // status: "success"
  // time: "2021-05-11T20:56:05.783"
  // transactionID: 1576066424

  return (
    <div className="p-16 px-8 md:px-16">
      <SubmitFeedback
        status="success"
        header={intl.formatMessage({
          defaultMessage: "Success!",
          description: "The feedback header",
        })}
      >
        <div className="py-8 leading-7 text-24">
          {intl.formatMessage({
            defaultMessage:
              "Your withdraw is requested and will be processed as soon as possible.",
            description: "The feedback after setting a limit",
          })}
        </div>
        <div className="px-8">
          <Button.Action
            trackName="Close"
            trackParams={{ component: "WithdrawSuccess" }}
            onClick={close}
            className="w-full"
            arrow="right"
          >
            {intl.formatMessage({
              defaultMessage: "Close",
              description: "Label",
            })}
          </Button.Action>
        </div>
      </SubmitFeedback>
    </div>
  );
};

export default WithdrawSuccess;
