import React from "react";
import cx from "classnames";
import Icon from "../../../../Icon/Icon";
import { DOCUMENT_STATUS } from "../../../../../data/constant";
import { useIntl } from "react-intl";

const DocumentCard = ({ document, icon }) => {
  const intl = useIntl();

  const getStatusMessage = (status) => {
    switch (status) {
      case DOCUMENT_STATUS.PENDING:
        return intl.formatMessage({
          defaultMessage: "We’re checking this file now. Come back soon.",
          description: "pending document info text",
        });
      case DOCUMENT_STATUS.APPROVED:
        return intl.formatMessage({
          defaultMessage:
            "Good news! This file has passed our checks and has been accepted.",
          description: "approved document info text",
        });
      case DOCUMENT_STATUS.REJECTED:
        return intl.formatMessage({
          defaultMessage:
            "Sorry, this file hasn’t passed our checks. Please upload a different file.",
          description: "rejected document info text",
        });
      default:
        return null;
    }
  };

  return (
    <div className="p-3 border rounded bg-brand-600 border-brrounded-md">
      <div className="flex items-center justify-between mb-1.5 gap-2.5">
        <div className="flex gap-2.5 items-center max-w-[70%]">
          <div className="flex items-center justify-center w-3 h-4 rounded-full">
            <Icon icon={icon} className={"w-3 h-4 text-white"} />
          </div>
          <h5
            className="overflow-hidden text-xs font-normal text-white"
            style={{
              textOverflow: "ellipsis",
            }}
          >
            {document?.description || document?.fileName}
          </h5>
        </div>
        {document.status && (
          <span
            className={cx(
              `capitalize px-2 py-1 rounded border text-10 sm:text-[8px] font-bold text-nowrap`,
              {
                "bg-[#0091ff] border-[#0091ff] text-[#102739]":
                  document.status === DOCUMENT_STATUS.PENDING,
                "bg-[#30d158] border-[#30d158] text-[#17311e]":
                  document.status === DOCUMENT_STATUS.APPROVED,
                "bg-[#d63232] border-[#d63232] text-[#321818]":
                  document.status === DOCUMENT_STATUS.REJECTED,
              }
            )}
          >
            {document?.status?.split("_")?.join(" ")}
          </span>
        )}
      </div>
      <p className="text-10 text-[#979797] font-normal">
        {getStatusMessage(document.status)}
      </p>
    </div>
  );
};

export default DocumentCard;
