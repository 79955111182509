import React from "react";
import { Transition } from "@headlessui/react";
import { useTracking } from "@hooks/useTracking";
import cx from "classnames";

const ValidationFeedback = ({
  meta,
  name,
  showFeedbackIcon,
  showFeedbackText,
  typeError = "warning",
}) => {
  const { track } = useTracking();
  // console.log("meta", meta, !!meta?.value, showFeedbackText);
  // useEffect(() => {
  //     track('Input error',{field:name, error: meta?.error, value: meta?.value});
  // },[meta?.error]);

  return (
    <>
      {/* <Transition */}
      {/*  show={!!meta?.error && meta?.touched} */}
      {/*  enter="transition ease-out duration-500 delay-500" */}
      {/*  enterFrom="transform -translate-x-4 opacity-0" */}
      {/*  enterTo="transform opacity-100 translate-x-0" */}
      {/*  leave="transition ease-in duration-700" */}
      {/*  leaveFrom="transform translate-x-0 opacity-100" */}
      {/*  leaveTo="transform -translate-x-4 opacity-0" */}
      {/*  unmount={false} */}
      {/*  className="absolute -right-7 h-6 w-5" */}
      {/* > */}
      {/*  {showFeedbackIcon && <Icon icon="checkError" className="h-4 w-4" />} */}
      {/* </Transition> */}
      {/* <Transition */}
      {/*  show={!meta?.error && !!meta.value && meta?.touched} */}
      {/*  enter="transition ease-out duration-500 delay-500" */}
      {/*  enterFrom="transform translate-x-4 opacity-0" */}
      {/*  enterTo="transform opacity-100 translate-x-0" */}
      {/*  leave="transition ease-in duration-700" */}
      {/*  leaveFrom="transform translate-x-0 opacity-100" */}
      {/*  leaveTo="transform translate-x-4 opacity-0" */}
      {/*  unmount={false} */}
      {/*  className="absolute -right-7 h-6 w-5" */}
      {/* > */}
      {/*  {showFeedbackIcon && ( */}
      {/*    <Icon icon="checkOk" className="h-5 w-5 text-green" /> */}
      {/*  )} */}
      {/* </Transition> */}
      <Transition
        show={!!meta?.error && meta?.touched && showFeedbackText}
        enter="transition-all ease-out duration-500 "
        enterFrom="opacity-0 h-0"
        enterTo="transform opacity-100 max-h-screen"
        leave="transition-all ease-in duration-700 "
        leaveFrom="transform opacity-100 max-h-screen"
        leaveTo="transform opacity-0 h-0"
        unmount={false}
        className="z-10 w-full overflow-hidden"
      >
        {showFeedbackText && (
          <>
            <div className="w-4 overflow-hidden inline-block ml-4">
              <div
                className={cx(
                  "h-3 w-3 rotate-45 transform origin-bottom-left rounded-sm",
                  {
                    "bg-warning": typeError === "warning",
                    "bg-red": typeError === "error",
                  }
                )}
              />
            </div>
            <div
              className={cx(
                "-mt-2 text-white px-3 py-2 text-xs w-full rounded border-1",
                {
                  "border-warning bg-warning": typeError === "warning",
                  "border-red bg-red": typeError === "error",
                }
              )}
            >
              {meta.error}
            </div>
          </>
        )}
      </Transition>
    </>
  );
};

export default ValidationFeedback;
