/**
 * TopBar Component
 *
 * This component is responsible for The public top menu login/ registration buttons
 *
 * @category   TopBarPublic
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Transition } from "@headlessui/react";
import Button from "@components/Button";
import {
  closeNavigation,
  openNavigation,
  toggleNavigation,
} from "@actions/navigation";
import Registration from "@components/Registration";
import RegistrationSuccess from "@components/Registration/FormSteps/Success";
import Faq from "@components/Faq";
import Auth from "@components/Auth";
import usePrevious from "@hooks/usePrevious";
import Icon from "../../Icon";
import Link from "../../Link";
import MenuPublic from "../TopBarPrivate/MenuItems/Public";
import ToggleNavigation from "../../Navigation/ToggleNavigation";
import MenuAuthenticated from "../TopBarPrivate/MenuItems/Authenticated";
import { useDevice } from "../../Provide/DeviceContext";
import { useRouter } from "../../Provide/RouterContext";
import history from "../../../core/history";

import countryIe from "../../../../public/img/flags/ie.svg";
import countryAu from "../../../../public/img/flags/au.svg";
import countryNz from "../../../../public/img/flags/nz.svg";
import countryCa from "../../../../public/img/flags/ca.svg";
import countryIn from "../../../../public/img/flags/in.svg";
import countryDe from "../../../../public/img/flags/de.svg";
import globe from "../../../../public/img/flags/globe.svg";
import countryCh from "../../../../public/img/flags/ch.svg";
import countryEE from "../../../../public/img/flags/ee.svg";
import countryAR from "../../../../public/img/flags/ar.svg";

const Items = {
  Auth,
  Registration,
  RegistrationSuccess,
  Faq,
  Root: null,
};

const TopBarPublic = ({ isAuthenticated, hideCTAsProp }) => {
  const intl = useIntl();
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const toggle = (section) => dispatch(toggleNavigation(section ?? "Root"));
  const open = (section) => dispatch(openNavigation(section ?? "Root"));
  const router = useRouter();
  const close = () => {
    if (router?.routename === "register") {
      history.push("/");
    }
    dispatch(closeNavigation());
  };

  const isNavigationOpen = useSelector(
    (state) => state.navigation.isNavigationOpen
  );
  const navigationItem = useSelector((state) => state.navigation.item);
  const params = useSelector((state) => state.navigation.params);
  const hideCTAs = hideCTAsProp || params?.hideCTAs || false;

  const [menuItem, setMenuItem] = useState(navigationItem ?? "Root");
  const [backActions, setBackActions] = useState([]);
  const [buttonToggle, setButtonToggle] = useState(false);

  useEffect(() => {
    if (navigationItem) {
      setMenuItem(navigationItem);
    }
    console.log(`NAV ITEM ${navigationItem}`);
  }, [navigationItem]);

  const prevMenuItem = usePrevious(menuItem);
  const Slide =
    Items[menuItem === "Root" ? prevMenuItem : menuItem] ?? MenuAuthenticated;

  return (
    <>
      {!isAuthenticated && !isMobile.any && (
        <LanguageSwitcher locale={intl?.locale} />
      )}

      {!hideCTAs && (
        <Button.Action
          trackName="Register open"
          trackParams={{ component: "TopBarPublic" }}
          onClick={() => {
            setMenuItem("Registration");
            open("Registration");
          }}
          arrow={!isMobile.any && "right"}
          className="-ml-0 lg:flex lg:mr-5 lg:rtl:ml-5 lg:rtl:mr-0"
        >
          {intl.formatMessage({
            defaultMessage: "Create account",
            description: "Button label",
          })}
        </Button.Action>
      )}
      {!isMobile.any && !isAuthenticated && (
        <Button.Action
          trackName="Submit log in"
          trackParams={{ component: "TopBarPublic" }}
          color={!isMobile.any && "gray"}
          onClick={() => {
            setButtonToggle(!buttonToggle), setMenuItem("Auth");
            open("Auth");
          }}
          className="lg:flex 3xs:hidden"
        >
          {intl.formatMessage({
            defaultMessage: "Log in",
            description: "Button label",
          })}
        </Button.Action>
      )}
      {!hideCTAs && !isNavigationOpen && (
        <Link
          trackName="Account Menu"
          trackParams={{ component: "TopBarPublic" }}
          onClick={() => {
            setMenuItem("Root");
            toggle("Root");
          }}
          className="relative items-center justify-center h-12 rounded-lg ltr:ml-5 rtl:mr-5 bg-brand-600 lg:hidden"
        >
          <ToggleNavigation open={isNavigationOpen} />
        </Link>
      )}
      {/* <Button.Action className="mr-8 font-normal" color="darkgray" onClick={toggle}> */}
      {/*  <Icon icon="user" className="w-5 h-5 mr-1 text-white" /> */}
      {/*  {' '} */}
      {/*  <FormattedNumber */}
      {/*    value={total} */}
      {/*    style="currency" */}
      {/*    currency={currency} */}
      {/*  /> */}
      {/* </Button.Action> */}
      {/* <Button.Action className="mr-8"> */}
      {/*  {intl.formatMessage({ */}
      {/*    defaultMessage: 'Deposit', */}
      {/*    description: 'Label of the deposit button', */}
      {/*  })} */}
      {/* </Button.Action> */}

      {/* <div className="z-30 mr-4 text-lg font-bold text-white"> */}
      {/*  <FormattedNumber value={total} style="currency" currency={currency} /> */}
      {/* </div> */}
      <div className="relative flex items-end justify-center inline-block text-left">
        <>
          {/* <Button.Action color="darkgray" className="flex items-center justify-center transition duration-150 ease-in-out focus:outline-none" > */}
          {/*  Menu */}
          {/* </Button.Action> */}
          <Transition
            show={isNavigationOpen}
            enter="transition-all duration-700"
            enterFrom="transform translate-x-full"
            enterTo="transform translate-x-0"
            leave="transition-all duration-700"
            leaveFrom="transform-gpu translate-x-0"
            leaveTo="transform-gpu translate-x-full"
            className={`fixed right-0 transform w-screen h-menu-medium pb-16 overflow-hidden overflow-x-hidden overflow-y-auto text-white lg:h-menu-bottom xs:w-108  bg-brand ${
              !hideCTAs ? "top-26 lg:top-0 lg:mt-26" : "top-0 z-50 mt-0"
            }`}
            unmount={false}
          >
            <>
              {/* {menuItem !== 'Root' && ( */}
              {/*  <Link */}
              {/*    onClick={() => { */}
              {/*      const cloneBackActions = [...backActions]; */}
              {/*      const lastItem = cloneBackActions.pop(); */}
              {/*      lastItem?.(); */}
              {/*      setBackActions(cloneBackActions); */}
              {/*    }} */}
              {/*    className="absolute p-2.5 left-4 rounded bg-brand-600" */}
              {/*  > */}
              {/*    <Icon */}
              {/*      icon="arrow" */}
              {/*      className="w-4 h-4 text-white transform rotate-90" */}
              {/*    /> */}
              {/*  </Link> */}
              {/* )} */}

              <Link
                trackName="Close"
                trackParams={{ component: "TopBarPublic" }}
                to={router?.routename === "register" ? "home" : undefined}
                onClick={close}
                className="absolute right-4 p-2.5 rounded bg-brand-600 z-10"
              >
                <Icon icon="close" className="w-4 h-4 text-white" />
              </Link>
              <Transition
                show={menuItem === "Root"}
                enter="transition-all duration-700"
                enterFrom="transform -translate-x-full"
                enterTo="transform translate-x-0"
                leave="transition-all duration-700"
                leaveFrom="transform-gpu translate-x-0"
                leaveTo="transform-gpu -translate-x-full"
                className="absolute top-0 left-0 w-full h-full pt-1 pb-16 overflow-auto"
                // unmount={false}
              >
                <MenuPublic
                  setMenuItem={setMenuItem}
                  setBackActions={setBackActions}
                />
              </Transition>
              <Transition
                show={menuItem !== "Root"}
                enter="transition-all duration-700"
                enterFrom="transform -translate-x-full"
                enterTo="transform translate-x-0"
                leave="transition-all duration-700"
                leaveFrom="transform-gpu translate-x-0"
                leaveTo="transform-gpu -translate-x-full"
                className={`absolute  left-0 z-0 w-full px-4 pt-6 pb-28 lg:pb-0 h-full overflow-auto ${
                  !hideCTAs ? "top-0" : "top-10"
                }`}
                unmount
              >
                <Slide
                  prevMenuItem={prevMenuItem}
                  menuItem={menuItem}
                  setMenuItem={setMenuItem}
                  setBackActions={setBackActions}
                  params={params}
                  {...params}
                  buttonToggle={buttonToggle}
                />
              </Transition>
              <div className="fixed bottom-0 right-0 z-20 w-full bg-black xs:w-108">
                {!isAuthenticated && isMobile.any && (
                  <LanguageSwitcher locale={intl?.locale} noMargin />
                )}
              </div>
            </>
          </Transition>
        </>
      </div>
    </>
  );
};
export function LanguageSwitcher({ locale, noMargin }) {
  const [showLanguage, setShowLanguage] = useState(false);
  const { isMobile } = useDevice();
  const availableLanguages = [
    { locale: "en-us", img: globe, name: "Eng" },
    { locale: "en-ie", img: countryIe, name: "en-ie" },
    { locale: "en-au", img: countryAu, name: "en-au" },
    { locale: "en-nz", img: countryNz, name: "en-nz" },
    { locale: "en-ca", img: countryCa, name: "en-ca" },
    { locale: "en-in", img: countryIn, name: "en-in" },
    { locale: "de-de", img: countryDe, name: "de-de" },
    { locale: "de-ch", img: countryCh, name: "de-ch" },
    { locale: "et-ee", img: countryEE, name: "et-ee" },
    { locale: "ar-ar", img: countryAR, name: "ar-ar" },
  ];
  const currentLocal = availableLanguages?.find(
    (item) => item?.locale === locale
  );
  return (
    <div
      className={`relative z-1 leading-none ${
        !noMargin ? "mr-5 rtl:mr-0 rtl:ml-5" : ""
      }`}
    >
      {console.log(currentLocal, "currentLocal")}
      <button
        onClick={() => setShowLanguage(!showLanguage)}
        className="flex items-center w-full p-3 align-middle border border-white border-solid md:rounded h-14 bg-opacity-20 lg:h-11 bg-gray"
      >
        <span className="relative block w-8 h-8 overflow-hidden rounded-full lg:w-6 lg:h-6">
          <img
            src={currentLocal ? currentLocal?.img : globe}
            className="object-cover w-full h-full"
            alt="Country logo"
          />
        </span>
        <span className="mx-2 text-sm font-medium text-white uppercase lg:text-xs">
          {currentLocal?.name ? currentLocal?.name : "Eng"}
        </span>
        <Icon icon="arrow" className="w-3 h-3 text-white" />
      </button>

      <Transition show={showLanguage}>
        <ul
          className={`absolute min-w-full w-24 p-3  md:rounded bg-black  ${
            isMobile.any
              ? "bottom-14 left-0 grid grid-cols-4 gap-5 space-y-0"
              : "right-0 space-y-3"
          }`}
        >
          {availableLanguages &&
            availableLanguages.map((item, index) => (
              <li className="flex items-center">
                <Link
                  href={`/${
                    item?.locale === "en-us" ? "en-row" : item?.locale
                  }`}
                  className="flex flex-col items-center justify-center bg-white bg-opacity-0 lg:gap-2 lg:flex-row lg:justify-start hover:bg-opacity-5"
                >
                  <span className="relative block w-10 h-10 overflow-hidden rounded-full lg:w-5 lg:h-5">
                    <img
                      src={item?.img}
                      className="object-cover w-full h-full"
                      alt="Country logo"
                    />
                  </span>
                  <span className="mt-1 text-sm font-medium text-white uppercase transition-colors duration-300 lg:mt-0 lg:text-xs group-hover:text-primary ">
                    {item?.name}
                  </span>
                </Link>
              </li>
            ))}
        </ul>
      </Transition>
    </div>
  );
}

export default TopBarPublic;
