import React, { useState } from "react";
import cx from "classnames";
import { Switch } from "@headlessui/react";

const RadioButton = ({
  value,
  name,
  label = "",
  hint = "",
  className,
  checked,
  onClick,
  currency,
  onChange,
}) => {
  const toggle = (value) => {
    // setIsChecked(!isChecked);
    // field.onChange(value);
    onChange(value);
  };
  const [isChecked, setIsChecked] = useState(checked);

  return (
    <Switch
      checked={checked == value}
      onChange={onChange}
      onClick={onClick}
      className={cx(
        "outline-none flex items-center justify-center focus:outline-none transition-all ease-in-out duration-500 relative inline-flex items-center h-5 w-5 rounded-full bg-brand-500 border border-brand-500",
        className
      )}
    >
      <span
        className={cx(
          "outline-none m-0 focus:outline-none transition-all ease-in-out duration-500 inline-block w-3 h-3 transform rounded-full  ",
          {
            "bg-white": checked == value,
            "bg-brand-600": !checked == value,
          }
        )}
      />
    </Switch>
  );
};

export default RadioButton;
