import React from "react";
import cx from "classnames";
/**
 * Import icons
 */
import pencil from "./src/pencil.svg";
import garbage from "./src/garbage.svg";
import arrow from "./src/arrow.svg";
import close from "./src/close.svg";

import checkError from "./src/check-error.svg";
import checkOk from "./src/check-ok.svg";
import checkFeedback from "./src/check-feedback.svg";

import accountNeutral from "./src/account-neutral.svg";

import fullscreen from "./src/fullscreen.svg";
import singleScreen from "./src/single-screen.svg";
import dualScreen from "./src/dual-screen.svg";
import multipleScreen from "./src/multiple-screen.svg";
import promotionDiamond from "./src/promotion-diamond.svg";
import screenOriginal from "./src/screen-original.svg";
import sliderArrow from "./src/slider-arrow.svg";
import timer from "./src/timer.svg";
import expandRow from "./src/expand-row.svg";
import minimizeRow from "./src/minimize-row.svg";
import all from "./src/all.svg";
import favorites from "./src/favorites.svg";
import jackpots from "./src/jackpots.svg";
import newIcon from "./src/new.svg";
import popular from "./src/popular.svg";
import slots from "./src/slots.svg";
import table from "./src/table.svg";
import celebrate from "./src/celebrate.svg";
import search from "./src/search.svg";
import diamond from "./src/diamond.svg";
import dealer from "./src/dealer.svg";
import flagDK from "./src/flag-dk.svg";
import tournamentColor from "./src/tournaments-color.svg";
import user from "./src/user.svg";
import home from "./src/home.svg";
import card from "./src/credit-card.svg";
import minus from "./src/minus.svg";
import plus from "./src/plus.svg";
import trash from "./src/trash.svg";

import eyeClosed from "./src/eye-closed.svg";
import eye from "./src/eye.svg";
import medal from "./src/medal.svg";
import noBGMedal from "./src/medal-nobg.svg";

import checkOutline from "./src/check-ok-outline.svg";
import document from "./src/document.svg";
import file from "./src/file.svg";
import homeOutline from "./src/home-outline.svg";
import infoOutline from "./src/info-outline.svg";
import lockOutline from "./src/lock-outline.svg";
import paymentCard from "./src/payment-card.svg";
import upload from "./src/upload.svg";
import userDocument from "./src/user-document.svg";
import userOutline from "./src/user-outline.svg";

const icons = {
  card,
  minus,
  plus,
  pencil,
  garbage,
  arrow,
  close,
  accountNeutral,
  checkError,
  checkOk,
  checkFeedback,
  fullscreen,
  singleScreen,
  dualScreen,
  multipleScreen,
  promotionDiamond,
  screenOriginal,
  sliderArrow,
  timer,
  expandRow,
  minimizeRow,
  all,
  favorites,
  jackpots,
  new: newIcon,
  popular,
  slots,
  table,
  celebrate,
  search,
  diamond,
  dealer,
  "flag-dk": flagDK,
  tournamentColor,
  user,
  home,
  eyeClosed,
  eye,
  medal,
  noBGMedal,
  trash,
  checkOutline,
  document,
  file,
  homeOutline,
  infoOutline,
  lockOutline,
  paymentCard,
  upload,
  userDocument,
  userOutline,
};

const Icon = ({ height, width, className, icon }) => (
  <svg width={width} height={height} className={cx("fill-current", className)}>
    <use xlinkHref={`${icons[icon]}#root`} />
  </svg>
);
export default Icon;
