import AutobahnReact from '../../core/websocket';
import { postCall } from '../../core/softswiss';

export const getPaymentMethods = () => AutobahnReact('everymatrix').call('/user/withdraw#getPaymentMethods');

export const getPaymentMethodCfg = (key, paymentMethodCode, payCardID = null) => AutobahnReact('everymatrix').call('/user/withdraw#getPaymentMethodCfg', {
  paymentMethodCode,
  payCardID,
});

export const prepare = (paymentMethodCode, fields) => AutobahnReact('everymatrix').call('/user/withdraw#prepare', {
  paymentMethodCode,
  fields,
  returnURL: 'https://dev.vindercasino.dk/',
});

export const confirm = (pid) => AutobahnReact('everymatrix').call('/user/withdraw#confirm', {
  pid,
});

export const subscribeWithdrawChanges = (callback) => AutobahnReact('everymatrix').subscribe('/withdraw/statusChanged', (result) => callback(result.argsDict));

export const getWithdrawTransactionInfo = (key, pid) => AutobahnReact('everymatrix').call('/user/withdraw#getTransactionInfo', {
  pid,
});

export const getPendingWithdrawals = () => AutobahnReact('everymatrix').call('/user/withdraw#getPendingWithdrawals');

export const rollbackWithdraw = (id) => postCall(`/api/player/payments/${id}/recall`);
