import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import useSWR from "swr";
import Link from "@components/Link";
import { useWallet } from "@components/Provide/WalletContext";
import cx from "classnames";
import payop from "../../../../public/img/logos/payop-payop_online_bank_transfer.svg";

import CurrencyChange from "../DepositForm/CurrencyChange/CurrencyChange";
import { useAuth } from "../../Provide/AuthContext";
import { getCurrencies, getGamingAccounts } from "../../../data/model/wallet";
import { openNavigation } from "@actions/navigation";
import { useDispatch } from "react-redux";
import { postCall } from "../../../core/softswiss";
import { getIpInfo } from "../../../data/model/session";
import { useDevice } from "../../Provide/DeviceContext";
import { useModal } from "../../Modal/ModalContext";
import getProvidersQuery from "@components/GameBrowser/Templates/ProvidersList/get-providers.graphql";
import { useQuery } from "@apollo/client";
import Icon from "../../Icon/Icon";
import { isBrowser } from "browser-or-node";
import { getDomainName } from "../../../core/utils";
import { random } from "lodash";

const ENDPOINT = isBrowser
  ? `https://backend.${getDomainName(window.location.hostname)}`
  : "https://backend.theclubhousecasino.com";

const titleize = (slug) => {
  if (slug === "redirect_deposit") {
    return "Sofort banking";
  }
  var words = slug?.split("_");

  return words
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(" ");
};

const getIconCDNNameFallback = (currency, paymentMethod) => {
  if (paymentMethod?.icon_cdn_name) {
    return `https://cdn2.softswiss.net/logos/payments/white/${paymentMethod.icon_cdn_name}.svg`;
  }
  // console.log("paymentMethod", paymentMethod);
  if (paymentMethod?.brand === "visa") {
    return `https://cdn2.softswiss.net/logos/payments/white/visa.svg`;
  }

  if (paymentMethod?.brand === "mastercard") {
    return `https://cdn2.softswiss.net/logos/payments/white/mastercard.svg`;
  }

  if (
    paymentMethod?.brand === "redirect_deposit" &&
    paymentMethod.provider === "finteqhub"
  ) {
    return payop;
  }

  if (paymentMethod?.provider === "payop") {
    return "https://cdn2.softswiss.net/logos/payments/white/bank_transfer.svg";
  }
  if (paymentMethod?.brand === "volt") {
    return "https://cdn2.softswiss.net/logos/payments/white/devcode_volt_white.svg";
  }
  if (paymentMethod.provider === paymentMethod?.brand) {
    paymentMethod.icon_cdn_name = paymentMethod.provider;
  } else {
    paymentMethod.icon_cdn_name =
      paymentMethod.provider + "-" + paymentMethod?.brand;
  }

  if (paymentMethod.provider === "coinspaid") {
    paymentMethod.icon_cdn_name = paymentMethod.currency
      ? paymentMethod.currency + "_" + paymentMethod.icon_cdn_name
      : paymentMethod?.termsOfService?.restrictions?.amountCurrencyCode
      ? paymentMethod.termsOfService.restrictions.amountCurrencyCode +
        "_" +
        paymentMethod.icon_cdn_name
      : paymentMethod.icon_cdn_name;
  }
  if (paymentMethod?.brand === "sticpay") {
    paymentMethod.icon_cdn_name =
      paymentMethod.provider + "_" + paymentMethod?.brand;
  }

  //   <a href="https://casino.com/payments" target="_blank"><img src="{{$root.CDN.SERVER}}/logos/payments/white/devcode_sticpay.svg
  //
  // <a href="https://casino.com/payments" target="_blank"><img src="{{$root.CDN.SERVER}}/logos/payments/white/rapid-transfer.svg

  if (paymentMethod?.brand === "rapidtransfer") {
    paymentMethod.icon_cdn_name = "rapid-transfer";
  }

  if (
    ["skrill", "eco_payz", "neosurf", "neteller", "paysafecard"].includes(
      paymentMethod?.brand
    )
  ) {
    paymentMethod.icon_cdn_name = paymentMethod?.brand.replace(/_/g, "");
  }
  if (["inpay", "netbanking"].includes(paymentMethod?.brand)) {
    paymentMethod.icon_cdn_name = "bank_transfer";
  }

  if (
    paymentMethod.provider === "accentpay" &&
    paymentMethod?.brand === "card"
  ) {
    //if (action && service.data[action].selectedCurrency) {
    paymentMethod.icon_cdn_name = currency + "_" + paymentMethod.icon_cdn_name;
    //}
  }

  return `https://cdn2.softswiss.net/logos/payments/white/${paymentMethod.icon_cdn_name}.svg`;
};

const DepositList = ({
  values,
  setStatus,
  setFieldValue,
  setMenuItem,
  isSubmitting,
  isValid,
  errors,
  hideModal,
}) => {
  const { currency } = useWallet();
  const { data: ipInfo } = useSWR("getIpInfo", getIpInfo);
  console.log("test ipinfo", ipInfo);
  const [paymentMethods, setPaymentMethods] = useState([]);
  // console.log('paymentMethods--paymentMethods',paymentMethods);
  //do not delete
  const { data: methods } = useSWR(
    [`fetchPaymentMethods_${random(20)}`, currency, ipInfo?.code],
    (url, currency) =>
      window.PaymentsAPI.config({
        serverUrl: ENDPOINT,
        apiPrefix: "/api",
        customCdnUrl: "https://cdn2.softswiss.net/logos/payments/white/",
      }).then(() =>
        window.PaymentsAPI.getMethods({
          currency: currency,
          paymentAction: "deposit",
        }).then((e) => {
          setPaymentMethods(e);
        })
      )
  );

  const { data: paymentProviders } = useQuery(getProvidersQuery, {
    variables: { psection: "Payment Provider" },
  });
  // const { data: savedChannels } = useSWR(
  //   ["fetchSavedDepositChannels", currency, ipInfo?.code],
  //   () => window?.PaymentsAPI?.savedChannels("deposit")
  // );
  const intl = useIntl();
  const { isAuthenticated } = useAuth();
  const { data: wallets } = useSWR(
    isAuthenticated ? "/api/player/accounts" : null,
    getGamingAccounts,
    { refreshInterval: 5000 }
  );
  const activeWallets = wallets?.filter((item) => item.active === true);
  const [currencySymbol, setCurrencySymbol] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const open = (section) => dispatch(openNavigation(section ?? "Root"));

  const { data: currencies } = useSWR("getCurrencies", getCurrencies);
  let currentWallet = currencies?.filter((cur) => cur?.code === currency);
  const { isMobile } = useDevice();
  const { showModal } = useModal();
  //  let availableWallets = [];
  // const removeExistentWallets = (
  //   currencies?.forEach((item) => {
  //     activeWallets?.forEach((wallet) => {
  //       if(item.code === wallet.currency){
  //         availableWallets.push(wallet)
  //       }
  //     })
  //   })
  // );

  // console.log("paymentProviders", paymentProviders);
  // console.log("paymentProviders methods", paymentMethods);

  const SetImagePath = (imagePath) => {
    if (imagePath !== undefined) {
      let start = imagePath.charAt(0);

      if (start !== "/") {
        return "/" + imagePath;
      }
    }

    return imagePath;
  };

  const getIconCDNName = (softswissID, method, currency) => {
    return getIconCDNNameFallback(currency, method);
    let item = paymentProviders?.ProvidersBySection?.find(
      (x) => x.softswiss_id === softswissID
    );

    if (!item) {
      return getIconCDNNameFallback(currency, method);
    }
    return `${COCKPITCFG.url}${
      SetImagePath(item?.image?.path) ?? `/${softswissID}`
    }`;
  };

  const handleCurrencyChange = (curr) => {
    setIsLoading(true);
    setFieldValue("method", null);
    postCall(`/api/player/accounts?compatibility=false&currency=${curr}`)
      .then(() => {
        setIsLoading(false);
        console.log("currency change success");
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("currency change failed", err);
      });
  };

  useEffect(() => {
    if (paymentMethods && !values?.method) {
      setFieldValue("method", paymentMethods[0]);
      // if (paymentMethods.length === 1 && paymentMethods[0].type === "crypto") {
      //   setFieldValue("method", paymentMethods[0]);
      // } else {
      //   setFieldValue(
      //     "method",
      //     paymentMethods?.find((item) => item.brand === "creditcard")
      //   );
      // }
    }
  }, [paymentMethods]);

  const addNewCurrency = (x) => {
    hideModal();
    showModal(x);
  };

  // paymentMethods?.map((item) => {
  //   const savedMethod = window.PaymentsAPI.savedChannels('deposit', item);
  //   ifsavedMethod.length > 0)
  // });

  const cards = [];
  // paymentMethods?.map((item) => {
  //   const savedCards = window.PaymentsAPI.savedChannels('deposit', item);
  //   if(savedCards.length > 0) {
  //     savedCards.map((savedItem) => {

  //       console.log('savedCards',savedCards);
  //       const card = window.PaymentsAPI.SUPPORTED_PROVIDERS.includes(item.provider) && window.PaymentsAPI.isPaymentMethodEnabled('deposit', item)  &&
  //         <Link trackName={"Payment Method "}
  //         trackParams={{component: 'DepositList', storedTitle: savedItem.storedTitle}} onClick={() => {
  //           setFieldValue('method', item);
  //           setFieldValue('identifier', savedItem.identifier);
  //           setStatus({ step: 'form' });
  //         }}
  //               className="relative flex flex-col items-center justify-center w-full h-24 mb-4 rounded bg-brand-600">
  //           <div className="flex items-center justify-center flex-1"><img src={item?.brand === 'combined' ? `https://cdn2.softswiss.net/logos/payments/white/Interac_new_white.svg` : getIconCDNName(currency, item)} className={cx("opacity-50", {'h-12': item.provider === 'payop' || item.provider === 'finteqhub', 'h-9' : item.provider !== 'payop'})}/></div>

  //           <div className="flex flex-row items-end justify-between w-full px-4 pb-2">
  //             <span className="font-bold text-brand-400">{savedItem.storedTitle}</span>
  //             <span className="text-sm text-brand-400">
  //               {intl.formatNumber(item?.deposit?.min, {
  //                 style: 'currency',
  //                 currency: currency,
  //                 minimumFractionDigits: (currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1) ?? 2,
  //                 maximumFractionDigits: (currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1) ?? 2,
  //               })}
  //               {' '}-{' '}
  //               {intl.formatNumber(item?.deposit?.max, {
  //                 style: 'currency',
  //                 currency: currency,
  //                 minimumFractionDigits: (currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1) ?? 2,
  //                 maximumFractionDigits: (currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1) ?? 2,
  //               })}
  //             </span>
  //           </div>
  //         </Link>

  //       if(card) {
  //         cards.push(card);
  //       }
  //     })
  //     }
  //   });

  return (
    <div className="flex flex-col w-full px-4 text-white md:px-2 md:w-2/3">
      {/* <h4 className="mb-16 text-2xl font-light leading-5 text-center text-white">
        {intl.formatMessage({
          defaultMessage: 'Deposit',
          description: 'header',
        })}
      </h4> */}
      <div className="relative flex flex-col items-stretch w-full pb-5 mt-3 md:mt-0">
        <CurrencyChange
          open={open}
          setMenuItem={addNewCurrency}
          onChange={(curr) => handleCurrencyChange(curr)}
          wallets={activeWallets}
          currency={currency}
          currencySymbol={currencySymbol}
          setCurrencySymbol={setCurrencySymbol}
        />
        {/* setNewCurrency={} currencySymbol={} setCurrencySymbol={} */}
      </div>
      <div className="items-center justify-center h-full md:overflow-y-auto h-full">
        {/* {cards?.length > 0 && (
          <span>
            {intl.formatMessage({
              defaultMessage: "Your stored card(s)",
              description: "Deposit card header",
            })}
          </span>
        )} */}
        {cards?.length > 0 && <div className="flex flex-col pt-4">{cards}</div>}
        <span className="text-lg md:pl-3">
          {intl.formatMessage({
            defaultMessage: "Choose your deposit method",
            description: "Deposit list header",
          })}
        </span>
        {!paymentMethods && paymentMethods?.length === 0 ? (
          <div className="pt-3 pl-3">
            <span className="text-white animate-pulse">loading...</span>
          </div>
        ) : (
          <div className="block pt-3 pl-8 space-x-3 space-y-0 overflow-auto md:overflow-visible md:space-y-0 md:space-x-0 whitespace-nowrap md:whitespace-normal md:p-3 3xs:px-0 md:grid md:gap-4 md:grid-cols-2 md:overflow-y-auto">
            {paymentMethods?.map((item, index) => (
              // window.PaymentsAPI.SUPPORTED_PROVIDERS.includes(item.provider)  && window.PaymentsAPI.isPaymentMethodEnabled('deposit', item) && window.PaymentsAPI.isNewPaymentEnabled('deposit', item) &&
              <Link
                trackName={`Select payment ${item.provider}`}
                trackParams={{ component: "DepositList" }}
                onClick={() => {
                  setFieldValue("paymentPlan", null);
                  setFieldValue("method", item);
                  setFieldValue("identifier", null);
                  setStatus({ step: "form" });
                }}
                className={`relative inline-flex  flex-col rounded-lg md:inline-flex items-center justify-center p-2 bg-brand-600 transition-transform transform cursor-pointer md:hover:scale-105 w-28 h-20  aspect-w-16 aspect-h-10  md:w-full`}
                style={
                  values?.method &&
                  (item?.id === values?.method.id
                    ? { border: `solid 2px` }
                    : {})
                }
              >
                <div className="flex items-center justify-center p-2 ">
                  {item?.brand !== "creditecard" ? (
                    <img
                      id={item.id}
                      src={
                        item?.brand === "combined"
                          ? `https://cdn2.softswiss.net/logos/payments/white/finteqhub-interac_finteqhub_cashout.svg`
                          : getIconCDNName(item.id, item, currency)
                      }
                      // onError={(e) => {
                      //   let selItem =
                      //     paymentProviders?.ProvidersBySection?.find(
                      //       (x) => x.softswiss_id === item?.id
                      //     );
                      //   e.target.src = `${COCKPITCFG.url}${
                      //     SetImagePath(selItem?.image?.path) ?? `/${item?.id}`
                      //   }`;
                      // }}
                      className={cx("opacity-50 object-contain", {
                        "md:h-9 h-9":
                          item.provider === "payop" ||
                          item.provider === "finteqhub" ||
                          item.brand === "mifinity",
                        "h-6 md:h-6": item.provider !== "payop",
                      })}
                    />
                  ) : (
                    <Icon
                      icon="card"
                      className={`w-6 h-6 ${
                        values?.method && item?.id === values?.method?.id
                          ? "text-gold"
                          : "text-brand-400"
                      }`}
                    />
                  )}
                </div>
                <div className="flex flex-col items-center justify-between w-full px-1 py-1 text-xs text-center h-full">
                  {/* <span className="font-bold text-brand-400">{(item?.brand === 'combined') ? 'Interac®' : titleize(item?.brand)}</span> */}
                  <span className="text-brand-400 block absolute bottom-0 text-brand-400 text-center w-full">
                    {"Min "}
                    {item?.type === "crypto"
                      ? item?.termsOfService?.restrictions?.minAmountValue
                      : intl.formatNumber(
                          item?.termsOfService?.restrictions?.minAmountValue,
                          {
                            style: "currency",
                            currency:
                              item?.termsOfService?.restrictions
                                ?.amountCurrencyCode ?? currency,
                            minimumFractionDigits:
                              currentWallet?.[0]?.subunits_to_unit?.toString()
                                ?.length - 1 ?? 2,
                            maximumFractionDigits:
                              currentWallet?.[0]?.subunits_to_unit?.toString()
                                ?.length - 1 ?? 2,
                          }
                        )}
                    {/* {' '}-{' '}
                    { item?.deposit?.max ?
                      (intl.formatNumber(item?.deposit?.max, {
                        style: 'currency',
                        currency: item?.deposit?.currency ?? currency,
                        minimumFractionDigits: (currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1) ?? 2,
                        maximumFractionDigits: (currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1) ?? 2,
                      })) :
                      intl.formatMessage({
                        defaultMessage: "No max",
                        description: "label",
                      })
                    } */}
                  </span>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default DepositList;
