import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import cx from "classnames";
import useSession from "@hooks/useSession";
import { Transition } from "@headlessui/react";
import Button from "@components/Button";
import { updateProfile, player_fields } from "@data/model/account";
import { PasswordField, TextField, MaskField } from "@components/Formik";
import SubmitFeedback from "@components/Formik/SubmitFeedback";
import useSWR from "swr";
import ChangeDetailsSchema from "./change-details.validation";
import ToggleSwitch from "../../../Formik/ToggleSwitch";
import { useTracking } from "@hooks/useTracking";

const ChangeDetails = ({
  onSuccess,
  setMenuItem,
  setBackActions,
  setSection,
}) => {
  useEffect(
    () =>
      setBackActions((prevState) => {
        prevState.push((x) => {
          setSection("Main");
          setMenuItem("Profile");
        });
        return prevState;
      }),
    []
  );
  const { track } = useTracking();
  const formikRef = useRef();

  const intl = useIntl();
  const { error: error_fields, data: fields } = useSWR(
    "player_fields",
    player_fields
  );

  const { session, isLoading } = useSession();
  // console.log("session--session", session);
  const submit = async (values, { setStatus, resetForm, setFieldError }) => {
    const formattedValues = {
      ...values,
      firstname: values.first_name,
      surname: values.last_name,
      address1: values.address,
      birthDate: values.date_of_birth,
      postalCode: values.postal_code,
      city: values.city,
    };
    console.log("formattedValues--formattedValues", formattedValues);
    updateProfile("edition", formattedValues)
      .then(() => {
        track("Profile detail changes success", formattedValues);
        resetForm();
        setStatus("success");
      })
      .catch((error) => {
        track("Profile detail changes error", { error: error });
        setFieldError("submitError", error.desc);
      });
  };

  const birthDateMask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];

  const handleBirthdate = (e, field, meta, helpers) => {
    const { value } = e.currentTarget;

    if (!value) {
      formikRef?.current?.setFieldValue("isEnterBirthDate", false);
    } else {
      formikRef?.current?.setFieldValue("isEnterBirthDate", true);
      const values = value.split("-");
      if (values?.length === 3) {
        formikRef?.current?.setFieldValue(
          "date_of_birth",
          `${values[0]}-${values[1]}-${values[2]}`
        );
      } else {
        formikRef?.current?.setFieldValue("birthDateInput", value);
      }
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      validationSchema={ChangeDetailsSchema(intl)}
      initialStatus="init"
      enableReinitialize
      initialTouched={{}}
      initialErrors={
        {
          // birthDateInput: session.date_of_birth
          //   ? undefined
          //   : intl.formatMessage({
          //       defaultMessage: "Your Birthdate is required",
          //       description:
          //         "The feedback for Birthdate input verification - required",
          //     }),
          // email: 'Required',
          // password: 'Required',
          // captcha: 'Required',
        }
      }
      initialValues={{
        ...session,
        birthDateInput: session.date_of_birth || "",
        isEnterBirthDate: false,
      }}
      onSubmit={(fields, actions) => submit(fields, actions).then(onSuccess)}
    >
      {({ isSubmitting, status, isValid, setFieldValue, errors }) => (
        <Form>
          <h4 className="mb-16 text-2xl font-light leading-5 text-center text-white  sticky top-0 bg-[#13161d] max-w-full ms-auto me-auto pt-0 pb-[11px] z-[9] ml-0">
            {intl.formatMessage({
              defaultMessage: "My profile",
              description: "Header of section",
            })}
          </h4>
          <Transition
            show={status === "init"}
            leave="transition ease-in duration-500"
            leaveFrom="transform opacity-100"
            leaveTo="transform -translate-x-80 opacity-0"
            className="flex flex-col w-full"
            unmount={false}
          >
            <ErrorMessage name="submitError">
              {(msg) => <div className="mt-8 text-sm text-red">{msg}</div>}
            </ErrorMessage>
            <div className="mx-auto mt-5.5 flex-col flex w-full items-stretch px-2.5 mb-10 md:mb-0 rtl:text-right">
              <TextField
                id="first_name"
                name="first_name"
                label={intl.formatMessage({
                  defaultMessage: "First name",
                  description: "Label of inputfield",
                })}
                className=""
                // hint={intl.formatMessage({
                //   defaultMessage: 'Please enter your name as indicated on your ID',
                //   description: 'Hint of inputfield',
                // })}
                disabled={session?.first_name}
              />
              <TextField
                id="last_name"
                name="last_name"
                label={intl.formatMessage({
                  defaultMessage: "Surname",
                  description: "Label of inputfield",
                })}
                // hint={intl.formatMessage({
                //   defaultMessage: 'Please enter your name as indicated on your ID',
                //   description: 'Hint of inputfield',
                // })}
                className="mt-8"
                disabled={session?.last_name}
              />
              <TextField
                id="email"
                name="email"
                label={intl.formatMessage({
                  defaultMessage: "E-mail",
                  description: "Label of inputfield",
                })}
                // hint={intl.formatMessage({
                //   defaultMessage: 'Please enter your name as indicated on your ID',
                //   description: 'Hint of inputfield',
                // })}
                className="mt-8"
                disabled
              />

              <div className="flex flex-row justify-start align-start">
                <TextField
                  id="address"
                  name="address"
                  className="mt-8 mr-8 w-52"
                  label={intl.formatMessage({
                    defaultMessage: "Address",
                    description: "Label of inputfield",
                  })}
                  // hint={intl.formatMessage({
                  //   defaultMessage: 'Please enter your address',
                  //   description: 'Hint of inputfield',
                  // })}
                  disabled={session?.address}
                />
                {/* <TextField
                  id="date_of_birth"
                  name="date_of_birth"
                  className="flex flex-1 mt-8"
                  label={intl.formatMessage({
                    defaultMessage: "Date of birth",
                    description: "Label of inputfield",
                  })}
                  // hint={intl.formatMessage({
                  //   defaultMessage: 'Please enter your date of birth',
                  //   description: 'Hint of inputfield',
                  // })}
                  disabled={session?.date_of_birth}
                /> */}
                <MaskField
                  showFeedbackText
                  mask={birthDateMask}
                  id="birthDateInput"
                  name="birthDateInput"
                  label={intl.formatMessage({
                    defaultMessage: "Date of birth",
                    description: "Label of inputfield",
                  })}
                  placeholder={"YYYY-MM-DD"}
                  className="flex-1 mt-8 "
                  onChange={handleBirthdate}
                  typeError={"warning"}
                  disabled={session?.date_of_birth}
                />
              </div>
              <div className="flex flex-row justify-start align-start">
                <TextField
                  id="postal_code"
                  name="postal_code"
                  label={intl.formatMessage({
                    defaultMessage: "Post Code",
                    description: "Label of inputfield",
                  })}
                  className="w-32 mt-8 mr-8"
                  disabled={session?.postal_code}
                />
                <TextField
                  id="city"
                  name="city"
                  className="flex flex-1 mt-8"
                  label={intl.formatMessage({
                    defaultMessage: "City",
                    description: "Label of inputfield",
                  })}
                  disabled={session?.city}
                />
              </div>
              <div className="flex items-center justify-start mt-8">
                <ToggleSwitch
                  name="receive_promos"
                  // onChange={setTermsAndConditions}
                />{" "}
                <span className="ml-2">
                  {intl.formatMessage({
                    defaultMessage: "Receive e-mail promotions",
                    description: "Label T&C consent",
                  })}
                </span>
              </div>
              <div className="flex items-center justify-start mt-8">
                <ToggleSwitch
                  name="receive_sms_promos"
                  // onChange={setTermsAndConditions}
                />{" "}
                <span className="ml-2">
                  {intl.formatMessage({
                    defaultMessage: "Receive sms promotions",
                    description: "Label T&C consent",
                  })}
                </span>
              </div>
            </div>

            <div className="flex flex-row justify-center px-4 my-8 items-left">
              <Button.Action
                className={cx("w-full", { "animate-pulse": isSubmitting })}
                disabled={!!isSubmitting || !isValid}
                type="submit"
                arrow="right"
              >
                {isSubmitting
                  ? intl.formatMessage({
                      defaultMessage: "Saving...",
                      description:
                        "When submitting a button this generic terms is used while waiting",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Save details",
                      description: "The label on a button for saving",
                    })}
              </Button.Action>
            </div>
          </Transition>
          <SubmitFeedback
            status={status}
            header={intl.formatMessage({
              defaultMessage: "Confirmed",
              description: "The feedback header",
            })}
          >
            <div className="p-8 leading-7 text-24">
              {intl.formatMessage({
                defaultMessage: "Your personal details have been update.",
                description: "The feedback after changing account details",
              })}
            </div>
            <div className="px-8">
              <Button.Action
                onClick={() => setMenuItem("Root")}
                className="w-full"
                arrow="right"
              >
                {intl.formatMessage({
                  defaultMessage: "Back to my account",
                  description: "Label",
                })}
              </Button.Action>
            </div>
          </SubmitFeedback>
        </Form>
      )}
    </Formik>
  );
};

export default ChangeDetails;
