import React, { useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import useSWR from "swr";
// import useSWRImmutable from "swr/immutable";
import {
  // getAllowedGames,
  // getBackendOrderedGames,
  getRecentGames,
} from "../../data/model/casino";
import { useAuth } from "../Provide/AuthContext";
// import getFavoritesQuery from "./get-favorites.graphql";
import { useDevice } from "../Provide/DeviceContext";
import { useWallet } from "../Provide/WalletContext";
import getSoftSwissGamesQuery from "./get-softswiss-games.graphql";
import getSoftSwissGamesByIdsQuery from "./get-softswiss-games-by-ids.graphql";
import useSession from "../../hooks/useSession";

// @todo, move to single function
const Games = ({
  subSelected = { fullId: "all", id: "all", originalId: "All" },
    page = 1,
  ...props
}) => {
  const { isMobile } = useDevice();
  const { isAuthenticated } = useAuth();
  const { currency } = useWallet();
  const { userCountry } = useSession();

  // const [favoriteFetch, { data: favoriteGames }] =
  //   useLazyQuery(getFavoritesQuery);

  const { loading: loadingGameCategories, data } = useQuery(
    getSoftSwissGamesQuery,
    {
      variables: {
        device: isMobile?.any ? "mobile" : "desktop",
        currencies: [currency],
        categories: [subSelected?.fullId],
        page,
        userCountry
      },
    }
  );

  const [
    getGamesByIds,
    { loading: loadingGetGamesByIds, data: getGamesByIdsData },
  ] = useLazyQuery(getSoftSwissGamesByIdsQuery, {
    onCompleted: (data) => {
      // console.log("recent--played--games", data);
    },
  });

  // console.log("data--data--getSoftSwissGames",subSelected, data);
  // const {
  //   loading,
  //   error,
  //   data: { getGames: allGames } = { getGames: [] },
  // } = useQuery(getGamesQuery, { variables: { isMobile: isMobile?.any, isDemo: !isAuthenticated, currency } });

  // const { error, data: allGamesWithoutCurrFilter = [] } = useSWRImmutable(
  //   ["getBackendOrderedGames", isMobile?.any, subSelected],
  //   getBackendOrderedGames
  // );
  // const allGamesWithoutCurrFilter = data?.getSoftSwissGames || [];

  // console.log("allGamesWithoutCurrFilter", allGamesWithoutCurrFilter);

  // const allGames = allGamesWithoutCurrFilter?.filter(
  //   (item) => item?.real?.[currency]
  // );
  const allGames = data?.getSoftSwissGames || [];

  const { data: recentGamesWithoutCurrFilter = [] } = useSWR(
    isAuthenticated ? "getRecentGames" : null,
    (url) => getRecentGames()
  );

  useEffect(() => {
    if (isAuthenticated && recentGamesWithoutCurrFilter?.length > 0) {
      getGamesByIds({
        variables: {
          gameIds: recentGamesWithoutCurrFilter?.map((x) => x.identifier),
          userCountry
        },
      });
    }
  }, [recentGamesWithoutCurrFilter, isAuthenticated]);
  const recentGames = recentGamesWithoutCurrFilter;

  // const { data: allUnorderedGamesWithoutCurrFilter = [] } = useSWRImmutable(
  //   ["getAllowedGames", isMobile?.any],
  //   getAllowedGames
  // );

  // const allUnorderedGames = allUnorderedGamesWithoutCurrFilter?.filter(
  //   (item) => item?.real?.[currency]
  // );
  //
  // useEffect(() => {
  //   async function fetchData() {
  //     // You can await here
  //     const result = await getCmsSessionID();
  //     favoriteFetch({
  //       variables: {
  //         playerId: '3620060',
  //         session: result.cmsSessionID,
  //       },
  //     });
  //   }
  //   if (isAuthenticated) {
  //     fetchData();
  //   }
  // }, [isAuthenticated]);

  const getGroup = (type = "Casino", filterGroupIncoming) => {
    console.log("filterGroupIncoming", filterGroupIncoming);
    const [id] = filterGroupIncoming?.split(":") ?? [];
    const [filterGroup] = id?.split?.("~") ?? [];

    console.log("filterGroup", filterGroup);
    //
    // const filteredGroupGames = allGames?.filter((item) =>
    //   item?.collections?.includes(filterGroup)
    // );

    if (filterGroup === "home|recently_played") {
      // console.log("home|recently_played");
      // console.log("recentGames--", recentGames);
      // const recentList = [];
      // // let sortedRecentList = recentGames?.sort((a,b) => a.last_activity_at = b.last_activity_at)
      // recentGames?.map((item) => {
      //   const foundgame = allUnorderedGames?.find(
      //     (game) => game.id === item.identifier
      //   );
      //   // console.log('foundgame', sortedRecentList);
      //   if (foundgame) {
      //     recentList.push(foundgame);
      //   }
      // });
      console.log("getGamesByIdsData", getGamesByIdsData);
      return getGamesByIdsData?.getSoftSwissGamesByIds;
    }

    console.log("filterGroup", allGames);
    return allGames ?? [];
  };

  // const getCategory = (filterCategory) => {
    // switch (filterCategory) {
    //   case "POPULARLIVE":
    //   case "BLACKJACK":
    //   case "ROULETTE":
    //   case "BACCARAT":
    //     const liveGames = allGames?.filter(
    //       (item) => item?.details?.category === filterCategory
    //     );
    //     return liveGames ?? [];

    //   case "GAMESHOWS":
    //     const gameShowList = allGames?.filter((item) =>
    //       [
    //         "TeenPatii",
    //         "Monopoly",
    //         "Holdem",
    //         "DealNoDeal",
    //         "SidebetCity",
    //         "Craps",
    //         "Crazytime",
    //       ].includes(item?.details?.vendorCategory)
    //     );
    //     return gameShowList ?? [];

    //   case "OTHER":
    //     const otherList = allGames?.filter((item) =>
    //       ["HOLDEM", "OTHER", "LOTTERY", "POKER", "SICBO"].includes(
    //         item?.details?.category
    //       )
    //     );
    //     return otherList ?? [];
    //   case "LIVE":
    //     const live = allGames?.filter((item) => item?.details);
    //     return live ?? [];
    //   case "FAVORITES":
    //     const filteredFav = favoriteGames?.getFavorites?.filter(
    //       (item) => item?.real?.[currency]
    //     );
    //     return filteredFav ?? [];
    //   case "TOPWINGAMES":
    //     return [];

    //   case "ALL":
    //     const sorted = [...allGames];
    //     sorted.sort((a, b) => a.name.localeCompare(b.name));
    //     return sorted ?? [];

    //   case "ALL-LIVE":
    //     const filteredLiveGames = allGames?.filter((item) =>
    //       item.categories.items.find(
    //         (categoryItem) => categoryItem.id === "LIVEDEALER"
    //       )
    //     );
    //     return filteredLiveGames ?? [];
    //   default:
    //     const filteredGames = allGames?.filter((item) =>
    //       item.categories.items.find(
    //         (categoryItem) => categoryItem.id === filterCategory
    //       )
    //     );
    //     return filteredGames ?? [];
    // }
  // };

  if (!props.children) {
    return null;
  }
  return props.children({
    ...{
      getGroup,
      // getCategory,
      loading: loadingGameCategories,
      allGames,
      recentGames,
    },
  });
};

export default Games;
