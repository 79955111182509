/**
 * QuickDeposit Component
 *
 * Shop options to deposit in the TopBar
 *
 * @category   QuickDeposit
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */
import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import Button from '@components/Button';
import { useModal } from '../../../Modal/ModalContext';
import DepositModal from '../../../DepositModal';
import { useRouter } from '../../../Provide/RouterContext';

const QuickDeposit = ({ setBackActions, setMenuItem }) => {
  const [section, setSection] = useState('Main');
  const router = useRouter();
  useEffect(() => setBackActions((prevState) => {
    prevState.push((x) => {
      setMenuItem('Root');
    }); return prevState;
  }),
  []);
  const intl = useIntl();
  const { showModal } = useModal();
  return (
    <div className="grid grid-cols-2 gap-x-5 gap-y-3 p-2.5">
      <Button.Action 
        trackName="Deposit"
        trackParams={{ component: 'QuickDeposit' }} className="flex justify-center items-center transition duration-150 ease-in-out focus:outline-none" onClick={() => showModal(DepositModal)} arrow="down">
        {intl.formatMessage({
          defaultMessage: 'Deposit',
          description: 'Button label',
        })}
      </Button.Action>
      <Button.Action 
        trackName="Withdraw"
        trackParams={{ component: 'QuickDeposit' }} className="flex justify-center items-center transition duration-150 ease-in-out focus:outline-none" onClick={() => setMenuItem('Withdraw')} arrow="up">
        {intl.formatMessage({
          defaultMessage: 'Withdraw',
          description: 'Button label',
        })}
      </Button.Action>
    </div>
  );
};

export default QuickDeposit;
