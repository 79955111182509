import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import cx from "classnames";
import Navigator from "../Form/Navigator";
import Icon from "../Icon";

// enter="transition-all duration-700"
// enterFrom="transform translate-y-full"
// enterTo="transform translate-y-0"
// leave="transition-all duration-700"
// leaveFrom="transform-gpu translate-y-0"
// leaveTo="transform-gpu translate-y-full"
// className="fixed top-0 left-0 md:absolute w-screen h-screen z-40 bg-gray-lightest"
const BlankModal = ({
  children,
  size = null,
  hideModal,
  title = "",
  hasClose = true,
}) => (
  <>
    <Transition.Child
      enter="transition-all duration-700 md:duration-150"
      enterFrom="transform translate-y-full md:translate-y-0 md:opacity-0"
      enterTo="transform translate-y-0 md:opacity-100"
      leave="transition-all duration-700 md:duration-150"
      leaveFrom="transform translate-y-0 md:opacity-100"
      leaveTo="transform translate-y-full md:translate-y-0 md:opacity-0"
      className="fixed top-0 left-0 md:relative flex flex-1 md:flex-none flex-col w-screen h-full z-50 bg-brand md:bg-transparent overflow-y-auto overflow-hidden"
    >
      {/* <Navigator title={title} closeAction={hideModal} backAction={null} /> */}

      <div
        className={cx(
          "flex flex-1  md:flex-none flex-col bg-brand bg-position-modal shadow-menu w-screen md:rounded relative transition transition-all duration-700 m-auto sm:my-10",
          { "md:w-modal-xl": size === "xl", "md:w-modal": !size }
        )}
      >
        {hasClose && (
          <a
            onClick={hideModal}
            className="rounded-full shadow-lg absolute bg-yellow top-5 right-5 p-2 transform transition-transform hover:scale-110 cursor-pointer hidden md:block"
          >
            <Icon icon="close" className="h-4 w-4 text-white" />
          </a>
        )}
        {children}
      </div>
    </Transition.Child>
    <Transition.Child
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog.Overlay className="hidden md:block absolute bg-black bg-opacity-70 h-screen w-screen top-0 l-0 z-40" />
    </Transition.Child>
  </>
);

export default BlankModal;
