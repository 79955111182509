// import { useEffect } from "react";

// const UseScript = (url, autoRemove = false) => {
//   useEffect(() => {
//     const script = document.createElement("script");

//     script.src = url;
//     script.async = true;
//     script.defer = true;
//     script.onerror = () => {
//       console.log("Error: Failed to load url", url);
//     };
//     document.body.appendChild(script);

//     return () => {
//       autoRemove && document.body.removeChild(script);
//     };
//   }, [url]);
// };

// export default UseScript;

export const useScript = (url) => {
  const script = document.createElement("script");
  script.async = true;
  script.defer = true;
  script.src = url;
  script.onerror = () => {
    console.log("Error: Failed to load url", url);
  };
  document.head.appendChild(script);
};