import { useField } from "formik";
import cx from "classnames";
import React from "react";
import { Listbox, Transition } from "@headlessui/react";
import Icon from "../../Icon/Icon";

const SelectBox = ({
  options,
  className,
  classNameButton,
  valueKey = "name",
  labelKey = "text",
  hasLabel = false,
  label,
  onChange,
  autoLabel = "",
  setFieldValue = "",
  trigger = "",
  setTrigger = "",
  initialValue,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const toggle = (value) => {
    if (value[valueKey] && label === "service") {
      helpers.setValue(value[valueKey]);
    } else {
      helpers.setValue(value);
    }
  };

  console.log("field select", field);
  console.log("meta select", meta);
  if (initialValue && !field.value) {
    helpers.setValue(initialValue);
  }

  return (
    <div className={cx("flex flex-col", className)}>
      <span className="mb-1 text-xs ltr:ml-3 rtl:mr-3 rtl:text-right text-gray">
        {label}
      </span>
      <div className="w-full ">
        <Listbox
          as="div"
          className={cx(
            "space-y-1 focus:outline-none focus:ring-offset-0 focus:ring-transparent"
          )}
          value={meta.value?.[labelKey]}
          onChange={(value) => {
            autoLabel = "";
            toggle(value);
            onChange?.(value);
            if (
              props.name === "country" &&
              trigger !== "" &&
              typeof setFieldValue !== undefined &&
              setFieldValue !== ""
            ) {
              setFieldValue(value);
              setTrigger(!trigger);
            } else if (
              typeof setFieldValue !== undefined &&
              setFieldValue !== ""
            ) {
              setFieldValue("");
            }
          }}
        >
          {({ open }) => (
            <>
              {/* <Listbox.Label className="block text-sm font-medium leading-5 text-gray-700"> */}
              {/*  Assigned to */}
              {/* </Listbox.Label> */}
              <div className="relative">
                <span className="inline-block w-full rounded-lg">
                  <Listbox.Button
                    className={cx(
                      "cursor-default relative w-full px-3 text-white border rounded-lg bg-brand-600 border-brand-500  pr-10 text-left outline-none ring-offset-0 ring-transparent focus:outline-none focus:ring-offset-0 focus:ring-transparent focus:text-bold ease-in-out duration-150 sm:text-sm sm:leading-5",
                      classNameButton,
                      { "h-10": label },
                      { "h-14": !label }
                    )}
                    style={{
                      borderColor: "#6b7280",
                      borderWidth: "1px",
                    }}
                  >
                    <span className="block overflow-hidden whitespace-nowrap rtl:text-right">
                      {autoLabel === ""
                        ? meta.value?.[labelKey]
                          ? meta.value?.[labelKey]
                          : meta?.value
                        : autoLabel}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <Icon
                        icon="arrow"
                        className={cx("ml-4 h-4 w-4 inline-block transform", {
                          "-rotate-180": open,
                          "-rotate-0": !open,
                        })}
                      />
                      {/* <svg
                      className="w-5 h-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path
                        d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg> */}
                    </span>
                  </Listbox.Button>
                </span>

                <Transition
                  show={open}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute z-20 w-full mt-1 text-white rounded-md bg-brand-600"
                >
                  <Listbox.Options
                    static
                    className="py-1 overflow-auto text-base leading-6 rounded-md shadow-xs max-h-60 focus:outline-none sm:text-sm sm:leading-5"
                  >
                    {options?.map((option, index) => (
                      <Listbox.Option key={option?.[valueKey]} value={option}>
                        {({ selected, active }) => (
                          <div
                            className={`${
                              index !== 0
                                ? " border-t border-brand-500"
                                : " border-0"
                            } 
                            ${
                              active ? "text-white bg-brand-600" : "text-white"
                            } cursor-default select-none relative py-2 mx-2 px-1`}
                          >
                            <span
                              className={`${
                                selected ? "font-semibold" : "font-normal"
                              } block rtl:text-right`}
                            >
                              {option?.[labelKey]}
                            </span>
                            {selected && (
                              <span
                                className={`${
                                  active ? "text-white" : "text-gray-dark"
                                } text-white absolute inset-y-0 left-0 flex items-center pl-1.5`}
                              >
                                <svg
                                  className="w-5 h-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
};
export default SelectBox;
