import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { closeNavigation, openNavigation } from "@actions/navigation";
import Button from "../Button";
import Link from "../Link";
import Success from "./FormSteps/Success";
import Error from "./FormSteps/Error";
import { useDevice } from "../Provide/DeviceContext";
import WelcomePromo from "../Registration/FormSteps/WelcomePromo";
// import { ModalContext } from "../Modal/ModalContext";
import DepositModal from "@components/DepositModal";
import { useModal } from "@components/Modal/ModalContext";

const RegForm = ({
  enableReinitialize,
  children,
  initialValues,
  onSubmit,
  stepNumber,
  setStepNumber,
  hideModal,
  submitLabel,
  showSubmitDesktop = true,
}) => {
  // const [stepNumber, setStepNumber] = useState(0);
  // const { closeModal } = useContext(ModalContext);
  const intl = useIntl();
  const { showModal } = useModal();
  const stepsLabel = [
    intl.formatMessage({
      defaultMessage: "Join",
      description: "Step",
    }),
    intl.formatMessage({
      defaultMessage: "Claim Bonus",
      description: "Step",
    }),
    intl.formatMessage({
      defaultMessage: "Play!",
      description: "Step",
    }),
  ];

  const navigationStatus = useSelector(
    (state) => state.navigation?.params?.status
  );

  const dispatch = useDispatch();

  const { isMobile } = useDevice();
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = (values) => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag);
    }
    if (isLastStep) {
      return onSubmit(values, bag);
    }
    bag.setTouched({});
    next(values);
  };

  const { values } = step.props;
  //
  // const res = {
  //   errors: {
  //     email: {
  //       invalid: 'is invalid',
  //     },
  //     password_confirmation: {
  //       confirmation: "doesn't match Password",
  //     },
  //     profile: {
  //       first_name: {
  //         blank: "can't be blank",
  //       },
  //     },
  //   },
  // };
  //
  // // feedback
  // const messages = [];
  // if (res?.reason) {
  //   if (feedback?.restriction?.[res?.reason]) {
  //     messages.push(feedback?.restriction?.[res?.reason]);
  //   }
  // }
  // if (res?.errors) {
  //   for (const [field, value] of Object.entries(res.errors)) {
  //     for (const [type, error] of Object.entries(value)) {
  //       if (typeof error === 'object') {
  //         for (const [typesub, suberror] of Object.entries(error)) {
  //           messages.push(`${feedback?.labels?.[type] ?? type} ${suberror}`);
  //         }
  //       } else {
  //         messages.push(`${feedback?.labels?.[field] ?? field} ${error}`);
  //       }
  //     }
  //   }
  // }
  //
  // console.log('messages', messages);
  return (
    <Formik
      initialValues={snapshot}
      initialStatus={navigationStatus ?? "inProgress"}
      // initialStatus="success"
      onSubmit={handleSubmit}
      //validationSchema={step.props.validationSchema}
      // initialTouched={{ birthDate: true }}
    >
      {({
        values,
        status,
        dirty,
        setStatus,
        pristine,
        isSubmitting,
        isValid,
        errors,
        touched,
        setFieldValue,
      }) => (
        <Form>
          <h4 className="mb-8 text-2xl font-light leading-5 text-center text-white">
            {status === "success"
              ? intl.formatMessage({
                  defaultMessage: "You're in!",
                  description: "Header of section",
                })
              : intl.formatMessage({
                  defaultMessage: "Create account",
                  description: "Header of section",
                })}
          </h4>

          {status === "inProgress" && (
            <>
              <WelcomePromo />
            </>
          )}
          {/* <p className='mb-8 text-center text-gray'>
            {intl.formatMessage({
              defaultMessage: 'It takes less than a minute to become a Member',
              description: 'label',
            })}
          </p> */}
          {/* <div
            className="flex flex-row items-center justify-between w-full px-10 mx-auto mb-12"
          >
            {stepsLabel.map((child, index) => (
              <>
                <a className={cx('text-xl font-book')}>
                  <div
                    className={cx(
                      'rounded-full ring-2 h-10 w-10 relative flex items-center justify-center',
                      {

                        'ring-gray bg-gray-dark text-gray':
                          (index !== 0 && status !== 'success') || (index >= 2 && status === 'success'),
                        'ring-gold bg-gold text-brand':
                          (index === 0 && stepNumber <= 2) || (index === 1 && status === 'success'),
                      },
                    )}
                  >
                    <span>{index < 2 ? index + 1 : <Icon icon="checkFeedback" className="w-6 h-6 text-gray" />}</span>
                    <span
                      className={cx('absolute top-12 text-sm whitespace-nowrap', {
                        'text-gray': (index !== 0 && status !== 'success') || (index >= 2 && status === 'success'),
                        'text-gold': (index === 0 && stepNumber <= 2) || (index === 1 && status === 'success'),
                      })}
                    >
                      {stepsLabel[index]}
                    </span>
                  </div>
                </a>
                {index < (stepsLabel.length - 1) && (
                  <div
                    className={cx('flex-1 h-1', {
                      'bg-gradient bg-gradient-to-r from-gold to-gray': index === 0 && stepNumber <= 2 && status !== 'success',
                      'bg-gray': index !== 0,
                      'bg-gold': index === 0 && stepNumber <= 2 && status === 'success',
                    })}
                  />
                )}
              </>
            ))}

          </div> */}

          {status === "inProgress" && React.cloneElement(step, { values })}
          {status === "inProgress" && (
            <div className="mt-7.5 flex flex-col items-center text-purple text-sm w-full mx-auto flex-1 justify-between px-2.5">
              {errors?.formState && (
                <>
                  <div className="w-full font-bold text-left">
                    {intl.formatMessage({
                      defaultMessage: "Registration was not complete:",
                      description: "Button text",
                    })}
                  </div>
                  <div className="w-full my-4 text-center text-white rounded-md bg-red">
                    {errors?.formState?.map((item, index) => (
                      <div
                        className={`w-full p-2 ${index !== 0 ? "pb-0" : ""}`}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </>
              )}

              {((!isMobile.any && showSubmitDesktop) || isMobile.any) && (
                <>
                  <Button.Action
                    trackName={isLastStep ? "Create Account" : "Register Next"}
                    trackParams={{ component: "RegForm", step: stepNumber }}
                    className="w-full"
                    arrow="right"
                    //disabled={!dirty || isSubmitting || !isValid}
                    isSubmitting={isSubmitting}
                    submittingLabel="Creating account..."
                    type="submit"
                  >
                    {isLastStep
                      ? submitLabel ??
                        intl.formatMessage({
                          defaultMessage: "Create account",
                          description: "Button text",
                        })
                      : intl.formatMessage({
                          defaultMessage: "Next",
                          description: "Button text",
                        })}
                  </Button.Action>
                  <div className="flex flex-row mt-5 mb-8">
                    <p className="lead">
                      {intl.formatMessage({
                        defaultMessage: "Already a member?",
                        description: "link text",
                      })}{" "}
                      <Link
                        trackName="login"
                        trackParams={{ component: "RegForm" }}
                        onClick={() => {
                          dispatch(openNavigation("Auth"));
                        }}
                        className="mb-8"
                      >
                        {intl.formatMessage({
                          defaultMessage: "Log in here",
                          description: "link text",
                        })}
                      </Link>
                    </p>
                  </div>
                </>
              )}

              {/* {isLastStep
              && (
              <div className="mb-6 -mt-4">
                {' '}
                {intl.formatMessage({
                  defaultMessage: 'By clicking Create Account, you accept our {terms} ',
                  description: 'Label T&C consent',
                }, {
                  terms: <Link trackName="Terms & Conditions"
                    trackParams={{ component: 'RegForm'}}
                    to="termsAndConditions"
                    target="_blank"
                    className="underline text-purple-dark"
                  >
                    {intl.formatMessage({
                      defaultMessage: 'terms & conditions',
                      description: 'Label T&C consent',
                    })}
                         </Link>,
                })}
                {intl.formatMessage({
                  defaultMessage: ', ',
                  description: 'Label T&C consent',
                })}
                {' '}
                <Link trackName="Privacy Policy"
                  trackParams={{ component: 'RegForm'}}
                  to="privacyPolicy"
                  target="_blank"
                  className="underline text-purple-dark"
                >
                  {intl.formatMessage({
                    defaultMessage: 'privacy policy',
                    description: 'privacy policy link',
                  })}
                </Link>
                {' '}
                {intl.formatMessage({
                  defaultMessage: 'and Protection of Customer Funds.',
                  description: 'Protection of Customer Funds label',
                })}
              </div>
              )} */}

              {stepNumber > 0 && (
                <Link
                  trackName="Back"
                  trackParams={{ component: "RegForm" }}
                  onClick={() => previous(values)}
                  className="mb-8"
                >
                  {intl.formatMessage({
                    defaultMessage: "Back",
                    description: "Button text",
                  })}
                </Link>
              )}

              {/* {stepNumber > 0 && ( */}
              {/*  <button onClick={() => previous(formik.values)} type="button"> */}
              {/*    Back */}
              {/*  </button> */}
              {/* )} */}

              {/* <button type="submit">{isLastStep ? "Submit" : "Next"}</button> */}
              {/* <hr className="md:hidden border-gray max-w-md w-full mx-auto mt-14 mb-7.5 bg-gray-lightest" /> */}
            </div>
          )}

          {status === "success" && (
            <Success
              handleClose={() => {
                dispatch(closeNavigation());

                // setStepNumber(0);
                // setStatus("inProgress");
                // closeModal();
              }}
              handleBonus={() => {
                showModal(DepositModal);

                // setStepNumber(0);
                // setStatus("inProgress");
                // closeModal();
              }}
            />
          )}
          {status === "error" && (
            <Error
              handleClose={() => {
                setStepNumber(0);
                setStatus("inProgress");
                // closeModal();
              }}
              setStepNumber={setStepNumber}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default RegForm;
