import * as Yup from "yup";
import {
  YupIsEighteenYearOld,
  YupValidateBirthdate,
} from "../../../../core/validation";

Yup.addMethod(Yup.string, "validateBirthdate", YupValidateBirthdate);
Yup.addMethod(Yup.string, "YupIsEighteen", YupIsEighteenYearOld);

export default (intl) =>
  Yup.object().shape({
    // email: Yup.string()
    //   .email(intl.formatMessage({
    //     defaultMessage: 'This an invalid e-mail address',
    //     description: 'The feedback for email input verification - invalid',
    //   }))
    //   .required(intl.formatMessage({
    //     defaultMessage: 'A valid e-mail address is required',
    //     description: 'The feedback for email input verification - required',
    //   }))
    //   .max(255, intl.formatMessage({
    //     defaultMessage: 'This e-mail address is too long',
    //     description: 'The feedback for email input verification - too long',
    //   })),
    // password: Yup.string()
    //   .required(intl.formatMessage({
    //     defaultMessage: 'A valid password is required',
    //     description: 'The feedback for password input verification - required',
    //   })),
    // captcha: Yup.string().required('Required'),
    birthDateInput: Yup.string()
    .when('isEnterBirthDate', {
      is: true,
      then: Yup.string()
      .validateBirthdate(
        intl.formatMessage({
          defaultMessage: "This is not a valid date",
          description: "Validation",
        })
      )
      .YupIsEighteen(
        intl.formatMessage({
          defaultMessage: "Must be 18 years old or older",
          description:
            "The feedback for Birthdate input Age verification - in use",
        })
      )
   })
      // .required(
      //   intl.formatMessage({
      //     defaultMessage: "Your Birthdate is required",
      //     description:
      //       "The feedback for Birthdate input verification - required",
      //   })
      // )
      // ,
      // date_of_birth: Yup.date()
      // .required(
      //   intl.formatMessage({
      //     defaultMessage: "A DOB is Required",
      //     description: "The feedback for DOB input verification -required",
      //   })
      // ).max(new Date(),
      // intl.formatMessage({
      //   defaultMessage: "A valid DOB is Required",
      //   description: "The feedback for DOB input verification -valid",
      // })
      // ),
  });
