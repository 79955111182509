import React, { useEffect, useRef } from "react";
import { Portal, Transition } from "@headlessui/react";
import Game from "../Game";
import SsrCompatibleSuspense from "../SsrCompatibleSuspense";
import { useDispatch, useSelector } from "react-redux";
import { forceFullyToggleGameModal } from "../../actions/navigation";
import GameAnalytics from "../GameAnalytics";

const GameModal = ({ hideModal, isActive, game, url }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();

  const isGameModalOpen = useSelector(
    (state) => state.navigation.isGameModalOpen
  );

  useEffect(() => {
    if (isGameModalOpen === false) {
      hideModal();
    }
    dispatch(forceFullyToggleGameModal(true));
  }, [isGameModalOpen]);

  return (
    <Transition show={isActive} appear initialFocus={ref}>
      <Portal>
        <div className="fixed top-0 z-50 w-screen h-screen p-0" ref={ref}>
          <SsrCompatibleSuspense fallback={<div>loading</div>}>
            <GameAnalytics>
              <Game game={game} hideModal={hideModal} url={url} />
            </GameAnalytics>
          </SsrCompatibleSuspense>
        </div>
        {/* <Dialog.Title>Game info</Dialog.Title> */}
        {/* <Dialog.Description>For fun game-info</Dialog.Description> */}
      </Portal>
    </Transition>
  );
};

GameModal.displayName = "GameModal";
export default GameModal;
