import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useIntl } from "react-intl";
import useSWR from "swr";
import { useTracking } from "@hooks/useTracking";
import { setBonusCode } from "../../data/model/deposit";
import { getIpInfo, getSessionInfo } from "../../data/model/session";
import DepositList from "./DepositList";
import DepositForm from "./DepositForm";
import DepositSuccess from "./DepositSuccess";
import { localeUrl } from "../../core/url";
import feedback from "../Registration/feedback.json";
import { isBrowser } from "browser-or-node";
import { getDomainName } from "../../core/utils";
import { useWallet } from "../Provide/WalletContext";
import { stats } from "@data/model/account";
import { useAuth } from "../Provide/AuthContext";
import Navigator from "../Form/Navigator";
import { createBrowserHistory } from "history";
import getCountriesQuery from "../LandingOffer/get-countries.graphql";
import getCurrenciesQuery from "../LandingOffer/get-currencies.graphql";
import { useQuery } from "@apollo/client";
import { getPlayerInfo, player_fields } from "../../data/model/account";
import { ValidateProfileValuesNeeded } from "@components/ProfileDynamicFields/ProfileValidations";
import { useDispatch } from "react-redux";
import { openNavigation } from "@actions/navigation";
import { ANALYTICS_DEPOSIT_RETURN_URL } from "../../constants/Analytics";
import { useScript } from "../UseScript";

const ENDPOINT = isBrowser
  ? `https://backend.${getDomainName(window.location.hostname)}`
  : "https://backend.theclubhousecasino.com";
export const Deposit = ({
  initialAmount = "",
  initialBonusCode = "",
  isFromMyClubhousePage,
  hideModal,
  setBackActions,
  setMenuItem,
}) => {
  const { currency } = useWallet();
  const { data: fields = {} } = useSWR("getSessionInfo", getSessionInfo);
  const { data: ipInfo } = useSWR("getIpInfo", getIpInfo);
  const { error: currencyError, data: currencies } =
    useQuery(getCurrenciesQuery);
  const { error: countryError, data: countries } = useQuery(getCountriesQuery);
  const countryInfo = countries?.countries?.find(
    (item) => item?.code === ipInfo?.country_code
  );
  const currencyInfo = currencies?.currencies?.find(
    (item) => item?.code === countryInfo?.currency
  );

  const { data: playerInfo } = useSWR("getPlayerInfo", getPlayerInfo);
  console.log("playerInfo", playerInfo);
  const localeInfo = {
    ...countryInfo,
    ...currencyInfo,
    currency: currencyInfo?.code,
    code: countryInfo?.code,
  };

  const dispatch = useDispatch();

  const [userDepositDetails, setUserDepositDetails] = useState({});

  const { isAuthenticated } = useAuth();
  const { data: playerStats } = useSWR(
    isAuthenticated ? "/api/player/stats" : null,
    stats
  );

  console.log("playerStats", playerStats);

  const intl = useIntl();
  const { track } = useTracking();

  useEffect(() => {
    setUserDepositDetails(playerStats);
  }, [playerStats]);

  useEffect(() => {
    const history = createBrowserHistory();
    history.replace({
      pathname: `/${intl.locale === "en-us" ? "en-row" : intl.locale}/deposit`,
      search: "",
    });

    track("Open Deposit");
  }, []);

  // useEffect(() => setBackActions((prevState) => {
  //   prevState.push((x) => {
  //     setMenuItem('Root');
  //   }); return prevState;
  // }),
  // []);

  const { data: profileFields } = useSWR(
    isAuthenticated ? "player_fields" : null,
    player_fields
  );
  const { data: userProfile = {} } = useSWR(
    isAuthenticated ? "getSessionInfo" : null,
    getSessionInfo
  );

  const eligibaleDeposit = () => {
    let fieldsNeeded = [];
    if (profileFields && userProfile) {
      //Compare the required fields in the context against the currently logged in profile to confirm if all fields are populated.
      fieldsNeeded = ValidateProfileValuesNeeded(
        profileFields,
        "deposit",
        userProfile
      );
    }

    return fieldsNeeded;
  };

  useEffect(() => {
    const fieldsNeeded = eligibaleDeposit();
    console.log("fieldsNeeded 2", fieldsNeeded);
    if (fieldsNeeded.length > 0) {
      hideModal();
      dispatch(
        openNavigation("UpdateProfile", {
          params: intl.formatMessage({
            defaultMessage: "To deposit, please update the below fields",
            description: "header",
          }),
          provider: "deposit",
          url: localeUrl("deposit", intl.locale),
        })
      );

      // return () => history.push(localeUrl("home", intl.locale));
    }
  }, [profileFields, userProfile]);

  console.log("profileFields", profileFields);

  const addPixieReturnDepositScript = () => {
    useScript(ANALYTICS_DEPOSIT_RETURN_URL);
  };

  const onSubmit = async (values, formikBag) => {
    return new Promise(async (resolve, reject) => {
      const { setFieldError, setSubmitting } = formikBag;
      //setSubmitting(true);
      console.log("deposit values test", values);
      const firstTimeDeposit = () => {
        let totalDeposits = userDepositDetails?.deposits_count;

        console.log("totalDeposits", totalDeposits);
        if (totalDeposits > 0) {
          return "&ftd=0";
        } else if (totalDeposits === 0) {
          return "&ftd=1";
        }
        return "";
      };

      if (values?.fields?.bonusCodeInput !== "") {
        try {
          await setBonusCode(values?.fields?.bonusCodeInput);
        } catch (e) {
          setFieldError(
            "fields[bonusCodeInput]",
            "This is not a valid bonus code"
          );
          return false;
        }
      }

      console.log("firstTimeDeposit", firstTimeDeposit());

      const inputtedFields = [];
      for (const [key, value] of Object.entries(values?.fields)) {
        if (value?.text) {
          inputtedFields[key] = value?.name;
        } else {
          if (key === "encCreditcardNumber") {
            inputtedFields[key] = value?.replace(/\s+/g, "");
          } else {
            inputtedFields[key] = value;
          }
        }
      }

      console.log("inputtedFields", inputtedFields);

      // const defaultRequester = {
      //   apiGet: function (uri) {
      //     return PaymentsAPI.defaultRequester.apiGet(`${ENDPOINT}${uri}`);
      //   },
      //   apiPost: function (uri, data) {
      //     return PaymentsAPI.defaultRequester.apiPost(`${ENDPOINT}${uri}`, data);
      //   },
      //   apiPut: function (uri, data) {
      //     return PaymentsAPI.defaultRequester.apiPut(`${ENDPOINT}${uri}`, data);
      //   },
      //   redirect: PaymentsAPI.defaultRequester.redirect,
      // };

      try {
        //  console.log("first url call", `https://theclubhousecasino.com${localeUrl('depositStatus', intl.locale, { status: 'success' })}?amount=${inputtedFields?.amount?.replace(',','.')}&currency=${currency}${userDepositDetails?.deposits_count > 0 ? `&ftd=0`:`&ftd=1`}`);

        track("Deposit submit init", {
          success_url: `https://${getDomainName(
            window.location.hostname
          )}${localeUrl("depositStatus", intl.locale, {
            status: "success",
          })}?amount=${inputtedFields?.amount?.replace(
            ",",
            "."
          )}&currency=${currency}${firstTimeDeposit()}`,
          failure_url: `https://${getDomainName(
            window.location.hostname
          )}${localeUrl("depositStatus", intl.locale, {
            status: "failure",
          })}?amount=${inputtedFields?.amount?.replace(
            ",",
            "."
          )}&currency=${currency}`,
          pending_url: `https://${getDomainName(
            window.location.hostname
          )}${localeUrl("depositStatus", intl.locale, {
            status: "pending",
          })}?amount=${inputtedFields?.amount?.replace(
            ",",
            "."
          )}&currency=${currency}`,
          support_url: localeUrl("depositStatus", intl.locale, {
            status: "support",
          }),
        });

        const submitFields = {
          id: values?.method?.id,
          amountValue: inputtedFields?.amount?.replace(",", "."),
          currency: currency,
          paymentAction: "deposit",
          methodFieldsData: values?.fields,
          savedProfileId: values?.savedProfileId,
          playerFieldsData: {
            first_name: values?.firstname,
            last_name: values?.surname,
            date_of_birth: values?.birthDate,
            gender: values?.gender,
            country: values?.country?.name,
            address: values?.address1,
            city: values?.city,
            mobile_phone: values?.mobile_phone,
            postal_code: values?.postalCode,
            state: values?.state?.name,
          },
        };
        console.log("im here submitFields", submitFields);
        // window.PaymentsAPI.config({
        //   serverUrl: "https://razorbill-staging.casino.p6m.tech",
        //   apiPrefix: "/api",
        //   customCdnUrl: "https://cdn2.softswiss.net/logos/payments/white/",
        // }).then(
        window.PaymentsAPI.submit({
          ...submitFields,
        })
          .then((result) => {
            console.log("im here");
            track("Deposit submit success", submitFields);
            track(
              "Profile detail changes success",
              submitFields.playerFieldsData
            );
            addPixieReturnDepositScript();
            resolve();
            setSubmitting(true);
          })
          .catch((res) => {
            track("Deposit error", { error: res, submitFields });
            console.log("im here test", res);
            setSubmitting(false);
            // feedback
            const messages = [];
            if (res?.message) {
              messages.push(
                feedback?.restriction?.[res?.message] ?? res?.message
              );
            }

            if (res?.methodFieldsErrors) {
              for (const [field, value] of Object.entries(
                res.methodFieldsErrors
              )) {
                if (typeof value !== "object") {
                  messages.push(
                    `${feedback?.labels?.[field] ?? value} ${value}`
                  );
                } else {
                  for (const [type, error] of Object.entries(value)) {
                    if (typeof error === "object") {
                      for (const [typesub, suberror] of Object.entries(error)) {
                        messages.push(
                          `${feedback?.labels?.[type] ?? type} ${suberror}`
                        );
                      }
                    } else {
                      messages.push(
                        `${feedback?.labels?.[field] ?? field} ${error}`
                      );
                    }
                  }
                }
              }
            }

            if (res?.commonErrors) {
              for (const [field, value] of Object.entries(res.commonErrors)) {
                if (typeof value !== "object") {
                  messages.push(
                    `${feedback?.labels?.[field] ?? value} ${value}`
                  );
                } else {
                  for (const [type, error] of Object.entries(value)) {
                    if (typeof error === "object") {
                      for (const [typesub, suberror] of Object.entries(error)) {
                        messages.push(
                          `${feedback?.labels?.[type] ?? type} ${suberror}`
                        );
                      }
                    } else {
                      messages.push(
                        `${feedback?.labels?.[field] ?? field} ${error}`
                      );
                    }
                  }
                }
              }
            }
            setFieldError("formState", messages);

            // const res = {
            //   errors: {
            //     email: {
            //       invalid: 'is invalid',
            //     },
            //     password_confirmation: {
            //       confirmation: "doesn't match Password",
            //     },
            //     profile: {
            //       first_name: {
            //         blank: "can't be blank",
            //       },
            //     },
            //   },
            // };
            // const messages = [];
            // if (res?.reason) {
            //   messages.push(res?.reason);
            // }
            // for (const [field, value] of Object.entries(res.errors)) {
            //   for (const [type, error] of Object.entries(value)) {
            //     messages.push(value);
            //   }
            // }
            resolve();
            setSubmitting(false);
          });
        //     .catch((err) => console.log("im here error 2", err))
        // );
      } catch (e) {
        resolve();
        setFieldError("depositState", "Error occured, please try again");
      }
    });
  };
  const initialValues = {
    bonusCode: "",
    useBonusCode: fields?.can_issue_bonuses,
    fields: {
      errors: [],
      amount: initialAmount ?? "",
      bonusCode: initialBonusCode ?? "",
      bonusCodeInput: "",
    },
    depositState: "initial",
    method: null,
    savedId: null,
    paymentPlan: null,
    identifier: null,
    email: playerInfo?.email,
    currency: {
      name: localeInfo?.currency,
      text: intl.formatMessage(
        {
          defaultMessage: `{curr} ({amount})`,
          description: "Label",
        },
        {
          curr: playerInfo?.currency,
          amount: intl
            .formatNumber(0, {
              style: "currency",
              currency:
                localeInfo?.currency === undefined
                  ? currency
                  : localeInfo?.currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
            .replace(/\d/g, "")
            .trim(),
        }
      ),
    },
    country:
      !Object.keys(localeInfo).length > 0
        ? { name: "", text: "Please select" }
        : { name: localeInfo?.code, text: localeInfo?.name },
    mobile_phone: playerInfo?.mobile_phone ?? "",
    gender: playerInfo?.gender ?? "",
    firstname: playerInfo?.first_name ?? "",
    surname: playerInfo?.last_name ?? "",
    address1: playerInfo?.address ?? "",
    city: playerInfo?.city ?? "",
    postalCode: playerInfo?.postal_code ?? "",
    birthDate: "",
    birthdateDay: "",
    birthdateMonth: "",
    birthdateYear: "",
    formState: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      initialStatus={{ step: "initial" }}
      initialTouched={{ formState: true, amount: false }}
    >
      {({
        isSubmitting,
        values,
        setStatus,
        status,
        isValid,
        setFieldValue,
        errors,
      }) => (
        <>
          <Navigator
            title={intl.formatMessage({
              defaultMessage: "Deposit",
              description: "Deposit headline",
            })}
            closeAction={hideModal}
            backAction={null}
          />
          <div className="w-full flex flex-row p-4 border-t-4 mt-3 md:mt-0">
            <div className="flex flex-1 flex-col justify-center items-center font-semibold">
              <h3 className="text-2xl text-700 text-white uppercase">
                {intl.formatMessage({
                  defaultMessage: "Deposit",
                  description: "Deposit headline",
                })}
              </h3>
              {/* <span className="text-purple-lighter">
                {intl.formatMessage({
                  defaultMessage: 'Make a deposit quick and secure',
                  description: 'Deposit subline',
                })}
              </span> */}
              <hr className="border-gray max-w-lg w-full mx-auto mt-5" />
            </div>
            {/* <div className="hidden md:flex md:w-28" /> */}
          </div>

          <Form className="flex flex-1 flex-col md:flex-row text-sm w-full sm:pb-4 md:justify-center">
            {
              <DepositList
                values={values}
                setMenuItem={setMenuItem}
                setStatus={setStatus}
                setFieldValue={setFieldValue}
                hideModal={hideModal}
              />
            }
            {status.step !== "success" && (
              <DepositForm
                userDepositCount={userDepositDetails?.deposits_count}
                values={values}
                setStatus={setStatus}
                setFieldValue={setFieldValue}
                isSubmitting={isSubmitting}
                isValid={isValid}
                errors={errors}
                countries={countries}
                currencies={currencies}
                initialValues={initialValues}
                profileFields={profileFields}
                isFromMyClubhousePage={isFromMyClubhousePage}
              />
            )}
            {/* {status.step === 'form' && <DepositForm values={values} setStatus={setStatus} setFieldValue={setFieldValue} isSubmitting={isSubmitting} isValid={isValid} setBackActions={setBackActions} errors={errors}/>}
             */}
            {status.step === "success" && (
              <DepositSuccess
                pid={status.pid}
                hideModal={hideModal}
                setBackActions={setBackActions}
              />
            )}
            {/* {status.step === 'error' && <div>error</div>} */}
            {/* {status.step === 'cancel' && <div>cancel</div>} */}
            {/* {!['success', 'error', 'cancel'].includes(status.step) && ( */}
            {/* <iframe */}
            {/*  ref={redirectFrame} */}
            {/*  className={cx({ 'h-96 w-96': status.step === 'redirect' })} */}
            {/* /> */}
            {/* )} */}
            {/* <DepositList /> */}
          </Form>
        </>
      )}
    </Formik>
  );
};

export default Deposit;
