/**
 * TopBar Component
 *
 * This component is responsible for The top menu like hamburger, logo & CTA's
 *
 * @category   TopBar
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */
import cx from 'classnames';
import React from 'react';
import Button from '../Button';
import Icon from '../../Icon';

const Action = ({
  children,
  className,
  disabled,
  onClick = undefined,
  type,
  isSubmitting = false,
  submittingLabel,
  color = "gold",
  to,
  params,
  arrow = false,
  trackName,
  trackParams,
  arrowClassName,
}) => (
  <Button
    href=""
    to={to}
    params={params}
    type={type}
    onClick={onClick}
    className={cx(
      {
        "h-11 bg-brand-600 text-white": color === "darkgray" && !disabled,
        "bg-brand-500 text-white": color === "gray" && !disabled,
        "bg-gold text-brand": color === "gold" && !disabled,
        "text-white":
          (color === "transparent" || color === "gray") && !disabled,
        "bg-brand-500 text-brand-600": disabled,
      },
      className
    )}
    disabled={disabled}
    isSubmitting={isSubmitting}
    submittingLabel={submittingLabel}
    trackName={trackName}
    trackParams={trackParams}
  >
    {children}
    {arrow && !isSubmitting && !disabled && (
      <Icon
        icon="arrow"
        className={cx(
          "ml-4 h-4 w-4 inline-block transform",
          {
            "-rotate-180": arrow === "up",
            "-rotate-0": arrow === "down",
            "-rotate-90": arrow === "right",
            "text-white": color === "transparent",
          },
          arrowClassName
        )}
      />
    )}
  </Button>
);

export default Action;
