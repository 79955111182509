import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useIntl } from "react-intl";
import { Transition } from "@headlessui/react";
import SubmitFeedback from "@components/Formik/SubmitFeedback";
import {
  getDocuments,
  saveDocument,
  removeDocument,
} from "@data/model/documents";
import useSWR from "swr";
import Link from "@components/Link";
import { useRouter } from "@components/Provide/RouterContext";
import Button from "../../../Button";
import { SelectBox, TextField } from "../../../Formik";
import { useTracking } from "@hooks/useTracking";

import DocumentValidation from "./document.validation";
import DocumentVerificationDescription from "./DocumentVerificationDescription/DocumentVerificationDescription";
import CompleteProfile from "./CompleteProfile/CompleteProfile";
import ProofOfIdentity from "./ProofOfIdentity/ProofOfIdentity";
import ProofOfAddress from "./ProofOfAddress/ProofOfAddress";
import ProofOfPayment from "./ProofOfPayment/ProofOfPayment";
import VerificationFromV1 from "./VerificationFromV1/VerificationFromV1";

const DocumentVerificationV2 = ({ section, setMenuItem }) => {
  const intl = useIntl();
  const router = useRouter();
  const { track } = useTracking();

  // const remove = (id, formikBag) => {
  //   const { setStatus, setFieldError, setSubmitting } = formikBag;
  //   removeDocument(id)
  //     .then(() => {
  //       track("Document Verification removal success", { id: id });
  //       setStatus("success");
  //       setSubmitting(false);
  //     })
  //     .catch((err) => {
  //       track("Document Verification removal error", { id: id });
  //       setSubmitting(false);
  //       setFieldError("formState", err.desc);
  //       setStatus("error");
  //     });
  // };

  const handleSubmit = (values, formikBag) => {
    const { setStatus, setFieldError, setSubmitting } = formikBag;

    saveDocument(values.attachment, values?.documentType?.name)
      .then(() => {
        track("Document Verification submit success", values);
        setStatus("success");
        setSubmitting(false);
      })
      .catch((err) => {
        track("Document Verification submit error", { error: err });
        setSubmitting(false);
        setFieldError("formState", err.desc);
        setStatus("error");
      });
  };

  return (
    <>
      <Formik
        initialStatus="init"
        initialValues={{
          documentType: {
            name: "",
            text: intl.formatMessage({
              defaultMessage: "Please Select",
              description: "Label of selectbox",
            }),
          },
          attachment: null,
          description: "",
        }}
        onSubmit={handleSubmit}
        // validationSchema={DocumentValidation(intl)}
      >
        {({
          status,
          values,
          isSubmitting,
          setStatus,
          setFieldError,
          setSubmitting,
          setFieldValue,
          ...actions
        }) => (
          <Form>
            <h4 className="mb-10 text-2xl font-light leading-5 text-center text-white capitalize  sticky top-0 bg-[#13161d] max-w-full ms-auto me-auto pt-0 pb-[11px] z-[9] ml-0">
              {intl.formatMessage({
                defaultMessage: "Verify Identity",
                description: "Header of section",
              })}
            </h4>
            <Transition
              show={status === "init"}
              leave="transition ease-in duration-500"
              leaveFrom="transform opacity-100"
              leaveTo="transform -translate-x-80 opacity-0"
              className="flex flex-col w-full pb-14"
              unmount={false}
            >
              <div className="mx-auto mt-5.5 flex-col flex w-full items-stretch px-2.5 mb-10 md:mb-0 rtl:text-right">
                <ErrorMessage name="submitError">
                  {(msg) => <div className="mt-8 text-sm text-red">{msg}</div>}
                </ErrorMessage>

                <DocumentVerificationDescription />

                <h3 className="font-normal text-white text-[22px] my-5">
                  {intl.formatMessage({
                    defaultMessage: "Verify now",
                    description: "label",
                  })}
                </h3>

                <div className="flex flex-col gap-5">
                  <CompleteProfile />
                  <ProofOfIdentity />
                  <ProofOfAddress />
                  <ProofOfPayment />
                  <VerificationFromV1 />
                </div>
              </div>
            </Transition>

            <SubmitFeedback
              status={status}
              header={intl.formatMessage({
                defaultMessage: "Confirmed",
                description: "The feedback header",
              })}
            >
              <div className="p-8 leading-7 text-24">
                {intl.formatMessage({
                  defaultMessage: "Documents successfully updated",
                  description: "The feedback after doing document changes",
                })}
              </div>
              <div className="px-8">
                <Button.Action
                  trackName="Back to account from Document Verification"
                  trackParams={{
                    route: router?.routename,
                    component: "DocumentVerification",
                  }}
                  onClick={() => setMenuItem("Root")}
                  arrow="right"
                >
                  {intl.formatMessage({
                    defaultMessage: "Back to my account",
                    description: "Label",
                  })}
                </Button.Action>
              </div>
            </SubmitFeedback>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DocumentVerificationV2;
