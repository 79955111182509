import { useIntl, defineMessages } from "react-intl";
import { useQuery } from "@apollo/client";
import { Disclosure, Transition } from "@headlessui/react";
import React from "react";
import Button from "@components/Button";
import getFaqList from "./get-faq-list.graphql";
import Icon from "../Icon";

const messages = defineMessages({
  textFaqListIntro: {
    id: "text.faqListIntro",
    defaultMessage:
      "Check out our Friendly Answered Questions. It has all manners of solutions to quickly anwers all common questions.\n" +
      "\n" +
      "Otherwise feel free to reach out to our amazing Live Support helpers. Live Chat is 24/7 available or email to support@playfrank.com.",
    description: "The introduction text on the faq page",
  },
  textFaqListTop5: {
    id: "text.faqListTop5",
    defaultMessage: "Top 5 questions",
    description: "The top 5 header",
  },
  textFaqListAllQuestions: {
    id: "text.faqListAllQuestions",
    defaultMessage: "All your questions",
    description: "All your questions header",
  },

  Bonus_and_Promotions: {
    id: "text.Bonus_and_Promotions",
    defaultMessage: "Bonus and Promotions Section",
    description: "Bonus and Promotions Section",
  },
  My_Account: {
    id: "text.My_Account",
    defaultMessage: "My Account Section",
    description: "My Account Section",
  },
  Registration: {
    id: "text.Registration",
    defaultMessage: "Registration Section",
    description: "Registration Section",
  },
  Security: {
    id: "text.Security",
    defaultMessage: "Security Section",
    description: "Security Section",
  },
  Financial: {
    id: "text.Financial",
    defaultMessage: "Financial Section",
    description: "Financial Section",
  },
  Deposit: {
    id: "text.Deposit",
    defaultMessage: "Deposit Section",
    description: "Deposit Section",
  },
  Withdrawals: {
    id: "text.Withdrawals",
    defaultMessage: "Withdrawals Section",
    description: "All your questions header",
  },
  Technical: {
    id: "text.Technical",
    defaultMessage: "Technical Section",
    description: "All your questions header",
  },
  Casino: {
    id: "text.Casino",
    defaultMessage: "Casino Section",
    description: "Casino Section",
  },
  ResponsibleGaming: {
    id: "text.ResponsibleGaming",
    defaultMessage: "Responsible gaming",
    description: "Responsible gaming",
  },
});

const categoryMapper = {
  Bonus_and_Promotions: "Bonus_and_Promotions",
  My_Account: "My_Account",
  Registration: "Registration",
  Security: "Security",
  Financial: "Financial",
  Deposit: "Deposit",
  Withdrawals: "Withdrawals",
  Technical: "Technical",
  Casino: "Casino",
};

const Faq = () => {
  const intl = useIntl();
  const { data } = useQuery(getFaqList, { variables: { locale: intl.locale } });
  return (
    <div className="max-w-2xl pb-16 mx-auto">
      <h4 className="mb-16 text-2xl font-light leading-5 text-center text-white  sticky top-0 bg-[#13161d] max-w-full ms-auto me-auto pt-0 pb-[11px] z-[9] ml-0">
        {intl.formatMessage({
          defaultMessage: "Need Help?",
          description: "Header of section",
        })}
      </h4>
      <div className="w-full mt-8 text-center">
        {intl.formatMessage({
          defaultMessage: "Check our support or contact us below",
          description: "header on help",
        })}
        :
      </div>
      <div className="flex items-center justify-center w-full">
        <Button.Action
          trackName="Open Chat"
          trackParams={{ component: "Faq" }}
          className="mt-8 mb-12"
          arrow="right"
          onClick={() => {
            window.LC_API.open_chat_window();
          }}
        >
          {intl.formatMessage({
            defaultMessage: "Open chat",
            description: "Label of the accept button",
          })}
        </Button.Action>
      </div>
      {Object.keys(categoryMapper).map((key) => {
        const items = data?.FaqList.filter((item) => item.category === key);

        if (items?.length === 0) {
          return null;
        }
        return (
          <div className="w-full px-3">
            <h3 className="mb-2 text-xl font-light">
              {intl.formatMessage(messages[categoryMapper[key]])}
            </h3>
            <div className="w-full mb-6 divide-y divide-gray ">
              {items?.map((item) => (
                <Disclosure as="div" className="">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex items-center justify-between w-full py-3 font-bold text-left ">
                        {item.question}
                        {open && <Icon icon="minus" className="w-4 h-4" />}
                        {!open && <Icon icon="plus" className="w-4 h-4" />}
                      </Disclosure.Button>

                      <Disclosure.Panel className="py-3 pt-0 text-base text-brand-400 rtl:text-right">
                        <div
                          dangerouslySetInnerHTML={{ __html: item.answer }}
                        />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Faq;
