/**
 * TopBar Component
 *
 * This component is responsible for The top menu like hamburger, logo & CTA's
 *
 * @category   TopBar
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */
import cx from "classnames";
import Button from "../Button";

const Submit = ({
  children,
  className,
  onClick = undefined,
  size = "medium",
  disabled = false,
  isSubmitting = false,
  submittingLabel
}) => {
  console.log("disabled", disabled);
  return (
    <Button
      type="submit"
      onClick={onClick}
      className={cx(
        `shadow-button-medium text-center`,
        { "text-white bg-green": !disabled },
        { "hover:bg-green-300": !disabled && !isSubmitting },
        className
      )}
      size={size}
      disabled={disabled}
      isSubmitting={isSubmitting}
      submittingLabel={submittingLabel}
    >
      {children}
    </Button>
  );
};

export default Submit;
