import React from "react";
import { Transition } from "@headlessui/react";
import Icon from "../../Icon";

const SubmitFeedback = ({ children, header = null, status = null }) => (
  <Transition
    show={["success", "failed"].includes(status)}
    className="w-full mt-8 overflow-hidden text-center"
  >
    <Transition.Child
      enter="transition ease-out duration-500 delay-500"
      enterFrom="transform -translate-y-80 opacity-0"
      enterTo="transform opacity-100 translate-y-0"
      leave="transition ease-in duration-700"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0"
      className="flex flex-col items-center justify-center w-full"
      unmount={false}
    >
      {status === "success" && (
        <>
          <Icon icon="checkFeedback" className="w-16 h-16 text-gold" />
          {/* {status === 'error' && <Icon icon="checkError" className="h-18 w-18" />} */}
        </>
      )}
    </Transition.Child>
    <Transition.Child
      enter="transition ease-out duration-500 delay-500"
      enterFrom="transform translate-y-80 opacity-0"
      enterTo="transform opacity-100 translate-y-0"
      leave="transition ease-in duration-700"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0"
      className="w-full mt-6 text-center"
      unmount={false}
    >
      <>
        {header && <h1>{header}</h1>}
        {children}
      </>
    </Transition.Child>
  </Transition>
);

export default SubmitFeedback;
