import {defineMessages} from "react-intl";
import * as Yup from "yup";

// function usernameExists(formats, parseStrict) {
//   return this.transform(function (value, originalValue) {
//     if (this.isType(value)) return value;
//
//     value = Moment(originalValue, formats, parseStrict);
//
//     return value.isValid() ? value.toDate() : yup.date.INVALID_DATE;
//   });
// }

// yup.addMethod(yup.date, 'format', parseDateFromFormats);

export const SendResetValidationSchema = (intl) => Yup.object().shape({
  usernameOrEmail: Yup.string()
    .email("Invalid email")
    .required("Required")
    .max(255, "Email address is too long")
});
