import { Fragment, useMemo } from "react";
import { useFormikContext } from "formik";
import { useQuery } from "@apollo/client";
import { Transition } from "@headlessui/react";

import Link from "../../../Link";
import getGamesQuery from "../../get-games.graphql";
import { useWallet } from "../../../Provide/WalletContext";
import { useAuth } from "../../../Provide/AuthContext";
import { useDevice } from "../../../Provide/DeviceContext";
import { cfImgUrl } from "../../../../config";

const SearchResults = ({ open, onSelect, className }) => {
  const {
    values: { query },
  } = useFormikContext();

  const { currency } = useWallet();
  const { isMobile } = useDevice();
  const { isAuthenticated } = useAuth();
  const { loading, error, data } = useQuery(getGamesQuery, {
    variables: { isMobile: isMobile?.any, isDemo: !isAuthenticated, currency },
  });

  // const { loading, error, data } = useQuery(getGamesQuery);

  const searchResult = useMemo(() => {
    if (!query) return [];
    const result = data?.getGames.filter(
      (game) =>
        game?.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
        game?.provider?.toLowerCase()?.includes(query?.toLowerCase())
    );
    return result;
  }, [query]);

  return (
    <Transition
      show={!!searchResult?.length && open}
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <div className={className}>
        <div className="overflow-hidden rounded shadow-lg">
          <div className="relative bg-white p-3 overflow-auto max-h-96">
            {searchResult?.map((item) => (
              <Link
                trackName="Game"
                trackParams={{ component: "SearchResult", game: item }}
                key={item.id}
                onClick={() => onSelect(item)}
                className="flex items-center p-3 transition duration-150 ease-in-out rounded hover:bg-gray-lightest focus:outline-none"
              >
                <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12">
                  <img
                    src={
                      item?.thumbnail?.includes(".svg") || !cfImgUrl
                        ? item?.thumbnail
                        : `${cfImgUrl}/format=auto,dpr=2,onerror=redirect/${item.thumbnail}`
                    }
                    loading="lazy"
                    className="h-full object-cover h-24 w-24 rounded"
                  />
                </div>
                <div className="ml-4">
                  <p className="text-sm text-purple-dark">{item.name}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default SearchResults;
