import useSWR from "swr";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import SubmitFeedback from "@components/Formik/SubmitFeedback";
import { useDispatch } from "react-redux";
import { closeNavigation } from "@actions/navigation";
import { useTracking } from "@hooks/useTracking";
import { stats } from "@data/model/account";
import Button from "../../Button";
import { useWallet } from "../../Provide/WalletContext";
import { useRouter } from "../../Provide/RouterContext";
import { createBrowserHistory } from "history";

const DepositSuccess = ({ setMenuItem, hideModal }) => {
  const intl = useIntl();
  const { queryParams } = useRouter();
  console.log("queryParams success", queryParams);
  const { currency } = useWallet();
  const dispatch = useDispatch();
  const close = () => dispatch(closeNavigation());
  const { track } = useTracking();
  useEffect(() => {
    if (window?.analytics?.initialized) {
      if (queryParams?.["amp;ftd"] === "1") {
        console.log("First time deposit success", queryParams);
        track("First time deposit success", { ...queryParams, ready: false });
      } else {
        track("Deposit success", { ...queryParams, ready: false });
      }
      const history = createBrowserHistory();
      history.replace({
        pathname: `/${
          intl.locale === "en-us" ? "en-row" : intl.locale
        }/deposit/success/${queryParams?.ftd ? queryParams?.ftd : ""}`,
        search: "",
      });
    } else {
      window?.analytics?.ready?.(() => {
        console.log("useEffect queryParams", queryParams);
        if (queryParams?.["amp;ftd"] === "1") {
          console.log("First time deposit success", queryParams);
          track("First time deposit success", { ...queryParams, ready: true });
        } else {
          track("Deposit success", { ...queryParams, ready: true });
        }
        const history = createBrowserHistory();
        history.replace({
          pathname: `/${
            intl.locale === "en-us" ? "en-row" : intl.locale
          }/deposit/success/${queryParams?.ftd ? queryParams?.ftd : ""}`,
          search: "",
        });
      });
    }
  }, [queryParams]);

  // credit: {currency: "DKK", amount: 100, name: ""}
  // debit: {currency: "DKK", amount: 100, name: "MoneyMatrix_PaySafeCard"}
  // fees: []
  // isFirstDeposit: false
  // status: "success"
  // time: "2021-05-11T20:56:05.783"
  // transactionID: 1576066424

  return (
    <div className="md:px-16 px-8 pt-2 pb-8 text-white">
      <SubmitFeedback
        status="success"
        header={intl.formatMessage({
          defaultMessage: "Success!",
          description: "The feedback header",
        })}
      >
        <div className="text-24 py-8 leading-7">
          {intl.formatMessage(
            {
              defaultMessage:
                "Your deposit has gone through and your balance has been updated. ",
              description: "The feedback after setting a limit",
            },
            {
              amount: (
                <span className="font-bold text-green">
                  {intl.formatNumber(200, {
                    style: "currency",
                    currency,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              ),
            }
          )}
        </div>
        <div className="px-8">
          <Button.Action
            trackName="Close"
            trackParams={{ component: "DepositSuccess" }}
            onClick={hideModal}
            className="w-full"
            arrow="right"
          >
            {intl.formatMessage({
              defaultMessage: "Play now",
              description: "Label",
            })}
          </Button.Action>
        </div>
      </SubmitFeedback>
    </div>
  );
};

export default DepositSuccess;
