import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import AuthForm from "./AuthForm";
import AcceptTermsAndConditions from "../AcceptTermsAndConditions";
import SendReset from "../ForgotPassword/SendReset";
import ResetForm from "../ForgotPassword/ResetForm";
import RootModal from "../Modal/RootModal";
import BlankModal from "../Modal/BlankModal";
import { useDevice } from "../Provide/DeviceContext";
import Logo from "../Logo";
import RemoveLimit from "../RemoveLimit/RemoveLimit";

const Auth = ({
  onSuccess,
  onCancel,
  isActive,
  initialAction,
  resetToken = "",
  limitToken = "",
  setMenuItem,
  buttonToggle,
}) => {
  console.log("topbar initialAction", initialAction);
  const { isMobile } = useDevice();
  const intl = useIntl();
  const [action, setAction] = useState(initialAction ?? "auth");
  const [callbackParams, setCallbackParams] = useState({});

  useEffect(() => {
    setAction(initialAction ?? "auth");
  }, [buttonToggle]);

  React.useEffect(() => {
    if (isActive) {
      setAction(initialAction ?? "auth");
    }
  }, [isActive]);

  const titles = {
    auth: intl.formatMessage({
      defaultMessage: "Welcome back",
      description: "Header from the authentication modals",
    }),
    acceptTermsAndConditions: intl.formatMessage({
      defaultMessage: "Please accept terms and condtions",
      description: "Header from the authentication modals",
    }),
    passwordForget: intl.formatMessage({
      defaultMessage: "Request a new password",
      description: "Header from the authentication modals",
    }),
    resetPasswordForm: intl.formatMessage({
      defaultMessage: "Please reset your password",
      description: "Header from the authentication modals",
    }),
  };
  return (
    <>
      {action === "auth" && (
        <AuthForm
          setAction={setAction}
          onSuccess={onSuccess}
          onCancel={onCancel}
          setMenuItem={setMenuItem}
          setCallbackParams={setCallbackParams}
        />
      )}

      {action === "acceptTermsAndConditions" && (
        <AcceptTermsAndConditions
          onSuccess={onSuccess}
          setAction={setAction}
          callbackParams={callbackParams}
        />
      )}
      {action === "passwordForget" && <SendReset setAction={setAction} />}
      {action === "resetPasswordForm" && (
        <ResetForm
          setAction={setAction}
          onSuccess={onSuccess}
          onCancel={onCancel}
          resetToken={resetToken}
        />
      )}
      {action === "removeLimit" && (
        <RemoveLimit setMenuItem={setMenuItem} limitToken={limitToken} />
      )}
    </>
  );
};

export default Auth;
