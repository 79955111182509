import withStyles from 'isomorphic-style-loader/withStyles';
import React from 'react';
import inEU from '@segment/in-eu';
import { defineMessages, injectIntl } from 'react-intl';
import cx from 'classnames';
import ConsentManagerBuilder from '../consent-manager-builder';
import Container from '../consent-manager/container';

import {
  ADVERTISING_CATEGORIES,
  FUNCTIONAL_CATEGORIES,
} from '../consent-manager/categories';
import { analytics } from '../../config';

import s from './ConsentBanner.scss';

const messages = defineMessages({
  cookiePolicyRoute: {
    id: 'router.cookiePolicy',
    defaultMessage: 'privacy-policy',
    description: 'The route used for the Privacy Policy page',
  },
  cookiePolicyMorInfo: {
    id: 'text.cookieControlMoreInfo',
    defaultMessage: 'For more information vist our',
    description: 'the more info text for cookie control',
  },
  cookiePolicy: {
    id: 'text.cookiePolicy',
    defaultMessage: 'Cookie Policy',
    description: 'the more info text for cookie control',
  },
  analytics: {
    id: 'text.analytics',
    defaultMessage: 'Analytics',
    description: 'the more info text for cookie control',
  },
  analyticsDescription: {
    id: 'text.cookieControlAnalytics',
    defaultMessage:
      'Analytical cookies help us to improve our website by collecting and reporting information on its usage.',
    description: 'the more info text for cookie control',
  },
  marketing: {
    id: 'text.marketing',
    defaultMessage: 'Marketing',
    description: 'the more info text for cookie control',
  },
  marketingDescription: {
    id: 'text.cookieControlMarketing',
    defaultMessage:
      'We use marketing cookies to help us improve the relevancy of advertising campaigns you receive.',
    description: 'the more info text for cookie control',
  },
  useCookies: {
    id: 'text.cookieControlSiteUseCookies',
    defaultMessage: 'This site uses cookies.',
    description: 'the more info text for cookie control',
  },
  necessaryTitle: {
    id: 'text.cookieControlNecessaryTitle',
    defaultMessage: 'Necessary Cookies',
    description: 'the more info text for cookie control',
  },
  necessaryDescription: {
    id: 'text.cookieControlNecessaryDescription',
    defaultMessage:
      'Necessary cookies enable core functionality. The website cannot function properly without these cookies, and can only be disabled by changing your browser preferences.',
    description: 'the more info text for cookie control',
  },
  thirdPartyTitle: {
    id: 'text.cookieControlThirdPartyTitle',
    defaultMessage: 'Warning: Some cookies require your attention',
    description: 'the more info text for cookie control',
  },
  thirdPartyDescription: {
    id: 'text.cookieControlThirdPartyDescription',
    defaultMessage:
      'Consent for the following cookies could not be automatically revoked. Please follow the link(s) below to opt out manually.',
    description: 'the more info text for cookie control',
  },
  intro: {
    id: 'text.cookieControlIntro',
    defaultMessage:
      'Some of these cookies are essential, while others help us to improve your experience by providing insights into how the site is being used.',
    description: 'the more info text for cookie control',
  },
  notifyTitle: {
    id: 'text.cookieControlNotifyTitle',
    defaultMessage: 'Your choice regarding cookies on this site',
    description: 'the more info text for cookie control',
  },
  notifyDescription: {
    id: 'text.cookieControlNotifyDescription',
    defaultMessage:
      'Please note that cookies are being used to optimise site functionality and give you the best possible experience. In order to continue using this website, accepting these cookies are obligatory.',
    description: 'the more info text for cookie control',
  },
  on: {
    id: 'text.cookieControlOn',
    defaultMessage: 'on',
    description: 'on',
  },
  off: {
    id: 'text.cookieControlOff',
    defaultMessage: 'off',
    description: 'the more info text for cookie control',
  },
  accept: {
    id: 'text.cookieControlAccept',
    defaultMessage: 'Accept',
    description: 'the more info text for cookie control',
  },
  acceptRecommended: {
    id: 'text.cookieControlAcceptRecommended',
    defaultMessage: 'Accept Recommended Settings',
    description: 'the more info text for cookie control',
  },
  settings: {
    id: 'text.cookieControlSettings',
    defaultMessage: 'Settings',
    description: 'the more info text for cookie control',
  },
});

const initialPreferences = {
  marketingAndAnalytics: null,
  advertising: null,
  functional: null,
};

const preferencesDialogTitle = 'Website Data Collection Preferences';
const preferencesDialogContent = 'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.';
const cancelDialogTitle = 'Are you sure you want to cancel?';
const cancelDialogContent = 'Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Website Data Collection Policy.';

@injectIntl
@withStyles(s)
class ConsentBanner extends React.PureComponent {
  static whyDidYouRender = false;

  static defaultProps = {
    otherWriteKeys: [],
    shouldRequireConsent: () => true,
    implyConsentOnInteraction: false,
    cookieDomain: 'theclubhousecasino.com',
    bannerTextColor: '#fff',
    bannerBackgroundColor: '#2cba97',
  };

  handleMapCustomPreferences = ({ destinations, preferences }) => {
    const destinationPreferences = {};
    const customPreferences = {};

    // Default unset preferences to true (for implicit consent)
    for (const preferenceName of Object.keys(preferences)) {
      const value = preferences[preferenceName];
      if (typeof value === 'boolean') {
        customPreferences[preferenceName] = value;
      } else {
        customPreferences[preferenceName] = true;
      }
    }

    for (const destination of destinations) {
      if (ADVERTISING_CATEGORIES.find((c) => c === destination.category)) {
        destinationPreferences[destination.id] = customPreferences.advertising;
      } else if (FUNCTIONAL_CATEGORIES.find((c) => c === destination.category)) {
        destinationPreferences[destination.id] = customPreferences.functional;
      } else {
        // Fallback to marketing
        destinationPreferences[destination.id] = customPreferences.marketingAndAnalytics;
      }
    }
    // .concat(CustomConsent)
    return { destinationPreferences, customPreferences };
  };

  render() {
    const {
      otherWriteKeys,
      shouldRequireConsent,
      implyConsentOnInteraction,
      cookieDomain,
      bannerBackgroundColor,
      bannerTextColor,
      intl,
    } = this.props;

    return (
      <div className={cx(s.root, 'bg-black text-white')}>
        <ConsentManagerBuilder
          writeKey={ANALYTICSCFG.segment.writeKey}
          shouldRequireConsent={() => true}
          mapCustomPreferences={this.handleMapCustomPreferences}
          initialPreferences={initialPreferences}
        >
          {({
            destinations,
            newDestinations,
            preferences,
            isConsentRequired,
            setPreferences,
            resetPreferences,
            saveConsent,
          }) => (
            <Container
              destinations={destinations}
              newDestinations={newDestinations}
              preferences={preferences}
              isConsentRequired={isConsentRequired}
              setPreferences={setPreferences}
              resetPreferences={resetPreferences}
              saveConsent={saveConsent}
              implyConsentOnInteraction={implyConsentOnInteraction}
              bannerTextColor={bannerTextColor}
              bannerBackgroundColor={bannerBackgroundColor}
              preferencesDialogTitle={preferencesDialogTitle}
              preferencesDialogContent={preferencesDialogContent}
              cancelDialogTitle={cancelDialogTitle}
              cancelDialogContent={cancelDialogContent}
            />
          )}
        </ConsentManagerBuilder>
      </div>
    );
  }
}
export default ConsentBanner;
