/**
 * TopBar Component
 *
 * This component is responsible for The top menu like hamburger, logo & CTA's
 *
 * @category   TopBar
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */
import cx from "classnames";
import React, { useEffect, useState } from "react";
import { closeNavigation, openNavigation } from "@actions/navigation";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Icon from "@components/Icon";
import { Transition } from "@headlessui/react";
import Logo from "../Logo";
import Link from "../Link";
import TopBarPublic from "./TopBarPublic";
import TopBarPrivate from "./TopBarPrivate";
import TopBarPrivateLoading from "./TopBarPrivateLoading";
import { useAuth } from "../Provide/AuthContext";
import { useWallet } from "../Provide/WalletContext";
import { useRouter } from "../Provide/RouterContext";
import { useDevice } from "../Provide/DeviceContext";
import { localeUrl } from "../../core/url";

const TopBar = ({ topbarPosition = "fixed", clickFromGame = () => {} }) => {
  const router = useRouter();
  const intl = useIntl();
  const { isAuthenticated } = useAuth();
  const { loading: isLoadingWallet } = useWallet();
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const close = () => dispatch(closeNavigation());
  const regexPromotions = /^promotion/g;
  const promString = "promotions";
  const [orientation, setOrientation] = useState();
  const [isFaqOpen, setIsFaqOpen] = useState();
  const params = useSelector((state) => state.navigation.params);
  useEffect(() => {
    window.addEventListener("orientationchange", detectScreenOrientation);
  }, []);

  function detectScreenOrientation() {
    // const orien = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
    const orien = !navigator.maxTouchPoints
      ? "desktop"
      : window.orientation > 0
      ? "landscape"
      : "portrait";
    setOrientation(orien);
  }

  const isNavigationOpen = useSelector(
    (state) => state.navigation.isNavigationOpen
  );

  let app;

  useEffect(() => {
    detectScreenOrientation();
  }, []);

  // useEffect(() => {
  //   app = document?.getElementById('app');
  //   if (app !== undefined) {
  //     if (orientation === 'portrait') {
  //       console.log('portrait mode');
  //       addTopPadding();
  //     }
  //   }
  // }, [orientation]);

  const removeTopPadding = () => {
    // }
  };

  const addTopPadding = () => {
    app = document.getElementById("app");
    // if(typeof(app) !== 'undefined'){
    return (app.style.paddingTop = "6rem"), console.log(" ORIEN WORKED");
    // }
  };

  useEffect(() => {
    !isNavigationOpen && setIsFaqOpen(false);
  }, [isNavigationOpen, isFaqOpen]);

  useEffect(() => {
    if (router?.routename === "game" && isMobile.any) {
      app = document.getElementById("app");
      app.style.padding = "0";
    }
  }, [router, isMobile]);

  return (
    <>
      <Transition
        show={params?.hideCTAsProp || false}
        enter="transition-all duration-700"
        enterFrom="transform translate-x-full"
        enterTo="transform translate-x-0"
        leave="transition-all duration-700"
        leaveFrom="transform-gpu translate-x-0"
        leaveTo="transform-gpu translate-x-full"
        className="fixed top-0 right-0 w-screen h-full pb-8 mt-0 overflow-hidden overflow-x-hidden overflow-y-auto text-white transition-all md:h-menu-bottom xs:w-108 bg-brand z-51"
      >
        <header
          className={cx(
            "md:p-5 p-3 bg-brand top-0 left-0 w-full z-51 flex h-26 items-center",
            {
              fixed: topbarPosition === "fixed",
              relative: topbarPosition === "relative",
            }
          )}
          id="top-bar"
        >
          <TopBarPublic isAuthenticated={isAuthenticated} hideCTAsProp />
        </header>
      </Transition>
      {router?.routename === "game" && isMobile.any ? (
        <>
          <div className="absolute z-51 lg:z-49 top-1 right-1 lg:right-4 lg:top-4">
            <Link
              trackName="Close"
              trackParams={{ component: "TopBar" }}
              to="home"
              className="absolute top-0 z-10 flex items-center justify-center w-5 h-5 mx-auto rounded-full cursor-pointer right-5 lg:w-10 hlg:-10 bg-yellow"
            >
              <Icon
                icon="close"
                className="w-5 h-5 fill-current md:w-6 md:h-6 text-gold"
              />
            </Link>
          </div>
          {!isAuthenticated && (
            <TopBarPublic isAuthenticated={isAuthenticated} hideCTAsProp />
          )}
        </>
      ) : (
        <header
          className={cx(
            "md:p-5 p-3 bg-brand top-0 left-0 w-full z-40 flex h-26 items-center",
            {
              fixed: topbarPosition === "fixed",
              relative: topbarPosition === "relative",
            }
          )}
          id="top-bar"
        >
          <div className="grid w-full grid-cols-12">
            <div
              className="items-center hidden col-span-4 mb-2 lg:mb-0 lg:flex"
              style={{ zIndex: "1" }}
            >
              <div className="flex space-x-3 xl:space-x-4 2xl:space-x-7 whitespace-nowrap">
                <Link
                  trackName="Casino"
                  trackParams={{ component: "TopBar" }}
                  to="home"
                  onClick={() => {
                    clickFromGame();
                    // window.location = `${localeUrl("home", intl.locale)}`;
                  }}
                  className={cx(
                    "text-white xl:text-base lg:text-sm 2xl:text-18",
                    {
                      "font-bold mt-px":
                        (router?.routename === "home" && !isFaqOpen) ||
                        (router?.routename === "lobby" && !isFaqOpen),
                    }
                  )}
                >
                  {intl.formatMessage({
                    defaultMessage: "Casino",
                    description: "top navigation",
                  })}
                </Link>
                <Link
                  trackName="Live Casino"
                  trackParams={{ component: "TopBar" }}
                  to="liveLobby"
                  onClick={() => {
                    clickFromGame();
                    // window.location = `${localeUrl("liveLobby", intl.locale)}`;
                  }}
                  className={cx(
                    "text-white xl:text-base lg:text-sm 2xl:text-18",
                    {
                      "font-bold mt-px":
                        router?.routename === "liveLobby" && !isFaqOpen,
                    }
                  )}
                >
                  {intl.formatMessage({
                    defaultMessage: "Live Casino",
                    description: "top navigation",
                  })}
                </Link>
                <Link
                  trackName="Tournaments"
                  trackParams={{ component: "TopBar" }}
                  to="tournaments"
                  onClick={() => {
                    clickFromGame();
                    // window.location = `${localeUrl(
                    //   "tournaments",
                    //   intl.locale
                    // )}`;
                  }}
                  className={cx(
                    "text-white xl:text-base lg:text-sm 2xl:text-18",
                    {
                      "font-bold mt-px":
                        router?.routename === "tournaments" && !isFaqOpen,
                    }
                  )}
                >
                  {intl.formatMessage({
                    defaultMessage: "Tournaments",
                    description: "top navigation",
                  })}
                </Link>
                <Link
                  trackName="Promotions"
                  trackParams={{ component: "TopBar" }}
                  to="promotions"
                  onClick={() => {
                    clickFromGame();
                    // window.location = `${localeUrl("promotions", intl.locale)}`;
                  }}
                  className={cx(
                    "text-white xl:text-base lg:text-sm 2xl:text-18",
                    {
                      "font-bold mt-px":
                        router?.routename.match(regexPromotions) && !isFaqOpen,
                    }
                  )}
                >
                  {intl.formatMessage({
                    defaultMessage: "Promotions",
                    description: "top navigation",
                  })}
                </Link>
                <Link
                  trackName="Loyalty"
                  trackParams={{ component: "TopBar" }}
                  to="loyalty"
                  onClick={() => {
                    clickFromGame();
                    // window.location = `${localeUrl("loyalty", intl.locale)}`;
                  }}
                  className={cx(
                    "text-white xl:text-base lg:text-sm 2xl:text-18",
                    {
                      "font-bold mt-px":
                        router?.routename === "loyalty" && !isFaqOpen,
                    }
                  )}
                >
                  {intl.formatMessage({
                    defaultMessage: "Loyalty",
                    description: "top navigation",
                  })}
                </Link>
                <Link
                  trackName="Help"
                  trackParams={{ component: "TopBar" }}
                  to="faq"
                  transition={false}
                  onClick={() => {
                    setIsFaqOpen(true), dispatch(openNavigation("Faq"));
                  }}
                  className={cx(
                    "text-white xl:text-base lg:text-sm 2xl:text-18",
                    { "font-bold mt-px": router?.routename === "faq" },
                    { "font-bold mt-px": isFaqOpen }
                  )}
                >
                  {intl.formatMessage({
                    defaultMessage: "Help",
                    description: "top navigation",
                  })}
                </Link>
              </div>
            </div>
            {console.log(
              isAuthenticated,
              "isAuthenticated",
              isMobile.any,
              "isMobile"
            )}
            <div
              className={cx(" md:col-span-4 text-start lg:text-center", {
                "col-span-6": isMobile.any && isAuthenticated,
                "col-span-4": isMobile.any && !isAuthenticated,
              })}
            >
              <Link
                trackName="Logo"
                trackParams={{ component: "TopBar" }}
                to="home"
                onClick={() => {
                  window.location = `${localeUrl("home", intl.locale)}`;
                }}
              >
                <Logo
                  classNameLogo={cx(" inline-block", {
                    "h-14": isMobile.any && isAuthenticated,
                    "h-10": !isAuthenticated && isMobile.any,
                    "h-12 xl:h-14 2xl:h-16": !isAuthenticated || !isMobile.any,
                  })}
                  alt="ClubHouse casino best casino"
                  icon={isMobile.any && isAuthenticated}
                  align={isMobile.any ? "left" : "center"}
                />
              </Link>
              {/* <img src="/images/mobile-logo.png" className="md:hidden" /> */}
            </div>
            <div
              className={cx(
                "flex items-center justify-end md:col-span-8 lg:col-span-4",
                {
                  "col-span-6": isMobile.any && isAuthenticated,
                  "col-span-8": isMobile.any && !isAuthenticated,
                }
              )}
            >
              {isLoadingWallet && isAuthenticated && <TopBarPrivateLoading />}
              {!isLoadingWallet && isAuthenticated && (
                <TopBarPrivate clickFromGame={clickFromGame} />
              )}
              {!isAuthenticated && (
                <TopBarPublic isAuthenticated={isAuthenticated} />
              )}
              {/* <div className="flex items-center"> */}
              {/*  */}
              {/* </div> */}
            </div>
          </div>
          {/* <Transition */}
          {/*  className="absolute left-0 w-full top-16 bg-brand" */}
          {/*  show={showMobileMenu} */}
          {/* > */}
          {/*  <div className="flex flex-col py-5 px-2.5 space-y-7 mt-2"> */}
          {/*    <a href="" className="font-bold text-white xl:text-base lg:text-sm 2xl:text-18"> */}
          {/*      Home */}
          {/*    </a> */}
          {/*    <a href="" className="font-bold text-white xl:text-base lg:text-sm 2xl:text-18"> */}
          {/*      Promotions */}
          {/*    </a> */}
          {/*  </div> */}
          {/* </Transition> */}
        </header>
      )}
    </>
  );
};

export default TopBar;
