import { useIntl } from "react-intl";
import React from "react";
import { useQuery } from "@apollo/client";
import Button from "@components/Button";
import Link from "@components/Link";
import PseudoModal from "@components/Modal/PseudoModal";
import RootModal from "@components/Modal/RootModal";
import {
  PasswordField,
  SelectBox,
  TextField,
  ToggleSwitch,
} from "@components/Formik";
import getPageQuery from "@routes/legal/get-page.graphql";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { closeNavigation } from "@actions/navigation";
import useSWR from "swr";
import { acceptTCv2 } from "../../data/model/account";
import { LoginValidationSchema } from "../Auth/authValidate";
import TermsValidationSchema from "./terms.validation";
import { useAuth } from "../Provide/AuthContext";
import { postCall } from "../../core/softswiss";
import { getSessionInfo } from "../../data/model/session";

const AcceptTermsAndConditions = ({
  onSuccess,
  onCancel,
  isActive,
  hideModal,
  required,
  callbackParams,
}) => {
  const intl = useIntl();
  const { setAuthenticationStatus } = useAuth();
  const dispatch = useDispatch();
  const { data: fields = {} } = useSWR("getSessionInfo", getSessionInfo);
  const accept = async () => {
    console.log("fields", fields);
    console.log("callbackParams", callbackParams);

    // newFields.user.profile_attributes.terms_acceptance = true;

    try {
      await postCall("/api/auth_providers/update_details", {
        user: { profile_attributes: { terms_acceptance: true } },
      });
      setAuthenticationStatus(true);
      dispatch(closeNavigation());
    } catch (e) {
      setAuthenticationStatus(true);
      dispatch(closeNavigation());
    }
  };

  const { loading, error, data } = useQuery(getPageQuery, {
    variables: { id: "terms-and-conditions" },
  });

  return (
    <Formik
      validationSchema={TermsValidationSchema(intl)}
      initialValues={{
        termsandcondition: false,
      }}
      initialErrors={{
        termsandcondition: "Required",
      }}
      onSubmit={accept}
    >
      {({ isSubmitting, isValid }) => (
        <Form className="mx-auto mt-5.5 flex-col flex w-full items-stretch px-2.5 mb-10 md:mb-0 rtl:text-right">
          <h4 className="text-center text-2xl leading-5 font-light text-white mb-16">
            {intl.formatMessage({
              defaultMessage: "Update",
              description: "Header of section",
            })}
          </h4>
          <div className="flex flex-col">
            {!loading && (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    data?.page?.content ??
                    intl.formatMessage({
                      defaultMessage: "Error fetching the page",
                      description:
                        "Generic message in case a page cannot be loaded",
                    }),
                }}
                className="flex-col mx-auto prose max-w-none max-w-2xl h-96 overflow-auto"
              />
            )}
            <div className="mt-5 mr-2">
              <ToggleSwitch name="termsandcondition" />{" "}
              {intl.formatMessage(
                {
                  defaultMessage: "Yes, I accept the {terms} ",
                  description: "Label T&C consent",
                },
                {
                  terms: (
                    <Link
                      trackName="Terms & Condition"
                      trackParams={{ component: "AcceptTermsAndConditions" }}
                      to="termsAndConditions"
                      target="_blank"
                      className="text-purple-dark underline"
                    >
                      {intl.formatMessage({
                        defaultMessage: "terms & conditions",
                        description: "Label T&C consent",
                      })}
                    </Link>
                  ),
                }
              )}{" "}
              {intl.formatMessage({
                defaultMessage: "and confirm i have read them",
                description: "Label T&C consent",
              })}
              .{" "}
              <Link
                trackName="Privacy policy"
                trackParams={{ component: "AcceptTermsAndConditions" }}
                to="privacyPolicy"
                target="_blank"
                className="text-purple-dark underline"
              >
                {intl.formatMessage({
                  defaultMessage: "privacy policy",
                  description: "privacy policy link",
                })}
              </Link>
            </div>
            <div className="flex flex-row my-8">
              <Button.Action
                type="submit"
                className="w-full"
                disabled={!!isSubmitting || !isValid}
                arrow="right"
                trackName="Accept Terms and Conditions"
                trackParams={{ component: "AcceptTermsAndConditions" }}
              >
                {intl.formatMessage({
                  defaultMessage: "Accept",
                  description: "Label of the accept button",
                })}
              </Button.Action>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AcceptTermsAndConditions;
