import { Form, Formik } from 'formik';
import React, {useState} from 'react';

import Button from '@components/Button';
import { useIntl } from 'react-intl';
import TransactionHistoryList from './TransactionHistoryList';
import { SelectBox } from '../../../Formik';

const TransactionHistory = ({clickedFrom, setMenuItem}) => {
  const intl = useIntl();

  let now = new Date()
  
  let previous7Days = new Date(now.setDate(now.getDate() - 7))
  let previous30Days = new Date(now.setDate(now.getDate() - 30))
  let previous90Days = new Date(now.setDate(now.getDate() - 90))
  
  const setProperPeriod = (period) =>{
    if(period === '30'){
      return previous30Days
    }else if(period === '90'){
      return previous90Days
    }else{
      return previous7Days
    }
  }

  const FilterTransactionsToShow = () => {
    if(clickedFrom === "AccountHistory"){
      return {
        name: 'all',
        text: intl.formatMessage({
          defaultMessage: 'All',
          description: 'Label of selectbox',
        }),
      }
    }
    if(clickedFrom === "PendingWithdrawal"){
      return {
        name: 'pending',
        text: intl.formatMessage({
          defaultMessage: 'Pending Withdrawals',
          description: 'Label of selectbox',
        }),
      }
    }
  }

  return (
    <Formik
      initialValues={{
        type: FilterTransactionsToShow(),
        duration: {
          name: "7",
          text: intl.formatMessage({
            defaultMessage: "Last 7 days",
            description: "Label of selectbox",
          }),
        },
        startTime: setProperPeriod("7"),
        endTime: now,
        results: 10,
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <h4 className="text-center text-2xl leading-5 font-light text-white mb-16 px-2.5  sticky top-0 bg-[#13161d] max-w-full ms-auto me-auto pt-0 pb-[11px] z-[9] ml-0">
            {intl.formatMessage({
              defaultMessage: "Account history",
              description: "Header of section",
            })}
          </h4>
          <div className="flex w-full">
            <div className="flex flex-row justify-start items-center w-1/2 px-2.5">
              <SelectBox
                name="type"
                className="flex flex-1 w-full"
                label={intl.formatMessage({
                  defaultMessage: "Transactions to show",
                  description: "Label of selectbox",
                })}
                options={[
                  {
                    name: "all",
                    text: intl.formatMessage({
                      defaultMessage: "All",
                      description: "Label of selectbox",
                    }),
                  },
                  {
                    name: "deposit",
                    text: intl.formatMessage({
                      defaultMessage: "Deposit",
                      description: "Label of selectbox",
                    }),
                  },
                  {
                    name: "withdraw",
                    text: intl.formatMessage({
                      defaultMessage: "Withdraw",
                      description: "Label of selectbox",
                    }),
                  },
                  {
                    name: "pending",
                    text: intl.formatMessage({
                      defaultMessage: "Pending Withdrawals",
                      description: "Label of selectbox",
                    }),
                  },
                  {
                    name: "game",
                    text: intl.formatMessage({
                      defaultMessage: "Gameplay",
                      description: "Label of selectbox",
                    }),
                  },
                ]}
              />
            </div>

            <div className="flex flex-row justify-start items-center w-1/2 px-2.5">
              <SelectBox
                name="duration"
                className="flex flex-1 w-full"
                label={intl.formatMessage({
                  defaultMessage: "Period",
                  description: "Label of selectbox",
                })}
                // onChange={setProperPeriod(values?.duration?.name)}
                options={[
                  {
                    name: "7",
                    text: intl.formatMessage({
                      defaultMessage: "Last 7 days",
                      description: "Label of selectbox",
                    }),
                  },
                  {
                    name: "30",
                    text: intl.formatMessage({
                      defaultMessage: "Last 30 days",
                      description: "Label of selectbox",
                    }),
                  },
                  {
                    name: "90",
                    text: intl.formatMessage({
                      defaultMessage: "Last 90 days",
                      description: "Label of selectbox",
                    }),
                  },
                ]}
              />
            </div>
          </div>

          <TransactionHistoryList
            startTime={setProperPeriod(values?.duration?.name)}
            endTime={now}
            period={values?.duration?.name}
            type={values?.type?.name ?? "deposit"}
            setMenuItem={setMenuItem}
          />
        </Form>
      )}
    </Formik>
  );
};
export default TransactionHistory;
