import React, { useState } from "react";

const DEFAULT_INDEX = 0;

const TabbedView = ({
  className = "",
  tabsClassName = "w-full",
  activeClassName = "",
  buttonClassName = "",
  children,
  defaultTab = DEFAULT_INDEX,
}) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const getTabComponents = () => (
    <nav
      className={`relative cursor-pointer items-center flex overflow-auto xl:overflow-hidden text-white border border-primary-500 shadow-card bg-dark transition-all text-left mb-5 gap-4 ${
        tabsClassName ? " " + tabsClassName : ""
      }`}
    >
      {children.map(({ props: { name, className = "" } }, index) => {
        return (
          <div
            className={`font-medium btn rounded-lg whitespace-nowrap hover:bg-opacity-70 transition-all !filter-none focus-visible:ring-0 focus-visible:outline-none py-2 px-4 ${className} ${buttonClassName} ${
              selectedTab === index
                ? `hover:bg-gold hover:text-black bg-gold text-black ${activeClassName}`
                : `bg-brand-600 text-white`
            }`}
            onClick={() => setSelectedTab(index)}
            key={`tab-nav-${index}`}
          >
            {name}
          </div>
        );
      })}
    </nav>
  );

  return (
    <div className={`tabs ${className ? " " + className : ""}`}>
      {getTabComponents()}
      {children[selectedTab]}
    </div>
  );
};

export default TabbedView;
