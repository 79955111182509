import cx from "classnames";
import React, { useState } from "react";

import { useField } from "formik";
import Icon from "@components/Icon";
import Label from "../Label";
import ValidationFeedback from "../ValidationFeedback";
import Hint from "../Hint";

const PasswordField = ({
  form,
  label = "",
  hint = "",
  showFeedbackIcon = true,
  showFeedbackText = true,
  className,
  onChange = () => {},
  typeError = "warning",
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const change = (e) => {
    field.onChange(e);
    onChange(e, field, meta, helpers);
  };

  const [isFocused, setIsFocused] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <div className={cx("relative", className)}>
        <Label name={field.name} hasValue={!!meta?.value} isFocused={isFocused}>
          {label}
        </Label>
        <input
          type={isVisible ? "text" : "password"}
          id={field.name}
          {...field}
          {...props}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
          }}
          onKeyUp={(e) => {
            helpers.setTouched(true);
          }}
          onChange={change}
          className="w-full px-3 pt-5 pb-1 text-white border rounded-lg bg-brand-600 border-brand-500 h-14 focus:outline-none focus:border-gold focus:ring-0 focus:ring-offset-0 focus:ring-transparent"
        />
        <a
          onClick={() => setIsVisible(!isVisible)}
          className={cx(
            "h-full z-10 transition tansition-all absolute flex items-center justify-center p-4 right-0 rtl:right-auto rtl:left-0  top-0 rtl:border-r-1 ltr:border-l-1 w-18 cursor-pointer ltr:rounded-r-lg rtl:rounded-l-lg hover:bg-purple-dark hover:text-white",
            {
              "border-purple-dark": isFocused,
              "border-gray-input": !isFocused,
            },
            { "border-brand-500": !meta?.error || !meta?.touched },
            {
              "border-warning":
                !!meta?.error && meta?.touched && typeError === "warning",
            },
            {
              "border-red":
                !!meta?.error && meta?.touched && typeError === "error",
            }
          )}
        >
          <Icon icon={isVisible ? "eyeClosed" : "eye"} className="w-5 h-4" />
        </a>
      </div>
      <ValidationFeedback
        meta={meta}
        name={field.name}
        showFeedbackIcon={showFeedbackIcon}
        showFeedbackText={showFeedbackText}
        typeError={typeError}
      />
      <Hint show={!!hint && !meta?.error}>{hint}</Hint>
    </>
  );
};

export default PasswordField;

//
// const PasswordField = ({ form, label = "", ...props }) => {
//   const [field, meta, helpers] = useField(props);
//   const [isFocused, setIsFocused] = useState(false);
//   const [isVisible, setIsVisible] = useState(false);
//   console.log("field", field);
//   return (
//     <div className="relative flex flex-row items-stretch justify-start">
//       <div className="relative w-full text-sm rounded-full background-white">
//         <input
//           type={isVisible ? "text" : "password"}
//           id={field.name}
//           {...field}
//           {...props}
//           onFocus={() => setIsFocused(true)}
//           onBlur={() => {
//             setIsFocused(false);
//           }}
//           className="z-10 w-full mt-5 transition-all transition rounded-full text-purple-dark h-13 border-gray-input placeholder-gray-light focus:outline-none focus:border-purple-dark focus:ring-offset-0 focus:ring-transparent focus:text-bold background-transparent"
//         />
//         <label
//           htmlFor={field.name}
//           className={cx(
//             "cursor-text z-0 origin-top-left h-13 mt-5 block transition duration-500 transition-all transform absolute left-6 text-input top-4",
//             {
//               "-translate-y-0 scale-100": !isFocused && !field.value
//             },
//             {
//               "-translate-y-3 scale-75": isFocused || !!field.value
//             }
//           )}
//         >
//           {label}
//         </label>
//       </div>
//       <a
//         onClick={() => setIsVisible(!isVisible)}
//         className={cx(
//           "transition tansition-all absolute flex items-center justify-center mt-5 p-4 right-4  border-l-1 w-24 cursor-pointer",
//           {
//             "border-purple-dark": isFocused,
//             "border-gray-input": !isFocused
//           }
//         )}
//       >
//         {isVisible ? "Skjul kode" : "Vis kode"}
//       </a>
//       {/* {touched[field.name] && errors[field.name] && ( */}
//       {/*  <div className="error">{errors[field.name]}</div> */}
//       {/* )} */}
//     </div>
//   );
// };
//
// export default PasswordField;
