import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import cx from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import { Transition } from "@headlessui/react";
import { useTracking } from "@hooks/useTracking";
import ChangePasswordSchema from "./change-password.validation";
import Button from "../../../Button";
import { setNewPassword } from "../../../../data/model/account";
import { PasswordField, TextField } from "../../../Formik";
import SubmitFeedback from "../../../Formik/SubmitFeedback";
import { useRouter } from "../../../Provide/RouterContext";

const ChangePassword = ({
  onSuccess,
  setMenuItem,
  setBackActions,
  setSection,
}) => {
  useEffect(
    () =>
      setBackActions((prevState) => {
        prevState.push((x) => {
          setSection("Main");
          setMenuItem("Profile");
        });
        return prevState;
      }),
    []
  );
  const recaptchaRef = React.useRef();
  const [oldPassword, setOldPasswordText] = useState("");
  const [newPassword, setNewPasswordText] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPassword, setConfirmPasswordText] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [canChange, setCanChange] = useState(false);
  const intl = useIntl();
  const router = useRouter();
  const { track } = useTracking();

  // {"user":{"current_password":"F2s3f1L3","password":"password","password_confirmation":"password"}}

  const submit = async (
    { current_password, password, password_confirmation },
    { setStatus, resetForm, setFieldError }
  ) =>
    // const token = recaptchaRef.current.props.grecaptcha.getResponse();
    setNewPassword(current_password, password, password_confirmation)
      .then(() => {
        track("Password change success");
        resetForm();
        setStatus("success");
      })
      .catch((error) => {
        track("Password change error", { error });
        setFieldError("submitError", error.desc);
      });
  return (
    <Formik
      validationSchema={ChangePasswordSchema(intl)}
      initialStatus="init"
      initialErrors={{
        current_password: "Required",
        password: "Required",
        password_confirmation: "Required",
      }}
      initialValues={{
        current_password: "",
        password: "",
        password_confirmation: "",
        submitError: "",
        // captcha: '',
      }}
      onSubmit={(fields, actions) => submit(fields, actions).then(onSuccess)}
    >
      {({ isSubmitting, status, isValid, setFieldValue }) => (
        <Form>
          <h4 className="mb-16 text-2xl font-light leading-5 text-center text-white  sticky top-0 bg-[#13161d] max-w-full ms-auto me-auto pt-0 pb-[11px] z-[9] ml-0">
            {intl.formatMessage({
              defaultMessage: "Change Password",
              description: "Header of section",
            })}
          </h4>
          <Transition
            show={status === "init"}
            leave="transition ease-in duration-500"
            leaveFrom="transform opacity-100"
            leaveTo="transform -translate-x-80 opacity-0"
            className="flex flex-col w-full"
            unmount={false}
          >
            <ErrorMessage name="submitError">
              {(msg) => <div className="mt-8 text-sm text-red">{msg}</div>}
            </ErrorMessage>
            <div className="mx-auto mt-5.5 flex-col flex w-full items-stretch px-2.5 mb-10 md:mb-0 rtl:text-right">
              <TextField
                name="current_password"
                label={intl.formatMessage({
                  defaultMessage: "Old password",
                  description: "The label for old password in account",
                })}
                // hint={intl.formatMessage({
                //   defaultMessage: 'Please fill in your old password',
                //   description: 'The hint for old password in account',
                // })}
                className="mt-4"
              />

              <PasswordField
                name="password"
                label={intl.formatMessage({
                  defaultMessage: "New password",
                  description: "The label for new password in account",
                })}
                hint={intl.formatMessage({
                  defaultMessage: "Please fill in your new password",
                  description: "The hint for new password in account",
                })}
                className="mt-8"
                showFeedbackText
              />
              {/* <div className="z-10 w-full overflow-hidden">
                {newPasswordError}
              </div> */}

              <PasswordField
                name="password_confirmation"
                label={intl.formatMessage({
                  defaultMessage: "New password confirmation",
                  description: "The label for new password in account",
                })}
                // hint={intl.formatMessage({
                //   defaultMessage: 'Please fill in the same password',
                //   description: 'The hint for new password in account',
                // })}
                showFeedbackText
                className="mt-8"
              />
            </div>
            {/* <div>{confirmPasswordError}</div> */}

            <div className="flex flex-row mt-12 px-2.5 items-left justify-center">
              <Button.Action
                trackName="Submit password change"
                trackParams={{ component: "ChangePassword" }}
                className={cx("w-full", { "animate-pulse": isSubmitting })}
                disabled={!!isSubmitting || !isValid}
                type="submit"
                arrow="right"
              >
                {isSubmitting
                  ? intl.formatMessage({
                      defaultMessage: "Saving...",
                      description:
                        "When submitting a button this generic terms is used while waiting",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Set new password",
                      description: "The label on a button for saving",
                    })}
              </Button.Action>
            </div>
            {/* <ReCAPTCHA
              ref={recaptchaRef}
              // size="invisible"
              sitekey={CAPTCHA_PUBLIC_KEY}
              onChange={token => setFieldValue("captcha", token)}
              onExpired={() => setFieldValue("captcha")}
              onErrored={() => setFieldValue("captcha")}
            /> */}
          </Transition>

          <SubmitFeedback
            status={status}
            header={intl.formatMessage({
              defaultMessage: "Confirmed",
              description: "The feedback header",
            })}
          >
            <div className="py-8 leading-7 text-24">
              {intl.formatMessage({
                defaultMessage:
                  "Form submit successful. Next time login with your new password",
                description: "The feedback after changing account details",
              })}
            </div>
            <div className="px-8">
              <Button.Action
                trackName="Back to account from Change Password"
                trackParams={{ component: "ChangePassword" }}
                onClick={() => setMenuItem("Root")}
                className="w-full"
                arrow="right"
              >
                {intl.formatMessage({
                  defaultMessage: "Back to my account",
                  description: "Label",
                })}
              </Button.Action>
            </div>
          </SubmitFeedback>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePassword;
