import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import useSWR from "swr";
import { useIntl } from "react-intl";
import cx from "classnames";
import Button from "../../Button";
import BonusSelector from "./BonusSelector";
import DynamicFields from "./DynamicFields";
import { useWallet } from "../../Provide/WalletContext";
import { useRouter } from "../../Provide/RouterContext";
import { defaultRequester } from "../../../core/default-requester";
import ProfileDynamicFields from "../../ProfileDynamicFields/DynamicFields";
import Link from "../../Link";
import Icon from "../../Icon/Icon";
import { Transition } from "@headlessui/react";
import { localeUrl } from "../../../core/url";
import { isBrowser } from "browser-or-node";
import { getDomainName } from "../../../core/utils";
import { useScript } from "../../UseScript";
import { ANALYTICS_FIRST_DEPOSIT_URL } from "../../../constants/Analytics";
import getOfferForYouQuery from "./get-offer-for-you.graphql";
import { useQuery } from "@apollo/client";
import stars from "./stars.svg";
const ENDPOINT = isBrowser
  ? `https://backend.${getDomainName(window.location.hostname)}`
  : "https://backend.theclubhousecasino.com";

export const DepositForm = ({
  values,
  isSubmitting,
  isValid,
  hideModal,
  setFieldValue,
  currencies,
  countries,
  initialValues,
  values: {
    fields,
    method,
    useBonusCode,
    bonusCode,
    identifier,
    amount,
    savedProfiles,
  },
  setBackActions,
  setStatus,
  errors,
  profileFields,
  userDepositCount,
  isFromMyClubhousePage: isFromMyClubhousePageTemp,
}) => {
  const intl = useIntl();
  const { currency } = useWallet();
  const [showConformation, setShowConformation] = useState("");
  const [isFromMyClubhousePage, setIsFromMyClubhousePage] = useState(
    isFromMyClubhousePageTemp
  );
  const [remvoedCard, setRemvoedCard] = useState("");
  // useEffect(() => setBackActions((prevState) => {
  //   prevState.push((x) => {
  //     // setSection('Main');
  //     setStatus({ step: 'initial' });
  //   }); return prevState;
  // }),
  // []);
  const { values: formValues } = useFormikContext();
  const router = useRouter();
  const [amountValue, setAmountValue] = useState(0);
  const [paymentPlanAPI, setPaymentPlanAPI] = useState(null);
  // console.log("method--method", method);
  // console.log("formValues--formValues", formValues);
  // console.log("paymentPlanAPI--paymentPlanAPI", paymentPlanAPI);
  // console.log("errors--errors", errors);

  const {
    loading: offerForYouLoading,
    error: offerForYouError,
    data: offerForYouData,
  } = useQuery(getOfferForYouQuery, { variables: { locale: intl.locale } });

  console.log("offerForYouData", offerForYouData);
  const cockpitDepositData =
    offerForYouData?.getOfferForYou?.find(
      (x) => x.id === formValues?.fields?.bonusCode?.name
    ) || {};
  console.log("cockpitDepositData", cockpitDepositData);
  // const { data: paymentPlanAPI } = useSWR(['buildpaymentPlanAPI',currency,method,identifier,defaultRequester], (url) => window.PaymentsAPI.buildPaymentPlan(currency, 'deposit', method, identifier, defaultRequester));
  const { data: paymentPlan } = useSWR(
    ["buildpaymentPlanAPI", currency, method?.id, identifier, defaultRequester],
    (url) =>
      window.PaymentsAPI.config({
        serverUrl: ENDPOINT,
        apiPrefix: "/api",
        customCdnUrl: "https://cdn2.softswiss.net/logos/payments/white/",
        redirectRoutes: {
          successUrl: `https://${window.location.hostname}${localeUrl(
            "depositStatus",
            intl.locale,
            { status: "success" }
          )}`,
          failureUrl: `https://${window.location.hostname}${localeUrl(
            "depositStatus",
            intl.locale,
            { status: "failure" }
          )}`,
          pendingUrl: `https://${window.location.hostname}${localeUrl(
            "depositStatus",
            intl.locale,
            { status: "pending" }
          )}`,
          supportUrl: localeUrl("depositStatus", intl.locale, {
            status: "support",
          }),
        },
      }).then(() =>
        window.PaymentsAPI.getMethodFields({
          id: method?.id,
          currency,
          paymentAction: "deposit",
        }).then((e) => {
          console.log("method?.id", e);
          //   e.methodFields.push({
          //     "fieldName": "protocol",
          //     "type": "select",
          //     "options": [
          //       {
          //         value: "ERC20",
          //         label: "ERC20"
          //       },
          //       {
          //         value: "TRC20",
          //         label: "TRC20"
          //       },
          //     ],
          //     "placeholder": null,
          //     "proposedValue": "ERC20"
          // },)
          setPaymentPlanAPI(e);
        })
      )
  );
  // console.log('window.PaymentsAPI test',  window.PaymentsAPI);
  // console.log("window.PaymentsAPI", window.PaymentsAPI);
  // console.log("paymentPlan test", paymentPlanAPI);
  // console.log('paymentPlan test 2',  test?.paymentPlan);
  useEffect(() => {
    setFieldValue("paymentPlan", paymentPlanAPI);
  }, [paymentPlanAPI, method]);

  useEffect(() => {
    setFieldValue("savedProfileId", method?.savedProfiles?.[0]?.id);
  }, [method]);

  useEffect(() => {
    console.log("userDepositCount", userDepositCount);
    if (userDepositCount !== undefined && userDepositCount === 0) {
      useScript(ANALYTICS_FIRST_DEPOSIT_URL);
    }
  }, [userDepositCount]);
  // useEffect(() => {
  //  setFieldValue('paymentPlan', paymentPlanAPI);
  //   if (paymentPlanAPI) {
  //     const savedChannels = window.PaymentsAPI.savedChannels('deposit', paymentPlanAPI);
  //   }
  // }, []);

  const onRemoveInitialBonus = () => {
    setIsFromMyClubhousePage(false);
    setFieldValue("fields[bonusCodeInput]", "");
    setFieldValue("useBonusCode", false);
  };

  return (
    <div className="flex flex-col w-full p-4 px-4 overflow-auto text-white">
      {/* <h4 className="mx-12 mb-16 text-2xl font-light leading-5 text-center text-white">
        {intl.formatMessage({
          defaultMessage: 'Deposit with {brand}',
          description: 'header',
        }, { brand: (paymentPlanAPI?.method?.brand === 'combined') ? 'Interac®' : titleize(paymentPlanAPI?.method?.brand) })}
      </h4> */}
      <div className="mx-auto mt-5.5 flex-col flex w-full items-stretch">
        {/* <ErrorMessage name="formState" /> */}
        <span class="text-lg block mb-2">
          {method?.savedProfiles.length > 0 && (
            <span>
              {intl.formatMessage({
                defaultMessage: "Your stored card(s)",
                description: "Deposit card header",
              })}
            </span>
          )}
        </span>
        <div className="mt-5.5 flex-col flex w-full space-y-3 md:grid md:space-y-0  grid-cols-2 md:gap-6">
          {method &&
            method?.savedProfiles
              ?.filter((savedItem) => savedItem?.id !== remvoedCard)
              ?.map((savedItem) => {
                console.log(
                  values?.savedProfileId === savedItem?.id,
                  "savedItem"
                );
                return (
                  <div
                    style={{ borderWidth: "2px" }}
                    onClick={() =>
                      setFieldValue("savedProfileId", savedItem?.id)
                    }
                    className={`relative items-center justify-between w-auto p-3 border-solid text-lg  rounded cursor-pointer flex bg-brand-600 ${
                      values?.savedProfileId === savedItem?.id
                        ? "border-gold text-gold"
                        : "border-brand-600 text-white"
                    }`}
                  >
                    <div className="flex items-center space-x-2">
                      <Icon
                        icon="card"
                        className={`w-5 h-5 ${
                          values?.savedProfileId === savedItem?.id
                            ? "text-gold"
                            : "text-brand-400"
                        }`}
                      />
                      <p className={""}>{savedItem?.title}</p>
                    </div>

                    <Link
                      onClick={() => {
                        setShowConformation(savedItem?.id);
                      }}
                    >
                      <Icon icon="trash" className={"w-5 h-5 text-brand-400"} />
                    </Link>
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                      className={
                        "fixed top-0 left-0 h-full w-full bg-black bg-opacity-70 z-50 origin-center"
                      }
                      show={showConformation === savedItem?.id}
                    >
                      <div className="absolute flex items-center w-full max-w-xs p-4 transform -translate-x-1/2 -translate-y-1/2 rounded-lg top-1/2 left-1/2 bg-brand-600">
                        <div className="flex items-center justify-between w-full">
                          <p> Are you sure?</p>
                          <div className="space-x-3">
                            <Button.Action
                              className={"h-8"}
                              onClick={(e) => {
                                window.PaymentsAPI.deleteSavedProfile({
                                  id: method.id,
                                  currency,
                                  paymentAction: "deposit",
                                  savedProfileId: savedItem?.id,
                                });
                                setRemvoedCard(savedItem?.id);
                              }}
                            >
                              Yes
                            </Button.Action>
                            <Button.Action
                              className={"bg-brand-400 h-8"}
                              onClick={() => setShowConformation("")}
                            >
                              No
                            </Button.Action>
                          </div>
                        </div>
                      </div>
                    </Transition>
                  </div>
                );
              })}

          {method?.savedProfiles.length > 0 && (
            <button
              style={{ borderWidth: "2px" }}
              arrow="right"
              type="button"
              className={`relative flex items-center justify-between w-auto p-3 text-lg  border-solid rounded cursor-pointer bg-brand-600 col-span-full ${
                values?.savedProfileId === null
                  ? "border-gold text-gold"
                  : "border-brand-600 text-white"
              }`}
              onClick={() => {
                const element = document.getElementById("card-form");
                element?.scrollIntoView();
                setFieldValue("savedProfileId", null);
              }}
            >
              <div className="flex items-center ">
                <Icon
                  icon="card"
                  className={`w-5 h-5 mr-3 ${
                    values?.savedProfileId === null
                      ? "text-gold"
                      : "text-brand-400"
                  }`}
                />{" "}
                {intl.formatMessage({
                  defaultMessage: "Add New Card",
                  description: "Label to add a new card on Deposit screen",
                })}
              </div>
              <Icon
                icon="arrow"
                className={`fill-current ml-4 h-4 w-4 inline-block transform -rotate-90`}
              />
            </button>
          )}
        </div>
        {isFromMyClubhousePage ? (
          <div className="mt-5.5 gap-3 flex w-full  ">
            <div>
              <img
                src={stars}
                alt=""
                className="w-20 h-auto filter invert md:w-10"
              />
            </div>
            <div>
              <h3 className="text-white text-left font-normal mb-1.5">
                {cockpitDepositData?.title ||
                  formValues?.fields?.bonusCode?.text}
              </h3>
              <div className="font-light leading-normal text-10">
                {cockpitDepositData?.terms}
              </div>
              <div className="">
                <span
                  className="font-light leading-normal underline cursor-pointer text-10 text-gold"
                  onClick={onRemoveInitialBonus}
                >
                  {intl.formatMessage({
                    defaultMessage: "remove this bonus",
                    description: "remove this bonus text",
                  })}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-5.5 flex-col flex w-full items-stretch">
            <BonusSelector
              paymentType={method?.type}
              setFieldValue={setFieldValue}
              useBonusCode={useBonusCode}
              bonusCode={fields?.bonusCode}
              values={values}
            />
          </div>
        )}
        {!paymentPlanAPI ? (
          <div className="pt-2">
            <span className="text-white animate-pulse">loading...</span>
          </div>
        ) : (
          <>
            <div id="card-form"></div>
            <div className="mt-5.5 flex-col flex w-full items-stretch">
              <DynamicFields
                setAmountValue={setAmountValue}
                setStatus={setStatus}
                paymentPlan={paymentPlanAPI}
                setFieldValue={setFieldValue}
                method={method}
                values={values}
                initialRequiredInputFields={paymentPlanAPI?.methodFields}
              />
            </div>

            <div className="flex flex-col items-stretch w-full -mt-3">
              <ProfileDynamicFields
                profileFieldsType="payment"
                countries={countries}
                currencies={currencies}
                initialValues={initialValues}
                method={method}
                initialRequiredInputFields={paymentPlanAPI?.playerFields.map(
                  (item) => ({ ...item, fieldName: item.field })
                )}
              />
            </div>
          </>
        )}
        {errors?.formState && (
          <div className="mt-8">
            <div className="w-full font-bold text-left">
              {intl.formatMessage({
                defaultMessage: "Deposit was not complete:",
                description: "Button text",
              })}
            </div>
            <div className="my-4 text-red">
              {errors?.formState?.map((item) => (
                <div className="w-full pb-2">{item}</div>
              ))}
            </div>
          </div>
        )}

        {method?.type !== "crypto" && (
          <div className="flex flex-row my-8">
            <Button.Action
              trackName="Submit Deposit"
              trackParams={{ component: "DepositForm" }}
              className={cx("w-full", { "animate-pulse": isSubmitting })}
              disabled={
                !!isSubmitting || !isValid || paymentPlanAPI === undefined
              }
              submittingLabel={intl.formatMessage({
                defaultMessage: "Submitting...",
                description:
                  "When submitting a button this generic terms is used while waiting",
              })}
              isSubmitting={isSubmitting}
              type="submit"
              arrow="right"
            >
              {intl.formatMessage({
                defaultMessage: "Deposit",
                description: "The label on a button for saving an amount",
              })}{" "}
              {intl.formatNumber(fields?.amount ?? 0, {
                style: "currency",
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Button.Action>
          </div>
        )}
      </div>
    </div>
  );
};
export default DepositForm;
