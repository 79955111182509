import React, { useEffect, useMemo, useState } from "react";
import Icon from "../../../../Icon/Icon";
import cx from "classnames";
import { useIntl } from "react-intl";
import { DOC_TYPE, DOCUMENT_STATUS } from "../../../../../data/constant";
import useSWR from "swr";
import {
  getDocuments,
  saveDocument,
} from "../../../../../data/model/documents";
import Button from "../../../../Button";
import DocumentInformationRulesList from "../DocumentInformationRulesList/DocumentInformationRulesList";
import DocumentCard from "../DocumentCard/DocumentCard";
import DocumentUploadInput from "../DocumentUploadInput/DocumentUploadInput";

const ProofOfAddress = () => {
  const intl = useIntl();

  const proofOfAddressRules = [
    intl.formatMessage({
      defaultMessage:
        "Your name and address are visible and match your profile",
      description: "proofOfAddressRules Rules 1 Label",
    }),
    intl.formatMessage({
      defaultMessage: "All information is clear and readable",
      description: "proofOfAddressRules Rules 2 Label",
    }),
    intl.formatMessage({
      defaultMessage: "The date of the document is no more than 90 days ago",
      description: "proofOfAddressRules Rules 2 Lablel",
    }),
    intl.formatMessage({
      defaultMessage: "Placed on a flat surface with all 4 corners visible",
      description: "proofOfAddressRules Rules 3 Label",
    }),
  ];

  const [documents, setDocuments] = useState([]);
  const [progress, setProgress] = useState(0);
  const [attachment, setAttachment] = useState();
  const [newUploadName, setNeWUploadName] = useState();
  const [showProofOfAddressRulesList, setShowProofOfAddressRulesList] =
    useState(false);
  const { error, data, mutate } = useSWR("getDocuments", getDocuments);

  useEffect(() => {
    if (data?.length > 0) {
      setDocuments(data?.filter((x) => x.document_type === DOC_TYPE.ADDRESS));
    }
  }, [data]);

  const handleSelectFile = (file) => {
    setNeWUploadName(file.name?.toString()?.substr(0, 30));
    setAttachment(file);
  };

  const handleSuccess = () => {
    mutate();
    setNeWUploadName(null);
    setAttachment(null);
  };

  const handleErr = (err) => {
    console.log("err--err", err);
    //   {
    //     "errors": [
    //         "Attachment content type should be image or pdf",
    //         "Attachment file size must be less than 10 MB"
    //     ]
    // }
    setNeWUploadName(null);
    setAttachment(null);
  };

  const handleSubmit = () => {
    saveDocument(attachment, newUploadName, DOC_TYPE.ADDRESS)
      .then(() => {
        handleSuccess();
        // track("Document Verification submit success", values);
        // setStatus("success");
        // setSubmitting(false);
      })
      .catch((err) => {
        handleErr(err);
        // track("Document Verification submit error", { error: err });
        // setSubmitting(false);
        // setFieldError("formState", err.desc);
        // setStatus("error");
      });
  };

  const isProofOfIdentityApproved = useMemo(() => {
    if (documents) {
      return !!(
        documents?.length > 0 &&
        documents?.some((doc) => doc.status === DOCUMENT_STATUS.APPROVED)
      );
    } else {
      return false;
    }
  }, [documents]);

  useEffect(() => {
    if (newUploadName && attachment) {
      handleSubmit();
    }
  }, [newUploadName, attachment]);

  return (
    <div className="border-1 border-brand-600 py-3.5 px-3 rounded bg-[#1e2229]">
      <div className="flex items-center w-full gap-3 mb-2.5">
        <div
          className={cx(
            "flex items-center justify-center w-full h-4 rounded-full max-w-4 ring-2 ring-opacity-50",
            {
              "bg-gold ring-gold": !isProofOfIdentityApproved,
              "bg-[#30d158] ring-[#30d158]": isProofOfIdentityApproved,
            }
          )}
        >
          <Icon icon="homeOutline" className={"w-2 h-2 text-brand"} />
        </div>
        <div className="flex items-center justify-between w-full">
          <h5 className="text-base font-normal text-white">
            {intl.formatMessage({
              defaultMessage: "Proof of address",
              description: "label",
            })}
          </h5>
          <span
            className={cx(
              `capitalize px-2.5 py-1 rounded border text-10 sm:text-[8px] font-bold`,
              {
                "bg-[#0091ff] border-[#0091ff] text-[#102739]":
                  !isProofOfIdentityApproved,
                "bg-[#30d158] border-[#30d158] text-[#17311e]":
                  isProofOfIdentityApproved,
              }
            )}
          >
            {isProofOfIdentityApproved
              ? intl?.formatMessage({
                  defaultMessage: "Approved",
                  description: "The label for identity Approved",
                })
              : intl?.formatMessage({
                  defaultMessage: "Requested",
                  description: "The label for identity Requested",
                })}
          </span>
        </div>
      </div>

      <div className="pl-7">
        {isProofOfIdentityApproved && (
          <Button.Action
            trackName="Action"
            arrow={showProofOfAddressRulesList ? "up" : "down"}
            onClick={() =>
              setShowProofOfAddressRulesList(!showProofOfAddressRulesList)
            }
            className={cx(
              "p-0 text-gold bg-transparent h-auto px-0 text-sm font-normal leading-tight",
              {
                "mb-2": showProofOfAddressRulesList,
              }
            )}
            arrowClassName={"!ml-1.5 !h-3 transition-all"}
          >
            {intl.formatMessage({
              defaultMessage: "Show what’s needed and upload more files",
              description: "Label",
            })}
          </Button.Action>
        )}
        <div
          className={cx("transition flex flex-col gap-3.5 overflow-hidden", {
            "h-0": isProofOfIdentityApproved && !showProofOfAddressRulesList,
            "h-max": showProofOfAddressRulesList,
          })}
        >
          <DocumentInformationRulesList
            heading={intl.formatMessage({
              defaultMessage:
                "Utility bill, bank statement or government communication.",
              description: "Label",
            })}
            rulesListData={proofOfAddressRules}
          />

          <DocumentUploadInput
            handleSelectFile={handleSelectFile}
            setProgress={setProgress}
            newUploadName={newUploadName}
            name={DOC_TYPE.ADDRESS}
            id={DOC_TYPE.ADDRESS}
          />
        </div>

        {documents && documents?.length > 0 ? (
          <div className="flex flex-col gap-3 mt-3.5">
            {newUploadName ? (
              <div className="p-3 border rounded bg-brand-600 border-brrounded-md">
                <div className="flex items-center justify-between mb-1.5 gap-2.5">
                  <div className="flex gap-2.5 items-center">
                    <div className="flex items-center justify-center w-3 h-4 rounded-full">
                      <Icon
                        icon="userDocument"
                        className={"w-3 h-4 text-white"}
                      />
                    </div>
                    <h5 className="text-sm font-normal text-white">
                      {newUploadName}
                    </h5>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3">
                  <div className="w-full rounded-full h-2 bg-[#1e2229] relative overflow-hidden">
                    <div
                      className="absolute top-0 left-0 h-full transition duration-300 ease-in-out rounded-full eas bg-gold"
                      style={{
                        width: progress + "%",
                      }}
                    />
                  </div>
                  <span className="text-[8px] font-normal text-gray">
                    {progress}%
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
            {documents
              ?.sort(
                (a, b) => new Date(b?.updated_at) - new Date(a?.updated_at)
              )
              ?.map((_item, index) => (
                <DocumentCard
                  document={_item}
                  key={`address_documents__${index}`}
                  icon={"file"}
                />
              ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ProofOfAddress;
