import React from "react";

import RootModal from "../Modal/RootModal";
import CenterModal from "../Modal/CenterModal";
import Deposit from "../Deposit";
import { useIntl } from "react-intl";

const DepositModal = ({ isActive, hideModal, initialAmount, initialBonusCode, isFromMyClubhousePage }) => {
  const intl = useIntl();
  return (
    <RootModal hideModal={hideModal} isActive={isActive}>
      <CenterModal
        hideModal={hideModal}
        isActive={isActive}
        title={intl.formatMessage({
          defaultMessage: "Deposit",
          description: "Deposit headline",
        })}
        size="xl"
      >
        <Deposit initialAmount={initialAmount} initialBonusCode={initialBonusCode} hideModal={hideModal} isFromMyClubhousePage={isFromMyClubhousePage} />
      </CenterModal>
    </RootModal>
  );
};

DepositModal.displayName = "DepositModal";
export default DepositModal;
