import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import withStyles from "isomorphic-style-loader/withStyles";
import s from "./banner.scss";
import cx from "classnames";
import { defineMessages, injectIntl } from "react-intl";
import Button from "@components/Button";
import { getIpInfo } from "../../data/model/session";

const messages = defineMessages({
  cookiePolicyRoute: {
    id: "router.cookiePolicy",
    defaultMessage: "privacy-policy",
    description: "The route used for the Privacy Policy page",
  },
  cookiePolicyMorInfo: {
    id: "text.cookieControlMoreInfo",
    defaultMessage: "For more information vist our",
    description: "the more info text for cookie control",
  },
  cookiePolicy: {
    id: "text.cookiePolicy",
    defaultMessage: "Cookie Policy",
    description: "the more info text for cookie control",
  },
  analytics: {
    id: "text.analytics",
    defaultMessage: "Analytics",
    description: "the more info text for cookie control",
  },
  analyticsDescription: {
    id: "text.cookieControlAnalytics",
    defaultMessage:
      "Analytical cookies help us to improve our website by collecting and reporting information on its usage.",
    description: "the more info text for cookie control",
  },
  marketing: {
    id: "text.marketing",
    defaultMessage: "Marketing",
    description: "the more info text for cookie control",
  },
  marketingDescription: {
    id: "text.cookieControlMarketing",
    defaultMessage:
      "We use marketing cookies to help us improve the relevancy of advertising campaigns you receive.",
    description: "the more info text for cookie control",
  },
  useCookies: {
    id: "text.cookieControlSiteUseCookies",
    defaultMessage: "This site uses cookies.",
    description: "the more info text for cookie control",
  },
  necessaryTitle: {
    id: "text.cookieControlNecessaryTitle",
    defaultMessage: "Necessary Cookies",
    description: "the more info text for cookie control",
  },
  necessaryDescription: {
    id: "text.cookieControlNecessaryDescription",
    defaultMessage:
      "Necessary cookies enable core functionality. The website cannot function properly without these cookies, and can only be disabled by changing your browser preferences.",
    description: "the more info text for cookie control",
  },
  thirdPartyTitle: {
    id: "text.cookieControlThirdPartyTitle",
    defaultMessage: "Warning: Some cookies require your attention",
    description: "the more info text for cookie control",
  },
  thirdPartyDescription: {
    id: "text.cookieControlThirdPartyDescription",
    defaultMessage:
      "Consent for the following cookies could not be automatically revoked. Please follow the link(s) below to opt out manually.",
    description: "the more info text for cookie control",
  },
  intro: {
    id: "text.cookieControlIntro",
    defaultMessage:
      "Some of these cookies are essential, while others help us to improve your experience by providing insights into how the site is being used.",
    description: "the more info text for cookie control",
  },
  notifyTitle: {
    id: "text.cookieControlNotifyTitle",
    defaultMessage: "Your choice regarding cookies on this site",
    description: "the more info text for cookie control",
  },
  notifyTitleForAU: {
    id: "text.cookieControlNotifyTitleAU",
    defaultMessage:
      "Your choice regarding cookies and location terms on this site",
    description:
      "Your choice regarding cookies and location terms on this site text",
  },
  notifyDescription: {
    id: "text.cookieControlNotifyDescription",
    defaultMessage:
      "Please note that cookies are being used to optimise site functionality and give you the best possible experience. In order to continue using this website, accepting these cookies are obligatory.",
    description: "the more info text for cookie control",
  },
  notifyDescriptionAU: {
    id: "text.cookieControlNotifyDescriptionAU",
    defaultMessage:
      "Please note that cookies are being used to optimise site functionality and give you the best possible experience. In order to continue using this website, you accept that you are outside Australia when accessing the site and that these cookies are obligatory.",
    description: "the more info text for cookie control",
  },
  on: {
    id: "text.cookieControlOn",
    defaultMessage: "on",
    description: "on",
  },
  off: {
    id: "text.cookieControlOff",
    defaultMessage: "off",
    description: "the more info text for cookie control",
  },
  accept: {
    id: "text.cookieControlAccept",
    defaultMessage: "Accept",
    description: "the more info text for cookie control",
  },
  settings: {
    id: "text.cookieControlSettings",
    defaultMessage: "Settings",
    description: "the more info text for cookie control",
  },
});

@withStyles(s)
@injectIntl
export default class Banner extends PureComponent {
  static displayName = "Banner";

  static propTypes = {
    innerRef: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    onChangePreferences: PropTypes.func.isRequired,
    content: PropTypes.string.isRequired,
    subContent: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { isAustralianIp: false };
  }
  componentDidMount() {
    getIpInfo().then((ipInfo) => {
      if (ipInfo?.country_code === "AU") {
        this.setState({ isAustralianIp: true });
      }
    });
  }
  render() {
    const {
      innerRef,
      onAccept,
      onChangePreferences,
      content,
      subContent,
      intl,
    } = this.props;
    const isAustralianIp = this.state.isAustralianIp;
    return (
      <div
        innerRef={innerRef}
        className="flex flex-col justify-between p-8 md:flex-row"
      >
        <div className={s.explainContainer}>
          <strong className="text-xl font-semibold">
            {isAustralianIp
              ? intl.formatMessage(messages.notifyTitle)
              : // ? intl.formatMessage(messages.notifyTitleForAU)
                intl.formatMessage(messages.notifyTitle)}
          </strong>
          <span className="text-normal">
            {isAustralianIp
              ? intl.formatMessage(messages.notifyDescription)
              : // ? intl.formatMessage(messages.notifyDescriptionAU)
                intl.formatMessage(messages.notifyDescription)}
          </span>
        </div>
        <div className={s.buttonContainer}>
          <div className={s.actionsContainer}>
            <Button.Action
              trackName="Submit banner"
              trackParams={{ component: "banner" }}
              onClick={onAccept}
              className="ltr:mr-8 rtl:ml-8"
              arrow="right"
            >
              {intl.formatMessage(messages.accept)}
            </Button.Action>
            <Button.Action
              trackName="Change banner"
              trackParams={{ component: "banner" }}
              onClick={onChangePreferences}
              className="ltr:mr-8 rtl:ml-8"
              color="darkgray"
            >
              {intl.formatMessage(messages.settings)}
            </Button.Action>
          </div>

          {/* {!isAustralianIp && ( */}
          <button
            className={s.closingButton}
            type="button"
            title="Accept policy"
            aria-label="Accept policy"
            onClick={onAccept}
          >
            ✕
          </button>
          {/* )} */}
        </div>
      </div>
    );
  }
}
