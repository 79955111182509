import React, { useEffect, useState } from "react";
import RootModal from "@components/Modal/RootModal";
import { Dialog, Transition } from "@headlessui/react";
import { useIntl } from "react-intl";
import Button from "../../Button";
import { openNavigation } from "@actions/navigation";
import { useDispatch } from "react-redux";
import useSession from "@hooks/useSession";
import { useTracking } from "@hooks/useTracking";
import { updateProfile } from "@data/model/account";
import { ErrorMessage, Form, Formik } from "formik";
import ToggleSwitch from "../../Formik/ToggleSwitch";
import SubmitFeedback from "@components/Formik/SubmitFeedback";
import cx from "classnames";
import Link from "../../Link";
import Cookies from "universal-cookie";

const MarketingModal = ({ isActive, hideModal }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const open = (section = "Root") =>
    dispatch(openNavigation(section, { fromModal: true }));
  const { session, isLoading } = useSession();
  const { track } = useTracking();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const cookies = new Cookies();

  const submit = async (values, { setStatus, resetForm, setFieldError }) =>
    updateProfile("edition", values)
      .then(() => {
        track("Profile detail changes success", values);
        resetForm();
        setUpdateSuccess(true);
        setStatus("success");
      })
      .catch((error) => {
        setUpdateSuccess(false);
        track("Profile detail changes error", { error: error });
        setFieldError("submitError", error.desc);
      });

  useEffect(() => {
    if (!isActive) {
      cookies.set("marketingTrigger", null);
    }
  }, [isActive]);

  return (
    <RootModal hideModal={hideModal} isActive={isActive}>
      <Transition.Child
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="relative z-50 flex flex-col items-start justify-start flex-shrink-0 w-screen h-full pb-24 overflow-auto md:h-auto md:items-center md:pb-0"
      >
        <>
          <div
            id="marketing-notification"
            className="flex flex-col items-start justify-start flex-shrink-0 m-auto mx-5 md:min-h-screen md:mx-0"
          >
            <div className="flex flex-col flex-1 my-auto overflow-auto rounded-md md:flex-none md:grid md:grid-rows-12 md:grid-flow-col md:min-h-modal-reg md:overflow-visible bg-brand">
              <div className="md:row-span-12 flex w-full md:order-last flex-col bg-gray-lightest md:w-modal py-10 px-2 md:px-10 md:p-9.5 md:rounded md:rounded-bl-none relative overflow-hidden">
                <div className="w-full text-white bg-brand">
                  {!updateSuccess && (
                    <h1 className="mb-6 text-4xl font-light text-center">
                      {intl.formatMessage({
                        defaultMessage:
                          "Get bespoke offers, invitations and content as a Clubhouse member",
                        description: "Header for Marketing Notification Modal",
                      })}
                    </h1>
                  )}

                  {!updateSuccess && (
                    <p className="mb-2 text-base text-center">
                      {intl.formatMessage({
                        defaultMessage:
                          "Just enable below to receive unique members benefits and insightful emails.",
                        description:
                          "Paragraph for Marketing Notification Modal",
                      })}
                    </p>
                  )}

                  <Formik
                    initialStatus="init"
                    enableReinitialize
                    initialValues={session}
                    onSubmit={(fields, actions) =>
                      submit(fields, actions).then(onSuccess)
                    }
                  >
                    {({ isSubmitting, status, isValid, setFieldValue }) => (
                      <Form>
                        <Transition
                          show={status === "init"}
                          leave="transition ease-in duration-500"
                          leaveFrom="transform opacity-100"
                          leaveTo="transform -translate-x-80 opacity-0"
                          className="flex flex-col w-full"
                          unmount={false}
                        >
                          <ErrorMessage name="submitError">
                            {(msg) => (
                              <div className="mt-8 text-sm text-red">{msg}</div>
                            )}
                          </ErrorMessage>
                          <div className="mx-auto mt-5.5 flex-col flex w-full items-stretch px-2.5 ">
                            <div className="flex items-center justify-start mt-8">
                              <ToggleSwitch name="receive_promos" />{" "}
                              <span className="ml-2">
                                {intl.formatMessage({
                                  defaultMessage: "Receive members promotions",
                                  description: "Label T&C consent",
                                })}
                              </span>
                            </div>
                          </div>

                          <div className="flex flex-row justify-center px-4 my-8 items-left">
                            <Button.Action
                              className={cx("w-full", {
                                "animate-pulse": isSubmitting,
                              })}
                              disabled={!!isSubmitting || !isValid}
                              type="submit"
                              arrow="right"
                            >
                              {isSubmitting
                                ? intl.formatMessage({
                                    defaultMessage: "Updating...",
                                    description:
                                      "When submitting a button this generic terms is used while waiting",
                                  })
                                : intl.formatMessage({
                                    defaultMessage: "Update preferences",
                                    description:
                                      "The label on a button for saving",
                                  })}
                            </Button.Action>
                          </div>
                        </Transition>
                        <SubmitFeedback
                          status={status}
                          header={intl.formatMessage({
                            defaultMessage: "Confirmed",
                            description: "The feedback header",
                          })}
                        >
                          <div className="py-8 leading-7 text-24">
                            {intl.formatMessage({
                              defaultMessage:
                                "Your personal details have been updated.",
                              description:
                                "The feedback after changing account details",
                            })}
                          </div>
                          <div className="px-8">
                            <Button.Action
                              trackName="Marketing"
                              trackParams={{ component: "Marketing Modal" }}
                              className="inline-flex items-center justify-between w-full btn"
                              arrow="right"
                            >
                              <Link
                                to="home"
                                onClick={hideModal}
                                className="w-full text-left text-brand"
                              >
                                {intl.formatMessage({
                                  defaultMessage: "Play games",
                                  description:
                                    "Marketing Notification Modal button",
                                })}{" "}
                              </Link>
                            </Button.Action>
                          </div>
                        </SubmitFeedback>
                      </Form>
                    )}
                  </Formik>
                  {/* <div className='mx-4 md:px-10 md:mx-0'>
                  <Button.Action
                      trackName="Marketing" trackParams={{component: 'Marketing Modal'}}
                      className="inline-flex items-center justify-between w-full btn"
                      arrow="right"
                      onClick={() => { hideModal(); open('Profile')}}
                  >
                      { intl.formatMessage({
                      defaultMessage: 'Get our latest news',
                      description: 'Marketing Notification Modal button',
                      })}{' '}
                  </Button.Action>
                </div> */}
                  <div className="w-full mt-6 text-center">
                    <a
                      style={{ color: `#ddaf6c` }}
                      onClick={hideModal}
                      className="mt-4 text-base cursor-pointer text-brand"
                    >
                      {updateSuccess
                        ? intl.formatMessage({
                            defaultMessage: "Close",
                            description:
                              "Close for Marketing Notification Modal",
                          })
                        : intl.formatMessage({
                            defaultMessage: "Not now",
                            description:
                              "Close for Marketing Notification Modal",
                          })}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Transition.Child>
      <Transition.Child
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="absolute top-0 z-40 block w-screen h-screen bg-black bg-opacity-70 l-0" />
      </Transition.Child>
    </RootModal>
  );
};
MarketingModal.displayName = "MarketingModal";
export default MarketingModal;
