import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
// import { openNavigation } from "../../actions/navigation";
import Button from "../Button";
import history from "../../core/history";
import { localeUrl } from "../../core/url";
import stars from "./stars.svg";
import DepositModal from "../DepositModal/DepositModal";
import { useModal } from "../Modal/ModalContext";
import {
  closeNavigation,
  forceFullyToggleGameModal,
} from "../../actions/navigation";
import { useTracking } from "../../hooks/useTracking";

const CustomToast = ({
  title,
  description,
  closeToast,
  btnText,
  extraProps,
}) => {
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const { track } = useTracking();
  const close = () => dispatch(closeNavigation());
  const closeGameModal = () => dispatch(forceFullyToggleGameModal(false));
  // const open = (section = "Root") => dispatch(openNavigation(section));
  // console.log("extraProps", extraProps);
  const intl = useIntl();

  const toastType = useMemo(() => {
    return extraProps?.isCashBonus
      ? "CashBonus"
      : extraProps?.isDepositToast
      ? "Desposit"
      : extraProps?.isFreeSpinBonus
      ? "FreeSpin"
      : extraProps?.isLoyaltyUpgrade
      ? "LoyaltyUpgrade"
      : extraProps?.isBonusToast
      ? "WagerBonus"
      : "Bonus Toast";
  }, [extraProps]);
  useEffect(() => {
    track("Toast Notification Triggred", {
      type: toastType,
    });
  }, []);

  return (
    <div
      className="flex items-start gap-2 bg-gold text-brand-600"
      id={
        extraProps?.isDepositToast
          ? "deposit-notification"
          : extraProps?.isCashBonus
          ? `bonus-notification-${extraProps?.bonusId}`
          : ""
      }
    >
      <div className="flex flex-0">
        <img src={stars} className="w-6" />
      </div>
      <div className="flex flex-col flex-1">
        {title && (
          <div className="text-lg font-bold md:text-base text-brand-600">
            {title}
          </div>
        )}
        {description && (
          <div className="text-sm font-light md:text-xs text-brand-600">
            {description} {extraProps?.bonusTitle}
          </div>
        )}

        {extraProps?.isBonusToast && (
          <div className="w-full mt-2">
            <Button.Action
              trackName="Go to MyClubhouse Page From Toast CTA"
              trackParams={{ type: toastType, component: "CustomToast" }}
              className="flex items-center justify-between w-full h-8 py-1 transform btn hover:bg-opacity-95 hover:scale-105"
              arrow="right"
              color="darkgray"
              size="small"
              onClick={() => {
                history.push(localeUrl("myClubhouse", intl.locale));
                closeGameModal();
                closeToast();
                close();
              }}
            >
              {btnText}
            </Button.Action>
          </div>
        )}
        {extraProps?.isDepositToast && (
          <div className="w-full mt-2">
            <Button.Action
              trackName="Open Deposit From Toast CTA"
              trackParams={{ type: toastType, component: "CustomToast" }}
              className="flex items-center justify-between w-full h-8 py-1 transform btn hover:bg-opacity-95 hover:scale-105"
              arrow="right"
              color="darkgray"
              size="small"
              onClick={() => {
                showModal(DepositModal);
                closeGameModal();
                closeToast();
                close();
              }}
            >
              {btnText}
            </Button.Action>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomToast;
