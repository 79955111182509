import { useField } from "formik";
import cx from "classnames";
import React from "react";
import { RadioGroup, Transition } from "@headlessui/react";

const Group = ({
  options,
  className,
  classNameButton,
  valueKey = "name",
  labelKey = "text",
  hasLabel = false,
  label,
  onChange,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const setSelected = (value) => {
    helpers.setValue(value);
  };

  console.log("field.value", field.value);
  return (
    <div className={cx("flex flex-col", className)}>
      <span className="text-xs text-gray-light ml-3 mb-1">{label}</span>
      <RadioGroup value={field?.value} onChange={setSelected}>
        {/* <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label> */}
        <div className="space-x-3 flex flex-row">
          {options?.map((option) => (
            <RadioGroup.Option
              key={option?.[valueKey]}
              value={option?.[valueKey]}
              className="relative rounded-lg bg-brand-600 border-brand-500 h-14 w-full"
            >
              {({ active, checked }) => (
                <>
                  <div className="flex items-center justify-center w-full h-full cursor-pointer">
                    <RadioGroup.Label
                      as="div"
                      className={checked ? "text-gold" : "text-gray"}
                    >
                      {option?.[labelKey]}
                    </RadioGroup.Label>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};
export default Group;
