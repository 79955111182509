import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Transition } from "@headlessui/react";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { useIntl } from "react-intl";
import { saveLimit } from "@data/model/responsible-gaming";
import { logOut } from "@data/model/account";
import { useTracking } from "@hooks/useTracking";
import Button from "../../../Button";
import { localeUrl } from "../../../../core/url";
import {
  setCoolOff,
  getCoolOffConfig,
} from "../../../../data/model/responsible-gaming";
import { DatePicker, SelectBox } from "../../../Formik";
import SubmitFeedback from "../../../Formik/SubmitFeedback";
import { useRouter } from "../../../Provide/RouterContext";

import createResource from "../../../../data/createResource";
// const CoolOffConfig = createResource(getCoolOffConfig);

const SetCoolOff = ({ setBackActions, setSection, setMenuItem }) => {
  useEffect(
    () =>
      setBackActions((prevState) => {
        prevState.push((x) => {
          setSection("Main");
          setMenuItem("ResponsibleGaming");
        });
        return prevState;
      }),
    []
  );
  const router = useRouter();
  const { track } = useTracking();

  const intl = useIntl();
  const [seconds, setSeconds] = useState([5]);
  // const cfg = CoolOffConfig.read();

  const handleSubmit = (values, formikBag) => {
    const { setStatus, setFieldError, setSubmitting } = formikBag;
    console.log("ERROR CAUSE FAILED: ", values.period.name);

    setSubmitting(false);
    saveLimit({ period: values.period.name, type: "coolingoff" })
      .then(() => {
        track("Set cool off success", values);
        setStatus("success");
        setSubmitting(false);

        let timeleft = seconds;
        const downloadTimer = setInterval(() => {
          if (timeleft <= 0) {
            clearInterval(downloadTimer);
            logOut(intl.locale);
            window.location = `${localeUrl("home", intl.locale)}`;
          }
          setSeconds(timeleft);
          timeleft -= 1;
        }, 1000);
      })
      .catch((err) => {
        track("Set cool off error", { error: err });
        setSubmitting(false);
        setFieldError("formState", err.desc);
        setStatus("error");
      });
  };

  return (
    <Formik
      initialStatus="init"
      initialValues={{
        period: {
          name: "day",
          text: intl.formatMessage({
            defaultMessage: "24 hours",
            description: "Label of selectbox",
          }),
        },
      }}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, status, setFieldValue }) => (
        <Form>
          <h4 className="mb-16 text-2xl font-light leading-5 text-center text-white capitalize  sticky top-0 bg-[#13161d] max-w-full ms-auto me-auto pt-0 pb-[11px] z-[9] ml-0">
            {intl.formatMessage({
              defaultMessage: "Cool Off",
              description: "Header of section",
            })}
          </h4>
          <Transition
            show={status === "init"}
            leave="transition ease-in duration-500"
            leaveFrom="transform opacity-100"
            leaveTo="transform -translate-x-80 opacity-0"
            className="flex flex-col w-full"
            unmount={false}
          >
            <ErrorMessage name="submitError">
              {(msg) => <div className="mt-8 text-sm text-red">{msg}</div>}
            </ErrorMessage>

            <span className="px-3">
              {intl.formatMessage({
                defaultMessage:
                  "If you want to take a break for up to 30 days you can cool off by selecting an option below.",
                description: "A label for the cooloff limit",
              })}
            </span>
            <div className="mx-auto mt-7 flex-col flex w-full items-stretch px-2.5">
              <SelectBox
                name="period"
                className="w-full"
                // setFieldValue={setFieldValue}
                // fieldValueTitle= 'period'
                label={intl.formatMessage({
                  defaultMessage: "Period",
                  description: "The label for period",
                })}
                options={[
                  {
                    name: "day",
                    text: intl.formatMessage({
                      defaultMessage: "24 hours",
                      description: "Label of selectbox",
                    }),
                  },
                  {
                    name: "week",
                    text: intl.formatMessage({
                      defaultMessage: "1 week",
                      description: "Label of selectbox",
                    }),
                  },
                  {
                    name: "month",
                    text: intl.formatMessage({
                      defaultMessage: "1 month",
                      description: "Label of selectbox",
                    }),
                  },
                  {
                    name: "month_3",
                    text: intl.formatMessage({
                      defaultMessage: "3 months",
                      description: "Label of selectbox",
                    }),
                  },
                  // {
                  //   name: 'month_6',
                  //   text: intl.formatMessage({
                  //     defaultMessage: '6 months',
                  //     description: 'Label of selectbox',
                  //   }),
                  // },
                ]}
                hint={intl.formatMessage({
                  defaultMessage: "Play responsibly and set a limit.",
                  description: "Hint of inputfield",
                })}
              />
              {/* {values.period.dateSelector && ( */}
              {/*  <DatePicker */}
              {/*    name="coolOffDate" */}
              {/*    minDate={parseISO(values.period.minimumDate)} */}
              {/*    maxDate={parseISO(values.period.maximumDate)} */}
              {/*  /> */}
              {/* )} */}

              {/* <SelectBox */}
              {/*  name="reason" */}
              {/*  label={intl.formatMessage({ */}
              {/*    defaultMessage: 'Reason', */}
              {/*    description: 'The label for reason', */}
              {/*  })} */}
              {/*  options={cfg.reasons} */}
              {/* /> */}

              {/* {values.reason.name === 'unsatisfied' && ( */}
              {/*  <SelectBox */}
              {/*    name="unsatisfiedReasons" */}
              {/*    label="Reason" */}
              {/*    options={cfg.unsatisfiedReasons} */}
              {/*  /> */}
              {/* )} */}
              {/* {values.reason.name === 'other' || values.unsatisfiedReasons.name === 'other' && ( */}
              {/*  <div> */}
              {/*    <Field */}
              {/*      name="unsatisfiedReason" */}
              {/*      as="textarea" */}
              {/*    /> */}

              {/*  </div> */}
              {/* )} */}
              <div className="flex flex-row my-8">
                <Button.Action
                  trackName="Submit Cool off"
                  trackParams={{ component: "SetCoolOff" }}
                  className={cx("w-full", { "animate-pulse": isSubmitting })}
                  disabled={!!isSubmitting}
                  type="submit"
                  arrow="right"
                >
                  {isSubmitting
                    ? intl.formatMessage({
                        defaultMessage: "Saving...",
                        description:
                          "When submitting a button this generic terms is used while waiting",
                      })
                    : intl.formatMessage({
                        defaultMessage: "Set Cool Off",
                        description: "The label on a button for saving",
                      })}
                </Button.Action>
              </div>
            </div>
          </Transition>

          <SubmitFeedback
            status={status}
            header={intl.formatMessage({
              defaultMessage: "Confirmed",
              description: "The feedback header",
            })}
          >
            <div className="p-8 leading-7 text-24">
              {intl.formatMessage(
                {
                  defaultMessage:
                    "You’ve set a cooloff for {period}. You will be automatically logged out in {seconds}",
                  description: "The feedback after setting a limit",
                },
                {
                  period: (
                    <span className="font-bold text-green">
                      {values.period.text}
                    </span>
                  ),
                  seconds: (
                    <span className="font-bold text-green">
                      {seconds}{" "}
                      {intl.formatMessage({
                        defaultMessage: "seconds",
                        description: "Label",
                      })}
                    </span>
                  ),
                }
              )}
            </div>
            <div className="px-8">
              <Button.Action
                trackName="Back to my account from Set Cool off"
                trackParams={{ component: "SetCoolOff" }}
                onClick={() => setMenuItem("Root")}
                className="w-full"
                arrow="right"
              >
                {intl.formatMessage({
                  defaultMessage: "Back to my account",
                  description: "Label",
                })}
              </Button.Action>
            </div>
          </SubmitFeedback>
        </Form>
      )}
    </Formik>
  );
};

export default SetCoolOff;
