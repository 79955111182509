import useSWR from "swr";
import React from "react";
import { useIntl } from "react-intl";
import { Formik } from "formik";
import { getGrantedBonuses, getGrantedFreespins } from "../../data/model/bonus";
import DepositBonus from "./Templates/DepositBonus";
import FreeSpinsBonuses from "./Templates/FreeSpinsBonuses";
import { useWallet } from "../Provide/WalletContext";
import { getCurrencies } from "../../data/model/wallet";
import { getSessionInfo } from "../../data/model/session";
import ActiveBonusSelector from "../Deposit/DepositForm/BonusSelector/ActiveBonusSelector";

const ActiveBonuses = ({ setMenuItem }) => {
  const { error, data, mutate } = useSWR(
    "getGrantedBonuses",
    getGrantedBonuses
  );
  const {
    error: freespinsError,
    data: freespinsData,
    mutate: freespinsMutate,
  } = useSWR("getGrantedFreespins", getGrantedFreespins);
  const intl = useIntl();

  if (!data) {
    return null;
  }

  const { data: fields = {} } = useSWR("getSessionInfo", getSessionInfo);

  const { currency } = useWallet();

  const bonuses = data?.filter?.((item) =>
    ["handle_bets", "wait", "issued"].includes(item.stage)
  );
  const freespins = freespinsData?.filter?.((item) =>
    ["handle_bets", "wait", "issued", "activated"].includes(item.stage)
  );
  const { data: currencies } = useSWR("getCurrencies", getCurrencies);

  console.log("bonuses for user", bonuses);

  return (
    <>
      <h4 className="mb-10 text-2xl font-light leading-5 text-center text-white  sticky top-0 bg-[#13161d] max-w-full ms-auto me-auto pt-0 pb-[11px] z-[9] ml-0">
        {intl.formatMessage({
          defaultMessage: "Bonuses",
          description: "Header of section",
        })}
      </h4>

      <div className="mx-auto  flex-col flex w-full items-stretch px-2.5 pb-16">
        <Formik
          initialValues={{
            bonusCode: "",
            useBonusCode: fields?.can_issue_bonuses,
            fields: { errors: [], bonusCode: "", bonusCodeInput: "" },
          }}
        >
          {({
            isSubmitting,
            values,
            setStatus,
            status,
            isValid,
            setFieldValue,
            errors,
          }) => (
            <div className="mt-5.5 flex-col flex w-full items-stretch">
              <ActiveBonusSelector
                type="activeBonus"
                setFieldValue={setFieldValue}
                useBonusCode={values?.useBonusCode}
                bonusCode={values?.fields?.bonusCode}
                values={values}
              />
            </div>
          )}
        </Formik>

        {bonuses?.length === 0 && freespins?.length === 0 && (
          <div className="mt-2">
            {intl.formatMessage({
              defaultMessage: "No bonuses active",
              description: "No bonuses available",
            })}
          </div>
        )}
        {/* {freespins?.length > 0 && <span className="font-bold mt-5.5 mb-3">Bonuses</span>} */}
        {bonuses?.map?.((item) => (
          <DepositBonus
            item={item}
            bonusMutate={mutate}
            currencies={currencies}
            bonuses={bonuses}
          />
        ))}

        {freespins?.map?.((item) => (
          <FreeSpinsBonuses
            item={item}
            freespinsMutate={freespinsMutate}
            setMenuItem={setMenuItem}
            currencies={currencies}
            freespins={freespins}
          />
        ))}
      </div>
    </>
  );
};

export default ActiveBonuses;
