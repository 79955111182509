import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { useIntl } from "react-intl";
import usePrevious from "@hooks/usePrevious";
import Link from "../../../Link";
import Button from "../../../Button";
import SetPeriodAmountLimit from "../../../Account/Forms/SetPeriodAmountLimit";
import SetCoolOff from "../../../Account/Forms/SetCoolOff";
import SetSelfExclusion from "../../../Account/Forms/SetSelfExclusion";
import SetSessionLimit from "../../../Account/Forms/SetSessionLimit";
import Authenticated from "../MenuItem";
import SsrCompatibleSuspense from "../../../SsrCompatibleSuspense";

const Items = {
  SetDepositLimit: SetPeriodAmountLimit,
  SetWagerLimit: SetPeriodAmountLimit,
  SetLossLimit: SetPeriodAmountLimit,
  SetSessionLimit,
  SetCoolOff,
  SetSelfExclusion,
  Main: React.Fragment,
  Root: null,
};

const ResponsibleGaming = ({
  menuItem,
  setMenuItem,
  setBackActions,
  params,
}) => {
  const intl = useIntl();
  const [section, setSection] = useState("Main");
  const prevSection = usePrevious(section);

  // useEffect(() => setBackActions((prevState) => {
  //   console.log('prevState', prevState);
  //   prevState.push((x) => {
  //     setMenuItem('Root');
  //     console.log('go back');
  //   }); return prevState;
  // }),
  // []);

  // useEffect(() => setBackActions((prevState) => {
  //   prevState.push((x) => {
  //     setMenuItem('Profile');
  //   }); return prevState;
  // }),
  // []);

  const Slide = Items[section === "Main" ? prevSection : section];

  return (
    <div className="relative z-50 w-full h-full">
      <Transition
        show={section === "Main"}
        enter="transition-all duration-700"
        enterFrom="transform -translate-x-full"
        enterTo="transform translate-x-0"
        leave="transition-all duration-700"
        leaveFrom="transform-gpu translate-x-0"
        leaveTo="transform-gpu -translate-x-full"
        className="absolute left-0 flex flex-col w-full"
        // unmount={false}
      >
        <div className="flex flex-col w-full pb-14">
          <h4 className="mb-16 text-2xl font-light leading-5 text-center text-white mx-14  sticky top-0 bg-[#13161d] max-w-full ms-auto me-auto pt-0 pb-[11px] z-[9] ml-0 w-full">
            {intl.formatMessage({
              defaultMessage: "Responsible Gaming",
              description: "header",
            })}
          </h4>

          {params?.length > 0 && (
            <p className="p-4 pt-0 text-base text-red">{params}</p>
          )}

          <div className="p-4 divide-y divide-white divide-opacity-25">
            <Authenticated
              trackName="Deposit Limit"
              trackParams={{ component: "ResponsibleGaming" }}
              onClick={() => setSection("SetDepositLimit")}
              label={intl.formatMessage({
                defaultMessage: "Deposit Limit",
                description: "Link label",
              })}
            />
            <Authenticated
              trackName="Wager Limit"
              trackParams={{ component: "ResponsibleGaming" }}
              onClick={() => setSection("SetWagerLimit")}
              label={intl.formatMessage({
                defaultMessage: "Wager Limit",
                description: "Link label",
              })}
            />
            <Authenticated
              trackName="Loss Limit"
              trackParams={{ component: "ResponsibleGaming" }}
              onClick={() => setSection("SetLossLimit")}
              label={intl.formatMessage({
                defaultMessage: "Loss Limit",
                description: "Link label",
              })}
            />
            <Authenticated
              trackName="Session Limit"
              trackParams={{ component: "ResponsibleGaming" }}
              onClick={() => setSection("SetSessionLimit")}
              label={intl.formatMessage({
                defaultMessage: "Session Limit",
                description: "Link label",
              })}
            />

            <Authenticated
              trackName="Cool Off"
              trackParams={{ component: "ResponsibleGaming" }}
              onClick={() => setSection("SetCoolOff")}
              label={intl.formatMessage({
                defaultMessage: "Cool Off",
                description: "Link label",
              })}
            />
            {/* <Authenticated */}
            {/*  trackName="Self Exclusion" */}
            {/*  trackParams={{component: 'ResponsibleGaming'}} */}
            {/*  onClick={() => setSection('SetSelfExclusion')} */}
            {/*  label={intl.formatMessage({ */}
            {/*    defaultMessage: 'Self Exclusion', */}
            {/*    description: 'Link label', */}
            {/*  })} */}
            {/* /> */}
          </div>
        </div>
      </Transition>
      <Transition
        show={section !== "Main"}
        enter="transition-all duration-700"
        enterFrom="transform translate-x-full"
        enterTo="transform translate-x-0"
        leave="transition-all duration-700"
        leaveFrom="transform-gpu translate-x-0"
        leaveTo="transform-gpu translate-x-full"
        className="absolute left-0 z-0 flex flex-col w-full pb-16"
        // unmount={false}
      >
        <SsrCompatibleSuspense fallback={<div>loading</div>}>
          <Slide
            menuItem={menuItem}
            setMenuItem={setMenuItem}
            section={section}
            setSection={setSection}
            setBackActions={setBackActions}
          />
        </SsrCompatibleSuspense>
      </Transition>
    </div>
  );
};

export default ResponsibleGaming;
