import useSWR from 'swr';
import { getTransactionHistory, getGamblingHistory } from '@data/model/history';
import { useEffect } from 'react';
import DepositHistory from './TransactionHistoryTypes/DepositHistory';
import WithdrawHistory from './TransactionHistoryTypes/WithdrawHistory';
import GamblingHistory from './TransactionHistoryTypes/GamblingHistory';
import AllHistory from './TransactionHistoryTypes/AllHistory';
import PendingWithdrawals from './TransactionHistoryTypes/PendingWithdrawals';
import { getCurrencies } from '../../../../data/model/wallet'
import { useWallet } from '../../../Provide/WalletContext';

const Types = {
  all: AllHistory,
  deposit: DepositHistory,
  withdraw: WithdrawHistory,
  pending: PendingWithdrawals,
  game: GamblingHistory,
};
const TransactionHistoryList = ({
  startTime, endTime, type, period, setMenuItem
}) => {
  const { error: filterError, data: filtered, mutate } = useSWR(
    [['deposit', 'withdraw', 'pending'].includes(type) ? 'getTransactionHistory' : 'getGamblingHistory', type, startTime, endTime],
    () => (['deposit', 'withdraw', 'pending'].includes(type) ? getTransactionHistory() : getGamblingHistory()),
  );
  const { currency } = useWallet();
  const {data: currencies} = useSWR('getCurrencies', getCurrencies);

  const { error: nonFilterError, data: nonFiltered, mutate: mutateNonFilter } = useSWR(
    'getTransactionHistory', getTransactionHistory,
  );

  let currentWallet = currencies?.filter(cur => cur?.code === currency);

  const ListComponent = Types[type];

  return (
    <div className="mb-0 lg:mb-24">
      <ListComponent
        setMenuItem={setMenuItem}
        period={period}
        transactions={filtered}
        mutate={mutate}
        transactionsGambling={nonFiltered}
        endTime={endTime}
        startTime={startTime}
        currencies={currencies}
      />
    </div>
  );
};
export default TransactionHistoryList;
