import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { useTracking } from "@hooks/useTracking";
import { getPlayerInfo, player_fields } from "../../data/model/account";
import WithdrawList from "./WithdrawList";
import WithdrawForm from "./WithdrawForm";
import WithdrawSuccess from "./WithdrawSuccess";
import WithdrawNoBalance from "./WithdrawNoBalance";
import WithdrawLocked from "./WithdrawLocked";
import { useWallet } from "../Provide/WalletContext";
import getCountriesQuery from "../LandingOffer/get-countries.graphql";
import getCurrenciesQuery from "../LandingOffer/get-currencies.graphql";
import { getIpInfo } from "../../data/model/session";
import useSWR from "swr";
import { useQuery } from "@apollo/client";
import { useAuth } from "../Provide/AuthContext";
import feedback from "../Registration/feedback.json";

export const Withdraw = ({
  onSuccess,
  setAction,
  initialAmount = "",
  hideModal,
  setBackActions,
  setMenuItem,
}) => {
  const { currency } = useWallet();
  const [section, setSection] = useState("Main");
  const intl = useIntl();
  const { track } = useTracking();
  const { totalWithdraw, totalBonusWallet } = useWallet();

  const { data: ipInfo } = useSWR("getIpInfo", getIpInfo);
  const { data: playerInfo } = useSWR("getPlayerInfo", getPlayerInfo);
  const { error: currencyError, data: currencies } =
    useQuery(getCurrenciesQuery);
  const { error: countryError, data: countries } = useQuery(getCountriesQuery);
  const countryInfo = countries?.countries?.find(
    (item) => item?.code === ipInfo?.country_code
  );
  const currencyInfo = currencies?.currencies?.find(
    (item) => item?.code === countryInfo?.currency
  );
  const localeInfo = {
    ...countryInfo,
    ...currencyInfo,
    currency: currencyInfo?.code,
    code: countryInfo?.code,
  };
  const { isAuthenticated } = useAuth();
  const { data: profileFields } = useSWR(
    isAuthenticated ? "player_fields" : null,
    player_fields
  );

  useEffect(
    () =>
      setBackActions((prevState) => {
        prevState.push((x) => {
          setMenuItem("Root");
        });
        return prevState;
      }),
    []
  );
  const onSubmit = (values, formikBag) => {
    const { setStatus, setFieldError, setSubmitting } = formikBag;
    setSubmitting(true);
    console.log("the values are", values);
    // Prepare fields
    const inputtedFields = [];
    for (const [key, value] of Object.entries(values?.fields)) {
      console.log("key", key);
      console.log("value", value);
      if (value?.text) {
        inputtedFields[key] = value?.name;
      } else {
        inputtedFields[key] = value;
      }
    }

    // let finalizedFields = {
    //   ...inputtedFields,
    //   amount: inputtedFields?.amount?.replace(",", "."),
    //   accountNumber: inputtedFields?.accountNumber?.replaceAll("-", ""),
    // };

    const finalizedFields = {
      ...inputtedFields,
      id: values?.method?.id,
      amountValue: inputtedFields?.amount?.replace(",", "."),
      currency: currency,
      paymentAction: "cashout",
      methodFieldsData: inputtedFields,
      savedProfileId: values?.accountId,
      playerFieldsData: {
        first_name: values?.firstname,
        last_name: values?.surname,
        date_of_birth: values?.birthDate,
        gender: values?.gender,
        country: values?.country?.name,
        address: values?.address1,
        city: values?.city,
        mobile_phone: values?.mobile_phone,
        postal_code: values?.postalCode,
        firstname: values?.firstname,
        surname: values?.surname,
        birthDate: values?.birthDate,
        postalCode: values?.postalCode,
      },
    };

    console.log("withdraw submit update", finalizedFields);

    return window.PaymentsAPI.submit({ ...finalizedFields })
      .then((result) => {
        // window.PaymentsAPI.savePlayerFields({
        //   id: values?.method?.id,
        //   currency,
        //   paymentAction: "cashout",
        //   playerFieldsData: finalizedFields?.playerFieldsData,
        // });

        track("Withdraw submit success", finalizedFields?.amount);
        setSubmitting(false);
        setStatus({ step: "success" });
      })
      .catch((res) => {
        console.log("withdrawal error", res);
        track("Withdraw error", { error: res });
        const messages = [];
        if (res?.message) {
          messages.push(feedback?.restriction?.[res?.message] ?? res?.message);
        }

        if (res?.methodFieldsErrors) {
          for (const [field, value] of Object.entries(res.methodFieldsErrors)) {
            if (typeof value !== "object") {
              messages.push(`${feedback?.labels?.[field] ?? value} ${value}`);
            } else {
              for (const [type, error] of Object.entries(value)) {
                if (typeof error === "object") {
                  for (const [typesub, suberror] of Object.entries(error)) {
                    messages.push(
                      `${feedback?.labels?.[type] ?? type} ${suberror}`
                    );
                  }
                } else {
                  messages.push(
                    `${feedback?.labels?.[field] ?? field} ${error}`
                  );
                }
              }
            }
          }
        }

        if (res?.commonErrors) {
          for (const [field, value] of Object.entries(res.commonErrors)) {
            if (typeof value !== "object") {
              messages.push(`${feedback?.labels?.[field] ?? value} ${value}`);
            } else {
              for (const [type, error] of Object.entries(value)) {
                if (typeof error === "object") {
                  for (const [typesub, suberror] of Object.entries(error)) {
                    messages.push(
                      `${feedback?.labels?.[type] ?? type} ${suberror}`
                    );
                  }
                } else {
                  messages.push(
                    `${feedback?.labels?.[field] ?? field} ${error}`
                  );
                }
              }
            }
          }
        }
        setFieldError("formState", messages);
        setSubmitting(false);
      });
  };

  const initialValues = {
    fields: { amount: "" },
    depositState: "initial",
    savedId: "",
    accountId: "",
    method: null,
    paymentPlan: null,
    email: playerInfo?.email,
    currency: {
      name: localeInfo?.currency,
      text: intl.formatMessage(
        {
          defaultMessage: `{curr} ({amount})`,
          description: "Label",
        },
        {
          curr: playerInfo?.currency,
          amount: intl
            .formatNumber(0, {
              style: "currency",
              currency:
                localeInfo?.currency === undefined
                  ? currency
                  : localeInfo?.currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
            .replace(/\d/g, "")
            .trim(),
        }
      ),
    },
    country:
      !Object.keys(localeInfo).length > 0
        ? { name: "", text: "Please select" }
        : { name: localeInfo?.code, text: localeInfo?.name },
    mobile_phone: playerInfo?.mobile_phone ?? "",
    gender: playerInfo?.gender ?? "",
    firstname: playerInfo?.first_name ?? "",
    surname: playerInfo?.last_name ?? "",
    address1: playerInfo?.address ?? "",
    city: playerInfo?.city ?? "",
    postalCode: playerInfo?.postal_code ?? "",
    birthDate: "",
    birthdateDay: "",
    birthdateMonth: "",
    birthdateYear: "",
    formState: "",
  };

  return (
    <Formik
      initialTouched={{ amount: true }}
      initialValues={initialValues}
      onSubmit={onSubmit}
      initialStatus={{
        step:
          totalBonusWallet > 0
            ? "locked"
            : !totalWithdraw
            ? "no-balance"
            : "initial",
      }}
    >
      {({
        isSubmitting,
        values,
        setStatus,
        setFieldError,
        status,
        isValid,
        setFieldValue,
        errors,
      }) => (
        <Form className="flex flex-col items-center text-purple text-sm px-2.5 w-full mx-auto">
          {status.step === "initial" && (
            <WithdrawList
              setMenuItem={setMenuItem}
              setStatus={setStatus}
              setFieldValue={setFieldValue}
              setBackActions={setBackActions}
            />
          )}
          {status.step === "form" && (
            <WithdrawForm
              values={values}
              setStatus={setStatus}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              isSubmitting={isSubmitting}
              isValid={isValid}
              countries={countries}
              currencies={currencies}
              setBackActions={setBackActions}
              errors={errors}
              initialValues={initialValues}
              profileFields={profileFields}
            />
          )}
          {status.step === "success" && (
            <WithdrawSuccess pid={status.pid} hideModal={hideModal} />
          )}
          {status.step === "no-balance" && (
            <WithdrawNoBalance hideModal={hideModal} />
          )}
          {status.step === "locked" && (
            <WithdrawLocked setMenuItem={setMenuItem} />
          )}
          {/* {status.step === 'error' && <div>error</div>} */}
          {/* {status.step === 'cancel' && <div>cancel</div>} */}
          {/* {!['success', 'error', 'cancel'].includes(status.step) && ( */}
          {/* <iframe */}
          {/*  ref={redirectFrame} */}
          {/*  className={cx({ 'h-96 w-96': status.step === 'redirect' })} */}
          {/* /> */}
          {/* )} */}
          {/* <WithdrawList /> */}
        </Form>
      )}
    </Formik>
  );
};

export default Withdraw;
