import React from "react";
import { useField } from "formik";
import cx from "classnames";
import { Switch } from "@headlessui/react";

const ToggleSwitch = ({
  value,
  name,
  label = "",
  hint = "",
  onChange = () => {},
}) => {
  const [field, meta, helpers] = useField(name);
  const toggle = (value) => {
    helpers.setValue(value, true);
    // field.onChange(value);

    onChange(value);
  };
  return (
    <Switch
      checked={!!meta.value}
      onChange={toggle}
      className={cx(
        "outline-none focus:outline-none transition-all ease-in-out duration-500 relative inline-flex items-center h-6 rounded-full w-11",
        {
          "bg-gold ": meta.value,
          "bg-gray": !meta.value,
        }
      )}
    >
      <span
        className={cx(
          "outline-none focus:outline-none transition-all ease-in-out duration-500 inline-block w-5 h-5 transform rounded-full",
          {
            "bg-white ltr:translate-x-5 rtl:-translate-x-5": meta.value,
            "bg-white ltr:translate-x-1 rtl:-translate-x-1 ": !meta.value,
          }
        )}
      />
    </Switch>
  );
};
export default ToggleSwitch;
