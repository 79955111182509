import React from 'react'
import sadface from './assets/sad-face.png'
import Button from '../Button';
import Logo from '../Logo';
import cx from 'classnames'

const NoAccess = ({router}) => {
  return (
        <div className=" bg-brand md:container m-auto w-full text-center text-white font-light -mt-16 md:-mt-20 px-4 md:px-0">
            {/* <div className="col-span-6 md:col-span-4 md:text-center"> */}
            <Logo
                classNameLogo={cx(' inline-block h-16 mb-10')}
                alt="ClubHouse casino best casino"
                align={'center'}
            />
              {/* <img src="/images/mobile-logo.png" className="md:hidden" /> */}
            {/* </div> */}
            <img src={sadface} className="w-20 h-20 m-auto my-10"/>
            <p className="text-3xl">Sorry!</p>
            <p className="mt-6">
                You are unable to access The Clubhouse Casino from your current location.
            </p>
            <p className="mt-3">
                If you feel this is a mistake, please contact us.
            </p>

            <Button.Action
                trackName="Contact us"
                trackParams={{ component: 'NoAccess'}}
                className="mt-10 w-16 md:w-80 m-auto inline-flex items-center justify-between w-full btn"
                type="submit"
                arrow="right"
                onClick={() => { window.LC_API.open_chat_window(); }}
            >
                Contact us{' '}

            </Button.Action>
        </div>
    )
}

export default NoAccess
