import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import Logo from "@components/Logo";
import Link from "@components/Link";
import { useSelector } from "react-redux";
import cx from "classnames";
import { useIntl } from "react-intl";
import eighteen from "../../images/logos/licensing/18plus.svg";
import getFooterSnippetQuery from "./get-footer-snippet.graphql";
import { useRouter } from "../Provide/RouterContext";
import { useAuth } from "../Provide/AuthContext";
import getFooterSEOQuery from "./get-footer-seo.graphql";
import getProvidersQuery from "@components/GameBrowser/Templates/ProvidersList/get-providers.graphql";
import { cfImgUrl } from "../../config";
import { useDevice } from "../Provide/DeviceContext";
import { isBrowser } from "browser-or-node";

const Footer = ({ hasFooterLogos = true }) => {
  const intl = useIntl();
  const router = useRouter();
  const [showMore, setShowMore] = useState(isBrowser ? false : true);
  const [showMoreClick, setShowMoreClick] = useState(true);
  const { isAuthenticated } = useAuth();
  const { isMobile } = useDevice();
  const isNavigationOpen = useSelector(
    (state) => state.navigation.isNavigationOpen
  );
  const { data } = useQuery(getFooterSnippetQuery, {
    variables: { id: "footer-content" },
  });
  const { data: gameProviders } = useQuery(getProvidersQuery, {
    variables: { psection: "Game Provider" },
  });
  const { data: paymentProviders } = useQuery(getProvidersQuery, {
    variables: { psection: "Payment Provider" },
  });
  let pathname = router?.pathname;

  const [currentPath, setCurrentPath] = useState(pathname);

  const searchRegExp = /\s/g;
  const replaceWith = "-";
  const result = "duck duck go".replace(searchRegExp, replaceWith);

  const {
    loading,
    error,
    data: footerseodata,
  } = useQuery(getFooterSEOQuery, {
    variables: {
      path: currentPath
        ?.replace(`/${intl.locale}/`, "")
        ?.replace(`/${intl.locale}`, "/"),
      locale: intl.locale,
    },
  });

  // useEffect(() => {
  //   console.log(history, "historyhistory");
  //   pathname = history?.location?.pathname;
  //   console.log(
  //     history?.location?.pathname,
  //     pathname
  //       ?.replaceAll(`/${intl.locale}/`, "")
  //       ?.replaceAll(`/${intl.locale}`, "/"),
  //     "pathname"
  //   );
  //   refetch();
  // }, [history?.listen]);
  // useEffect(() => {
  //   if (window) {
  //     pathname = window.location.pathname;
  //     loadGreeting({
  //       variables: {
  //         path: pathname
  //           ?.replaceAll(`/${intl.locale}/`, "")
  //           ?.replaceAll(`/${intl.locale}`, "/"),
  //         locale: intl.locale,
  //       },
  //     });
  //   }
  // }, [router?.routename]);

  useEffect(() => {
    if (window) {
      pathname = window.location.pathname;
      setCurrentPath(pathname);
    }
  }, [router?.routename]);

  useEffect(() => {
    console.log(
      "footerseodata?.getFooterSeo?.entries[0]?.content?.length",
      footerseodata?.getFooterSeo?.entries[0]?.content?.length
    );
    if (footerseodata?.getFooterSeo?.entries[0]?.content?.length > 500) {
      setShowMore(true);
      setShowMoreClick(false);

      console.log("setShowMoreClick", false);
    } else {
      setShowMore(false);
      setShowMoreClick(true);
    }
  }, [footerseodata]);

  useEffect(() => {
    // document.addEventListener("DOMContentLoaded", function (event) {
    window?.apg_62969b95_615c_479b_bb96_b765733c3d28?.init?.();
    // });
  }, []);

  const SetImagePath = (imagePath) => {
    if (imagePath !== undefined) {
      let start = imagePath.charAt(0);

      if (start !== "/") {
        return "/" + imagePath;
      }
    }

    return imagePath;
  };

  return (
    <>
      <div className="w-full px-3 mx-auto md:px-0 md:container xl:px-28 ">
        {!isAuthenticated &&
          footerseodata?.getFooterSeo?.entries[0]?.content && (
            <div className="py-4 mb-8">
              <div
                id="footer-seo-data"
                dangerouslySetInnerHTML={{
                  __html: footerseodata?.getFooterSeo?.entries[0]?.content,
                }}
                className="h-auto mx-auto text-center text-gray "
                // style={
                //   showMoreClick
                //     ? { overflow: `auto` }
                //     : {
                //         overflow: `hidden`,
                //         height: isMobile?.any ? "200px" : "170px",
                //       }
                // }
              />
              {/* {showMore &&
              footerseodata?.getFooterSeo?.entries[0]?.content?.length >
                500 && (
                <button
                  className="w-full text-sm text-gold"
                  onClick={() => setShowMoreClick(!showMoreClick)}
                >
                  {intl.formatMessage(
                    {
                      defaultMessage: "{message}",
                      description: "Expand label",
                    },
                    {
                      message: showMoreClick
                        ? "Click to show less"
                        : "Click to expand",
                    }
                  )}
                </button>
              )} */}
            </div>
          )}
      </div>
      <footer
        className={cx(
          "py-12 bg-brand-600 mt-8",
          isNavigationOpen ? "filter blur-sm " : ""
        )}
      >
        <div className="w-full px-3 mx-auto md:px-0 md:container ">
          <Logo
            classNameLogo="h-16 hidden md:inline-block"
            alt="ClubHouse casino best casino"
            align="left"
          />
          <div className="grid gap-10 mt-8 md:grid-cols-2 md:gap-x-32">
            <div className="col-span-1">
              <div
                className="text-sm lead text-gray"
                dangerouslySetInnerHTML={{ __html: data?.snippet?.content }}
              />
              <div className="flex flex-row items-center justify-start h-26">
                <img src={eighteen} className="h-16 " loading="lazy" />
                <div
                  id="apg-62969b95-615c-479b-bb96-b765733c3d28"
                  data-apg-seal-id="62969b95-615c-479b-bb96-b765733c3d28"
                  data-apg-image-size="96"
                  data-apg-image-type="basic-small"
                  className="h-24 pl-4"
                />
              </div>
            </div>
            <div className="col-span-1">
              <div className="grid grid-cols-2">
                <div className="flex flex-col space-y-4 ">
                  <Link
                    trackName="Games"
                    trackParams={{ component: "Footer" }}
                    to="home"
                    className="font-bold text-white text-16"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Games",
                      description: "Link label",
                    })}
                  </Link>
                  <Link
                    trackName="Live Casino"
                    trackParams={{ component: "Footer" }}
                    to="liveLobby"
                    className="font-bold text-white text-16"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Live Casino",
                      description: "top navigation",
                    })}
                  </Link>
                  <Link
                    trackName="Promotions"
                    trackParams={{ component: "Footer" }}
                    to="promotions"
                    className="font-bold text-white text-16"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Promotions",
                      description: "top navigation",
                    })}
                  </Link>

                  <Link
                    trackName="Loyalty"
                    trackParams={{ component: "Footer" }}
                    to="loyalty"
                    className="font-bold text-white text-16"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Loyalty",
                      description: "top navigation",
                    })}
                  </Link>
                  <Link
                    trackName="Payments"
                    trackParams={{ component: "Footer" }}
                    to="payments"
                    className="font-bold text-white text-16"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Payments",
                      description: "top navigation",
                    })}
                  </Link>
                  <Link
                    trackName="Help"
                    trackParams={{ component: "Footer" }}
                    to="faq"
                    className="font-bold text-white text-16"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Help",
                      description: "top navigation",
                    })}
                  </Link>
                </div>
                <div className="flex flex-col space-y-4 ">
                  <Link
                    trackName="Terms & Conditions"
                    trackParams={{ component: "Footer" }}
                    to="termsAndConditions"
                    className="font-bold text-white text-16"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Terms & Conditions",
                      description: "top navigation",
                    })}
                  </Link>
                  <Link
                    trackName="Bonus Terms & Conditions"
                    trackParams={{ component: "Footer" }}
                    to="bonusTerms"
                    className="font-bold text-white text-16"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Bonus Terms & Conditions",
                      description: "top navigation",
                    })}
                  </Link>
                  <Link
                    trackName="Privacy Policy"
                    trackParams={{ component: "Footer" }}
                    to="privacyPolicy"
                    className="font-bold text-white text-16"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Privacy Policy",
                      description: "Privacy Policy footer navigation",
                    })}
                  </Link>
                  <Link
                    trackName="Cookie Policy"
                    trackParams={{ component: "Footer" }}
                    to="cookiePolicy"
                    className="font-bold text-white text-16"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Cookie Policy",
                      description: "Cookie Policy footer navigation",
                    })}
                  </Link>
                  <Link
                    trackName="Affiliates"
                    trackParams={{ component: "Footer" }}
                    href="https://theclubhouseaffiliates.com"
                    target="_blank"
                    className="font-bold text-white text-16"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Affiliates",
                      description: "top navigation",
                    })}
                  </Link>
                  <Link
                    trackName="Responsible Gaming"
                    trackParams={{ component: "Footer" }}
                    to="responsibleGamingLegal"
                    className="font-bold text-white text-16"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Responsible Gaming",
                      description: "top navigation",
                    })}
                  </Link>
                  {intl.locale === "en-ie" && (
                    <Link
                      trackName="blog"
                      trackParams={{ component: "Footer" }}
                      to="https://theclubhousecasino.com/en-ie/blog/"
                      href="https://theclubhousecasino.com/en-ie/blog/"
                      className="font-bold text-white text-16"
                    >
                      {intl.formatMessage({
                        defaultMessage: "Blog",
                        description: "top navigation",
                      })}
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <p className="text-xl italic text-purple-dark">
              {/*{BITBUCKET_BUILD_NUMBER && `Build: ${BITBUCKET_BUILD_NUMBER}`}{' '}*/}
              {/*  /!* {BUILD_DATE && `Build date: ${BUILD_DATE}`} *!/ */}
            </p>
          </div>
          {hasFooterLogos && (
            <div className="flex flex-row flex-wrap items-center justify-center mt-10">
              {gameProviders?.ProvidersBySection?.map((item) => (
                <img
                  src={
                    item?.image?.path?.includes(".svg") || !cfImgUrl
                      ? `${COCKPITCFG.url}${SetImagePath(
                          item?.footerImage?.path
                        )}`
                      : `${cfImgUrl}/format=auto,dpr=2,onerror=redirect/${
                          COCKPITCFG.url
                        }${SetImagePath(item?.footerImage?.path)}`
                  }
                  // onError={({ currentTarget }) => {
                  //   currentTarget.onerror = null; // prevents looping
                  //   currentTarget.src=`https://cockpit-ch.madmenmt.com/storage/uploads/gameproviders/${item?.softswiss_id}.svg`;
                  // }}
                  alt="Providers"
                  className="h-16 p-2 py-4 m-auto"
                  loading="lazy"
                />
              ))}
            </div>
          )}
          {hasFooterLogos && (
            <div className="flex flex-row flex-wrap items-center justify-center mb-10">
              {paymentProviders?.ProvidersBySection?.map((item) => (
                <img
                  src={
                    item?.image?.path?.includes(".svg") || !cfImgUrl
                      ? `${COCKPITCFG.url}${SetImagePath(
                          item?.footerImage?.path
                        )}`
                      : `${cfImgUrl}/format=auto,dpr=2,onerror=redirect/${
                          COCKPITCFG.url
                        }${SetImagePath(item?.footerImage?.path)}`
                  }
                  // onError={({ currentTarget }) => {
                  //   currentTarget.onerror = null; // prevents looping
                  //   currentTarget.src=`https://cockpit-ch.madmenmt.com/storage/uploads/payments/${item?.softswiss_id}.svg`;
                  // }}
                  alt="Providers"
                  className="h-16 p-2 py-4 m-auto"
                  loading="lazy"
                />
              ))}
            </div>
          )}
        </div>
      </footer>
    </>
  );
};

export default Footer;
