import React, { Fragment } from "react";
import { injectIntl } from "react-intl";
import Hamburger from "hamburger-react";
import backIcon from "../../../public/img/icons/back.svg";
import Link from "../Link";
import Icon from "../Icon";

class Navigator extends React.PureComponent {
  render() {
    const {
      backAction = null,
      backActionClassname,
      title,
      nextAction,
      nextActionClassname,
      closeAction,
      CloseWrapper = Fragment,
      className,
    } = this.props;

    return (
      <div className="flex justify-between items-center bg-gradient-to-b justify-between from-purple to-purple-dark top-0 w-screen h-18 p-9.5 z-10 md:hidden">
        <div>
          {backAction && (
            <button onClick={backAction}>
              <svg width="20" height="15">
                <use xlinkHref={`#${backIcon.id}`} />
              </svg>
            </button>
          )}

          {closeAction && (
            <CloseWrapper>
              <Link
                trackName="Close"
                trackParams={{ component: "Navigator" }}
                onClick={closeAction}
                className="absolute p-2 transition-transform transform rounded-full shadow-xl cursor-pointer bg-yellow top-5 right-5 hover:scale-110"
              >
                <Icon icon="close" className="w-4 h-4 text-white" />
              </Link>
            </CloseWrapper>
          )}
        </div>

        {/* {title && <div className="text-white text-button-medium">{title}</div>} */}
      </div>
    );
  }
}

export default injectIntl(Navigator);
