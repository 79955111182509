import * as Yup from 'yup';

export const LoginValidationSchema = (intl) => Yup.object().shape({
  usernameOrEmail: Yup.string()
    .min(4, intl.formatMessage({
      defaultMessage: 'The input is too short. Please use at least {num} characters',
      description: 'Validation',
    }, { num: 4 }))
    .max(100, intl.formatMessage({
      defaultMessage: 'The input is too long. Please use less then {num} characters',
      description: 'Validation',
    }, { num: 100 }))
    .required(intl.formatMessage({
      defaultMessage: 'This field is required',
      description: 'Validation',
    })),
  // .usernameExists('Username exists'),
  password: Yup.string()
    .required(intl.formatMessage({
      defaultMessage: 'Password required',
      description: 'Validation',
    })),
  // .matches(/(?=.*\d+)(?=.*[A-Za-z]+).{8,20}/g, intl.formatMessage({
  //   defaultMessage: "Invalid password",
  //   description: "Validation"
  // }))
});
