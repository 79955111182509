import React, { useState } from "react";
import { useIntl } from "react-intl";
import Icon from "../../../../Icon/Icon";
import cx from "classnames";

const DocumentUploadInput = ({
  handleSelectFile,
  setProgress,
  newUploadName,
  name,
  id,
}) => {
  const intl = useIntl();
  const [dragging, setDragging] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFileUpload(files[0]);
    handleSelectFile(files[0]);
    setDragging(false);
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onloadstart = () => setProgress(0);
    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        setProgress(percentCompleted);
      }
    };
    reader.onloadend = () => setProgress(100);
    reader.readAsDataURL(file);
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    handleSelectFile(file);
  };

  return (
    <div
      className={cx(
        `flex flex-col items-center gap-2.5 justify-center w-full p-3 px-6  rounded-md cursor-pointer border-1 border-gold transition-all hover:bg-black hover:border-solid text-gold group group-hover:bg-black group-hover:border-solid ${
          dragging ? "bg-black border-solid" : "border-dashed"
        }`
      )}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={() => setDragging(false)}
    >
      <label
        for={id}
        className={`text-center flex flex-col gap-2.5 justify-center items-center cursor-pointer`}
      >
        <Icon icon="upload" className={"w-4 h-4"} />
        {newUploadName ? (
          newUploadName
        ) : (
          <p className="flex gap-1 m-0">
            <span>
              {intl?.formatMessage({
                defaultMessage: "Choose a File",
                description: "The label for choosing file",
              })}
            </span>
            <span className="text-white">
              {intl?.formatMessage({
                defaultMessage: "or drag and drop",
                description: "The label for choosing file",
              })}
            </span>
          </p>
        )}
      </label>
      <input
        name={name}
        type="file"
        onChange={handleChange}
        id={id}
        className="hidden"
        accept=".jpg,.jpeg,.png,.pdf"
      />
    </div>
  );
};

export default DocumentUploadInput;
