import React, { useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import {
  PasswordField,
  SelectBox,
  TextField,
  ToggleSwitch,
} from "@components/Formik";
import useSWR from "swr";
// import { setAuthenticationStatus } from "../../actions/auth";
import { useIntl } from "react-intl";
import Link from "@components/Link";
import { useDispatch, useSelector } from "react-redux";
import { closeNavigation } from "@actions/navigation";

import { useTracking } from "@hooks/useTracking";

import Cookies from "universal-cookie";
import { getRofusStatus, logOut, logUserIn } from "../../data/model/account";
import { getCmsSessionID } from "../../data/model/session";

import { LoginValidationSchema } from "./authValidate";
import Button from "../Button";
import { useAuth } from "../Provide/AuthContext";
import history from "../../core/history";
import { localeUrl } from "../../core/url";
import { useModal } from "../Modal/ModalContext";


export const AuthForm = ({
  onSuccess,
  setAction,
  params,
  setMenuItem,
  setCallbackParams,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const close = () => dispatch(closeNavigation());
  const { setAuthenticationStatus, setCmsSessionID } = useAuth();
  const { identify, track } = useTracking();
  const { showModal } = useModal();
  const [formError, setFormError] = useState("");
  const [loginError, setLoginError] = useState("");
  const cookies = new Cookies();
  const initParams = useSelector((state) => state.navigation.params);
  const returnUrl = new URLSearchParams(window?.location?.search).get("return");

  const startRegister = () => {
    setMenuItem("Registration");
  };

  const onSubmit = (values, formikBag) => {
    try {
      const { setFieldError, setSubmitting } = formikBag;
      logUserIn(values.usernameOrEmail, values.password)
        .then(async (result) => {
          const callback = async () => {
            // if (returnUrl) {
            //   setTimeout(() => {
            //     history.push(returnUrl);
            //   }, 1000);
            // } else
            if (!(params?.onSuccess || initParams?.onSuccess) && !returnUrl) {
              history.push(localeUrl("myClubhouse", intl.locale));
            }
            cookies.set("marketingTrigger", true);
            const session = await getCmsSessionID();
            setAuthenticationStatus(!!session?.id);
            !!session?.id === false &&
              setLoginError(
                "Something went wrong, please try logging in again."
              );
            !!session?.id === true && close();
          };

          if (result.auth_fields_missed.includes("terms_acceptance")) {
            setCallbackParams({ password: values.password });
            setAction("acceptTermsAndConditions");
          }

          if (params?.onSuccess || initParams?.onSuccess) {
            params?.onSuccess?.();
            initParams?.onSuccess?.();
          }
          setSubmitting(false);
          identify(result.id);
          callback();
        })
        // .then(() => {
        //   loginError?.length === 0 && close();
        // })
        .catch(async (res) => {
          console.log("Login error", res);
          track("Login error", { error: res });
          for (const [field, value] of Object.entries(res.errors)) {
            for (const [type, error] of Object.entries(value)) {
              switch (field) {
                case "email":
                case "password":
                  setFieldError("password", error);
                  break;
                default:
                  setFieldError("loginState", error);
              }
            }
          }
          setSubmitting(false);
        });
    } catch (error) {
      console.log("this is the log in result error", error);
    }
  };

  const registerLine = (
    <Link
      trackName="Register"
      trackParams={{ component: "AuthForm" }}
      onClick={startRegister}
      className="underline"
    >
      {intl.formatMessage({
        defaultMessage: "Register now",
        description:
          "Regiser now, used for example in registration line on authorization",
      })}
    </Link>
  );

  return (
    <Formik
      validationSchema={LoginValidationSchema(intl)}
      initialValues={{
        usernameOrEmail: "",
        password: "",
        loginState: "",
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="mx-auto mt-5.5 flex-col flex w-full items-stretch px-2.5">
          <h4 className="mb-16 text-2xl font-light leading-5 text-center text-white  sticky top-0 bg-[#13161d] max-w-full ms-auto me-auto pt-0 pb-[11px] z-[9] ml-0">
            {intl.formatMessage({
              defaultMessage: "Welcome back",
              description: "Header of section",
            })}
          </h4>
          <p className="lead mb-7">
            {intl.formatMessage({
              defaultMessage: "Don’t yet have an account?",
              description: "intro string",
            })}{" "}
            <Link
              trackName="Join now"
              trackParams={{ component: "AuthForm" }}
              href=""
              onClick={startRegister}
            >
              {intl.formatMessage({
                defaultMessage: "Join now",
                description: "link text",
              })}
            </Link>
          </p>

          <div className="w-full">
            <TextField
              name="usernameOrEmail"
              label={intl.formatMessage({
                defaultMessage: "Email Address",
                description:
                  "Placeholder of inputfield to enter your e-mailaddress",
              })}
              type="text"
              showFeedbackIcon={false}
              showFeedbackText={false}
            />
            <div className="flex items-center mt-2 font-book text-12 text-gray">
              <input
                type="checkbox"
                className="mr-2 rounded rtl:ml-2 rtl:mr-0 form-checkbox bg-brand text-gold"
              />

              {intl.formatMessage({
                defaultMessage: "Remember me",
                description: "label",
              })}
            </div>

            <PasswordField
              name="password"
              label={intl.formatMessage({
                defaultMessage: "Password",
                description: "Placeholder of inputfield",
              })}
              className="mt-7"
              typeError="error"
              showFeedbackIcon={false}
              showFeedbackText
            />
            <Link
              trackName="Forgotten Password"
              trackParams={{ component: "AuthForm" }}
              href=""
              onClick={() => setAction("passwordForget")}
              className="font-book text-12"
            >
              {intl.formatMessage({
                defaultMessage: "Forgotten Password",
                description: "Link to recover the password",
              })}{" "}
            </Link>
            <ErrorMessage name="loginState" className="my-4 text-red" />
            <Button.Action
              trackName="Submit log in"
              trackParams={{ component: "AuthForm" }}
              className="w-full mt-6"
              arrow="right"
              type="submit"
              isSubmitting={isSubmitting}
              submittingLabel={intl.formatMessage({
                defaultMessage: "Logging in...",
                description: "Label while submitting login",
              })}
            >
              {intl.formatMessage({
                defaultMessage: "Log in",
                description: "Button label",
              })}
            </Button.Action>
            <span className="mt-2 text-base text-red">
              {intl.formatMessage(
                {
                  defaultMessage: "{loginError}",
                  description: "Error label",
                },
                { loginError }
              )}
            </span>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AuthForm;
