import React, { useEffect } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { useIntl } from "react-intl";
import { Transition } from "@headlessui/react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { SendResetValidationSchema } from "./send-reset-validation";
import { requestPasswordReset } from "../../data/model/account";
import { TextField } from "../Formik";
import Link from "../Link";
import Button from "../Button";
import { useModal } from "../Modal/ModalContext";
import { useDevice } from "../Provide/DeviceContext";
import SubmitFeedback from "../Formik/SubmitFeedback";
import Icon from "../Icon";

export const SendReset = ({ onSuccess, hideModal, setAction, action }) => {
  const intl = useIntl();
  const { isMobile } = useDevice();
  const { showModal } = useModal();
  const recaptchaRef = React.useRef();
  const isNavigationOpen = useSelector(
    (state) => state.navigation.isNavigationOpen
  );

  useEffect(() => {
    !isNavigationOpen && setAction("auth");
  }, [isNavigationOpen]);

  const onSubmit = (values, formikBag) => {
    const { setFieldError, setStatus, setSubmitting } = formikBag;
    // const token = recaptchaRef.current.props.grecaptcha.getResponse();
    requestPasswordReset(values.usernameOrEmail).then(
      () => {
        setStatus("success");
        setSubmitting(false);
        return true;
      },
      (err) => {
        setSubmitting(false);
        recaptchaRef.current.reset();
        setFieldError("loginState", err.desc);
      }
    );
  };


  return (
    <Formik
      validationSchema={SendResetValidationSchema(intl)}
      initialStatus="init"
      initialValues={{
        loginState: "",
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, status, isValid, setFieldValue }) => (
        <Form>
          <div className="z-10 flex justify-between relative p-3 hidden md:block -mt-6">
            <Link
              trackName="Back"
              trackParams={{ component: "SendReset" }}
              onClick={() => setAction("auth")}
              className="absolute p-2.5 left-4 rounded bg-brand-600"
            >
              <Icon
                icon="arrow"
                className="h-4 w-4 text-white transform rotate-90"
              />
            </Link>
          </div>

          <h4 className="text-center text-2xl leading-5 font-light text-white mb-16">
            {intl.formatMessage({
              defaultMessage: "Change Password",
              description: "Header of section",
            })}
          </h4>
          <Transition
            show={status === "init"}
            leave="transition ease-in duration-500"
            leaveFrom="transform opacity-100"
            leaveTo="transform -translate-x-80 opacity-0"
            className="flex flex-col w-full"
            unmount={false}
          >
            <ErrorMessage name="submitError">
              {(msg) => <div className="mt-8 text-sm text-red">{msg}</div>}
            </ErrorMessage>
            <div className="mx-auto mt-5.5 flex-col flex w-full items-stretch px-2.5">
              <TextField
                name="usernameOrEmail"
                label={intl.formatMessage({
                  defaultMessage: "Enter your email address",
                  description:
                    "Placeholder of input field to enter your email address",
                })}
                type="text"
                className="mb-4"
                showFeedbackIcon={false}
              />
            </div>
            <div className="flex flex-col px-4 items-left justify-center">
              <Button.Action
                trackName="Request password"
                trackParams={{ component: "SendReset" }}
                type="submit"
                className="w-full mt-7.5"
                arrow="right"
                isSubmitting={isSubmitting}
                submittingLabel={intl.formatMessage({
                  defaultMessage: "Requesting password...",
                  description: "Label while submitting password reset form",
                })}
              >
                {intl.formatMessage({
                  defaultMessage: "Request Password",
                  description: "Label ",
                })}
              </Button.Action>
            </div>
          </Transition>

          <SubmitFeedback
            status={status}
            header={intl.formatMessage({
              defaultMessage: "Confirmed",
              description: "The feedback header",
            })}
          >
            <div className="text-24 py-8 leading-7">
              {intl.formatMessage({
                defaultMessage:
                  "We have sent you an email with a link to reset your password",
                description: "The feedback after changing account details",
              })}
            </div>
          </SubmitFeedback>
        </Form>
      )}
    </Formik>
  );
};

export default SendReset;
