/**
 * TopBar Component
 *
 * This component is responsible for The top menu like hamburger, logo & CTA's
 *
 * @category   TopBar
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */
import cx from "classnames";
import React, { useCallback } from "react";
import { Transition } from "@headlessui/react";
import Link from "@components/Link";
import { useTracking } from "../../../hooks/useTracking";

const handleClick = (event, onClick, trackName = "Click", trackParams) => {
  const { click } = useTracking();

  if (onClick) {
    onClick(event);
  }

  if (trackName) {
    click(trackName, trackParams);
  }
};

const Button = ({
  children,
  className,
  onClick,
  size = "medium",
  type = "button",
  disabled = false,
  shadow = false,
  isSubmitting = false,
  submittingLabel,
  trackName,
  trackParams,
  to,
}) => {
  const onClickEvent = useCallback(
    (event) => {
      handleClick(event, onClick, trackName, trackParams);
    },
    [onClick]
  );

  return (
    <button
      onClick={onClickEvent}
      type={type}
      className={cx(
        " rounded-lg text-center items-center justify-between rtl:justify-end rtl:flex-row-reverse inline-flex transition-all hover:bg-opacity-70 whitespace-nowrap overflow-hidden",
        {
          "font-medium": className?.indexOf("font-normal") === -1,
          "px-5": className?.indexOf("px-") === -1,
          "h-11": className?.indexOf("h-") === -1,
        },
        className
      )}
      disabled={disabled || isSubmitting}
    >
      {isSubmitting ? submittingLabel : children}

      <Transition
        show={isSubmitting}
        enter="transition-all duration-700"
        enterFrom="transform translate-x-8 opacity-0"
        enterTo="transform translate-x-0 opacity-1"
        leave="transition-all duration-700"
        leaveFrom="transform-gpu translate-x-0 opacity-1"
        leaveTo="transform-gpu translate-x-8 opacity-0"
        className="absolute right-2"
      >
        <svg
          className="w-5 h-5 ml-2 -ml-10 animate-spin "
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx={12}
            cy={12}
            r={10}
            stroke="currentColor"
            strokeWidth={4}
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </Transition>
    </button>
  );
};

export default Button;
