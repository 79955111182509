/**
 * TopBar Component
 *
 * This component is responsible for The public top menu login/ registration buttons
 *
 * @category   TopBarPublic
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */
import React, { useState, useEffect } from "react";
import { FormattedNumber, useIntl } from "react-intl";
import { Menu, Switch, Transition } from "@headlessui/react";
import Button from "@components/Button";
import { closeNavigation } from "@actions/navigation";

import Icon from "@components/Icon";
import Link from "@components/Link";
import { useWallet } from "@components/Provide/WalletContext";
import Navigator from "@components/Form/Navigator";
import Bonusses from "@components/Bonusses";
import WithdrawModal from "@components/WithdrawModal";
import { useModal } from "@components/Modal/ModalContext";
// import ResponsibleGamingModal from '../../Account/ResponsibleGamingModal';
// import UserProfile from '../../Account/UserProfile';

import { useDispatch } from "react-redux";
import { getTransactionHistory, getGamblingHistory } from "@data/model/history";
import useSWR from "swr";
import QuickDeposit from "../QuickDeposit";
import WalletTable from "../WalletTable";
import MenuItem from "../MenuItem";
import LoyaltyProgramProgress from "../LoyaltyProgramProgress/LoyaltyProgramProgress";

const Authenticated = ({
  open,
  setMenuItem,
  setBackActions,
  setClickedFrom,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const close = () => dispatch(closeNavigation());
  // const [withdraws, setWithdraws] = useState();
  const [hasPendingWithdraws, setHasPendingWithdraws] = useState(false);

  const {
    error: filterError,
    data: filtered,
    mutate,
  } = useSWR(["getTransactionHistory"], getTransactionHistory);

  useEffect(() => {
    const withdraws = filtered?.filter(
      (item) => item.action === "cashout" && item.recallable
    );
    // setWithdraws(
    //   filtered?.filter((item) => item.action === "cashout" && item.recallable)
    // );
    if (withdraws?.length > 0) {
      setHasPendingWithdraws(withdraws?.length);
    } else {
      setHasPendingWithdraws(false);
    }
  }, [filtered]);

  return (
    <>
      <div className="flex flex-row p-2.5 bg-gray-lightest ">
        <WalletTable setMenuItem={setMenuItem} />
      </div>
      <QuickDeposit setMenuItem={setMenuItem} setBackActions={setBackActions} />
      <div className="divide-y divide-white divide-opacity-25 p-2.5 mb-16 md:mb-0">
        <Bonusses>
          {({ freeRoundBonuses, mutate }) => (
            <>
              {freeRoundBonuses?.length > 0 && (
                <Button.Action
                  trackName="Freespins"
                  trackParams={{ component: "Authenticated" }}
                  color="transparent"
                  to="wallet"
                  scrollTo="freerounds"
                  className="w-full"
                  arrow="right"
                >
                  {intl.formatMessage({
                    defaultMessage: "Freespins",
                    description: "Link label",
                  })}
                </Button.Action>
              )}
            </>
          )}
        </Bonusses>
        {/* <Link */}
        {/*  onClick={() => showModal(WithdrawModal)} */}
        {/*  className={`text-white */}
        {/*  flex justify-between w-full p-4 text-2xl leading-5 font-light text-left`} */}
        {/* > */}
        {/*  <span className="flex flex-1"> */}
        {/*    {intl.formatMessage({ */}
        {/*      defaultMessage: 'Withdraws', */}
        {/*      description: 'Link label', */}
        {/*    })} */}
        {/*  </span> */}
        {/*  <Icon */}
        {/*    icon="arrow" */}
        {/*    className="w-4 h-4 ml-2 -mr-1 transform -rotate-90 text-blue-darkest" */}
        {/*  /> */}
        {/* </Link> */}

        {/* <Link */}
        {/*  to="profile" */}
        {/*  className={`text-white */}
        {/*  flex justify-between w-full p-4 text-2xl leading-5 font-light text-left`} */}
        {/* > */}
        {/*  <span className="flex flex-1"> */}
        {/*    {intl.formatMessage({ */}
        {/*      defaultMessage: 'Personal details', */}
        {/*      description: 'Link label', */}
        {/*    })} */}
        {/*  </span> */}
        {/*  <Icon */}
        {/*    icon="arrow" */}
        {/*    className="w-4 h-4 ml-2 -mr-1 transform -rotate-90 text-blue-darkest" */}
        {/*  /> */}
        {/* </Link> */}
        {/* <Link */}
        {/*  to="wallet" */}
        {/*  className={`text-white */}
        {/*  flex justify-between w-full p-4 text-2xl leading-5 font-light text-left`} */}
        {/* > */}
        {/*  <span className="flex flex-1"> */}
        {/*    {intl.formatMessage({ */}
        {/*      defaultMessage: 'Account overview', */}
        {/*      description: 'Link label', */}
        {/*    })} */}
        {/*  </span> */}
        {/*  <Icon */}
        {/*    icon="arrow" */}
        {/*    className="w-4 h-4 ml-2 -mr-1 transform -rotate-90 text-blue-darkest" */}
        {/*  /> */}
        {/* </Link> */}

        {/* insert loyaly progress */}
        <LoyaltyProgramProgress />

        <MenuItem
          trackName="Wallets"
          trackParams={{ component: "Authenticated" }}
          onClick={() => {
            open("Wallets"), setMenuItem("Wallets");
          }}
          label={intl.formatMessage({
            defaultMessage: "Wallets",
            description: "Link label",
          })}
        />

        <MenuItem
          trackName="Account History"
          trackParams={{ component: "Authenticated" }}
          onClick={() => {
            setMenuItem("TransactionHistory"), setClickedFrom("AccountHistory");
          }}
          label={intl.formatMessage({
            defaultMessage: "Account History",
            description: "Link label",
          })}
        />

        {/* if user has pending withdraws */}
        {hasPendingWithdraws && (
          <MenuItem
            isPendingWithdraw
            hasPendingWithdraws={hasPendingWithdraws}
            trackName="Pending Withdrawal"
            trackParams={{ component: "Authenticated" }}
            onClick={() => {
              setMenuItem("TransactionHistory"),
                setClickedFrom("PendingWithdrawal");
            }}
            label={intl.formatMessage({
              defaultMessage: "Pending Withdrawal",
              description: "Link label",
            })}
          />
        )}

        <MenuItem
          trackName="Active Bonuses"
          trackParams={{ component: "Authenticated" }}
          onClick={() => setMenuItem("ActiveBonuses")}
          label={intl.formatMessage({
            defaultMessage: "Active Bonuses",
            description: "Link label",
          })}
        />

        <MenuItem
          trackName="Verify Identity"
          trackParams={{ component: "Authenticated" }}
          onClick={() => setMenuItem("DocumentVerification")}
          label={intl.formatMessage({
            defaultMessage: "Verify Identity",
            description: "Link label",
          })}
        />
        {/* <MenuItem
          trackName="Verify Identity V2"
          trackParams={{ component: "Authenticated" }}
          onClick={() => setMenuItem("DocumentVerificationV2")}
          label={intl.formatMessage({
            defaultMessage: "Verify Identity V2",
            description: "Link label",
          })}
        /> */}
        <MenuItem
          trackName="Responsible Gaming"
          trackParams={{ component: "Authenticated" }}
          onClick={() => setMenuItem("ResponsibleGaming")}
          label={intl.formatMessage({
            defaultMessage: "Responsible Gaming",
            description: "Link label",
          })}
        />
        <MenuItem
          trackName="Contact Us"
          trackParams={{ component: "Authenticated" }}
          onClick={() => {
            window.LC_API.open_chat_window();
            close();
          }}
          label={intl.formatMessage({
            defaultMessage: "Contact Us",
            description: "Link label",
          })}
        />
        <MenuItem
          trackName="Settings"
          trackParams={{ component: "Authenticated" }}
          onClick={() => setMenuItem("Profile")}
          label={intl.formatMessage({
            defaultMessage: "Settings",
            description: "Link label",
          })}
        />
      </div>

      {/* <div className="p-4 bg-blue-darkest"> */}

      {/*  <div className="flex items-center justify-between"> */}
      {/*    <a */}
      {/*      href="#sign-out" */}
      {/*      className="text-2xl font-light font-bold leading-5 text-left text-white" */}
      {/*    > */}
      {/*      {intl.formatMessage({ */}
      {/*        defaultMessage: 'Sign out', */}
      {/*        description: 'Link label', */}
      {/*      })} */}
      {/*    </a> */}
      {/*    <Switch */}
      {/*      checked={enabled} */}
      {/*      onChange={() => { */}
      {/*        logOut().then(() => { */}
      {/*          // Digest a sec */}
      {/*          setTimeout(() => { */}
      {/*            window.location.href = '/'; */}
      {/*          }, 500); */}
      {/*        }); */}
      {/*      }} */}
      {/*      className="relative inline-flex items-center h-6 rounded-full bg-green w-11" */}
      {/*    > */}
      {/*      <span className="sr-only">Logout</span> */}
      {/*      <span */}
      {/*        className={`${ */}
      {/*          enabled ? 'translate-x-6' : 'translate-x-1' */}
      {/*        } inline-block w-5 h-5 transform bg-white rounded-full`} */}
      {/*      /> */}
      {/*    </Switch> */}
      {/*  </div> */}
      {/* </div> */}
    </>
  );
};

export default Authenticated;
