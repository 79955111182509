import React, { useState } from "react";
import { useIntl } from "react-intl";
import { forfeitBonus } from "@data/model/bonus";
import classNames from "classnames";
import { useTracking } from "../../../hooks/useTracking";
import ConfirmationBox from "../../ConfirmationBox/ConfirmationBox";

const DepositBonus = ({ item, bonusMutate, currencies, bonuses }) => {
  const intl = useIntl();
  const { click } = useTracking();
  const [isCancelling, setIsCancelling] = useState(false);

  const calculateDate = () => {
    let originalDate = item?.valid_until;
    if (originalDate !== null && originalDate !== undefined) {
      originalDate = originalDate?.slice(0, 10);
      const [year, month, day] = originalDate?.split("-");
      return `${day}/${month}/${year}`;
    }
  };

  const calculateTime = () => {
    let originalTime = item?.valid_until;
    if (originalTime !== null && originalTime !== undefined) {
      originalTime = originalTime?.substring(originalTime.indexOf("T") + 1);
      originalTime = originalTime?.slice(0, 5);
    }

    return originalTime;
  };

  function calculatePercentage() {
    const final = Math.round(
      (item?.amount_wager_cents * 100) / item?.amount_wager_requirement_cents
    );
    return `${final}%`;
  }

  const getDividedCurrency = (curr) => {
    const wallet = currencies?.filter((cur) => cur?.code === curr);
    return wallet[0]?.subunits_to_unit;
  };

  const calculateDecimalPlaces = (curr) => {
    const wallet = currencies?.filter((cur) => cur?.code === curr);
    return wallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2;
  };

  return (
    <div className="w-full m-auto mb-6">
      <div className="p-3 rounded-lg bg-brand-600 rtl:text-right">
        <h1 className="text-2xl">{item?.title}</h1>

        <span className="text-xs text-brand-400">
          {item?.valid_until && (
            <>
              {intl.formatMessage({
                defaultMessage: "Valid until",
                description: "Label",
              })}{" "}
              {calculateDate()}{" "}
              {intl.formatMessage({
                defaultMessage: "at",
                description: "Label",
              })}{" "}
              {calculateTime()}
            </>
          )}
        </span>
        <div className="mt-4">
          <span className="text-xl text-brand-400">
            {item?.activatable
              ? intl.formatMessage({
                  defaultMessage: "Bonus amount",
                  description: "label",
                })
              : intl.formatMessage({
                  defaultMessage: "Bonus awarded",
                  description: "Label",
                })}
          </span>
          <br />
          <span className="text-4xl font-light">
            {intl.formatNumber(
              item?.amount_cents / getDividedCurrency(item.currency),
              {
                style: "currency",
                currency: item?.currency,
                minimumFractionDigits: calculateDecimalPlaces(item?.currency),
                maximumFractionDigits: calculateDecimalPlaces(item?.currency),
              }
            )}
          </span>
        </div>

        <div className="relative py-4">
          <div
            className="w-full h-10 rounded-full"
            style={{ border: "solid 1px #5b616e" }}
          >
            <div className="flex flex-row items-center w-full h-10 px-4 m-auto z-1">
              <h3 className="z-10 w-1/2 ml-0 text-base font-medium ">
                {intl.formatNumber(
                  item?.amount_wager_cents / getDividedCurrency(item.currency),
                  {
                    style: "currency",
                    currency: item?.currency,
                    minimumFractionDigits: calculateDecimalPlaces(
                      item?.currency
                    ),
                    maximumFractionDigits: calculateDecimalPlaces(
                      item?.currency
                    ),
                  }
                )}
              </h3>
              <h3 className="z-10 w-1/2 mr-0 text-baseltr:text-right rtl:text-left">
                {intl.formatNumber(
                  item?.amount_wager_requirement_cents /
                    getDividedCurrency(item.currency),
                  {
                    style: "currency",
                    currency: item?.currency,
                    minimumFractionDigits: calculateDecimalPlaces(
                      item?.currency
                    ),
                    maximumFractionDigits: calculateDecimalPlaces(
                      item?.currency
                    ),
                  }
                )}
              </h3>
            </div>
          </div>
          <div
            className="absolute w-full h-10 overflow-hidden rounded-full "
            style={{ bottom: "16px" }}
          >
            <div
              className="w-full h-10"
              style={{
                background: "#54a850",
                width: `${calculatePercentage()}`,
              }}
            />
          </div>
        </div>

        <div className="flex flex-row w-full text-xs">
          <div className="flex flex-col w-1/2">
            <p className="font-normal " style={{ color: "#828999" }}>
              {intl.formatMessage({
                defaultMessage: "Turnover requirement",
                description: "label",
              })}
            </p>
            <h3 className=" white" style={{ fontSize: "18px" }}>
              {intl.formatNumber(
                item?.amount_wager_requirement_cents /
                  getDividedCurrency(item.currency),
                {
                  style: "currency",
                  currency: item?.currency,
                  minimumFractionDigits: calculateDecimalPlaces(item?.currency),
                  maximumFractionDigits: calculateDecimalPlaces(item?.currency),
                }
              )}
            </h3>
          </div>
          <div className="flex flex-col items-start w-1/2">
            <p className="font-normal " style={{ color: "#828999" }}>
              {intl.formatMessage({
                defaultMessage: "Turnover remaining",
                description: "label",
              })}
            </p>
            <h3 className=" white" style={{ fontSize: "18px" }}>
              {intl.formatNumber(
                (item?.amount_wager_requirement_cents -
                  item?.amount_wager_cents) /
                  getDividedCurrency(item.currency),
                {
                  style: "currency",
                  currency: item?.currency,
                  minimumFractionDigits: calculateDecimalPlaces(item?.currency),
                  maximumFractionDigits: calculateDecimalPlaces(item?.currency),
                }
              )}
            </h3>
          </div>
        </div>

        {item?.cancelable && (
          <>
            {/* <button
                        onClick={() => {setIsCancelling(true), click("Cancel Bonus", {component: 'ActiveBonuses'}), forfeitBonus(item?.id).then(() => {setIsCancelling(false), bonusMutate('getGrantedBonuses')});}}
                        className={cx("mt-2 text-gold", { 'animate-pulse': isCancelling })}>
                        {intl.formatMessage({
                            defaultMessage: 'Cancel bonus',
                            description: 'label',
                        })}
                        </button> */}
            <ConfirmationBox
              trigger={
                <button className="mt-2">
                  <span
                    className={classNames("text-gray text-sm", {
                      "animate-pulse": isCancelling,
                    })}
                  >
                    {intl.formatMessage({
                      defaultMessage: "Cancel bonus",
                      description: "label",
                    })}
                  </span>
                </button>
              }
              intro={intl.formatMessage({
                defaultMessage: "Are you sure you want to cancel your bonus?",
                description: "Are you sure label",
              })}
              text={intl.formatMessage({
                defaultMessage:
                  "Cancelling your bonus will remove any bonus balance accrued that has not completed the wagering requirements subject to the terms and conditions of the offer",
                description: "Are you sure message",
              })}
              confirmationAction={() => {
                setIsCancelling(true),
                  click("Cancel Bonus", { component: "ActiveBonuses" }),
                  forfeitBonus(item?.id).then(() => {
                    setIsCancelling(false), bonusMutate("getGrantedBonuses");
                  });
              }}
              confirmationLabel={intl.formatMessage({
                defaultMessage: "Cancel my bonus",
                description: "Label",
              })}
              cancelLable={intl.formatMessage({
                defaultMessage: "Do not cancel my bonus",
                description: "Label",
              })}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DepositBonus;
