import React, { memo, useState, useEffect } from "react";
import { useField } from "formik";
import cx from "classnames";
import { useIntl } from "react-intl";
import Label from "../Label";
import Icon from "../../Icon";
import { useWallet } from "../../Provide/WalletContext";

const DropDown = ({
  list,
  header = "",
  open,
  onChange,
  className,
  name,
  currency,
  currencySymbol,
  setCurrencySymbol,
  setMenuItem,
  ...props
}) => {
  const [openCloseToggle, setOpenCloseToggle] = useState(false);
  const [selected, setSelected] = useState(currency);
  const [arrow, setArrow] = useState("down");
  // const addNewCurrency = [{code : 'Add new currency', symbol : ''}]
  // const [newCurrency, setNewCurrency] = useState('')
  const intl = useIntl();
  const { total } = useWallet();

  useEffect(() => {
    list?.forEach((element) => {
      if (element?.currency?.toLowerCase() === currency?.toLowerCase()) {
        setCurrencySymbol(
          intl
            .formatNumber(0, {
              style: "currency",
              currency: element?.currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
            .replace(/\d/g, "")
            .trim()
        );
      }
    });
    setSelected(currency);
  }, [list, currency]);

  return (
    <div className="relative w-full h-10 px-3 m-0 mt-2 text-white border rounded-lg appearance-none bg-brand-600 focus:outline-none focus:border-gold focus:ring-0 focus:ring-offset-0 focus:ring-transparent border-brand-500">
      <button
        type="button"
        className="w-full h-10 m-auto ml-0 text-left "
        onClick={() => {
          setOpenCloseToggle(!openCloseToggle),
            setArrow(arrow === "down" ? "up" : "down");
        }}
      >
        <div className="flex justify-center h-10 text-center rtl:flex-row-reverse ">
          <p className="w-full m-auto ml-0 rtl:text-right ltr:text-left">
            {selected}
          </p>
          <p className="w-10 m-auto ml-0 text-right text-gray"> {total}</p>
          <Icon
            icon="arrow"
            className={cx(
              "ml-4 h-4 w-4 inline-block transform m-auto transition ease-in-out duration-150",
              {
                "-rotate-180": arrow === "up",
                "-rotate-0": arrow === "down",
                "-rotate-90": arrow === "right",
              }
            )}
          />
        </div>
      </button>
      {openCloseToggle && (
        <div
          role="list"
          className="absolute left-0 z-10 flex flex-col w-full pr-5 overflow-auto text-white border rounded-lg max-h-40 border-brand-500 bg-brand-600 "
        >
          {list?.length > 0 &&
            list?.map((item) => (
              <button
                type="button"
                className="px-3 py-2 text-left"
                key={intl
                  .formatNumber(0, {
                    style: "currency",
                    currency: item.currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                  .replace(/\d/g, "")
                  .trim()}
                onClick={() => {
                  setOpenCloseToggle(!openCloseToggle),
                    setCurrencySymbol(
                      intl
                        .formatNumber(0, {
                          style: "currency",
                          currency: item.currency,
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                        .replace(/\d/g, "")
                        .trim()
                    ),
                    setSelected(item.currency),
                    setArrow("down"),
                    onChange(item.currency);
                }}
              >
                {item.currency}{" "}
                {intl
                  .formatNumber(0, {
                    style: "currency",
                    currency: item.currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                  .replace(/\d/g, "")
                  .trim()}
              </button>
            ))}

          <button
            type="button"
            className="px-3 py-2 text-left"
            onClick={() => {
              open("Wallets"), setMenuItem("Wallets");
            }}
          >
            {intl.formatMessage({
              defaultMessage: "Add new currency",
              description: "Label for add new currency",
            })}
          </button>
        </div>
      )}
    </div>
  );
};

export default memo(DropDown);
