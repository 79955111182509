import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { ToggleSwitch, TextField } from "@components/Formik";
import { useIntl } from "react-intl";
import Button from "@components/Button";
import cx from "classnames";
import {
  addDepositBonus,
  deleteDepositBonus,
  addCouponBonus,
} from "@data/model/bonus";
import { SelectBox } from "../../../Formik";
import { getPlayerDetails } from "../../../../data/model/profile";
import { getDepositBonuses } from "../../../../data/model/bonus";
import { updateBonusPreference } from "../../../../data/model/account";
import deleteIcon from "../../../../../public/delete-icon.png";
import { useAuth } from "../../../Provide/AuthContext";
import Icon from "../../../Icon";

const BonusSelector = ({
  setFieldValue,
  useBonusCode,
  bonusCode,
  values,
  paymentType,
}) => {
  const intl = useIntl();
  const { isAuthenticated } = useAuth();
  const { data: bonuses, mutate: refetchBonuses } = useSWR(
    "/user/bonus#getApplicableBonuses",
    getDepositBonuses
  );

  const { data: personal, mutate: refetchPersonalData } = useSWR(
    isAuthenticated ? "/api/player" : null,
    getPlayerDetails
  );

  const [bonusError, setBonusError] = useState("");

  const [isSubmittingBonus, setIsSubmittingBonus] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [bonusStatus, setBonusStatus] = useState("");

  const options = bonuses?.map((bonus) => {
    const nameParts = [];
    bonus.bonuses.map((item) => nameParts.push(item.title));
    return {
      name: bonus.id,
      text: nameParts.join(" & "),
    };
  });

  useEffect(() => {
    if (personal?.deposit_bonus_code?.length > 0) {
      setHasData(true);
    } else {
      setHasData(false);
    }
  }, [personal]);

  const setUseBonus = async (hasBonus) => {
    await updateBonusPreference(hasBonus);
    refetchBonuses();
  };
  console.log(personal?.deposit_bonus_code, "hasData");
  useEffect(() => {
    if (options?.length && !values?.fields?.bonusCode?.name) {
      // console.log('values--123',values);
      // console.log('bonuses--',bonuses);
      // console.log('options--',options);
      setFieldValue("fields[bonusCode]", options[0]);
    }
    // options?.length > 0 && setFieldValue('bonusCode', options[0]);
  }, [bonuses]);

  const submitAddingNewBonus = async () => {
    setIsSubmittingBonus(true);
    try {
      await addDepositBonus(values?.fields?.bonusCodeInput).then(() => {
        setIsSubmittingBonus(false);
        setBonusStatus(`Bonus '${values.fields.bonusCodeInput}' added!`);
      });
    } catch (e) {
      setIsSubmittingBonus(false);
      // setFieldError('fields[bonusCodeInput]', 'This is not a valid bonus code');
      setBonusError("Bonus code entered is incorrect.");
      return false;
    }
  };

  console.log("hasData", hasData);
  console.log("bonusCode", bonusCode);
  console.log("useBonuscode", useBonusCode);
  return (
    <div>
      <div className="ltr:ml-3 rtl:mr-3 text-gray rtl:text-right">
        {intl.formatMessage({
          defaultMessage: "Available bonuses:",
          description: "Label",
        })}
      </div>

      <div className="flex flex-row gap-2 mt-5 mb-4">
        <ToggleSwitch name="useBonusCode" onChange={setUseBonus} />
        <div className="m-auto ml-2">
          {intl.formatMessage({
            defaultMessage: "Use bonuses",
            description: "bonuses toggle switch",
          })}
        </div>
      </div>
      {bonuses && useBonusCode && options?.length > 0 && (
        <SelectBox
          name="fields[bonusCode]"
          value={{
            name: "",
            text: intl.formatMessage({
              defaultMessage: "I don't want a bonus",
              description: "Label",
            }),
          }}
          // label="Please select your bonus"
          options={
            options && [
              ...options,
              {
                name: "",
                text: intl.formatMessage({
                  defaultMessage: "I have a new bonus code",
                  description: "Label",
                }),
              },
            ]
          }
          className="w-full my-4 mr-8"
          hint="Select the applicable bonus"
        />
      )}
      {((bonusCode?.name === "" && useBonusCode) ||
        (bonuses?.length === 0 && useBonusCode)) && (
        <div className="relative mb-4">
          {!hasData ? (
            <div className="relative">
              <TextField
                id="bonusCodeInput"
                name="fields[bonusCodeInput]"
                type="text"
                label={intl.formatMessage({
                  defaultMessage: "Add Bonus Code",
                  description: "Label of inputfield",
                })}
                className="w-full mt-4"
              />
              {paymentType === "crypto" && (
                <Button.Action
                  trackName="Submit Bonus"
                  trackParams={{ component: "BonusSelector" }}
                  className={cx(
                    "absolute w-35 h-35 top-0 right-0 m-2.5 rounded-full p-0 text-center text-xl px-0 pl-3 pr-3 ",
                    { "animate-pulse": isSubmittingBonus }
                  )}
                  disabled={
                    !!isSubmittingBonus ||
                    values?.fields?.bonusCodeInput?.length === 0
                  }
                  isSubmitting={isSubmittingBonus}
                  onClick={() => submitAddingNewBonus()}
                >
                  <svg
                    className="w-8 h-8 text-white"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="4"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    {" "}
                    <path stroke="none" d="M0 0h24v24H0z" />{" "}
                    <line x1="12" y1="5" x2="12" y2="19" />{" "}
                    <line x1="5" y1="12" x2="19" y2="12" />
                  </svg>
                </Button.Action>
              )}
            </div>
          ) : (
            <div className="relative">
              <TextField
                id="bonusCodeDelete"
                type="bonusCodeDelete"
                name="bonusCodeDelete"
                label={intl.formatMessage({
                  defaultMessage: "Remove Bonus Code",
                  description: "Label of inputfield",
                })}
                disabled
                value={personal?.deposit_bonus_code}
                className="w-full mt-4"
              />
              <div className="absolute w-35 h-35 top-0 right-0 m-2.5 p-2 rounded-full bg-brand-600">
                <span
                  className="w-5 cursor-pointer"
                  onClick={() =>
                    deleteDepositBonus()
                      .then(() => {
                        setBonusStatus("Bonus deleted!"),
                          refetchPersonalData(),
                          setFieldValue("fields[bonusCodeInput]", "");
                      })
                      .catch((error) => setBonusError(error))
                  }
                >
                  <img className="h-5 m-auto" src={deleteIcon} />
                </span>
              </div>
            </div>
          )}
          {bonusStatus?.length > 0 && (
            <div
              className={cx(
                "pl-3 pb-1 text-sm capitalize",
                bonusStatus.includes("fail") ? "text-red" : "text-green"
              )}
            >
              {bonusStatus}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BonusSelector;
