import AutobahnReact from '../../core/websocket';

import { getCall } from '../../core/softswiss';

export const getTransactionHistory = (
  key,
  type,
  startTime,
  endTime,
  pageIndex = 1,
  pageSize = 100000,
) => getCall('/api/player/payments');

export const getGamblingHistory = (
  key,
  type,
  startTime,
  endTime,
  pageIndex = 1,
  pageSize = 100000,
) => getCall('/api/player/games');


// AutobahnReact("everymatrix").call("/user#getTransactionHistory", {
//   type,
//   startTime,
//   endTime,
//   pageIndex,
//   pageSize
// });
// for payment ransactions (deposits, withdrawals) - GET /api/player/payments
// for bets - GET /api/player/games
