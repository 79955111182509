/**
 * TopBar Component
 *
 * This component is responsible for The public top menu login/ registration buttons
 *
 * @category   TopBarPublic
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */
import React, { useState } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { Menu, Switch, Transition } from '@headlessui/react';
import Button from '@components/Button';

import Icon from '@components/Icon';
import Link from '@components/Link';
import { useWallet } from '@components/Provide/WalletContext';
import Navigator from '@components/Form/Navigator';
import Bonusses from '@components/Bonusses';
import WithdrawModal from '@components/WithdrawModal';
import { useModal } from '@components/Modal/ModalContext';
// import ResponsibleGamingModal from '../../Account/ResponsibleGamingModal';
// import UserProfile from '../../Account/UserProfile';
import { closeNavigation } from '@actions/navigation';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import MenuItem from '../MenuItem';
import { useAuth } from '../../../Provide/AuthContext';
import { scrollTo } from '../../../../core/utils';

const Public = ({ setMenuItem, setBackActions }) => {
  const intl = useIntl();
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const close = () => dispatch(closeNavigation());
  return (

    <>
      <div className="mt-8 divide-y divide-white divide-opacity-25 p-3">

        {!isAuthenticated && (
        <MenuItem
        trackName="Login"
        trackParams={{component: 'Public'}}
          onClick={() => setMenuItem('Auth')}
          label={intl.formatMessage({
            defaultMessage: 'Login',
            description: 'Link label',
          })}
        />
        )}
        {!isAuthenticated && (
        <MenuItem
        trackName="Register"
        trackParams={{component: 'Public'}}
          onClick={() => setMenuItem('Registration')}
          label={intl.formatMessage({
            defaultMessage: 'Register',
            description: 'Link label',
          })}
        />
        )}
        <MenuItem
          trackName="Promotions"
          trackParams={{component: 'Public'}}
          to="promotions"
          onClick={close}
          label={intl.formatMessage({
            defaultMessage: 'Promotions',
            description: 'Link label',
          })}
        />
        <MenuItem
          trackName="Tournaments"
          trackParams={{component: 'Public'}}
          to="tournaments"
          onClick={close}
          label={intl.formatMessage({
            defaultMessage: 'Tournaments',
            description: 'Link label',
          })}
        />
        <MenuItem
          trackName="Casino"
          trackParams={{component: 'Public'}}
          to="home"
          onClick={() => {
            window.setTimeout(() => scrollTo(document.getElementById('main-menu'), 1000, { scrollTo: { x: 0 } }), 3000);
            close();
          }}
          label={intl.formatMessage({
            defaultMessage: 'Casino',
            description: 'Link label',
          })}
        />

        <MenuItem
          trackName="Live casino"
          trackParams={{component: 'Public'}}
          to="liveLobby"
          onClick={() => {
            window.setTimeout(() => scrollTo(document.getElementById('main-menu'), 1000, { scrollTo: '#gambrowser-live' }), 3000);
            close();
          }}
          label={intl.formatMessage({
            defaultMessage: 'Live casino',
            description: 'Link label',
          })}
        />

        <MenuItem
          trackName="Loyalty"
          trackParams={{component: 'Public'}}
          to="loyalty"
          onClick={close}
          label={intl.formatMessage({
            defaultMessage: 'Loyalty',
            description: 'Link label',
          })}
        />

        <MenuItem
          trackName="Help"
          trackParams={{component: 'Public'}}
          onClick={() => setMenuItem('Faq')}
          label={intl.formatMessage({
            defaultMessage: 'Help',
            description: 'Link label',
          })}
        />
      </div>

    </>
  );
};

export default Public;
