import useSWR from "swr";
import React from "react";
import { useIntl } from "react-intl";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { useDispatch } from "react-redux";
import { closeNavigation } from "@actions/navigation";
import Button from "../../Button";
import { useWallet } from "../../Provide/WalletContext";
import { titleize } from "../../../core/utils";

const WithdrawNoBalance = ({ hideModal }) => {
  const intl = useIntl();
  const {} = useWallet();
  const dispatch = useDispatch();
  const close = () => dispatch(closeNavigation());
  return (
    <div className="w-full flex flex-col pb-14">
      <h4 className="text-center text-2xl leading-5 font-light text-white mb-16">
        {intl.formatMessage({
          defaultMessage: "Withdraw",
          description: "header",
        })}
      </h4>
      <div className="mx-auto mt-5.5 flex-col flex w-full items-stretch">
        {intl.formatMessage({
          defaultMessage:
            "Unfortunately you aren't available to withdraw as you don't have enough money in your account to meet the minimum withdrawal amount.",
          description: "The feedback after setting a limit",
        })}
      </div>
      <div className="pt-8  w-full">
        <Button.Action
          trackName="Close"
          trackParams={{ component: "WithdrawNoBalance" }}
          onClick={close}
          className="w-full"
          arrow="right"
        >
          {intl.formatMessage({
            defaultMessage: "Close",
            description: "Label",
          })}
        </Button.Action>
      </div>
    </div>
  );
};

export default WithdrawNoBalance;
