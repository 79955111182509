import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import Button from '@components/Button';
import { rollbackWithdraw } from '@data/model/withdraw';
import { useRouter } from '@components/Provide/RouterContext';
/**
 * @todo add {item.fees} when used
 */

const WithdrawHistory = ({
  transactions, mutate, startTime, period, currencies
}) => {
  const intl = useIntl();
  const itemsPerLoad = 10;
  const [items, setItems] = useState(transactions?.filter((item) => item.action === 'cashout' && !!item.finished_at)?.sort((a, b) => -a.created_at.localeCompare(b.created_at))?.slice(0, itemsPerLoad));
  const router = useRouter();
  const [itemsPeriod, setItemsPeriod] = useState(filteredItemPeriod?.sort((a, b) => -a.created_at.localeCompare(b.created_at))?.slice(0, itemsPerLoad));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (typeof (transactions) !== 'undefined' && (typeof (filteredItemPeriod) !== 'undefined')) {
      setItems(transactions?.filter((item) => item.action === 'cashout' && !!item.finished_at));
      setItemsPeriod(filteredItemPeriod?.sort((a, b) => -a.created_at.localeCompare(b.created_at))?.slice(0, itemsPerLoad));
      setIsLoading(false);
    }
  }, [transactions, filteredItemPeriod, period, isLoading]);

  const loadMore = () => {
    setItemsPeriod(filteredItemPeriod?.filter((item) => item.action === 'cashout' && !!item.finished_at)?.sort((a, b) => -a.created_at.localeCompare(b.created_at))?.slice(0, itemsPeriod.length + itemsPerLoad));
  };

  const getDividedCurrency = (curr) => {
    let wallet = currencies?.filter(cur => cur?.code === curr);
    return wallet[0]?.subunits_to_unit;
  }

  let filteredItemPeriod = [];
  let transactionDate;
  const filterItemsByPeriod = (
    items?.map((item) => {
      transactionDate = new Date(item?.finished_at?.slice(0, 10));
      if (startTime < transactionDate) {
        filteredItemPeriod.push(item);
      }
    })
  );

  const calculateDecimalPlaces = (curr) => {
    let wallet = currencies?.filter(cur => cur?.code === curr);
    return ((wallet?.[0]?.subunits_to_unit?.toString()?.length - 1) ?? 2);
  }

  return (
    <div className="space-y-4 divide-y divide-brand-400 divide-solid px-2.5">
      {itemsPeriod
        ?.sort((a, b) => -a.created_at.localeCompare(b.created_at))
        ?.map((item, key) => (
          <div className="w-full">
            <div className="flex flex-row items-center justify-between ">
              <span className="text-xs text-brand-400 mt-4">{format(parseISO(item.created_at), 'dd MMMM yyyy, HH:mm:ss')}</span>
              <span className={cx('text-xs mt-4', { 'text-green': item.success, 'text-red': !item.success && !item.recallable, 'text-orange': item.recallable })}>
                {item.success ? intl.formatMessage({
                  defaultMessage: 'Successful',
                  description: 'Label',
                }) : intl.formatMessage({
                  defaultMessage: 'Failed',
                  description: 'Label',
                })}
              </span>
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="text-white text-lg font-book">{item.payment_system}</span>
              <span className={cx('text-lg font-book text-white')}>
                {intl.formatNumber((item.amount_cents / getDividedCurrency(item.currency)), {
                  style: 'currency',
                  currency: item.currency,
                  minimumFractionDigits: calculateDecimalPlaces(item.currency),
                  maximumFractionDigits: calculateDecimalPlaces(item.currency),
                })
                }
              </span>

            </div>
            {item.recallable && (
              <Button.Action 
              trackName = "Cancel Withdrawal"
              trackParams={{ component: 'WithdrawHistory' }} onClick={() => { rollbackWithdraw(item.id).then(() => mutate()); }} arrow="right" className="w-full mt-2">
                {intl.formatMessage({
                  defaultMessage: 'Cancel withdrawal',
                  description: 'Label',
                })}
              </Button.Action>
            )}
          </div>
        ))}

      {itemsPeriod?.filter((item) => item.action === 'cashout' && !!item.finished_at)?.length === 0 && (
      <div className="mt-2">
        {intl.formatMessage({
          defaultMessage: 'No transactions found within the last {period} days.',
          description: 'Label',
        }, { period })}
      </div>
      )}
      {filteredItemPeriod?.filter((item) => item.action === 'cashout' && !!item.finished_at)?.length > itemsPeriod?.length && (
        <Button.Action trackName="Load more"
        trackParams={{ component: 'WithdrawHistory' }} type="button" onClick={loadMore}>
         {intl.formatMessage({
            defaultMessage: 'Load more',
            description: 'Label',
          })}
        </Button.Action>
      )}
    </div>
  );
};

export default WithdrawHistory;
