import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import cx from "classnames";
import classNames from "classnames";
import { activateFreespins, forfeitFreespins } from "@data/model/bonus";
import Button from "../../Button";
import { useTracking } from "../../../hooks/useTracking";
import { Transition } from "@headlessui/react";
import Icon from "../../Icon";
import { useDispatch } from "react-redux";
import { closeNavigation } from "@actions/navigation";
import Link from "@components/Link";
import ConfirmationBox from "../../ConfirmationBox/ConfirmationBox";
import history from "../../../core/history";
import { localeUrl } from "../../../core/url";
import slugify from "slugify";
import { toggleNavigation } from "../../../actions/navigation";

const FreeSpinsBonuses = ({ item, freespinsMutate, currencies, freespins }) => {
  const intl = useIntl();
  const [showAllGames, setShowAllGames] = useState(true);
  const [showGamesClick, setShowGamesClick] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activated, setActivated] = useState(false);
  const { click } = useTracking();
  const [isCancelling, setIsCancelling] = useState(false);
  const dispatch = useDispatch();
  const close = () => dispatch(closeNavigation());
  const [freespinError, setFreespinError] = useState("");
  const toggle = (section = "Root") => dispatch(toggleNavigation(section));

  console.log("freespin error active", freespinError);
  useEffect(() => {
    calculateWidthOfAllGames();
  }, [item]);

  const calculateDate = useMemo(() => {
    let originalDate = item.activatable
      ? item.activatable_until
      : item.valid_until || "";
    originalDate = originalDate?.slice(0, 10);
    const [year, month, day] = originalDate?.split("-");
    return day + "/" + month + "/" + year;
  }, [item]);

  const calculateTime = useMemo(() => {
    let originalTime = item.activatable
      ? item.activatable_until
      : item.valid_until;
    originalTime = originalTime?.substring(originalTime.indexOf("T") + 1);
    originalTime = originalTime?.slice(0, 5);
    return originalTime;
  }, [item]);

  function calculatePercentage() {
    let final = Math.round(
      ((item?.freespins_performed === null ? 0 : item?.freespins_performed) *
        100) /
        item?.freespins_total
    );
    return final + "%";
  }
  const calculateWidthOfAllGames = () => {
    let gameListStringCount = 0;
    let games = item?.games_info?.map?.(
      (gameinfo, index) => (gameListStringCount += gameinfo?.title?.length)
    );
    let gamecont = document.getElementById(
      `allgames${item?.id}`.split(" ").join("-")
    );
    console.log("container details", gamecont);
    if (gameListStringCount > 30) {
      //overflow-hidden whitespace-nowrap
      setShowAllGames(false);
      gamecont.style.overflow = "hidden";
      gamecont.style.whiteSpace = `nowrap`;
      gamecont.style.maxWidth = "16rem";
    } else {
      setShowAllGames(true);
    }
  };

  const getDividedCurrency = (curr) => {
    let wallet = currencies?.filter((cur) => cur?.code === curr);
    return wallet[0]?.subunits_to_unit;
  };

  const calculateDecimalPlaces = (curr) => {
    let wallet = currencies?.filter((cur) => cur?.code === curr);
    return wallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2;
  };

  return (
    <div className="relative w-full m-auto mb-6">
      <Transition
        show={activated}
        className="absolute z-10 flex flex-col justify-center w-full m-auto overflow-hidden text-center rounded-lg bg-brand-600"
        style={{ height: `-webkit-fill-available` }}
      >
        <Transition.Child
          enter="transition ease-out duration-500 delay-500"
          enterFrom="transform -translate-y-80 opacity-0"
          enterTo="transform opacity-100 translate-y-0"
          leave="transition ease-in duration-700"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0"
          className="flex flex-col items-center justify-center w-full"
          unmount={false}
        >
          {activated && (
            <>
              <Icon icon="checkFeedback" className="w-16 h-16 text-gold" />
              {/* {status === 'error' && <Icon icon="checkError" className="h-18 w-18" />} */}
            </>
          )}
        </Transition.Child>
        <Transition.Child
          enter="transition ease-out duration-500 delay-500"
          enterFrom="transform translate-y-80 opacity-0"
          enterTo="transform opacity-100 translate-y-0"
          leave="transition ease-in duration-700"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0"
          className="w-full mt-6 mb-2 text-center"
          unmount={false}
        >
          <>
            <h1>
              {intl.formatMessage({
                defaultMessage: "Activated",
                description: "Label",
              })}
            </h1>
            <div className="mx-10 mt-6">
              <Button.Action
                trackName="Activate Freespin"
                trackParams={{
                  component: "FreeSpinsBonuses",
                  title: item?.title,
                }}
                className="w-full mt-4"
                arrow="right"
              >
                <Link
                  onClick={close}
                  to="home"
                  className={cx(" w-full text-brand text-left")}
                  arrow="right"
                >
                  {intl.formatMessage({
                    defaultMessage: "Play games",
                    description: "label",
                  })}
                </Link>
              </Button.Action>
            </div>
          </>
        </Transition.Child>
      </Transition>
      <div className="p-3 rounded-lg bg-brand-600 rtl:text-right">
        <h1 className="text-2xl">{item?.title}</h1>

        {calculateTime && calculateDate && (
          <span className="text-xs text-brand-400">
            {item?.activatable
              ? intl.formatMessage({
                  defaultMessage: "Activate by",
                  description: "Label",
                })
              : intl.formatMessage({
                  defaultMessage: "Use before",
                  description: "Label",
                })}{" "}
            {calculateDate}{" "}
            {intl.formatMessage({
              defaultMessage: "at",
              description: "Label",
            })}{" "}
            {calculateTime}
          </span>
        )}

        <div className="mt-4">
          <span className="text-xl text-brand-400">
            {item?.activatable
              ? intl.formatMessage({
                  defaultMessage: "Freespins",
                  description: "label",
                })
              : intl.formatMessage({
                  defaultMessage: "Freespins awarded",
                  description: "Label",
                })}
          </span>
          <br />
          <span className="text-4xl font-light">{item?.freespins_total}</span>
        </div>

        <div className="">
          <span className="text-xs text-brand-400">
            {intl.formatMessage({
              defaultMessage: "Games",
              description: "Header label",
            })}
          </span>
          <div id="allgamescontainer" className="flex flex-row -mt-1">
            <div
              id={`allgames${item?.id}`?.split(" ").join("-")}
              className={cx("text-white w-auto")}
              style={
                showAllGames && showGamesClick
                  ? {
                      overflow: `auto`,
                      width: `auto`,
                      maxWidth: `none`,
                      whiteSpace: `normal`,
                    }
                  : {}
              }
            >
              {item?.games_info?.map?.((gameinfo, index) => (
                <>
                  {gameinfo.title}
                  {index !== item.games_info?.length - 1 && <>{", "}</>}
                </>
              ))}
            </div>

            {!showAllGames && (
              <div className="text-white">
                {intl.formatMessage({
                  defaultMessage: "...",
                  description: "Label",
                })}
              </div>
            )}

            {!showAllGames && (
              <div
                className="ml-1 cursor-pointer text-gold"
                onClick={() => {
                  setShowAllGames(true), setShowGamesClick(true);
                }}
              >
                {intl.formatMessage({
                  defaultMessage: "see all games",
                  description: "Label",
                })}
              </div>
            )}
          </div>
        </div>

        {/* {!item?.activatable && (
                    <div className="relative py-4">
                        <div className="w-full h-10 rounded-full" style={{ border: `solid 1px #5b616e` }}>
                            <div className="flex flex-row items-center w-full h-10 px-4 m-auto z-1">
                                <h3 className="z-10 w-1/2 ml-0 text-base font-bold ">
                                    {item?.freespins_performed === null ? 0 : item?.freespins_performed}
                                    {' '}
                                    {intl.formatMessage({
                                        defaultMessage: 'used',
                                        description: 'Label',
                                    })}
                                </h3>
                                <h3 className="z-10 w-1/2 mr-0 text-baseltr:text-right rtl:text-left">
                                    {item?.freespins_total}
                                    {' '}
                                    {intl.formatMessage({
                                        defaultMessage: 'freespins',
                                        description: 'Label',
                                    })}
                                </h3>
                            </div>
                        </div>
                        <div className="absolute h-10 rounded-full " style={{ background: `#54a850`, width: `${calculatePercentage()}`, bottom: `16px` }}></div>
                    </div>
                )} */}

        {/* {!item.activatable && (
                    <div className="flex flex-row w-full text-xs">
                        <div className="flex flex-col w-1/2">
                            <p className="font-normal " style={{ color: `#828999` }}>{intl.formatMessage({
                                defaultMessage: "Freespins remaining",
                                description: "label",
                            })}</p>
                            <h3 className=" white" style={{ fontSize: `18px` }}>
                                {item.freespins_total - (item?.freespins_performed === null ? 0 : item?.freespins_performed)}
                            </h3>
                        </div>
                    </div>
                )} */}

        {item?.activatable ? (
          <Button.Action
            trackName="Activate Freespin"
            trackParams={{ component: "FreeSpinsBonuses", title: item?.title }}
            // onClick={() => {setActivated(true)}}
            onClick={() => {
              setIsSubmitting(true),
                activateFreespins(item.id)
                  .then(
                    () => freespinsMutate(),
                    setIsSubmitting(false),
                    setActivated(true),
                    localStorage.setItem(
                      "initialFreespins",
                      JSON.stringify(freespins)
                    )
                  )
                  .catch((error) => setFreespinError(error));
            }}
            className={cx("mt-4 w-full", { "animate-pulse": isSubmitting })}
            arrow="right"
            disabled={isSubmitting}
          >
            {isSubmitting
              ? intl.formatMessage({
                  defaultMessage: "Activating...",
                  description:
                    "When submitting a button this generic terms is used while waiting",
                })
              : intl.formatMessage({
                  defaultMessage: "Activate freespins",
                  description: "label",
                })}
          </Button.Action>
        ) : (
          <Button.Action
            trackName="Navigate to Freespin page"
            trackParams={{ component: "FreeSpinsBonuses" }}
            onClick={() => {
              toggle();
              history.push(
                localeUrl("freeSpinGames", intl.locale, {
                  bonusTitle: slugify(item?.title, { lower: true }),
                })
              );
            }}
            className={cx("mt-4 w-full")}
            arrow="right"
          >
            {intl.formatMessage({
              defaultMessage: "To the games",
              description: "To the games label",
            })}
          </Button.Action>
        )}
        {item?.cancelable && (
          <>
            {/* <button
                    onClick={() => {setIsCancelling(true), click("Cancel Freespin", {component: 'FreeSpinsBonuses'}), forfeitFreespins(item.id).then(() => {setIsCancelling(false), freespinsMutate()});}} 
                    className={cx("mt-2 text-gold", { 'animate-pulse': isCancelling })}>
                    {intl.formatMessage({
                        defaultMessage: 'Cancel freespins',
                        description: 'label',
                    })}
                    </button> */}
            <ConfirmationBox
              trigger={
                <button className="mt-2">
                  <span
                    className={classNames("text-gray text-sm", {
                      "animate-pulse": isCancelling,
                    })}
                  >
                    {intl.formatMessage({
                      defaultMessage: "Cancel bonus",
                      description: "label",
                    })}
                  </span>
                </button>
              }
              intro={intl.formatMessage({
                defaultMessage: "Are you sure you want to cancel your bonus?",
                description: "Are you sure label",
              })}
              text={intl.formatMessage({
                defaultMessage:
                  "Cancelling your bonus will remove any bonus balance accrued that has not completed the wagering requirements subject to the terms and conditions of the offer",
                description: "Are you sure message",
              })}
              confirmationAction={() => {
                setIsCancelling(true),
                  click("Cancel Freespin", { component: "FreeSpinsBonuses" }),
                  forfeitFreespins(item.id).then(() => {
                    setIsCancelling(false), freespinsMutate();
                  });
              }}
              confirmationLabel={intl.formatMessage({
                defaultMessage: "Cancel my bonus",
                description: "Label",
              })}
              cancelLable={intl.formatMessage({
                defaultMessage: "Do not cancel my bonus",
                description: "Label",
              })}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default FreeSpinsBonuses;
