import React from "react";
import Icon from "../../../../Icon/Icon";
import { useIntl } from "react-intl";

const DocumentVerificationDescription = () => {
  const intl = useIntl();
  return (
    <div className="text-xs font-normal text-white">
      <div className="flex flex-col gap-5 mb-5">
        <p>
          To make a withdrawal, you’ll need to verify your identity. We do this
          to protect players and to keep gambling crime free.
        </p>
        <p className="m-0">
          To verify your identity we need to see proof of your identity and
          evidence of your payment methods.
        </p>
      </div>
      <div className="flex flex-col gap-5">
        <div className="flex gap-3">
          <div className="h-4 max-w-4 w-full bg-[#0091ff] rounded-full flex items-center justify-center ring-2 ring-[#0091ff] ring-opacity-50">
            <Icon icon="infoOutline" className={"w-2 h-2 text-white"} />
          </div>
          <div className="flex flex-col gap-5">
            <p>
              Photos from your camera phone are usually ok to send, as are files
              you’ve downloaded from online banking apps.
            </p>
            <p className="m-0">
              We can’t accept screenshots except in particular cases.
            </p>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="h-4 max-w-4 w-full bg-[#0091ff] rounded-full flex items-center justify-center ring-2 ring-[#0091ff] ring-opacity-50">
            <Icon icon="lockOutline" className={"w-2 h-2 text-white"} />
          </div>
          <div className="flex flex-col gap-5">
            <p>
              Your security is important to us. Anything you upload is securely
              sent & saved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentVerificationDescription;
