import React from "react";
import { DropDown } from "../../../Formik";
import { useIntl } from "react-intl";

const CurrencyChange = ({
  onChange,
  open,
  wallets,
  currency,
  currencySymbol,
  setCurrencySymbol,
  setMenuItem,
}) => {
  const intl = useIntl();
  return (
    <div>
      <span className="ltr:ml-3 rtl:mr-3 text-gray rtl:text-right">
        {intl.formatMessage({
          defaultMessage: "Change currency",
          description: "Label",
        })}
      </span>
      <DropDown
        open={open}
        onChange={onChange}
        list={wallets}
        currencySymbol={currencySymbol}
        setCurrencySymbol={setCurrencySymbol}
        currency={currency}
        setMenuItem={setMenuItem}
      />
    </div>
  );
};

export default CurrencyChange;
