import React from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { Dialog } from '@headlessui/react';
import { useIntl } from 'react-intl';
import Button from '../../../Button';

const Success = ({ handleClose, setStepNumber }) => {
  const { setStatus } = useFormikContext();
  const intl = useIntl();
  return (
    <>
      <div className="w-full">
        <h3 className="mx-auto font-light text-2xl md:text-2.5xl text-purple mt-6 text-center">
          {intl.formatMessage({
            defaultMessage: 'Registration is aborted',
            description: 'Header of the form section',
          })}
        </h3>
        <hr className="border-gray max-w-md w-full mx-auto mt-5.5 mb-7.5" />
      </div>
      <div className="mt-5.5 flex-col flex w-full items-stretch px-2.5">
        <div className="flex flex-col items-center text-purple text-sm max-w-sm w-full mx-auto flex-1 justify-between">
          <ErrorMessage name="formState" />
          <Button.Submit
            trackName="Try again"
            trackParams={{ component: 'Error'}}
            type="button"
            className="w-full mt-7.5"
            size="large"
            onClick={() => {
              setStatus('inProgress');
              setStepNumber(0);
            }}
            shadow={false}
          >
            {intl.formatMessage({
              defaultMessage: 'Try again',
              description: 'Button text',
            })}
          </Button.Submit>

          <Button.Submit
            trackName="Close"
            trackParams={{ component: 'Error'}}
            type="button"
            className="w-full mt-7.5"
            size="large"
            onClick={handleClose}
            shadow={false}
          >
            {intl.formatMessage({
              defaultMessage: 'Close registration',
              description: 'Button text',
            })}

          </Button.Submit>
          {/* <Link onClick={() => previous(values)}>TILBAGE</Link> */}

          {/* {stepNumber > 0 && ( */}
          {/*  <button onClick={() => previous(formik.values)} type="button"> */}
          {/*    Back */}
          {/*  </button> */}
          {/* )} */}

          {/* <button type="submit">{isLastStep ? "Submit" : "Next"}</button> */}
        </div>
      </div>
    </>
  );
};

export default Success;
