import cx from "classnames";

const Label = ({
  children,
  name,
  className,
  hasValue,
  isFocused,
  hasPlaceholder,
}) => (
  <label
    htmlFor={name}
    className={cx(
      "absolute text-12 text-gray top-2 left-3 rtl:left-auto rtl:right-3 capitalize",
      className
      // {
      //   "-translate-y-0 scale-100": !isFocused && !hasValue && !hasPlaceholder
      // },
      // {
      //   "-translate-y-3 scale-75": isFocused || hasValue || hasPlaceholder
      // }
    )}
  >
    {children}
  </label>
);

export default Label;
