import React from "react";
import {connect} from "react-redux";
import {TRACK_EVENTS} from "../constants/Analytics";
import {trackAction} from "../actions/analytics.js";

@connect(null, dispatch => {
  return {
    reportError: (error, info) => {
      dispatch({
        type: "UNCAUGHT_ERROR",
        payload: { status: "error", error: error, info: info }
      });

      dispatch(
        trackAction(TRACK_EVENTS.CLIENTERROR, {
          type: "UNCAUGHT ERROR",
          error: error,
          info: info
        })
      );
    }
  };
})
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    this.props.reportError(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback ?? <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
