import { useTracking } from "@hooks/useTracking";
import hasOwn from "object.hasown";

export const ValidateProfileValuesNeeded = (
  profileFields,
  profileFieldsType,
  userProfile,
  method
) => {
  // Compare the required fields in the context against the currently logged in profile to confirm if all fields are populated.
  const requiredInputFields = [];

  const getPaymentProfileFields = () => {
    let contextObj = [];
    Object.keys(profileFields?.contexts?.payment_systems).map((key) => {
      console.log("ValidateProfileValuesNeeded", key, method?.provider);
      if (key === method?.provider) {
        console.log("ValidateProfileValuesNeeded FOUND", key, method?.provider);
        console.log("ValidateProfileValuesNeeded FOUND method", key, method);
        contextObj =
          profileFields?.contexts?.payment_systems[key][method?.brand]?.deposit;
      }
    });

    return contextObj;
  };

  const setProfileOrder = (fieldName) => {
    switch (fieldName) {
      case "email":
        return { fieldName, orderNo: 1 };
      case "password":
        return { fieldName, orderNo: 2 };
      case "password_confirmation":
        return { fieldName, orderNo: 3 };
      case "first_name":
        return { fieldName, orderNo: 4 };
      case "last_name":
        return { fieldName, orderNo: 5 };
      case "date_of_birth":
        return { fieldName, orderNo: 6 };
      case "gender":
        return { fieldName, orderNo: 7 };
      case "mobile_phone":
        return { fieldName, orderNo: 8 };
      case "country":
        return { fieldName, orderNo: 9 };
      case "currency":
        return { fieldName, orderNo: 10 };
      case "city":
        return { fieldName, orderNo: 11 };
      case "address":
        return { fieldName, orderNo: 12 };
      case "postal_code":
        return { fieldName, orderNo: 13 };
      case "age_acceptance":
        return { fieldName, orderNo: 14 };
      case "receive_promos":
        return { fieldName, orderNo: 15 };
      default:
        return {
          fieldName,
          orderNo: requiredInputFields?.length + 1,
        };
    }
  };

  const { track } = useTracking();
  // Check if the context passed exists in the Profile settings
  let contextObject = null;

  if (profileFieldsType !== "payment") {
    console.log();
    if (hasOwn(profileFields.contexts, profileFieldsType)) {
      const { [profileFieldsType]: contextObj } = profileFields.contexts;
      contextObject = contextObj;

      // To remove once done
      console.log("Profile Fields (Context Fields):", contextObject);
    } else {
      console.log("Failed to get context settings in Profile Dynamic Fields");
      track("Failed to get context settings in Profile Dynamic Fields", {
        contexts: profileFields.contexts,
        profileFieldsType,
      });
      return [];
    }
  } else {
    contextObject = getPaymentProfileFields();
  }

  if (profileFieldsType !== "registration") {
    contextObject?.map((profileField) => {
      console.log("userProfile profileFieldsType", userProfile);
      if (hasOwn(userProfile, profileField)) {
        const { [profileField]: accountField } = userProfile;
        if (accountField === null || accountField === "") {
          requiredInputFields.push(setProfileOrder(profileField));
        }
      } else {
        console.log(
          "Logged in profile does not have mandatory fields available"
        );
        track("Logged in profile does not have mandatory fields available", {
          error: { contextObject, userProfile },
        });
        return [];
      }
    });
  } else {
    contextObject?.map((profileField) => {
      requiredInputFields.push(setProfileOrder(profileField));
    });
  }

  return requiredInputFields;
};
