import { Transition } from "@headlessui/react";

const Hint = ({ children, show }) => (
  <Transition
    show={show}
    enter="transition ease-out duration-500 delay-500"
    enterFrom="transform translate-y-4 opacity-0"
    enterTo="transform opacity-100 translate-y-0"
    leave="transition ease-in duration-700"
    leaveFrom="transform translate-y-0 opacity-100"
    leaveTo="transform translate-y-4 opacity-0"
    unmount={false}
    className="absolute h-6 ltr:left-0 rtl:right-0 -bottom-5"
  >
    <div className="mt-2 mb-4 ml-3 text-xs text-brand-500">{children}</div>
  </Transition>
);

export default Hint;
