import AutobahnReact from '../../core/websocket';
import { getCall, postCall, deleteCall } from '../../core/softswiss';

export const getLimits = () => getCall('/api/user_limits');

export const saveLimit = (config) => postCall('/api/user_limits', { user_limit: config });

export const removeLimit = (id) => deleteCall(`/api/user_limits/${id}`);

export const confirmLimit = (token) => postCall(`/api/user_limits/confirm?token=${token}`);

// return getCall('/api/user_limits');

export const setDepositLimit = ({ period, currency, amount }) => AutobahnReact('everymatrix').call('/user/limit#setDepositLimit', {
  period,
  currency,
  amount,
});

export const removeDepositLimit = ({ period }) => AutobahnReact('everymatrix').call('/user/limit#removeDepositLimit', {
  period,
});

export const setWageringLimit = ({ period, currency, amount }) => AutobahnReact('everymatrix').call('/user/limit#setWageringLimit', {
  period,
  currency,
  amount,
});

export const removeWageringLimit = () => AutobahnReact('everymatrix').call('/user/limit#removeWageringLimit');

export const setLossLimit = ({ period, currency, amount }) => AutobahnReact('everymatrix').call('/user/limit#setLossLimit', {
  period,
  currency,
  amount,
});

export const removeLossLimit = () => AutobahnReact('everymatrix').call('/user/limit#removeLossLimit');

export const setSessionLimit = ({ minutes }) => AutobahnReact('everymatrix').call('/user/limit#setSessionLimit', {
  minutes,
});

export const removeSessionLimit = () => AutobahnReact('everymatrix').call('/user/limit#removeSessionLimit');

export const setMaxStakeLimit = ({ currency, amount }) => AutobahnReact('everymatrix').call('/user/limit#setMaxStakeLimit', {
  currency,
  amount,
});

export const removeMaxStakeLimit = () => AutobahnReact('everymatrix').call('/user/limit#removeMaxStakeLimit');

export const getSelfExclusionConfig = () => AutobahnReact('everymatrix').call('/user/selfExclusion#getCfg');

export const setSelfExclusion = (
  period,
  selfExclusionDate,
) => AutobahnReact('everymatrix').call('/user/selfExclusion#enable', {
  reason: '',
  unsatisfiedReason: '',
  period,
  selfExclusionDate,
});

export const getCoolOffConfig = () => AutobahnReact('everymatrix').call('/user/coolOff#getCfg');

export const setCoolOff = (reason, unsatisfiedReason, period, coolOffDate) => AutobahnReact('everymatrix').call('/user/coolOff#enable', {
  reason,
  unsatisfiedReason,
  period,
  coolOffDate,
});
