import React, { useRef, useState } from "react";
import cx from "classnames";
import Icon from "@components/Icon";
import Link from "@components/Link";
import TopBar from "@components/TopBar";

import SearchGame from "./SearchGame";
import { useModal } from "@components/Modal/ModalContext";
import { useAuth } from "@components/Provide/AuthContext";
import { openNavigation } from "@actions/navigation";
import Button from "@components/Button";
import { useDevice } from "@components/Provide/DeviceContext";
import { useWallet } from "@components/Provide/WalletContext";
import { compileLaunchUrl } from "@data/model/casino";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useRouter } from "../Provide/RouterContext";
// import () from "@components/GameLoader";
import Games from "@components/GameBrowser/Games";
import GameModal from "../GameModal/GameModal";
import { localeUrl } from "../../core/url";
// import s from "./Game.scss";

const Game = ({ game, hideModal=()=>{}, isActive, id }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const { currency } = useWallet();
  const { isAuthenticated, cmsSessionID } = useAuth();
  const router = useRouter();

  const { showModal } = useModal();

  const gameFrame = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const [numEntries, setNumEntries] = useState(1);
  const [currentGame, setCurrentGame] = useState("");

  const [gamesList, setGamesList] = useState([{ ...game }, {}, {}, {}]);
  const fullscreen = () => {
    if (!document.fullscreenElement) {
      gameFrame.current.requestFullscreen();
      setIsFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const onSelect = (gameItem, position) => {
    const newList = [...gamesList];
    newList[position] = gameItem;
    setGamesList(newList);
  };

  const closeItem = (position) => {
    const newList = [...gamesList];
    newList[position] = {};
    setGamesList(newList);
  };

  let gW = 4,
    gH = 3;
  if (gamesList[0]?.collections?.includes?.("_hd")) {
    gW = 16;
    gH = 9;
  }
  if (gamesList[0]?.provider == "ezugi") {
    gW = 48;
    gH = 31;
  }
  if (gamesList[0]?.provider == "gameart") {
    gW = 1209;
    gH = 756;
  }

  return (
    <>
      {/* <Link
        onClick={() => closeItem(i)}
        // to='home'
        className="absolute top-0 right-0 z-50 flex items-center justify-center w-4 h-4 mx-auto rounded-full bg-yellow"
      >
        <Icon icon="close" className="w-6 h-6 fill-current text-purple-dark" />
      </Link> */}

      {isMobile.any ? (
        <div
          className={`relative z-40 h-full min-h-screen bg-brand-600 md:px-10 xl:px-0 md:py-0 py-10`}
        >
          {!isAuthenticated && (
            <div
              className={cx(
                "absolute flex flex-row py-4 z-20 w-full justify-center px-2 "
              )}
              style={{ background: `rgba(0, 0, 0, 0.4)` }}
            >
              <span className="m-auto text-xl font-bold text-white">
                {intl.formatMessage({
                  defaultMessage: "You are playing for fun",
                  description: "Info on game page if playing for fun.",
                })}
              </span>
              <Button.Action
                trackName="Play for real"
                trackParams={{ component: "GameRoute" }}
                onClick={() => {
                  // hideModal();
                  dispatch(
                    openNavigation("Auth", {
                      hideCTAsProp: true,
                      onSuccess: () =>
                        showModal(GameModal, {
                          game: game,
                          url: localeUrl("game", intl.locale, { id: game.id }),
                          id: game.id,
                        }),
                    })
                  );
                }}
                arrow="right"
                className="mx-2 mr-20"
              >
                {intl.formatMessage({
                  defaultMessage: "Play for real",
                  description: "Label on button to start playing for real",
                })}
              </Button.Action>
              <span
                trackName="Close"
                trackParams={{ component: "Game", game: game }}
                onClick={hideModal}
                className="flex items-start justify-center w-12 h-8 mt-3 mr-0 transition-all duration-150 cursor-pointer"
              >
                <Icon icon="close" className="w-6 h-6 fill-current text-gold" />
              </span>
            </div>
          )}
          {isAuthenticated && (
            <span
              trackName="Close"
              trackParams={{ component: "Game", game: game }}
              onClick={hideModal}
              className="absolute z-50 cursor-pointer top-1 right-5 lg:right-4 lg:top-4"
            >
              <Icon
                icon="close"
                className="w-5 h-5 fill-current md:w-6 md:h-6 text-gold"
              />
            </span>
          )}
          <iframe
            src={compileLaunchUrl(game, isAuthenticated, currency, "")}
            marginheight="0"
            marginwidth="0"
            width="100%"
            height="100%"
            isFullscreen
            allowFullScreen
            scrolling="auto"
            frameBorder="0"
            id="gameFrame"
            className="z-40"
          />
        </div>
      ) : (
        <div
          ref={gameFrame}
          className={cx(
            "flex w-full flex-col bg-cover bg-no-repeat bg-brand min-h-screen",
            {
              "bg-brand": !isMobile.any && !game?.backgroundImageUrl,
            }
          )}
          style={{
            backgroundImage:
              !isMobile.any &&
              game?.backgroundImageUrl &&
              `url('${game?.backgroundImageUrl}')`,
            height: `calc(100vh - 7rem)`,
          }}
        >
          {!isFullscreen && (
            <TopBar topbarPosition="relative" clickFromGame={hideModal} />
          )}

          <div className="flex flex-col items-center justify-center flex-1 w-full">
            <div
              style={{
                maxWidth:
                  !isMobile.any &&
                  `calc((100vh - ${false ? "20.879rem" : "10rem"})*${
                    (game?.width ?? 800) / (game?.height ?? 600)
                  })`,
              }}
              className="relative z-0 flex flex-col items-start justify-start w-full"
            >
              {game && (
                <div
                  className={cx(
                    "flex flex-row items-start justify-start absolute bottom-0  right-0 w-full h-full z-10  ",
                    { "pt-18": !isAuthenticated }
                  )}
                >
                  <div
                    className={cx("w-full h-full relative", {
                      "grid grid-cols-2 gap-8": numEntries > 1,
                    })}
                  >
                    {!isAuthenticated && (
                      <div
                        className="absolute z-20 flex flex-row justify-center w-full py-4 "
                        style={{ background: `rgba(0, 0, 0, 0.3)` }}
                      >
                        <span className="text-2xl font-bold text-white">
                          {intl.formatMessage({
                            defaultMessage: "You are playing for fun",
                            description:
                              "Info on game page if playing for fun.",
                          })}
                        </span>
                        <Button.Action
                          trackName="Play for real"
                          trackParams={{ component: "Game" }}
                          onClick={() => {
                            // hideModal();
                            dispatch(
                              openNavigation("Auth", {
                                onSuccess: () =>
                                  showModal(GameModal, {
                                    game: game,
                                    url: localeUrl("game", intl.locale, {
                                      id: game.id,
                                    }),
                                    id: game.id,
                                  }),
                              })
                            );
                          }}
                          arrow="right"
                          className="ml-12"
                        >
                          {intl.formatMessage({
                            defaultMessage: "Play for real",
                            description:
                              "Label on button to start playing for real",
                          })}
                        </Button.Action>
                      </div>
                    )}
                    {[...Array(numEntries)].map((_, i) => {
                      if (game.id) {
                        return (
                          !!game?.id && (
                            <div
                              className={cx(
                                "h-full w-full bg-purple-dark border-6 md:border-purple-dark relative"
                              )}
                              key={`game_${i}`}
                            >
                              {numEntries > 1 && i > 0 && (
                                <span
                                  trackName="Close"
                                  trackParams={{
                                    component: "Game",
                                    game: i,
                                  }}
                                  onClick={() => closeItem(i)}
                                  // to='home'
                                  className="absolute top-0 z-10 flex items-center justify-center w-4 h-4 mx-auto rounded-full right-5 bg-yellow"
                                >
                                  <Icon
                                    icon="close"
                                    className="w-2 h-2 text-gold"
                                  />
                                </span>
                              )}
                              <iframe
                                src={compileLaunchUrl(
                                  game,
                                  isAuthenticated,
                                  currency,
                                  cmsSessionID
                                )}
                                // src={compileLaunchUrl(gamesList[i], isAuthenticated, currency, cmsSessionID)}
                                id="gameFrame"
                                className={cx("h-full w-full")}
                                // onLoad={this.loaded}
                              />
                            </div>
                          )
                        );
                      }
                      return (
                        <div
                          className={cx(
                            "h-full w-full bg-purple-dark border-6 md:border-purple-dark flex items-center justify-center"
                          )}
                        >
                          <SearchGame
                            onSelect={(gameItem) => onSelect(gameItem, i)}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex-col hidden md:flex ">
                    <span
                      trackName="Close"
                      trackParams={{ component: "Game", game: game }}
                      onClick={hideModal}
                      // params={parametersForNav()}
                      // to="home"
                      className="flex items-center justify-center w-12 h-12 transition-all duration-150 cursor-pointer bg-purple-darkest hover:bg-purple-dark"
                    >
                      <Icon icon="close" className="w-5 h-5 text-white" />
                    </span>
                    <Link
                      trackName="Fullscreen"
                      trackParams={{ component: "Game", game: game }}
                      onClick={fullscreen}
                      className="flex items-center justify-center w-12 h-12 transition-all duration-150 bg-purple-darkest hover:bg-purple-dark"
                    >
                      <Icon
                        icon={isFullscreen ? "screenOriginal" : "fullscreen"}
                        className="text-white h-7 w-7"
                      />
                    </Link>
                  </div>
                </div>
              )}

              <div
                style={{
                  paddingTop: `${
                    ((game?.height ?? 600) / (game?.width ?? 800)) * 100
                  }%`,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Game;
