import React from "react";
import { Form, Formik, useFormikContext } from "formik";
import { Dialog } from "@headlessui/react";
import { useIntl } from "react-intl";
import Button from "../../../Button";
import Link from "@components/Link";
import hero from "../../../LandingOffer/assets/img/hero.png";
import { PasswordField, SelectBox, TextField } from "../../../Formik";
import { useDispatch } from "react-redux";
import { useRouter } from "../../../Provide/RouterContext";
import useSWR from "swr";
import { getIpInfo } from "../../../../data/model/session";
import { useQuery } from "@apollo/client";
import getCurrenciesQuery from "../../../LandingOffer/get-currencies.graphql";
import getCountriesQuery from "../../../LandingOffer/get-countries.graphql";
import { template } from "../../../../core/utils";
import getPromotionBlockQuery from "../../../LandingOffer/get-promotion-block.graphql";
import { useDevice } from "../../../Provide/DeviceContext";
import { useWallet } from "../../../Provide/WalletContext";

const WelcomePromo = ({ handleClose }) => {
  const { isMobile } = useDevice();
  const intl = useIntl();
  const router = useRouter();
  const { currency } = useWallet();
  const {
    loading,
    error,
    data: promotionBlocks,
  } = useQuery(getPromotionBlockQuery, {
    variables: {
      locale: intl.locale,
      route: router.routename,
      isAuthenticated: false,
    },
  });

  const promoBlock = promotionBlocks?.PromotionBlock?.find(
    (item) => item.component === "PromotionBlockRegistrationStep2"
  );
  const newLocale = intl.locale === "en-us" ? "en-row" : intl.locale;

  if (!promoBlock) {
    return null;
  }

  let offer = promoBlock?.settings?.offer ?? "";
  const paramsPattern = /[^{\}]+(?=})/g;
  let extractParams = offer.match(paramsPattern);

  extractParams?.map((item) => {
    let replaceWith = "";
    const [func, value] = item.split("|");
    switch (func) {
      case "currency":
        replaceWith = intl.formatNumber(value, {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
    }
    offer = offer.replace(`{${item}}`, replaceWith);
  });
  const backroundImg = isMobile?.any
    ? promoBlock?.settings?.backgroundMobile?.path
    : promoBlock?.settings?.backgroundDesktop?.path;

  const imgUrl = `url(https://cockpit-ch.madmenmt.com/${backroundImg})`;

  return promoBlock?.settings?.offer ||
    promoBlock?.settings?.header ||
    backroundImg ? (
    <section
      className={`z-0 mt-5 relative w-full flex flex-col justify-start items-center p-3 bg-center bg-cover ${
        backroundImg ? "bg-brand-500 h-40" : "-mt-7 h-22 pl-0"
      }`}
      style={{
        backgroundPosition: "top right",
        backgroundImage: `${backroundImg ? imgUrl : ""}`,
      }}
    >
      <div className="z-20 w-full">
        {promoBlock?.settings?.header && (
          <span className="font-bold text-white uppercase">
            {" "}
            {promoBlock?.settings?.header}
          </span>
        )}
        {promoBlock?.settings?.offer && (
          <div
            className="mt-3 font-bold leading-none text-white text-3xl"
            dangerouslySetInnerHTML={{ __html: offer }}
          />
        )}
        {promoBlock?.settings?.termsLine && backroundImg && (
          <Link
            trackName="Promoblock"
            trackParams={{ component: "WelcomePromo", item: promoBlock }}
            href={template(promoBlock?.settings?.termsLink, {
              locale: newLocale,
            })}
            className="mt-3 block text-12 text-gray"
          >
            {promoBlock?.settings?.termsLine}
          </Link>
        )}
      </div>
    </section>
  ) : (
    <></>
  );
};

export default WelcomePromo;
