import React from "react";
import cx from "classnames";
import sadface from "./assets/sad-face.png";
import Button from "../Button";
import Logo from "../Logo";

const ErrorOccurred = ({ router }) => (
  <div className="w-full px-4 m-auto -mt-16 font-light text-center text-white  bg-brand md:container md:-mt-20 md:px-0">
    {/* <div className="col-span-6 md:col-span-4 md:text-center"> */}
    <Logo
      classNameLogo={cx(" inline-block h-16 mb-10")}
      alt="ClubHouse casino best casino"
      align="center"
    />
    {/* <img src="/images/mobile-logo.png" className="md:hidden" /> */}
    {/* </div> */}
    <img src={sadface} className="w-20 h-20 m-auto my-10" />
    <p className="text-3xl">Sorry!</p>
    <p className="mt-6">
      We currently have maintenance. Please check back later.
    </p>
    <p className="mt-3">For any questions, please contact us.</p>

    <Button.Action
      trackName="Contact us"
      trackParams={{ component: "NoAccess" }}
      className="inline-flex items-center justify-between w-16 w-full m-auto mt-10 md:w-80 btn"
      type="submit"
      arrow="right"
      onClick={() => {
        window.LC_API.open_chat_window();
      }}
    >
      Contact us{" "}
    </Button.Action>
  </div>
);

export default ErrorOccurred;
