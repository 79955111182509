import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import cx from "classnames";
import { defineMessages, useIntl } from "react-intl";
import {
  getLimits,
  removeLimit,
  saveLimit,
} from "@data/model/responsible-gaming";
import SubmitFeedback from "@components/Formik/SubmitFeedback";
import useSWR from "swr";
import { Transition } from "@headlessui/react";
import Button from "../../../Button";
import { useWallet } from "../../../Provide/WalletContext";
import { SelectBox, TextField } from "../../../Formik";
import { useRouter } from "../../../Provide/RouterContext";
import deleteIcon from "../../../../../public/delete-icon.png";
import { useTracking } from "@hooks/useTracking";
import {
  getCurrencies,
  getGamingAccounts,
} from "../../../../data/model/wallet";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { useAuth } from "../../../Provide/AuthContext";

const types = {
  SetDepositLimit: "deposit",
  SetWagerLimit: "wager",
  SetLossLimit: "loss",
};
const { track } = useTracking();

const messages = defineMessages({
  deposit: {
    defaultMessage:
      "Control the amount you can deposit into your Clubhouse Casino account every day, week or month.",
    description: "A label for the deposit limit",
  },
  wager: {
    defaultMessage:
      "Control the amount you are able to wager every day, week or month.",
    description: "A label for the wager limit",
  },
  loss: {
    defaultMessage:
      "Control the amount you are able to lose every day, week or month.",
    description: "A label for the loss limit",
  },
});

const SetPeriodAmountLimit = ({
  section,
  setMenuItem,
  setBackActions,
  setSection,
}) => {
  useEffect(
    () =>
      setBackActions((prevState) => {
        prevState.push((x) => {
          setSection("Main");
          setMenuItem("ResponsibleGaming");
        });
        return prevState;
      }),
    []
  );

  const { currency } = useWallet();

  const intl = useIntl();
  const [isSettingALimit, setIsSettingALimit] = useState(false);
  const [currentLimits, setCurrentLimits] = useState([]);
  const selectedType = types[section];
  const { error, data } = useSWR("getLimits", getLimits);
  const router = useRouter();
  const [hasUncomfirmed, setHasUncomfirmed] = useState(false);
  const [isInactiveOrDisabled, setIsInactiveOrDisabled] = useState(false);
  const { data: currencies } = useSWR("getCurrencies", getCurrencies);
  const [limitInList, setLimitsInList] = useState([]);
  const [periodFilter, setPeriodFilter] = useState("day");
  const [newPeriodFilter, setNewPeriodFilter] = useState("day");
  const [submissionError, setSubmissionError] = useState("");
  const { isAuthenticated } = useAuth();
  // console.log("data--data--data--", data);
  // console.log("limitInList--limitInList--limitInList--", limitInList);
  const messageType = (type) => {
    switch (type) {
      case "deposit":
        return intl.formatMessage({
          defaultMessage: "deposit",
          description: "Label",
        });
      case "wager":
        return intl.formatMessage({
          defaultMessage: "wager",
          description: "Label",
        });
      case "loss":
        return intl.formatMessage({
          defaultMessage: "loss",
          description: "Label",
        });
    }
  };

  const { data: personalwallets } = useSWR(
    isAuthenticated ? "/api/player/accounts" : null,
    getGamingAccounts,
    { refreshInterval: 10000 }
  );
  const activeWallets = personalwallets?.filter((item) => item.active === true);

  const wagersListTranslationsList = Object.keys(activeWallets)?.map((slug) => {
    return { id: "amount" + slug };
  });

  console.log("activeWallets", activeWallets);

  let currentWallet = currencies?.filter((cur) => cur?.code === currency);

  useEffect(() => {
    const current = data?.filter((item) => item.type === selectedType);
    if (current) {
      setCurrentLimits(current);
    }
  }, [data]);

  useEffect(() => {
    currentLimits?.map(
      (item) => (
        item?.status === "unconfirmed" && setHasUncomfirmed(true),
        (item.status === "confirmed - inactive limit" ||
          item.status === "confirmed_disable - active limit") &&
          setIsInactiveOrDisabled(true)
      )
    );

    getPeriodLimits();
  }, [currentLimits, periodFilter]);

  const remove = (id, formikBag) => {
    const { setStatus, setFieldError, setSubmitting, setFieldValue } =
      formikBag;
    setFieldValue("action", "remove");
    removeLimit(id)
      .then(() => {
        track("Amount Limit removal success", { id: id });
        setStatus("success");
        setSubmitting(false);
      })
      .catch((err) => {
        track("Amount Limit removal error", { error: error });
        setSubmitting(false);
        setFieldError("formState", err.desc);
        setStatus("error");
      });
  };

  const returnAmount = (values, index) => {
    let amtPropIndicator = "amount" + index;
    if (values.hasOwnProperty(amtPropIndicator)) {
      return values[amtPropIndicator];
    }
  };

  const validateAmount = (values) => {
    let error = "error";

    let total = 0;
    activeWallets.forEach((item, index) => {
      let amount = returnAmount(values, index);
      console.log("this is the amount returned", amount);
      if (amount > 0) {
        total += amount;
        error = "";
      }
    });

    if (total > 0) {
      error = "";
      return false;
    }

    if (values?.amount?.length > 0) {
      error = "";
      return false;
    }

    if (error?.length > 0) {
      return true;
    }

    return true;
  };

  const handleSubmit = (values, formikBag) => {
    const { setStatus, setFieldError, setSubmitting, setFieldValue } =
      formikBag;

    let errorList = [];
    let error = "You must enter a value more than 0.";
    try {
      if (activeWallets?.length > 0) {
        let totalLimits = [];
        activeWallets.forEach((item, index) => {
          let thisWallet = currencies?.filter(
            (cur) => cur?.code === item.currency
          );
          let amount = returnAmount(values, index);
          if (amount > 0) {
            totalLimits.push({
              currency: item.currency,
              amount_cents: amount * thisWallet[0].subunits_to_unit,
            });
          }
        });

        console.log("these are the total limits", totalLimits);

        if (totalLimits?.length) {
          setFieldValue("action", "add");
          saveLimit({
            period: values.limit.name,
            type: selectedType,
            accounts: totalLimits,
          })
            .then((respnse) => {
              console.log("current error catch ", respnse);
              track("Amount Limit added success", values);
              // setStatus('success');
              // setSubmitting(false);
              // means a value was more than 0
              error = "";
            })
            .catch((err) => {
              console.log("current error catch ", err);
              track("Amount Limit added error", { error: err });
              setSubmitting(false);
              setFieldError("formState", err.desc);
              setStatus("error");
              errorList.push(err);
            });
        }
        // })
      } else {
        let amount = values?.amount;
        let thisWallet = currencies?.filter((cur) => cur?.code === currency);
        setFieldValue("action", "add");
        saveLimit({
          period: values.limit.name,
          type: selectedType,
          accounts: [
            {
              currency: currency,
              amount_cents: amount * thisWallet[0].subunits_to_unit,
            },
          ],
        })
          .then((respnse) => {
            console.log("current error catch ", respnse);
            track("Amount Limit added success", values);
            // setStatus('success');
            // setSubmitting(false);
            // means a value was more than 0
            error = "";
          })
          .catch((err) => {
            console.log("current error catch ", err);
            track("Amount Limit added error", { error: err });
            setSubmitting(false);
            setFieldError("formState", err.desc);
            setStatus("error");
            errorList.push(err);
          });
      }
    } catch (e) {
      console.log("current error catch ", e);
    }

    if (!errorList.length > 0 && error.length > 0) {
      setStatus("success");
      setSubmitting(false);
    } else {
      setSubmissionError(error);
      setStatus("error");
      setSubmitting(false);
    }
  };

  const getPeriodLimits = () => {
    setLimitsInList([]);
    let allLimits = [];
    let limits = [];
    currentLimits?.map(
      (item) =>
        item.period === periodFilter &&
        item.accounts?.filter((subitem) =>
          allLimits.push({
            subitem,
            status: item.status,
            type: item.type,
            period: item.period,
            id: item.id,
            effectiveFrom: item?.confirm_until ? item.confirm_until : null,
          })
        )
    );
    activeWallets?.map((wallet) =>
      allLimits?.filter(
        (item) => wallet.currency === item.subitem.currency && limits.push(item)
      )
    );

    setLimitsInList(allLimits);
  };

  const calculateLimitLeftForDeposit = (
    used,
    total,
    curr,
    calculateWidth = false
  ) => {
    if (calculateWidth) {
      if (used === 0 && total === 0) {
        return "100%";
      }
      let width = (used * 100) / total;
      return `${width}%`;
    }

    let thisWallet = currencies?.filter((cur) => cur?.code === curr);

    total = total / thisWallet?.[0]?.subunits_to_unit;
    used = used / thisWallet?.[0]?.subunits_to_unit;

    let usedCurr = intl.formatNumber(total - used, {
      style: "currency",
      currency: curr,
      minimumFractionDigits:
        thisWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
      maximumFractionDigits:
        thisWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
    });

    let totalCurr = intl.formatNumber(total, {
      style: "currency",
      currency: curr,
      minimumFractionDigits:
        thisWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
      maximumFractionDigits:
        thisWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
    });

    return `${usedCurr} ${intl.formatMessage({
      defaultMessage: "of",
      description: "Label",
    })} ${totalCurr} ${curr} ${intl.formatMessage({
      defaultMessage: "left",
      description: "Label",
    })}`;
  };

  const calculateLimitLeft = (used, total, curr, calculateWidth = false) => {
    //used parameter is being retrieved by a negative symbol, therefor we are multiplying it by -1
    if (calculateWidth) {
      if (used === 0 && total === 0) {
        return "100%";
      }
      let width = (used * -1 * 100) / total;
      return `${width}%`;
    }

    let thisWallet = currencies?.filter((cur) => cur?.code === curr);

    total = total / thisWallet?.[0]?.subunits_to_unit;
    used = used / thisWallet?.[0]?.subunits_to_unit;

    let usedCurr = intl.formatNumber(total - used * -1, {
      style: "currency",
      currency: curr,
      minimumFractionDigits:
        thisWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
      maximumFractionDigits:
        thisWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
    });

    let totalCurr = intl.formatNumber(total, {
      style: "currency",
      currency: curr,
      minimumFractionDigits:
        thisWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
      maximumFractionDigits:
        thisWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
    });

    return `${usedCurr} ${intl.formatMessage({
      defaultMessage: "of",
      description: "Label",
    })} ${totalCurr} ${curr} ${intl.formatMessage({
      defaultMessage: "left",
      description: "Label",
    })}`;
  };

  const getNewPeriodAmount = () => {
    switch (newPeriodFilter) {
      case "day":
        return intl.formatMessage({
          defaultMessage: "daily",
          description: "Label",
        });

      case "week":
        return intl.formatMessage({
          defaultMessage: "weekly",
          description: "Label",
        });

      case "month":
        return intl.formatMessage({
          defaultMessage: "monthly",
          description: "Label",
        });
    }
  };

  const generateAllSubmittedAmounts = (values) => {
    let totalAmounts = [];
    activeWallets.forEach((item, index) => {
      let amount = returnAmount(values, index);
      if (amount > 0 && amount !== "") {
        let thisWallet = currencies?.filter(
          (cur) => cur?.code === item.currency
        );
        totalAmounts.push(
          intl.formatNumber(amount, {
            style: "currency",
            currency: item.currency,
            minimumFractionDigits:
              thisWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
            maximumFractionDigits:
              thisWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
          }) + " "
        );
      }
    });

    return totalAmounts;
  };

  const getDividedCurrency = (curr) => {
    let wallet = currencies?.filter((cur) => cur?.code === curr);
    return wallet[0]?.subunits_to_unit;
  };

  const calculateDecimalPlaces = (curr) => {
    let thisWallet = currencies?.filter((cur) => cur?.code === curr);
    return thisWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2;
  };

  const limitStatus = (status, item) => {
    switch (status) {
      case "unconfirmed_disable":
        return intl.formatMessage({
          defaultMessage: "Awaiting deletion verification",
          description: "Label",
        });

      case "confirmed_disable":
        return intl.formatMessage(
          {
            defaultMessage: "Disable confirmed effective from {date}",
            description: "Label",
          },
          {
            date: format(
              parseISO(item?.effectiveFrom),
              "dd MMMM yyyy, HH:mm:ss"
            ),
          }
        );

      default:
        return intl.formatMessage(
          {
            defaultMessage: "{status}",
            description: "Label",
          },
          { status: status }
        );
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialStatus="init"
        // validationSchema={WalletAmountSchema(intl)}
        initialValues={
          (wagersListTranslationsList,
          {
            action: "",
            limit: {
              name: "day",
              text: intl.formatMessage({
                defaultMessage: "Limit per day",
                description: "Label of selectbox",
              }),
            },
            limitPeriod: {
              name: "day",
              text: intl.formatMessage({
                defaultMessage: "View daily limits",
                description: "Label of selectbox",
              }),
            },
            type: types[section],
          })
        }
        onSubmit={handleSubmit}
      >
        {({
          status,
          values,
          isSubmitting,
          setStatus,
          setFieldError,
          setSubmitting,
          setFieldValue,
          isValid,
          ...actions
        }) => (
          <Form>
            <h4 className="mb-16 text-2xl font-light leading-5 text-center text-white capitalize  sticky top-0 bg-[#13161d] max-w-full ms-auto me-auto pt-0 pb-[11px] z-[9] ml-0">
              {intl.formatMessage(
                {
                  defaultMessage: "{type} limit",
                  description: "Header of section",
                },
                { type: messageType(selectedType) }
              )}
            </h4>
            <Transition
              show={status === "init"}
              leave="transition ease-in duration-500"
              leaveFrom="transform opacity-100"
              leaveTo="transform -translate-x-80 opacity-0"
              className="flex flex-col w-full"
              unmount={false}
            >
              {!isSettingALimit ? (
                <div className="mx-auto mt-5.5 flex-col flex w-full items-stretch px-2.5 mb-10 md:mb-0 rtl:text-right">
                  {/* <ErrorMessage name="submitError">
                    {(msg) => <div className="mt-8 text-sm text-red">{msg}</div>}
                  </ErrorMessage> */}
                  <span className="">
                    {selectedType && intl.formatMessage(messages[selectedType])}
                  </span>

                  <div className="flex w-full space-y-2.5 mt-7 flex-col mb-4">
                    <div>
                      <SelectBox
                        name="limitPeriod"
                        onChange={(value) => setPeriodFilter(value?.name)}
                        options={[
                          {
                            name: "day",
                            text: intl.formatMessage({
                              defaultMessage: "View daily limits",
                              description: "Label of selectbox",
                            }),
                          },
                          {
                            name: "week",
                            text: intl.formatMessage({
                              defaultMessage: "View weekly limits",
                              description: "Label of selectbox",
                            }),
                          },
                          {
                            name: "month",
                            text: intl.formatMessage({
                              defaultMessage: "View monthly limits",
                              description: "Label of selectbox",
                            }),
                          },
                        ]}
                        className="mb-4"
                        hint={intl.formatMessage({
                          defaultMessage: "Play responsibly and set a limit.",
                          description: "Hint of inputfield",
                        })}
                      />
                    </div>
                    {limitInList?.length > 0 && (
                      <>
                        {limitInList?.map((item) => (
                          <div className="mx-2">
                            {item.status === "active" ? (
                              <div>
                                <div className="flex flex-row items-center justify-between ">
                                  <h1 className="text-xl font-bold">
                                    {item.type === "deposit"
                                      ? calculateLimitLeftForDeposit(
                                          item?.subitem
                                            ?.current_value_amount_cents,
                                          item?.subitem?.amount_cents,
                                          item?.subitem?.currency
                                        )
                                      : calculateLimitLeft(
                                          item?.subitem
                                            ?.current_value_amount_cents,
                                          item?.subitem?.amount_cents,
                                          item?.subitem?.currency
                                        )}
                                  </h1>
                                  <div className="flex w-auto px-2 font-bold text-right">
                                    <span
                                      className="w-5 cursor-pointer"
                                      onClick={() =>
                                        remove(item.id, {
                                          setStatus,
                                          setFieldError,
                                          setSubmitting,
                                          setFieldValue,
                                        })
                                      }
                                    >
                                      <img className="h-5 " src={deleteIcon} />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                {item.status === "unconfirmed" ||
                                item.status === "unconfirmed_disable" ||
                                item.status === "confirmed_disable" ? (
                                  <h1 className="text-xl font-bold">
                                    {intl.formatNumber(
                                      item?.subitem?.amount_cents /
                                        getDividedCurrency(
                                          item?.subitem?.currency
                                        ),
                                      {
                                        style: "currency",
                                        currency: item?.subitem?.currency,
                                        minimumFractionDigits:
                                          calculateDecimalPlaces(
                                            item?.subitem?.currency
                                          ),
                                        maximumFractionDigits:
                                          calculateDecimalPlaces(
                                            item?.subitem?.currency
                                          ),
                                      }
                                    )}
                                  </h1>
                                ) : (
                                  <h1 className="text-xl font-bold">
                                    {intl.formatNumber(0, {
                                      style: "currency",
                                      currency: item?.subitem?.currency,
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                    })}
                                  </h1>
                                )}
                              </div>
                            )}

                            <div className="py-1">
                              {item.status === "active" && (
                                <>
                                  <span className="text-sm text-gray">
                                    {intl.formatMessage({
                                      defaultMessage: "Resets on ",
                                      description: "Label",
                                    })}{" "}
                                    {format(
                                      parseISO(item?.subitem?.active_until),
                                      "dd MMMM yyyy, HH:mm:ss"
                                    )}
                                  </span>
                                  <div className="relative">
                                    <div className="w-full h-2 my-2 rounded bg-gray" />
                                    <div
                                      className="absolute h-2 my-2 rounded bg-green -bottom-2"
                                      style={{
                                        width: `${
                                          item.type === "deposit"
                                            ? calculateLimitLeftForDeposit(
                                                item?.subitem
                                                  ?.current_value_amount_cents,
                                                item?.subitem?.amount_cents,
                                                null,
                                                true
                                              )
                                            : calculateLimitLeft(
                                                item?.subitem
                                                  ?.current_value_amount_cents,
                                                item?.subitem?.amount_cents,
                                                null,
                                                true
                                              )
                                        }`,
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>

                            <div className="flex flex-row mb-4">
                              <div
                                className={cx(
                                  "h-3 w-3 bg-gray rounded-full m-auto",
                                  { "bg-green": item?.status === "active" },
                                  {
                                    "bg-warning":
                                      item?.status === "unconfirmed",
                                  },
                                  {
                                    "bg-warning":
                                      item?.status === "unconfirmed_disable",
                                  },
                                  {
                                    "bg-warning":
                                      item?.status === "confirmed_disable",
                                  }
                                )}
                              ></div>
                              <span
                                className={cx(
                                  "capitalize w-full text-sm text-gray pl-2",
                                  {
                                    "normal-case":
                                      item?.status === "confirmed_disable" ||
                                      item?.status === "unconfirmed_disable",
                                  }
                                )}
                              >
                                {" "}
                                {limitStatus(item?.status, item)}
                              </span>
                            </div>
                          </div>
                        ))}
                      </>
                    )}

                    <Button.Action
                      trackName="Add new Period Amount Limit"
                      trackParams={{ component: "SetPeriodAmountLimit" }}
                      className={cx("w-full", {
                        "animate-pulse": isSubmitting,
                      })}
                      arrow="right"
                      onClick={() => setIsSettingALimit(true)}
                    >
                      {intl.formatMessage({
                        defaultMessage: "Add new",
                        description: "The label on a button for saving",
                      })}
                    </Button.Action>
                  </div>
                </div>
              ) : (
                <div className="mx-auto mt-5.5 flex-col flex w-full items-stretch px-2.5 mb-10 md:mb-0 rtl:text-right">
                  <span className="">
                    {intl.formatMessage(
                      {
                        defaultMessage: "Set a new {type} limit",
                        description: "Setting section limit",
                      },
                      { type: messageType(selectedType) }
                    )}
                  </span>

                  <div className="mt-4">
                    <SelectBox
                      name="limit"
                      onChange={(value) => setNewPeriodFilter(value?.name)}
                      // label={intl.formatMessage({
                      //   defaultMessage: 'Period',
                      //   description: 'Label of inputfield',
                      // })}
                      options={[
                        {
                          name: "day",
                          text: intl.formatMessage({
                            defaultMessage: "Limit per day",
                            description: "Label of selectbox",
                          }),
                        },
                        {
                          name: "week",
                          text: intl.formatMessage({
                            defaultMessage: "Limit per week",
                            description: "Label of selectbox",
                          }),
                        },
                        {
                          name: "month",
                          text: intl.formatMessage({
                            defaultMessage: "Limit per month",
                            description: "Label of selectbox",
                          }),
                        },
                      ]}
                      className="w-full mb-4"
                      hint={intl.formatMessage({
                        defaultMessage: "Play responsibly and set a limit.",
                        description: "Hint of inputfield",
                      })}
                    />
                  </div>

                  {hasUncomfirmed && (
                    <div className="my-4 text-base text-red-error ">
                      {intl.formatMessage({
                        defaultMessage:
                          "You have requested a change to your session limit that you have not yet confirmed. Please open the link in the email we have sent to confirm your new limit.",
                        description: "Label ",
                      })}
                    </div>
                  )}

                  {activeWallets?.map((item, index) => (
                    <div>
                      <div className="relative w-full">
                        <TextField
                          className="mt-4 "
                          name={"amount" + index}
                          label={item?.currency}
                          type="text"
                          placeholder="0"
                          inputClassName=" pr-20 text-right"
                        />
                        <div className="absolute z-10 w-16 h-6 overflow-hidden text-right right-3 bottom-2 whitespace-nowrap">
                          {getNewPeriodAmount()}
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* {isInactiveOrDisabled && (
                    <div>{intl.formatMessage({
                      defaultMessage: 'You have a pending update of your Session limit. This will come into effect in {hours} hours and {minutes} minutes',
                      description: 'Label '
                      }, {hours: Math.floor(item.period / 60)},
                        {minutes: item.period % 60} )}
                    </div>
                  )} */}

                  {/* <div className="relative mt-4">
                    
                    <div className="w-1/2">
                      <TextField
                        className="mr-4 "
                        name="amount"
                        label={intl.formatMessage({
                          defaultMessage: 'Amount',
                          description: 'The placeholder for the amount field',
                        })}
                        type="text"
                      />
                    </div>
                    
                  </div> */}
                  {/* {currency} */}
                  <div className="flex flex-row my-8">
                    {console.log("these are the current values", values)}
                    <Button.Action
                      trackName="Submit Period Amount Limit"
                      trackParams={{ component: "SetPeriodAmountLimit" }}
                      className={cx("w-full", {
                        "animate-pulse": isSubmitting,
                      })}
                      disabled={!!isSubmitting || validateAmount(values)}
                      type="submit"
                      arrow="right"
                    >
                      {isSubmitting
                        ? intl.formatMessage({
                            defaultMessage: "Saving...",
                            description:
                              "When submitting a button this generic terms is used while waiting",
                          })
                        : intl.formatMessage({
                            defaultMessage: "Set limits",
                            description: "The label on a button for saving",
                          })}
                    </Button.Action>
                  </div>

                  {submissionError.length > 0 && (
                    <div className="my-4 text-base text-red-error ">
                      {submissionError}
                    </div>
                  )}
                </div>
              )}
            </Transition>

            {values?.action === "add" && (
              <SubmitFeedback
                status={status}
                header={intl.formatMessage({
                  defaultMessage: "Confirmed",
                  description: "The feedback header",
                })}
              >
                <div className="p-8 leading-7 text-24">
                  {intl.formatMessage(
                    {
                      defaultMessage:
                        "You’ve set a {amount} per {period} {type} limit.",
                      description: "The feedback after setting a limit",
                    },
                    {
                      period: (
                        <span className="font-bold text-green">
                          {values.limit.name}
                        </span>
                      ),
                      type: (
                        <span className="font-bold text-green">
                          {selectedType}
                        </span>
                      ),
                      amount: (
                        <span className="font-bold text-green">
                          {generateAllSubmittedAmounts(values)}
                          {/* {intl.formatNumber(values.amount, {
                      style: 'currency',
                      currency,
                      minimumFractionDigits: (currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1) ?? 2,
                      maximumFractionDigits: (currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1) ?? 2,
                    })} */}
                          {/* {intl.formatNumber(0, {
                      style: 'currency',
                      currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                      .replace(/\d/g, '')
                      .trim()}
                    {values.amount} */}
                        </span>
                      ),
                    }
                  )}
                </div>
                <div className="px-8">
                  <Button.Action
                    trackName="Back to account from Period Amount Limit"
                    trackParams={{ component: "SetPeriodAmountLimit" }}
                    onClick={() => setMenuItem("Root")}
                    className="w-full"
                    arrow="right"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Back to my account",
                      description: "Label",
                    })}
                  </Button.Action>
                </div>
              </SubmitFeedback>
            )}
            {values?.action === "remove" && (
              <SubmitFeedback
                status={status}
                header={intl.formatMessage({
                  defaultMessage: "Confirmed",
                  description: "The feedback header",
                })}
              >
                <div className="p-8 leading-7 text-24">
                  {intl.formatMessage({
                    defaultMessage:
                      "Your limit will be removed after a 24 hour grace period from confirming limit removal via email link.",
                    description: "The feedback after setting a limit",
                  })}
                </div>
                <div className="px-8">
                  <Button.Action
                    trackName="Back to account from Period Amount Limit"
                    trackParams={{ component: "SetPeriodAmountLimit" }}
                    onClick={() => setMenuItem("Root")}
                    className="w-full"
                    arrow="right"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Back to my account",
                      description: "Label",
                    })}
                  </Button.Action>
                </div>
              </SubmitFeedback>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SetPeriodAmountLimit;
