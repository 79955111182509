import React, { memo, useState } from "react";
import { useField } from "formik";
import cx from "classnames";
import Label from "../Label";
import Hint from "../Hint";
import ValidationFeedback from "../ValidationFeedback";

const TextField = ({
  label = "",
  hint = "",
  className,
  name,
  placeholder = null,
  onChange,
  onFocus,
  onBlur,
  onKeyUp,
  validate,
  disabled,
  maxLength,
  showFeedbackIcon = true,
  showFeedbackText = true,
  hasExternalLabel,
  typeError = "warning",
  inputClassName,
  autocomplete = false,
  ...props
}) => {
  const [field, meta, helpers] = useField({
    name,
    onChange,
    onFocus,
    onBlur,
    onKeyUp,
    validate,
  });
  const [isFocused, setIsFocused] = useState(false);

  console.log(field, meta);
  // const change = (e) => {
  //   field.onChange(e);
  //   onChange(e, field, meta, helpers);
  // };

  // const change = (e) => {
  //   field.onChange(e);
  //   onChange?.(e, field, meta, helpers);
  // };
  //
  // const focus = (e) => {
  //   setIsFocused(true);
  //   onFocus?.(e);
  // };
  //
  // const blur = (e) => {
  //   setIsFocused(false);
  //   onBlur?.(e);
  // };
  //
  // const keyUp = (e) => {
  //   onKeyUp?.(e);
  //   setTimeout(() => helpers.setTouched(true, false), 1000);
  // };
  return (
    <div className={cx("relative", className, { "mb-4": !!hint })}>
      <Label
        name={field.name}
        hasValue={!!meta?.value}
        hasPlaceholder={!!placeholder}
        isFocused={isFocused}
      >
        {label}
      </Label>
      <input
        type="text"
        disabled={disabled}
        id={field.name}
        maxLength={maxLength}
        placeholder={placeholder}
        {...field}
        {...props}
        value={field.value}
        spellCheck="false"
        autoComplete={autocomplete}
        // onChange={change}
        // onFocus={focus}
        // onBlur={blur}
        // onKeyUp={keyUp}
        className={cx(
          "w-full appearance-none m-0 px-3  text-white border rounded-lg bg-brand-600 rtl:text-right  focus:outline-none focus:border-gold focus:ring-0  focus:ring-offset-0 focus:ring-transparent",
          { "h-10": hasExternalLabel },
          { "h-14 pt-5 pb-1": !hasExternalLabel },
          { "border-brand-500": !meta?.error || !meta?.touched },
          {
            "border-warning":
              !!meta?.error && meta?.touched && typeError === "warning",
          },
          {
            "border-red":
              !!meta?.error && meta?.touched && typeError === "error",
          },
          inputClassName
        )}
      />

      <ValidationFeedback
        meta={meta}
        name={name}
        showFeedbackIcon={showFeedbackIcon}
        showFeedbackText={showFeedbackText && !isFocused}
        typeError={typeError}
      />
      <Hint show={!!hint}>{hint}</Hint>
    </div>
  );
};

export default memo(TextField);
