import React, { useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Transition } from "@headlessui/react";
import { isResetPwdKeyAvailable, resetPwd } from "../../data/model/account";
import { PasswordField } from "../Formik";
import ResetValidationSchema from "./change-password.validation";
import Button from "../Button";
import { useDevice } from "../Provide/DeviceContext";
import SubmitFeedback from "../Formik/SubmitFeedback";

export const ResetForm = ({ onSuccess, setAction, resetToken }) => {
  const intl = useIntl();
  const { isMobile } = useDevice();
  const [formError, setFormError] = useState("");

  // isResetPwdKeyAvailable(resetToken).then((result) => {
  //   if (!result.isAvailable) {
  //     setFormError('error with token, please try again');
  //   }
  // });

  console.log("resetToken", resetToken);
  const onSubmit = async (values, formikBag) => {
    const { setFieldError, setSubmitting, setStatus } = formikBag;
    resetPwd(values.password, resetToken).then(
      (result) => {
        setStatus("success");
        return true;
      },
      (err) => {
        setFieldError("loginState", err.desc);
        setStatus("error");
      }
    );
  };

  return (
    <Formik
      validationSchema={ResetValidationSchema(intl)}
      initialValues={{
        password: "",
        password_confirmation: "",
        loginState: "",
      }}
      initialStatus="init"
      onSubmit={onSubmit}
    >
      {({ isSubmitting, status }) => (
        <Form className="mx-auto mt-5.5 flex-col flex w-full items-stretch px-2.5">
          <h4 className="text-center text-2xl leading-5 font-light text-white mb-16">
            {intl.formatMessage({
              defaultMessage: "Password reset",
              description: "Header of section",
            })}
          </h4>
          <Transition
            show={status === "error"}
            leave="transition ease-in duration-500"
            leaveFrom="transform opacity-100"
            leaveTo="transform -translate-x-80 opacity-0"
            className="flex flex-col w-full"
            unmount={false}
          >
            <div>
              Something went wrong, please request a new password reset link.{" "}
            </div>
            <Button.Action
              trackName="Forgot password"
              trackParams={{ component: "ResetForm" }}
              onClick={() => setAction("passwordForget")}
              className="w-full mt-7.5"
              size="large"
            >
              {intl.formatMessage({
                defaultMessage: "Request new password",
                description: "Label of the button to request a new password",
              })}
            </Button.Action>
          </Transition>
          <Transition
            show={status === "init"}
            leave="transition ease-in duration-500"
            leaveFrom="transform opacity-100"
            leaveTo="transform -translate-x-80 opacity-0"
            className="flex flex-col w-full"
            unmount={false}
          >
            <div className="w-full">
              <PasswordField
                name="password"
                label={intl.formatMessage({
                  defaultMessage: "New password",
                  description: "Placeholder of inputfield",
                })}
                className="mt-7"
                typeError="error"
                showFeedbackIcon={false}
                showFeedbackText
              />

              <PasswordField
                name="password_confirmation"
                label={intl.formatMessage({
                  defaultMessage: "New password check",
                  description: "Placeholder of inputfield",
                })}
                className="mt-7"
                typeError="error"
                showFeedbackIcon={false}
                showFeedbackText
              />
              <ErrorMessage name="loginState" className="text-red my-4" />
              <Button.Action
                trackName="Submit log in"
                trackParams={{ component: "ResetForm" }}
                className="w-full mt-6"
                arrow="right"
                type="submit"
                isSubmitting={isSubmitting}
                submittingLabel={intl.formatMessage({
                  defaultMessage: "Submitting...",
                  description: "Label while submitting login",
                })}
              >
                {intl.formatMessage({
                  defaultMessage: "Reset",
                  description: "Button label",
                })}
              </Button.Action>
            </div>
          </Transition>
          <SubmitFeedback
            status={status}
            header={intl.formatMessage({
              defaultMessage: "Confirmed",
              description: "The feedback header",
            })}
          >
            <div className="text-24 py-8 leading-7">
              {intl.formatMessage({
                defaultMessage:
                  "Form submit successful. Next time login with your new password",
                description: "The feedback after changing account details",
              })}
            </div>
            <div className="px-8">
              <Button.Action
                trackName="Go to log in"
                trackParams={{ component: "ResetForm" }}
                onClick={() => setAction("auth")}
                className="w-full"
                arrow="right"
              >
                {intl.formatMessage({
                  defaultMessage: "Go to login",
                  description: "Label",
                })}
              </Button.Action>
            </div>
          </SubmitFeedback>
        </Form>
        //
        // <Form className="flex flex-col items-center text-purple text-sm max-w-sm w-full mx-auto">
        //   {formError && (
        //     <>
        //       <div>{formError}</div>
        //       <Button.Default
        //         onClick={() => setAction('passwordForget')}
        //         className="w-full mt-7.5"
        //         size="large"
        //       >
        //         {intl.formatMessage({
        //           defaultMessage: 'Request new password',
        //           description: 'Label of the button to request a new password',
        //         })}
        //       </Button.Default>
        //     </>
        //   )}
        //   {!formError && (
        //     <>
        //       <div>
        //         {
        //           intl.formatMessage({
        //             defaultMessage: 'Please enter a new password below',
        //             description: 'A title for the password reset form',
        //           })
        //       }
        //         :
        //       </div>
        //       <div className="mt-5.5 flex-col flex w-full items-stretch">
        //         <PasswordField
        //           name="password"
        //           placeholder={
        //               intl.formatMessage({
        //                 defaultMessage: 'Password',
        //                 description: 'A placeholder for the password field',
        //               })
        //           }
        //           className="mt-8"
        //         />
        //       </div>
        //       <Button.Submit
        //         className="w-full mt-7.5"
        //         size="large"
        //         isSubmitting={isSubmitting}
        //         submittingLabel={
        //             intl.formatMessage({
        //               defaultMessage: 'Saving...',
        //               description: 'When submitting a button this generic terms is used while waiting',
        //             })
        //         }
        //       >
        //         {intl.formatMessage({
        //           defaultMessage: 'Reset password',
        //           description: 'Label of the button to reset a new password',
        //         })}
        //       </Button.Submit>
        //     </>
        //   )}
        // </Form>
      )}
    </Formik>
  );
};

export default ResetForm;
