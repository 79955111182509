import * as Yup from 'yup';

const validationSchema = (intl) => Yup.object().shape({
  termsandcondition: Yup.boolean()
    .required('The terms and conditions must be accepted.').oneOf(
      [true],
      intl.formatMessage({
        defaultMessage: 'The terms and conditions must be accepted.',
        description: 'Validation',
      }),
    ),

});

export default validationSchema;
