import React, { useEffect, useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Transition } from "@headlessui/react";
import { confirmLimit } from "@data/model/responsible-gaming";
import Button from "../Button";
import { useDevice } from "../Provide/DeviceContext";
import SubmitFeedback from "../Formik/SubmitFeedback/SubmitFeedback";

export const RemoveLimit = ({ limitToken }) => {
  const intl = useIntl();
  const { isMobile } = useDevice();

  // isResetPwdKeyAvailable(limitToken).then((result) => {
  //   if (!result.isAvailable) {
  //     setFormError('error with token, please try again');
  //   }
  // });
  const [status, setStatus] = useState("");
  console.log("limitToken", limitToken);

  const deleteLimit = () => {
    // console.log("token2", limitToken);
    confirmLimit(limitToken)
      .then(() => {
        setStatus("success");
      })
      .catch((err) => {
        console.log("removeLimit2", err);
        setStatus("error");
      });
  };

  useEffect(() => {
    deleteLimit();
  }, []);

  return (
    <Formik>
      <Form className="mx-auto mt-5.5 flex-col flex w-full items-stretch px-2.5">
        <h4 className="mb-16 text-2xl font-light leading-5 text-center text-white  sticky top-0 bg-[#13161d] max-w-full ms-auto me-auto pt-0 pb-[11px] z-[9] ml-0">
          {intl.formatMessage({
            defaultMessage: "Remove Limit",
            description: "Header of section",
          })}
        </h4>
        <Transition
          show={status === "error" || status === "success"}
          leave="transition ease-in duration-500"
          leaveFrom="transform opacity-100"
          leaveTo="transform -translate-x-80 opacity-0"
          className="flex flex-col w-full"
          unmount={false}
        >
          <div>
            {status === "error" &&
              intl.formatMessage({
                defaultMessage:
                  "Something went wrong, please contact administrator.",
                description: "statusMessage",
              })}

            <SubmitFeedback
              status={status}
              header={intl.formatMessage({
                defaultMessage: "Confirmed",
                description: "The feedback header",
              })}
            >
              <div className="py-8 leading-7 text-24">
                {intl.formatMessage({
                  defaultMessage:
                    "Your limit will be removed after a 24 hour grace period.",
                  description: "The feedback after setting a limit",
                })}
              </div>
            </SubmitFeedback>
          </div>
        </Transition>
      </Form>
    </Formik>
  );
};

export default RemoveLimit;
