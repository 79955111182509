/**
 * TopBar Component
 *
 * This component is responsible for The public top menu login/ registration buttons
 *
 * @category   TopBarPublic
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { FormattedNumber, useIntl } from "react-intl";
import { Menu, Switch, Transition } from "@headlessui/react";
import Button from "@components/Button";
import ActiveBonuses from "@components/ActiveBonuses";
import { openNavigation, toggleNavigation } from "@actions/navigation";
import Deposit from "@components/Deposit";
import DepositSuccess from "@components/Deposit/DepositSuccess";
import DepositFailed from "@components/Deposit/DepositFailed";

import Withdraw from "@components/Withdraw";
import Auth from "@components/Auth";
import usePrevious from "@hooks/usePrevious";
import useSWR from "swr";
import { isBrowser } from "browser-or-node";
import Faq from "@components/Faq";
import RegistrationSuccess from "@components/Registration/FormSteps/Success";
import Registration from "@components/Registration";

import Cookies from "universal-cookie";
import Icon from "../../Icon";
import Link from "../../Link";
import WalletTable from "./WalletTable";
import QuickDeposit from "./QuickDeposit";
import { status_groups } from "../../../data/model/account";
import { useWallet } from "../../Provide/WalletContext";
import Navigator from "../../Form/Navigator";
import MenuAuthenticated from "./MenuItems/Authenticated";
import { useRouter } from "../../Provide/RouterContext";

import Bonusses from "../../Bonusses";
import WithdrawModal from "../../WithdrawModal";
import ResponsibleGamingVerify from "../../Account/Forms/ResponsibleGamingVerify";
import DocumentVerification from "../../Account/Forms/DocumentVerification";
import { useModal } from "../../Modal/ModalContext";
import ResponsibleGaming from "./Menu/ResponsibleGaming";
import Profile from "./Menu/Profile";
import { useDevice } from "../../Provide/DeviceContext";
import ToggleNavigation from "../../Navigation/ToggleNavigation";
import TransactionHistory from "../../Account/Forms/TransactionHistory";
import MenuPublic from "./MenuItems/Public";
import { defaultRequester } from "../../../core/default-requester";
import Wallets from "../../Wallets";
import { getCurrencies } from "../../../data/model/wallet";
import DepositModal from "../../DepositModal";
import UpdateProfile from "../../UpdateProfile/UpdateProfile";
import { LanguageSwitcher } from "../TopBarPublic/TopBarPublic";
import starts from "./stars.svg";
import { toggleCheckMark } from "../../../actions/app";
import DocumentVerificationV2 from "../../Account/Forms/DocumentVerification/DocumentVerificationV2";

const Items = {
  ActiveBonuses,
  Auth,
  ResponsibleGaming,
  ResponsibleGamingVerify,
  TransactionHistory,
  Profile,
  Deposit,
  DepositSuccess,
  DepositFailure: DepositFailed,
  Withdraw,
  DocumentVerification: DocumentVerificationV2,
  DocumentVerificationV2,
  Faq,
  Registration,
  RegistrationSuccess,
  MenuPublic,
  Wallets,
  UpdateProfile,
  Root: null,
};

const TopBarPrivate = ({ clickFromGame = () => {} }) => {
  const intl = useIntl();
  const { isMobile } = useDevice();
  const { total, currency } = useWallet();
  const router = useRouter();
  const { showModal } = useModal();

  // Start cache //
  const { data } = useSWR(
    isBrowser ? ["fetchPaymentMethods", currency] : null,
    (url, currency) =>
      window.PaymentsAPI.fetchPaymentMethods(currency, defaultRequester)
  );
  const { data: currencies } = useSWR("getCurrencies", getCurrencies);

  const dispatch = useDispatch();
  const open = (section = "Root") => dispatch(openNavigation(section));
  const toggle = (section = "Root") => dispatch(toggleNavigation(section));
  const isNavigationOpen = useSelector(
    (state) => state.navigation.isNavigationOpen
  );
  const navigationItem = useSelector((state) => state.navigation.item);
  const isShowCheckMark = useSelector((state) => state.app.isShowCheckMark);

  const params = useSelector((state) => state.navigation.params);

  const [menuItem, setMenuItem] = useState(
    navigationItem && Items?.[navigationItem] ? navigationItem : "Root"
  );
  const [backActions, setBackActions] = useState([]);
  const [clickedFrom, setClickedFrom] = useState("");

  useEffect(() => {
    if (navigationItem) {
      setMenuItem(navigationItem);
    }
  }, [navigationItem]);

  const prevMenuItem = usePrevious(menuItem);
  const Slide =
    Items[menuItem === "Root" ? prevMenuItem : menuItem] ?? MenuAuthenticated;

  const GenerateCurrencyAmount = () => {
    const currentWallet = currencies?.filter((cur) => cur?.code === currency);
    if (currentWallet) {
      // let finalAmount = total/currentWallet[0].subunits_to_unit;
      console.log("user currency total ", total);
      // console.log("user currency total ", finalAmount)
      // return finalAmount;
    }
  };

  const cookies = new Cookies();

  // useEffect(() => {
  // const cookiesSaved = cookies.get("isShowCheckMark") === "true";
  // dispatch(toggleCheckMark(cookiesSaved));
  // }, [cookies]);

  const [tooltipopen, setToolTip] = useState(false);
  let tooltipCount = cookies.get("tooltipCount");
  useEffect(() => {
    if (router?.routename !== "myClubhouse") {
      tooltipCount = cookies.get("tooltipCount");

      if (!tooltipCount) {
        tooltipCount = 0;
      }

      if (tooltipCount < 7) {
        if (tooltipCount < 3) {
          setToolTip(true);
        }
        cookies.set("tooltipCount", Number(tooltipCount) + 1, { path: "/" });

        setTimeout(() => {
          setToolTip(false);
        }, 10000);
      }
    }
  }, []);

  return (
    <>
      {!isMobile.any && <LanguageSwitcher locale={intl?.locale} />}
      <div className="relative ltr:mr-2 rtl:ml-2 md:mr-0">
        <span
          onMouseOver={() => !isMobile.any && setToolTip(true)}
          onMouseOut={() => !isMobile.any && setToolTip(false)}
          onClick={() => {
            setToolTip(!tooltipopen);
            setTimeout(() => {
              setToolTip(false);
            }, 2000);
          }}
        >
          {isShowCheckMark && (
            <div className="absolute w-4 h-4 text-xs text-center rounded-full bg-green -top-1 lg:right-3 -right-1">
              {isShowCheckMark}
            </div>
          )}
          <Link
            trackName="My Clubhouse"
            trackParams={{ component: "TopBarPrivate" }}
            className="inline-flex items-center justify-between px-5 overflow-hidden font-normal text-center transition-all rounded-lg bg-gold hover:bg-opacity-70 whitespace-nowrap h-11 text-brand ltr:md:mr-5 rtl:md:ml-5"
            to="myClubhouse"
            onClick={clickFromGame}
          >
            <img
              src={starts}
              alt=""
              className="w-7"
              style={{ minWidth: "28px" }}
            />
          </Link>
        </span>
        <Transition show={tooltipCount < 7 ? tooltipopen : false}>
          <div className="absolute z-50 p-2 -ml-2.5 text-xs text-white transform -translate-x-1/2 border-white rounded-lg w-52 top-14 lg:left-1/2 bg-brand-600 border-1 border-opacity-30 up-arrow left-10">
            {intl.formatMessage({
              defaultMessage:
                "Get back to My Clubhouse for new bonuses, your loyalty level and more",
            })}
          </div>
        </Transition>
      </div>
      <div className="ltr:mr-2 rtl:ml-2 ltr:md:mr-0 rtl:md:ml-0">
        <Button.Action
          trackName="Account details"
          trackParams={{ component: "TopBarPrivate" }}
          className="font-normal rtl:md:ml-5 ltr:md:mr-5"
          color="darkgray"
          onClick={() => open("Root")}
        >
          <Icon icon="user" className="w-5 h-5 mr-2 text-white" /> {total}
        </Button.Action>
      </div>

      <Button.Action
        trackName="Deposit"
        trackParams={{ component: "TopBarPrivate" }}
        onClick={() => showModal(DepositModal)}
        className="hidden 2xl:block"
      >
        {intl.formatMessage({
          defaultMessage: "Deposit",
          description: "Label of the deposit button",
        })}
      </Button.Action>

      <Link
        trackName="Account Menu"
        trackParams={{ component: "TopBarPrivate" }}
        onClick={() => {
          setMenuItem("MenuPublic");
          toggle("MenuPublic");
        }}
        className="relative items-center justify-center rounded-lg bg-brand-600 h-11 2xl:hidden"
      >
        <ToggleNavigation open={isNavigationOpen} />
      </Link>

      {/* <div className="z-30 mr-4 text-lg font-bold text-white"> */}
      {/*  <FormattedNumber value={total} style="currency" currency={currency} /> */}
      {/* </div> */}
      <div className="relative flex items-end justify-center inline-block text-left">
        <>
          {/* <Button.Action color="darkgray" className="flex items-center justify-center transition duration-150 ease-in-out focus:outline-none" > */}
          {/*  Menu */}
          {/* </Button.Action> */}
          <Transition
            show={isNavigationOpen}
            enter="transition-all duration-700"
            enterFrom="transform translate-x-full"
            enterTo="transform translate-x-0"
            leave="transition-all duration-700"
            leaveFrom="transform-gpu translate-x-0"
            leaveTo="transform-gpu translate-x-full"
            className="fixed right-0 w-screen pb-8 overflow-hidden overflow-x-hidden overflow-y-auto text-white transform h-menu-medium lg:h-menu-bottom top-26 lg:top-0 xs:w-108 lg:mt-26 bg-brand"
            unmount={false}
          >
            <>
              <div className="relative z-10 flex justify-between p-3 bg-[#13161d]">
                {menuItem !== "Root" && backActions.length > 0 && (
                  <Link
                    trackName="Back"
                    trackParams={{ component: "TopBarPrivate" }}
                    onClick={() => {
                      const cloneBackActions = [...backActions];
                      const lastItem = cloneBackActions.pop();
                      lastItem?.();
                      setBackActions(cloneBackActions);
                      open();
                    }}
                    className="absolute p-2.5 left-4 rounded bg-brand-600"
                  >
                    <Icon
                      icon="arrow"
                      className="w-4 h-4 text-white transform rotate-90"
                    />
                  </Link>
                )}

                <Link
                  trackName="Close"
                  trackParams={{ component: "TopBarPrivate" }}
                  onClick={toggle}
                  className="absolute right-4 p-2.5 rounded bg-brand-600 hidden lg:block"
                >
                  <Icon icon="close" className="w-4 h-4 text-white" />
                </Link>
              </div>
              <Transition
                show={menuItem === "Root"}
                enter="transition-all duration-700"
                enterFrom="transform -translate-x-full"
                enterTo="transform translate-x-0"
                leave="transition-all duration-700"
                leaveFrom="transform-gpu translate-x-0"
                leaveTo="transform-gpu -translate-x-full"
                className="absolute top-0 left-0 w-full h-full pt-1 pb-20 overflow-auto"
                // unmount={false}
              >
                <MenuAuthenticated
                  open={open}
                  setMenuItem={setMenuItem}
                  setBackActions={setBackActions}
                  setClickedFrom={setClickedFrom}
                  clickedFrom={clickedFrom}
                />
              </Transition>
              <Transition
                show={menuItem !== "Root"}
                enter="transition-all duration-700"
                enterFrom="transform translate-x-full"
                enterTo="transform translate-x-0"
                leave="transition-all duration-700"
                leaveFrom="transform-gpu translate-x-0"
                leaveTo="transform-gpu translate-x-full"
                className="absolute top-0 left-0 z-0 w-full h-full pt-6 pb-20 overflow-auto lg:pb-0"
                // unmount
              >
                <Slide
                  prevMenuItem={prevMenuItem}
                  menuItem={menuItem}
                  setMenuItem={setMenuItem}
                  setBackActions={setBackActions}
                  setClickedFrom={setClickedFrom}
                  clickedFrom={clickedFrom}
                  {...params}
                />
              </Transition>
              <div className="fixed bottom-0 right-0 z-20 w-full bg-black xs:w-108">
                {isMobile.any && (
                  <LanguageSwitcher locale={intl?.locale} noMargin />
                )}
              </div>
            </>
          </Transition>
        </>
      </div>
    </>
  );
};

export default TopBarPrivate;
