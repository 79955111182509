import useSWR from "swr";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import RadioButton from "@components/RadioButton";
import { useAuth } from "../Provide/AuthContext";
import { getCurrencies, getGamingAccounts } from "../../data/model/wallet";
import { useWallet } from "../Provide/WalletContext";
import Button from "@components/Button";
import { useRouter } from "../Provide/RouterContext";
import Icon from "../Icon/Icon";
import cx from "classnames";
import { postCall } from "../../core/softswiss";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { openNavigation } from "@actions/navigation";
import { Transition } from "@headlessui/react";
import { useTracking } from "@hooks/useTracking";
import DepositModal from "@components/DepositModal";
import { useModal } from "@components/Modal/ModalContext";

const Wallets = () => {
  const intl = useIntl();
  const { showModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated } = useAuth();
  const { currency } = useWallet();
  const router = useRouter();
  const { track } = useTracking();

  const { data } = useSWR(
    isAuthenticated ? "/api/player/accounts" : null,
    getGamingAccounts,
    { refreshInterval: 5000 }
  );

  const navigationItem = useSelector((state) => state.navigation.item);

  const dispatch = useDispatch();
  const open = (section) => dispatch(openNavigation(section ?? "Root"));
  const [menuItem, setMenuItem] = useState(navigationItem ?? "Root");
  useEffect(() => {
    if (navigationItem) {
      setMenuItem(navigationItem);
    }
    console.log("NAV ITEM " + navigationItem);
  }, [navigationItem]);

  const [selectedCurrency, setSelectedCurrency] = useState(currency);
  const [openCloseToggle, setOpenCloseToggle] = useState(false);
  const [newCurrencySelected, setNewCurrencySelected] =
    useState("Please select..");
  const [currencyCode, setCurrencyCode] = useState("");
  const [arrow, setArrow] = useState("down");
  const activeWallets = data?.filter((item) => item.active === true);

  const { data: currencies } = useSWR("getCurrencies", getCurrencies);
  console.log("active wallets", activeWallets);
  console.log("active wallets currencies", currencies);
  let currentWallet = currencies?.filter((cur) => cur?.code === currency);

  // let nonFlatWallets = [];
  // const removeNonFlatWallets = (
  //   currencies?.forEach((item) => {
  //     activeWallets?.forEach((wallet) => {
  //       if(item.code === wallet.currency){
  //         item.fiat && nonFlatWallets.push(wallet)
  //       }
  //     })
  //   })
  // );

  const availableCurr = currencies?.filter((item) => {
    // if(!item.fiat) {
    //   return false;
    // }
    const found = activeWallets.find((active) => active.currency === item.code);
    if (found) {
      return false;
    }
    return true;
  });

  const handleAddCurrency = async (formikBag) => {
    const { setSubmitting } = formikBag;
    const newCurrencyParams = {
      currency: newCurrencySelected,
      amount_cents: 0,
      available_to_cashout_cents: 0,
    };
    postCall(
      `/api/player/accounts?compatibility=false&currency=${newCurrencySelected}`,
      newCurrencyParams
    )
      .then(() => {
        track("Wallet currency added success", {
          currency: newCurrencySelected,
        });
        setSelectedCurrency(newCurrencySelected);
        setSubmitting(false);
        setNewCurrencySelected("Please select..");
        console.log("CURRENCIES ADDED SUCCESSFULLY");
      })
      .catch((err) => {
        track("Wallet currency added error", { error: err });
        // setSubmitting(false);
        console.log("CURRENCIES ADDED FAILED", err);
      });
  };

  const handleCurrencyChange = async (curr) => {
    setIsLoading(true);
    return postCall(`/api/player/accounts?compatibility=false&currency=${curr}`)
      .then(() => {
        track("Wallet currency change success", { currency: curr });
        setIsLoading(false);
        console.log("currency change success");
        return setSelectedCurrency(curr);
      })
      .catch((err) => {
        track("Wallet currency change error", { error: err });
        setIsLoading(false);
        console.log("currency change failed", err);
      });
  };

  function CalculateCurrency(amount, item) {
    let currentWallet = currencies?.filter(
      (cur) => cur?.code === activeWallets[item].currency
    );
    if (currentWallet !== undefined) {
      console.log("multiplier", currentWallet[0].subunits_to_unit);
      let finalAmount = amount / currentWallet[0].subunits_to_unit;
      return intl.formatNumber(finalAmount, {
        style: "currency",
        currency: activeWallets[item].currency,
        minimumFractionDigits:
          currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
        maximumFractionDigits:
          currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
      });
    }
  }

  return (
    <Formik
      initialValues={{
        currencyDefault: currency,
      }}
      onSubmit={handleAddCurrency}
    >
      {({
        isSubmitting,
        values,
        setStatus,
        status,
        isValid,
        setFieldValue,
        errors,
      }) => (
        <Form>
          <h4 className="mb-10 text-2xl font-light leading-5 text-center text-white  sticky top-0 bg-[#13161d] max-w-full ms-auto me-auto pt-0 pb-[11px] z-[9] ml-0">
            {intl.formatMessage({
              defaultMessage: "Wallets",
              description: "Header of section",
            })}
          </h4>
          <div className="w-full px-3 pb-20 mb-24">
            {Object.keys(activeWallets)?.map((slug) => {
              // Object.keys(nonFlatWallets)?.map(slug => {
              return (
                <div className="w-full my-6 rounded-lg bg-brand-600 rtl:text-right">
                  <div
                    className={cx("w-full p-3 relative", {
                      "animate-pulse": isLoading,
                    })}
                  >
                    {isLoading && (
                      <div className="absolute w-full h-full pb-6 pr-6">
                        <Transition
                          show={isLoading}
                          enter="transition-all duration-700"
                          enterFrom="transform translate-x-8 opacity-0"
                          enterTo="transform translate-x-0 opacity-1"
                          leave="transition-all duration-700"
                          leaveFrom="transform-gpu translate-x-0 opacity-1"
                          leaveTo="transform-gpu translate-x-8 opacity-0"
                          className="absolute"
                          style={{
                            left: `45%`,
                            top: `40%`,
                            transform: `scale(2)`,
                          }}
                        >
                          <svg
                            className="w-5 h-5 animate-spin "
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx={12}
                              cy={12}
                              r={10}
                              stroke="currentColor"
                              strokeWidth={4}
                            />
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                          </svg>
                        </Transition>
                      </div>
                    )}
                    <div className="flex justify-between w-full">
                      <h4 className="text-2xl ">
                        {activeWallets[slug].currency}
                      </h4>
                      <div className="flex items-center gap-1 whitespace-nowrap">
                        <RadioButton
                          value={activeWallets[slug].currency}
                          name="currencyDefault"
                          onChange={() =>
                            handleCurrencyChange(activeWallets[slug].currency)
                          }
                          checked={selectedCurrency}
                        />
                        <span className="">
                          {intl.formatMessage({
                            defaultMessage: "Default",
                            description: "Label",
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="my-4">
                      <span className="text-lg text-brand-400">
                        {intl.formatMessage({
                          defaultMessage: "Total Balance",
                          description: "Label",
                        })}
                      </span>
                      <div className="text-4xl">
                        {CalculateCurrency(
                          activeWallets[slug].amount_cents,
                          slug
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="flex flex-col mr-10">
                        <span className="text-xs text-brand-400 whitespace-nowrap">
                          {intl.formatMessage({
                            defaultMessage: "Bonus Balance",
                            description: "Label",
                          })}
                        </span>
                        <div className="text-lg">
                          {CalculateCurrency(
                            activeWallets[slug].amount_cents,
                            slug
                          )}
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <span className="text-xs text-brand-400 whitespace-nowrap">
                          {intl.formatMessage({
                            defaultMessage: "Withdrawable Balance",
                            description: "Label",
                          })}
                        </span>
                        <div className="text-lg">
                          {CalculateCurrency(
                            activeWallets[slug].available_to_cashout_cents,
                            slug
                          )}
                        </div>
                      </div>
                    </div>

                    <Button.Action
                      trackName="Deposit"
                      trackParams={{ component: "Wallets" }}
                      arrow="right"
                      className="w-full mt-6 md:flex"
                      onClick={() => {
                        handleCurrencyChange(activeWallets[slug].currency).then(
                          () => {
                            showModal(DepositModal);
                          }
                        );
                      }}
                    >
                      {intl.formatMessage({
                        defaultMessage: "Deposit",
                        description: "Label",
                      })}{" "}
                      {intl
                        .formatNumber(0, {
                          style: "currency",
                          currency: activeWallets[slug].currency,
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                        .replace(/\d/g, "")
                        .trim()}
                    </Button.Action>
                  </div>
                </div>
              );
            })}
            <div className="mt-8">
              {intl.formatMessage({
                defaultMessage: "Add another currency",
                description: "Label",
              })}

              <div className="relative w-full h-10 px-3 m-0 my-2 text-white border rounded-lg appearance-none bg-brand-600 focus:outline-none focus:border-gold focus:ring-0 focus:ring-offset-0 focus:ring-transparent border-brand-500">
                <button
                  type="button"
                  className="w-full h-10 m-auto ml-0 text-left "
                  onClick={() => {
                    setOpenCloseToggle(!openCloseToggle),
                      setArrow(arrow === "down" ? "up" : "down");
                  }}
                >
                  <div className="flex justify-center h-10 text-center rtl:flex-row-reverse ">
                    <p className="w-full m-auto ml-0 ltr:text-left rtl:text-right">
                      {newCurrencySelected}
                    </p>
                    <p className="w-10 m-auto ml-0 text-right text-gray">
                      {" "}
                      {currencyCode}{" "}
                    </p>
                    <Icon
                      icon="arrow"
                      className={cx(
                        "ml-4 h-4 w-4 inline-block transform m-auto",
                        {
                          "-rotate-180": arrow === "up",
                          "-rotate-0": arrow === "down",
                          "-rotate-90": arrow === "right",
                        }
                      )}
                    />
                  </div>
                </button>
                {openCloseToggle && (
                  <div
                    role="list"
                    className="absolute z-10 flex flex-col w-full pr-5 -ml-3 overflow-auto text-white border rounded-lg max-h-40 border-brand-500 bg-brand-600 "
                  >
                    {availableCurr?.map((item) => (
                      <button
                        type="button"
                        className="px-3 py-2 text-left"
                        key={item.code}
                        onClick={() => {
                          setOpenCloseToggle(!openCloseToggle),
                            setNewCurrencySelected(item.code),
                            setArrow("down"),
                            setCurrencyCode(item.symbol);
                        }}
                      >
                        {item.symbol} {item.code}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <Button.Action
                disabled={
                  !!isSubmitting ||
                  newCurrencySelected ===
                    intl.formatMessage({
                      defaultMessage: "Please select..",
                      description: "Label",
                    })
                    ? true
                    : false
                }
                trackParams={{ component: "Wallets" }}
                trackName="Add currency"
                // onClick={handleAddCurrency}
                arrow="right"
                type="submit"
                isSubmitting={isSubmitting}
                className={cx("w-full md:flex mt-8", {
                  "animate-pulse": isSubmitting,
                })}
              >
                {intl.formatMessage({
                  defaultMessage: "Add currency",
                  description: "Label",
                })}
              </Button.Action>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Wallets;
