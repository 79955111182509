import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import SubmitFeedback from "@components/Formik/SubmitFeedback";
import { useDispatch } from "react-redux";
import { closeNavigation } from "@actions/navigation";
import { useTracking } from "@hooks/useTracking";
import DepositModal from "@components/DepositModal";
import { useModal } from "@components/Modal/ModalContext";
import Button from "../../Button";
import { useWallet } from "../../Provide/WalletContext";

const DepositFailed = ({ setMenuItem }) => {
  const intl = useIntl();
  const { showModal } = useModal();
  const { currency } = useWallet();
  const dispatch = useDispatch();
  const close = () => dispatch(closeNavigation());
  const { track } = useTracking();
  useEffect(() => {
    track("Deposit failed");
  }, []);
  // credit: {currency: "DKK", amount: 100, name: ""}
  // debit: {currency: "DKK", amount: 100, name: "MoneyMatrix_PaySafeCard"}
  // fees: []
  // isFirstDeposit: false
  // status: "success"
  // time: "2021-05-11T20:56:05.783"
  // transactionID: 1576066424

  return (
    <div className="p-16 px-8 md:px-16">
      <SubmitFeedback
        status="failed"
        header={intl.formatMessage({
          defaultMessage: "Failed!",
          description: "The feedback header",
        })}
      >
        <div className="py-8 leading-7 text-24">
          {intl.formatMessage({
            defaultMessage: "Your deposit has failed. Please try again. ",
            description: "The feedback after deposit",
          })}
        </div>
        <div className="px-8">
          <Button.Action
            trackName="Deposit Try Again"
            trackParams={{ component: "DepositFailed" }}
            onClick={() => showModal(DepositModal)}
            className="w-full"
            arrow="right"
          >
            {intl.formatMessage({
              defaultMessage: "Try again",
              description: "Label",
            })}
          </Button.Action>
        </div>
      </SubmitFeedback>
    </div>
  );
};

export default DepositFailed;
