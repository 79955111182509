import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import cx from "classnames";
import useSession from "@hooks/useSession";
import { Transition } from "@headlessui/react";
import Button from "@components/Button";
import {
  updateProfile,
  player_fields,
  getPlayerInfo,
} from "@data/model/account";
import { PasswordField, TextField } from "@components/Formik";
import SubmitFeedback from "@components/Formik/SubmitFeedback";
import useSWR from "swr";
import ToggleSwitch from "../Formik/ToggleSwitch";
import { useTracking } from "@hooks/useTracking";
import DynamicFields from "../ProfileDynamicFields/DynamicFields";
import getCurrenciesQuery from "../LandingOffer/get-currencies.graphql";
import getCountriesQuery from "../LandingOffer/get-countries.graphql";
import { useAuth } from "../Provide/AuthContext";
import Cookies from "universal-cookie";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { getCmsSessionID, getIpInfo } from "../../data/model/session";
import history from "../../core/history";
import { localeUrl } from "../../core/url";
import { postCall } from "../../core/softswiss";
import { useWallet } from "../Provide/WalletContext";
import { openNavigation } from "@actions/navigation";
import GameModal from "../GameModal";
import { useModal } from "../Modal/ModalContext";
import { closeNavigation } from "@actions/navigation";

const UpdateProfile = ({
  menuItem,
  setMenuItem,
  setSection,
  setBackActions,
  params,
  game,
  provider,
  url,
}) => {
  useEffect(
    () =>
      setBackActions((prevState) => {
        prevState.push((x) => {
          setMenuItem("Main");
        });
        return prevState;
      }),
    []
  );

  const { track } = useTracking();
  const intl = useIntl();
  const { error: currencyError, data: currencies } =
    useQuery(getCurrenciesQuery);
  const { error: countryError, data: countries } = useQuery(getCountriesQuery);

  const { session, isLoading } = useSession();
  console.log("session test", session);
  const { data: ipInfo } = useSWR("getIpInfo", getIpInfo);
  const { data: playerInfo } = useSWR("getPlayerInfo", getPlayerInfo);
  console.log("getPlayerInfo", playerInfo);

  const dispatch = useDispatch();

  const countryInfo = countries?.countries?.find(
    (item) => item?.code === ipInfo?.country_code
  );
  const currencyInfo = currencies?.currencies?.find(
    (item) => item?.code === countryInfo?.currency
  );

  const { currency } = useWallet();
  const [subtitle, setSubtitle] = useState(params ?? "");
  const { showModal } = useModal();
  const localeInfo = {
    ...countryInfo,
    ...currencyInfo,
    currency: currencyInfo?.code,
    code: countryInfo?.code,
  };
  const submit = async (values, formikBag) => {
    console.log("Registration submit", values);
    const { setStatus, setFieldError, setFieldTouched, setSubmitting } =
      formikBag;

    console.log("updateProfileParams", values);
    updateProfile("edition", values)
      .then((res) => {
        track("Profile detail changes success", values);
        // resetForm();
        //dispatch(openNavigation("Main", { status: "success" }));
        //setStatus("success");
        setSubtitle("");
        dispatch(closeNavigation());

        //window.history.pushState("", "", url);
        //showModal(GameModal, { game: game, url, id: game?.id });
        window.location.href = url;
      })
      .catch((error) => {
        track("Profile detail changes error", { error: error });
        setFieldError("submitError", error.desc);
      });
  };
  console.log("game", game);

  const initialValues = {
    email: playerInfo?.email,
    currency: {
      name: localeInfo?.currency,
      text: intl.formatMessage(
        {
          defaultMessage: `{curr} ({amount})`,
          description: "Label",
        },
        {
          curr: playerInfo?.currency,
          amount: intl
            .formatNumber(0, {
              style: "currency",
              currency:
                localeInfo?.currency === undefined
                  ? currency
                  : localeInfo?.currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })
            .replace(/\d/g, "")
            .trim(),
        }
      ),
    },
    country:
      !Object.keys(localeInfo).length > 0
        ? { name: "", text: "Please select" }
        : { name: localeInfo?.code, text: localeInfo?.name },
    language: "en",
    password: playerInfo?.password ?? "",
    // confirmPassword: '',
    //username: '',
    mobile_phone: playerInfo?.mobile_phone ?? "",
    // title: '',
    //gender: playerInfo?.gender ?? "",
    gender: "",
    firstname: playerInfo?.first_name ?? "",
    surname: playerInfo?.last_name ?? "",
    address1: playerInfo?.address ?? "",
    city: playerInfo?.city ?? "",
    postalCode: playerInfo?.postal_code ?? "",
    // region: '',
    birthDate: "",
    birthdateDay: "",
    birthdateMonth: "",
    birthdateYear: "",
    termsandcondition: true,
    marketing: playerInfo?.receive_promos ?? false,
    "marketing-yes": playerInfo?.receive_promos ?? false,
    marketingOption: playerInfo?.receive_promos ?? false,
    "marketing-no": playerInfo?.receive_promos ?? false,
    // affiliateMarker: '',
    // limit: { name: 'daily', text: 'Limit per day' },
    // limitAmount: '',
    formState: "",
  };

  console.log("form initial values", initialValues);

  return (
    <>
      <Formik
        // validationSchema={ChangeDetailsSchema(intl)}
        initialStatus="init"
        enableReinitialize
        // initialErrors={{
        //   email: 'Required',
        //   password: 'Required',
        //   captcha: 'Required',
        // }}

        initialValues={initialValues}
        // onSubmit={(fields, actions) => submit(fields, actions).then(onSuccess)}
        initialTouched={{
          formState: true,
          marketing: true,
          termsandcondition: true,
          mobile_phone_error: true,
        }}
        onSubmit={submit}
      >
        {({ isSubmitting, status, isValid, setFieldValue }) => (
          <Form>
            <h4 className="text-center text-2xl leading-5 font-light text-white mb-4">
              {intl.formatMessage({
                defaultMessage: "My profile",
                description: "Header of section",
              })}
            </h4>
            <div>
              {subtitle?.length > 0 && (
                <p className="text-red text-base p-4 pt-0 text-center">
                  {subtitle}
                </p>
              )}
            </div>

            <Transition
              show={status === "init"}
              leave="transition ease-in duration-500"
              leaveFrom="transform opacity-100"
              leaveTo="transform -translate-x-80 opacity-0"
              className="flex flex-col w-full p-6"
              unmount={false}
            >
              <ErrorMessage name="submitError">
                {(msg) => <div className="mt-8 text-sm text-red">{msg}</div>}
              </ErrorMessage>

              <DynamicFields
                profileFieldsType={provider}
                countries={countries}
                currencies={currencies}
                initialValues={initialValues}
              />

              <div className="flex flex-row mt-12 items-left justify-center">
                <Button.Action
                  className={cx("w-full", { "animate-pulse": isSubmitting })}
                  // disabled={!!isSubmitting || !isValid}
                  type="submit"
                  arrow="right"
                  isSubmitting={isSubmitting}
                >
                  {isSubmitting
                    ? intl.formatMessage({
                        defaultMessage: "Saving...",
                        description:
                          "When submitting a button this generic terms is used while waiting",
                      })
                    : intl.formatMessage({
                        defaultMessage: "Save details",
                        description: "The label on a button for saving",
                      })}
                </Button.Action>
              </div>
            </Transition>
            <SubmitFeedback
              status={status}
              header={intl.formatMessage({
                defaultMessage: "Confirmed",
                description: "The feedback header",
              })}
            >
              <div className="text-24 py-8 leading-7">
                {intl.formatMessage({
                  defaultMessage: "Your personal details have been update.",
                  description: "The feedback after changing account details",
                })}
              </div>
              <div className="px-8">
                <Button.Action
                  onClick={() => setMenuItem("Root")}
                  className="w-full"
                  arrow="right"
                >
                  {intl.formatMessage({
                    defaultMessage: "Back to my account",
                    description: "Label",
                  })}
                </Button.Action>
              </div>
            </SubmitFeedback>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default UpdateProfile;
