import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { closeNavigation } from '@actions/navigation';
import emptyPicture from './assets/empty.png'
import { useRouter } from '../Provide/RouterContext';
import history from '../../core/history';

const Overlay = () => {
  const isNavigationOpen = useSelector(
    (state) => state.navigation.isNavigationOpen,
  );
  const router = useRouter();
  const dispatch = useDispatch();

  const close = () => {
    if(router?.routename === "register"){
      history.push('/');
    }
    dispatch(closeNavigation());
  };

  return isNavigationOpen ? <div className="z-30 backdrop-filter backdrop-blur-sm fixed top-0 left-0 h-screen w-screen" onClick={close} >
        {/* <img src={emptyPicture} className="md:w-full md:h-full"/> */}
  </div> : null;
};

export default Overlay;
