import React, { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import cx from "classnames";
import { useWallet } from "../Provide/WalletContext";
import { useIntl } from "react-intl";
import { useTracking } from "@hooks/useTracking";
import {
  Checkbox,
  PasswordField,
  RadioGroup,
  SelectBox,
  TelInput,
  TextField,
} from "../Formik";
import { differenceInYears, isValid, parseISO } from "date-fns";
import { player_fields } from "@data/model/account";
import { getIpInfo, getSessionInfo } from "../../data/model/session";
import { useFormikContext } from "formik";
import { ValidateProfileValuesNeeded } from "./ProfileValidations";

var validation = {
  isEmailAddress: function (str) {
    var pattern = /[a-zA-Z0-9!#$%&'*+-/=? ^_`{|}~]{2}@/g;
    return pattern.test(str); // returns a boolean
  },
  isValidPassword: function (str) {
    var pattern = /(?=.*\d+)(?=.*[A-Za-z]+).{8,20}/g;
    return pattern.test(str); // returns a boolean
  },
  isNumber: function (str) {
    var pattern = /^[1-9]\d*$/;
    return pattern.test(str); // returns a boolean
  },
  IsValidDate: function (value) {
    return isValid(parseISO(value));
  },
  IsEighteen: function (value) {
    return differenceInYears(new Date(), parseISO(value)) >= 18;
  },
};

const DynamicFields = ({
  profileFieldsType,
  countries,
  currencies,
  initialValues,
  method = "",
  initialRequiredInputFields = null,
}) => {
  //Get the logged in user Profile
  const { data: userProfile = {} } = useSWR("getSessionInfo", getSessionInfo);

  //Get the required fields for different contexts
  const { error: error_fields, data: profileFields } = useSWR(
    "player_fields",
    player_fields
  );

  console.log("profileFields", profileFields);
  const { data: ipInfo } = useSWR("getIpInfo", getIpInfo);
  const ref = useRef("");

  const intl = useIntl();
  const { track } = useTracking();

  //Get current wallet data
  const { currency } = useWallet();

  //Get Formik properties
  const { setFieldValue, touched, setFieldTouched, errors, values } =
    useFormikContext();

  const [currentCountrySelected, setCurrentCountrySelected] = useState(
    initialValues?.country
  );
  const [defaultFlag, setDefaultFlag] = useState(values?.country?.name);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    console.log("setDefaultFlag useEffect", values);
    setDefaultFlag(values?.country?.name);
  }, [initialValues]);

  useEffect(() => {
    setCurrentCountrySelected(initialValues?.country);
  }, [initialValues]);

  useEffect(() => {
    setCurrentCountrySelected(initialValues?.country);
  }, [initialValues]);

  useEffect(() => {
    setFieldValue("gender", "m");
  }, [initialValues]);

  useEffect(() => {
    if (
      Object.keys(touched).includes("birthdateDay") &&
      Object.keys(touched).includes("birthdateMonth") &&
      Object.keys(touched).includes("birthdateYear")
    ) {
      setFieldTouched("birthDate");
    }
    setFieldValue(
      "birthDate",
      `${values.birthdateYear}-${`0${values.birthdateMonth}`.slice(
        -2
      )}-${`0${values.birthdateDay}`.slice(-2)}`,
      true
    );
  }, [
    `${values?.birthdateYear}-${`0${values?.birthdateMonth}`?.slice(
      -2
    )}-${`0${values?.birthdateDay}`?.slice(-2)}`,
  ]);

  useEffect(() => {
    setFieldValue("mobile_phone", values?.mobile_phone?.replace(" ", ""), true);
  }, [values?.mobile_phone]);

  //Get data from SS
  const currenciesList = currencies?.currencies?.map((item, i) => ({
    name: item.code,
    text: `${item.code} (${item.symbol})`,
  }));

  const countryList = countries?.countries?.map((item, i) => ({
    name: item.code,
    text: item.name,
  }));


  useEffect(() => {
    const countryInfo = countries?.countries?.find(
      (item) => item?.code === currentCountrySelected?.name
    );
    const currencyInfo = currencies?.currencies?.find(
      (item) => item?.code === countryInfo?.currency
    );
    const euro = currencies?.currencies?.find((item) => item?.code === "EUR");
    if (countryInfo !== undefined && currencyInfo !== undefined) {
      setCurrentCountrySelected({
        name: currencyInfo?.code + " (" + currencyInfo?.symbol + ")",
      });
    } else {
      setCurrentCountrySelected({ name: "EUR (" + euro?.symbol + ")" });
    }
  }, [trigger, initialValues]);

  console.log("profileFieldsType", profileFieldsType);
  //if the type is not provided or the fields required cannot be retrieved then stop the process.
  if (!profileFieldsType || !profileFields) {
    // console.log("Failed to get SS settings on Profile Dynamic Fields");
    // track("Failed to get SS settings on Profile Dynamic Fields", {
    //   error: { profileFields, profileFieldsType },
    // });
    return null;
  } else {
    // track("SS settings on Profile Dynamic Fields loaded");
  }

  const handleRadioClick = (value) => {
    console.log("setTermsAndConditions marketing", value);

    setFieldValue("marketingOption", value, true);
    setFieldValue("marketing", value, true);

    track("Set registration marketing", {
      enable: value,
    });

    // setFieldValue(name, true);
    // setFieldValue(turnoff, false);
  };

  const setTermsAndConditions = (enabled) => {
    console.log("setTermsAndConditions", enabled);
    setFieldValue("userConsents.termsandconditions", enabled, true);
    setFieldValue("termsandcondition", enabled, true);
  };

  //Compare the required fields in the context against the currently logged in profile to confirm if all fields are populated.
  let requiredInputFields =
    initialRequiredInputFields ??
    ValidateProfileValuesNeeded(
      profileFields,
      profileFieldsType,
      userProfile,
      method
    );

  console.log("requiredInputFields", requiredInputFields);
  //Collection used to translate labels for Mandatory fields.
  const messageLabels = (fieldName) => {
    switch (fieldName) {
      case "state":
        return intl.formatMessage({
          defaultMessage: "State",
          description: "Label of inputfield",
        });
      case "email":
        return intl.formatMessage({
          defaultMessage: "E-mail",
          description: "Label of inputfield",
        });
      case "password":
      case "password_confirmation":
        return intl.formatMessage({
          defaultMessage: "Password",
          description: "Label of inputfield",
        });
      case "first_name":
        return intl.formatMessage({
          defaultMessage: "First name",
          description: "Label of inputfield",
        });
      case "last_name":
        return intl.formatMessage({
          defaultMessage: "Last name",
          description: "Label of inputfield",
        });
      case "date_of_birth":
        return intl.formatMessage({
          defaultMessage: "Date of Birth",
          description: "Label of inputfield",
        });
      case "country":
        return intl.formatMessage({
          defaultMessage: "Country",
          description: "Label of inputfield",
        });
      case "currency":
        return intl.formatMessage({
          defaultMessage: "Currency",
          description: "Label of inputfield",
        });
      case "city":
        return intl.formatMessage({
          defaultMessage: "City",
          description: "Label of inputfield",
        });
      case "address":
        return intl.formatMessage({
          defaultMessage: "Address",
          description: "Label of inputfield",
        });
      case "postal_code":
        return intl.formatMessage({
          defaultMessage: "Postcode",
          description: "Label of inputfield",
        });
      case "receive_promos":
        return intl.formatMessage({
          defaultMessage: "I would like to receive news and bonus offers",
          description: "Label Marketing consent",
        });
      case "mobile_phone":
        return intl.formatMessage({
          defaultMessage: "Mobile number",
          description: "Label of inputfield",
        });
    }
  };

  //Function used to set the correct component based on the Type of field from SS
  const PrepareFieldMarkup = (item) => {
    let fieldName = item?.fieldName;
    let fieldDetails = profileFields["fields"].filter((entry) =>
      entry.field.includes(fieldName)
    );
    console.log("Profile Fields Mandatory Details:", profileFields["fields"]);
    console.log("Profile Fields Mandatory Obj:", fieldDetails);
    console.log("Profile Fields Name:", fieldName);

    switch (fieldName) {
      case "terms_acceptance":
        return null;
      case "first_name":
        return (
          <>
            <TextField
              id="firstname"
              name="firstname"
              label={messageLabels(fieldName)}
              className="mt-5"
              validate={(value) => validate(value, fieldName)}
              // hint={intl.formatMessage({
              //   defaultMessage: 'Please enter first your name as indicated on your ID',
              //   description: 'Hint of inputfield',
              // })}
            />
          </>
        );
      case "last_name":
        return (
          <>
            <TextField
              id="surname"
              name="surname"
              label={messageLabels(fieldName)}
              // hint={intl.formatMessage({
              //   defaultMessage: 'Please enter your surname as indicated on your ID',
              //   description: 'Hint of inputfield',
              // })}
              className="mt-5"
              validate={(value) => validate(value, fieldName)}
            />
          </>
        );
      case "email":
        return (
          <>
            {!initialValues.email && (
              <TextField
                id="email"
                name="email"
                className="mt-5"
                label={messageLabels(fieldName)}
                showFeedbackIcon={false}
                showFeedbackText={true}
                validate={(value) => validate(value, fieldName)}
              />
            )}
            {initialValues.email && (
              <TextField
                id="email"
                name="email"
                type="hidden"
                showFeedbackIcon={false}
                showFeedbackText={true}
                validate={(value) => validate(value, fieldName)}
              />
            )}
          </>
        );
      case "password":
        return (
          <>
            {!initialValues.password && (
              <PasswordField
                id="password"
                name="password"
                className="mt-5"
                // hint={intl.formatMessage({
                //   defaultMessage: 'Use a minimum of 8 and a maximum of 20 characters ',
                //   description: 'Hint of inputfield',
                // })}
                label={messageLabels(fieldName)}
                validate={(value) => validate(value, fieldName)}
              />
            )}
            {initialValues.password && (
              <TextField
                id="password"
                name="password"
                type="hidden"
                showFeedbackIcon={false}
                showFeedbackText={true}
                validate={(value) => validate(value, fieldName)}
              />
            )}
          </>
        );
      case "currency":
        return (
          <>
            <SelectBox
              name="currency"
              // label={intl.formatMessage({
              //   defaultMessage: 'Currency',
              //   description: 'Label of inputfield',
              // })}
              // autoLabel={currentCountrySelected?.name}
              // setFieldValue={setCurrentCountrySelected}
              options={[
                {
                  name: "",
                  text: intl.formatMessage({
                    defaultMessage: "Please select",
                    description: "selectbox",
                  }),
                },
                ...currenciesList,
              ]}
              className="mt-5 w-full"
              showFeedbackIcon={false}
              showFeedbackText={true}
            />
          </>
        );
      case "country":
        return (
          <>
            <SelectBox
              name="country"
              // label={intl.formatMessage({
              //   defaultMessage: 'Country',
              //   description: 'Label of inputfield',
              // })}
              // setFieldValue={setCurrentCountrySelected}
              // setTrigger={setTrigger}
              // trigger={trigger}
              options={[
                {
                  name: "",
                  text: intl.formatMessage({
                    defaultMessage: "Please select",
                    description: "selectbox",
                  }),
                },
                ...countryList,
              ]}
              className="mt-5 w-full"
              showFeedbackIcon={false}
              showFeedbackText={true}
            />
          </>
        );
      case "state":

        console.log('fieldDetails', fieldDetails);
        const stateList = fieldDetails?.[0]?.inclusion?.in?.map((item, i) => ({
          name: item,
          text: item,
        })) ?? [];


        return (
            <>
              <SelectBox
                  name="state"
                  label={intl.formatMessage({
                    defaultMessage: 'State',
                    description: 'Label of inputfield',
                  })}
                  // setFieldValue={setCurrentCountrySelected}
                  // setTrigger={setTrigger}
                  // trigger={trigger}
                  options={[
                    {
                      name: "",
                      text: intl.formatMessage({
                        defaultMessage: "Please select",
                        description: "selectbox",
                      }),
                    },
                    ...stateList,
                  ]}
                  className="mt-5 w-full"
                  showFeedbackIcon={false}
                  showFeedbackText={true}
              />
            </>
        );
      case "mobile_phone":
        return (
          <>
            {ipInfo && (
              <TelInput
                hasExternalLabel
                id="mobile_phone"
                defaultCountry={ipInfo?.country_code.toLowerCase()}
                // setDefaultFlag={ipInfo?.country_code.toLowerCase()}
                preferredCountries={["de", "ca", "in"]}
                name="mobile_phone"
                label={messageLabels(fieldName)}
                className="mt-5"
                ref={ref}
                validate={(value) => validate(value, fieldName)}
              />
            )}
            <TextField
              id="mobile_phone_error"
              name="mobile_phone_error"
              type="hidden"
              showFeedbackIcon={false}
              showFeedbackText={true}
              validate={(value) => validate(value, fieldName)}
            />
          </>
        );
      case "age_acceptance":
        return (
          <>
            <div className="mt-5 mr-2">
              <div className="flex flex-row space-x-2 items-center">
                <div className="">
                  <Checkbox
                    name="termsandcondition"
                    onChange={setTermsAndConditions}
                    isTermsAndConditions={true}
                    validate={(value) => validate(value, "age_acceptance")}
                  />
                </div>
              </div>
            </div>
          </>
        );
      case "date_of_birth":
        return (
          <>
            <div className="flex flex-col align-start justify-start">
              <span className="mt-5 block text-xs text-gray ml-3 mb-1">
                {messageLabels(fieldName)}
              </span>
              <div className="flex flex-row align-start justify-start space-x-3">
                <TextField
                  type="tel"
                  hasExternalLabel
                  id="birthdateDay"
                  name="birthdateDay"
                  autocomplete={true}
                  placeholder={intl.formatMessage({
                    defaultMessage: "DD",
                    description: "Label of inputfield",
                  })}
                  className="w-20"
                  showFeedbackIcon={false}
                  showFeedbackText={false}
                  validate={(value) => validate(value, "birthdateDay")}
                />
                <TextField
                  type="tel"
                  hasExternalLabel
                  id="birthdateMonth"
                  name="birthdateMonth"
                  autocomplete={true}
                  placeholder={intl.formatMessage({
                    defaultMessage: "MM",
                    description: "Label of inputfield",
                  })}
                  className="w-20"
                  showFeedbackIcon={false}
                  showFeedbackText={false}
                  validate={(value) => validate(value, "birthdateMonth")}
                />
                <TextField
                  type="tel"
                  hasExternalLabel
                  id="birthdateYear"
                  name="birthdateYear"
                  autocomplete={true}
                  className="flex flex-1 items-end justify-end"
                  placeholder={intl.formatMessage({
                    defaultMessage: "YYYY",
                    description: "Label of inputfield",
                  })}
                  showFeedbackIcon={false}
                  showFeedbackText={false}
                  validate={(value) => validate(value, "birthdateYear")}
                />
              </div>
              <TextField
                id="birthDate"
                name="birthDate"
                type="hidden"
                showFeedbackIcon={false}
                showFeedbackText
                validate={(value) => validate(value, fieldName)}
              />
            </div>
          </>
        );

      case "gender":
        return (
          <>
            <RadioGroup
              name="gender"
              // label={intl.formatMessage({
              //   defaultMessage: 'Title',
              //   description: 'Label of inputfield',
              // })}
              options={[
                {
                  name: "m",
                  text: intl.formatMessage({
                    defaultMessage: "Male",
                    description: "Label of selectbox",
                  }),
                },
                {
                  name: "f",
                  text: intl.formatMessage({
                    defaultMessage: "Female",
                    description: "Label of selectbox",
                  }),
                },
              ]}
              className="w-full mt-5"
            />
          </>
        );
      case "address":
        return (
          <>
            <TextField
              id="address1"
              name="address1"
              className="mt-5"
              label={messageLabels(fieldName)}
              validate={(value) => validate(value, fieldName)}
            />
          </>
        );
      case "postal_code":
        return (
          <>
            <TextField
              id="postalCode"
              name="postalCode"
              label={messageLabels(fieldName)}
              className="mt-5"
              validate={(value) => validate(value, fieldName)}
            />
          </>
        );
      case "city":
        return (
          <>
            <TextField
              id="city"
              name="city"
              className="mt-5"
              label={messageLabels(fieldName)}
              validate={(value) => validate(value, fieldName)}
            />
          </>
        );
      //receive_promos is the same field
      case "receive_promos":
        return (
          <>
            <div className="mt-5 mr-2">
              <div className="flex flex-row space-x-2 items-center">
                <div className="">
                  <Checkbox
                    name="marketing"
                    onChange={handleRadioClick}
                    label={intl.formatMessage({
                      defaultMessage: "Send me free spins and bonuses",
                      description: "Label of freespins",
                    })}
                  />
                </div>
              </div>
            </div>
          </>
        );
      case "receive_sms_promos":
        return <></>;
      case "password_confirmation":
        return <></>;
      default:
        return (
          <div className="mt-4">
            <div className="flex flex-row">
              <TextField
                type="text"
                className="w-full"
                name={`fields[${fieldName}]`}
                label={messageLabels(fieldName)}
              />
            </div>
          </div>
        );
    }
  };

  const validate = (value, item = "") => {
    let errorMessage;

    switch (item) {
      case "email":
        if (value === "" || value === undefined || value === null) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        } else if (!validation.isEmailAddress(value)) {
          errorMessage = intl.formatMessage({
            defaultMessage: `Invalid e-mail address`,
            description: "Error",
          });
        }
        break;
      case "password":
        if (value === "" || value === undefined || value === null) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        } else if (!validation.isValidPassword(value)) {
          errorMessage = intl.formatMessage({
            defaultMessage: `A minimum of 8 characters and 1 number`,
            description: "Error",
          });
        }
        break;
      case "mobile_phone":
        if (value === "" || value === undefined || value === null) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        } else if (value === "invalid_phone_number") {
          errorMessage = intl.formatMessage({
            defaultMessage: `This is not a valid phone number`,
            description: "Error",
          });
        }
        break;
      case "first_name":
      case "last_name":
        if (value === "" || value === undefined || value === null) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        } else {
          if (value.length < 2) {
            errorMessage = intl.formatMessage(
              {
                defaultMessage:
                  "The input is too short. Please use at least {num} characters",
                description: "Validation",
              },
              { num: 2 }
            );
          } else if (value.length > 50) {
            errorMessage = intl.formatMessage(
              {
                defaultMessage:
                  "The input is too long. Please use less then {num} characters",
                description: "Validation",
              },
              { num: 50 }
            );
          }
        }

        break;
      // case "birthdateDay":
      // case "birthdateMonth":
      // case "birthdateYear":
      //     if(value === '' || value === undefined || value === null)
      //     {
      //         errorMessage = intl.formatMessage({
      //             defaultMessage: `This field is required`,
      //             description: 'Error',
      //         })
      //     }
      //     else
      //     {
      //          //console.log("Profile validation test:", item, value);
      //         if(!validation.isNumber(value)){

      //           errorMessage = intl.formatMessage({
      //               defaultMessage: 'This field can only contain numbers',
      //               description: 'Validation',
      //               })
      //         }
      //     }
      case "date_of_birth":
        if (value === "" || value === undefined || value === null) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        } else {
          if (!validation.IsValidDate(value)) {
            errorMessage = intl.formatMessage({
              defaultMessage: "This is not a valid date",
              description: "Validation",
            });
          } else if (!validation.IsEighteen(value)) {
            errorMessage = intl.formatMessage({
              defaultMessage: "You need to be 18 years or older to join",
              description: "Validation",
            });
          }
        }
        break;
      case "city":
        if (value === "" || value === undefined || value === null) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        }
        break;
      case "address":
      case "postal_code":
        if (value === "" || value === undefined || value === null) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        } else {
          if (value.length < 4) {
            errorMessage = intl.formatMessage(
              {
                defaultMessage:
                  "The input is too short. Please use at least {num} characters",
                description: "Validation",
              },
              { num: 4 }
            );
          } else if (value.length > 50) {
            errorMessage = intl.formatMessage(
              {
                defaultMessage:
                  "The input is too long. Please use less then {num} characters",
                description: "Validation",
              },
              { num: 50 }
            );
          }
        }
        break;

      case "age_acceptance":
        if (
          value === "" ||
          value === undefined ||
          value === null ||
          value === false
        ) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        }
        break;

      case "city":
        if (value === "" || value === undefined || value === null) {
          errorMessage = intl.formatMessage({
            defaultMessage: `This field is required`,
            description: "Validation",
          });
        }
        break;
    }

    return errorMessage;
  };

  return (
    <>
      {requiredInputFields && requiredInputFields.length > 0 ? (
        requiredInputFields
          ?.sort((a, b) => (a.orderNo > b.orderNo ? 1 : -1))
          .map((item, i) => {
            return <div className={cx("mt-0")}>{PrepareFieldMarkup(item)}</div>;
          })
      ) : (
        <></>
      )}
    </>
  );
};

export default DynamicFields;
