import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useIntl } from "react-intl";
import { TextField } from "@components/Formik";
import useSWR from "swr";
import {
  getLimits,
  saveLimit,
  removeLimit,
} from "@data/model/responsible-gaming";
import SubmitFeedback from "@components/Formik/SubmitFeedback";
import { Transition } from "@headlessui/react";
import { useTracking } from "@hooks/useTracking";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import Button from "../../../Button";
import { useRouter } from "../../../Provide/RouterContext";
import deleteIcon from "../../../../../public/delete-icon.png";

const SetSessionLimit = ({ setMenuItem, setBackActions, setSection }) => {
  useEffect(
    () =>
      setBackActions((prevState) => {
        prevState.push((x) => {
          setSection("Main");
          setMenuItem("ResponsibleGaming");
        });
        return prevState;
      }),
    []
  );
  const intl = useIntl();
  const { error, data } = useSWR("getLimits", getLimits);
  const { track } = useTracking();
  const [currentLimits, setCurrentLimits] = useState([]);
  const [hasUncomfirmedDisable, setHasUncomfirmedDisable] = useState(false);
  const [hasUncomfirmed, setHasUncomfirmed] = useState(false);
  const [isInactiveOrDisabled, setIsInactiveOrDisabled] = useState(false);
  const [isInactiveOrDisabledItem, setIsInactiveOrDisabledItem] = useState("");

  useEffect(() => {
    const current = data?.filter((item) => item.type === "session");
    if (current) {
      setCurrentLimits(current);
    }
  }, [data]);

  useEffect(() => {
    currentLimits?.map(
      (item) => (
        item?.status === "unconfirmed_disable" &&
          setHasUncomfirmedDisable(true),
        item?.status === "unconfirmed" && setHasUncomfirmed(true),
        (item.status === "confirmed - inactive limit" ||
          item.status === "confirmed_disable" ||
          item.status === "confirmed_disable - active limit") &&
          (setIsInactiveOrDisabled(true), setIsInactiveOrDisabledItem(item))
      )
    );
  }, [currentLimits]);

  console.log("currentLimits", isInactiveOrDisabledItem);

  const remove = (id, formikBag) => {
    const { setStatus, setFieldError, setSubmitting, setFieldValue } =
      formikBag;
    setFieldValue("action", "remove");
    removeLimit(id)
      .then(() => {
        track("Session limit removal success", { id });
        setStatus("success");
        setSubmitting(false);
      })
      .catch((err) => {
        track("Session limit removal error", { error: err });
        setSubmitting(false);
        setFieldError("formState", err.desc);
        setStatus("error");
      });
  };
  const router = useRouter();

  const handleSubmit = (values, formikBag) => {
    const { setStatus, setFieldError, setSubmitting, setFieldValue } =
      formikBag;
    setFieldValue("action", "add");
    saveLimit({ period: values.minutes, type: "session" })
      .then(() => {
        track("Session limit added success", values);
        setStatus("success");
        setSubmitting(false);
      })
      .catch((err) => {
        track("Session limit added error", { error: err });
        setSubmitting(false);
        setFieldError("formState", err.desc);
        setStatus("error");
      });
  };
  return (
    <>
      <Formik
        initialStatus="init"
        initialValues={{
          action: "",
          minutes:
            currentLimits?.find((item) => item.status === "active")?.period ??
            60,
        }}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          values,
          status,
          setStatus,
          setFieldError,
          setSubmitting,
          setFieldValue,
        }) => (
          <Form>
            <h4 className="mb-16 text-2xl font-light leading-5 text-center text-white capitalize  sticky top-0 bg-[#13161d] max-w-full ms-auto me-auto pt-0 pb-[11px] z-[9] ml-0">
              {intl.formatMessage({
                defaultMessage: "Session limit",
                description: "Header of section",
              })}
            </h4>
            <Transition
              show={status === "init"}
              leave="transition ease-in duration-500"
              leaveFrom="transform opacity-100"
              leaveTo="transform -translate-x-80 opacity-0"
              className="flex flex-col w-full"
              unmount={false}
            >
              <div className="mx-auto mt-5.5 flex-col flex w-full items-stretch px-2.5 mb-10 md:mb-0 rtl:text-right">
                <span className="mb-4 text-white">
                  {intl.formatMessage({
                    defaultMessage:
                      "Control the duration you are able to play every day.",
                    description: "Label",
                  })}
                </span>

                {currentLimits?.map((item) => (
                  <>
                    {item.status === "active" && (
                      <div className="flex flex-row justify-between w-full px-3 pt-4 pb-1 my-2 text-white rounded-lg bg-brand-600 h-14">
                        <span className="text-brand-400">
                          {intl.formatMessage({
                            defaultMessage: "Current Limit",
                            description: "Label ",
                          })}
                        </span>{" "}
                        <span className="flex font-bold text-green ">
                          {item.period}{" "}
                          {intl.formatMessage({
                            defaultMessage: "minutes per day",
                            description: "Label",
                          })}
                          <span
                            className="cursor-pointer "
                            onClick={() =>
                              remove(item.id, {
                                setStatus,
                                setFieldError,
                                setSubmitting,
                                setFieldValue,
                              })
                            }
                          >
                            <img className="h-4 mt-1 ml-2 " src={deleteIcon} />
                          </span>
                        </span>
                      </div>
                    )}
                  </>
                ))}

                {hasUncomfirmedDisable && (
                  <div className="my-2 text-base text-red-error">
                    {intl.formatMessage({
                      defaultMessage: "Pending Disable via email link.",
                      description: "Label ",
                    })}
                  </div>
                )}

                {hasUncomfirmed && (
                  <div className="m-2 text-base text-red-error">
                    {intl.formatMessage({
                      defaultMessage:
                        "You have requested a change to your session limit that you have not yet confirmed. Please open the link in the email we have sent to confirm your new limit.",
                      description: "Label ",
                    })}
                  </div>
                )}
                {isInactiveOrDisabled && (
                  <div className="my-2 text-base text-red-error">
                    {intl.formatMessage(
                      {
                        defaultMessage:
                          "You have a pending update of your Session limit. This will come into effect on {date}.",
                        description: "Label ",
                      },
                      {
                        date: format(
                          parseISO(isInactiveOrDisabledItem?.confirm_until),
                          "dd MMMM yyyy, HH:mm:ss"
                        ),
                      }
                    )}
                  </div>
                )}

                <span className="mt-2 text-lg">
                  {intl.formatMessage({
                    defaultMessage: "Set a session limit",
                    description: "Label",
                  })}
                </span>

                <TextField
                  className="mt-4"
                  name="minutes"
                  label={intl.formatMessage({
                    defaultMessage: "Minutes",
                    description: "Label",
                  })}
                  type="text"
                />

                <div className="flex flex-row my-8">
                  <Button.Action
                    trackName="Submit Session limit"
                    trackParams={{ component: "SetSessionLimit" }}
                    className={cx("w-full", { "animate-pulse": isSubmitting })}
                    disabled={!!isSubmitting}
                    type="submit"
                    arrow="right"
                  >
                    {isSubmitting
                      ? intl.formatMessage({
                          defaultMessage: "Saving...",
                          description:
                            "When submitting a button this generic terms is used while waiting",
                        })
                      : intl.formatMessage({
                          defaultMessage: "Set session limit",
                          description: "The label on a button for saving",
                        })}
                  </Button.Action>
                </div>
              </div>
            </Transition>

            {values?.action === "add" && (
              <SubmitFeedback
                status={status}
                header={intl.formatMessage({
                  defaultMessage: "Confirmed",
                  description: "The feedback header",
                })}
              >
                <div className="p-8 leading-7 text-24">
                  {intl.formatMessage(
                    {
                      defaultMessage:
                        "You’ve set a session limit of {amount} minutes.",
                      description: "The feedback after setting a limit",
                    },
                    {
                      amount: (
                        <span className="font-bold text-green">
                          {values.minutes}
                        </span>
                      ),
                    }
                  )}
                </div>
                <div className="px-8">
                  <Button.Action
                    trackName="Back to my account from Set Session Limit"
                    trackParams={{ component: "SetSessionLimit" }}
                    onClick={() => setMenuItem("Root")}
                    className="w-full"
                    arrow="right"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Back to my account",
                      description: "Label",
                    })}
                  </Button.Action>
                </div>
              </SubmitFeedback>
            )}
            {values?.action === "remove" && (
              <SubmitFeedback
                status={status}
                header={intl.formatMessage({
                  defaultMessage: "Confirmed",
                  description: "The feedback header",
                })}
              >
                <div className="p-8 leading-7 text-24">
                  {intl.formatMessage({
                    defaultMessage:
                      "Your limit will be removed after a 24 hour grace period from confirming limit removal via email link.",
                    description: "The feedback after setting a limit",
                  })}
                </div>
                <div className="px-8">
                  <Button.Action
                    trackName="Back to my account from Set Session Limit"
                    trackParams={{ component: "SetSessionLimit" }}
                    onClick={() => setMenuItem("Root")}
                    className="w-full"
                    arrow="right"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Back to my account",
                      description: "Label",
                    })}
                  </Button.Action>
                </div>
              </SubmitFeedback>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SetSessionLimit;
