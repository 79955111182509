import React, { Fragment, useRef } from "react";
import { Popover, Transition } from "@headlessui/react";
import { useIntl } from "react-intl";
import Button from "../Button";
import Link from "../Link/Link";

/**
 * BreadCrumbs Component
 *
 * Show Breadcrumbs
 *
 * @category   BreadCrumbs
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */

const ConfirmationBox = ({
  intro,
  text,
  trigger,
  confirmationAction,
  confirmationLabel,
  cancelLable,
}) => {
  const intl = useIntl();
  const buttonRef = useRef();

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button ref={buttonRef}>{trigger}</Popover.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="fixed z-10 w-full max-w-sm px-4 mt-3 overflow-hidden transform -translate-x-1/2 -translate-y-1/2 rounded-lg top-1/2 left-1/2 sm:px-0 lg:max-w-lg">
              <div className="p-8 overflow-hidden bg-black rounded shadow-lg">
                <div className="text-xl font-bold text-center text-white">
                  {intro}
                </div>
                <div className="text-base text-center text-brand-400">
                  {text}
                </div>
                <div className="flex flex-col items-center justify-center mt-4 space-y-4 md:flex-row md:space-x-4 md:space-y-0">
                  <Button.Action
                    onClick={confirmationAction}
                    className=""
                    arrow="right"
                  >
                    {confirmationLabel}
                  </Button.Action>
                  {/* <Popover.Button as={Fragment}> */}
                  <Popover.Panel onClick={() => buttonRef?.current?.click()}>
                    <Link className="text-gray">{cancelLable}</Link>
                  </Popover.Panel>
                  {/* </Popover.Button> */}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ConfirmationBox;
