import { isBrowser } from 'browser-or-node';
import {getDomainName} from "./utils";

const ENDPOINT = isBrowser ? `https://backend.${getDomainName(window.location.hostname)}` : 'https://backend.theclubhousecasino.com';

export const defaultRequester = {
  apiGet: (uri) => window.PaymentsAPI.defaultRequester.apiGet(ENDPOINT + uri),
  apiPost: (uri, data) => window.PaymentsAPI.defaultRequester.apiPost(ENDPOINT + uri, data),
  apiPut: (uri, data) => window.PaymentsAPI.defaultRequester.apiPut(ENDPOINT + uri, data),
  redirect: (isBrowser) ? window?.PaymentsAPI?.defaultRequester?.redirect : null,
};
