import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import useSWR from "swr";
import Link from "@components/Link";
import { useWallet } from "@components/Provide/WalletContext";
import { getDomainName, titleize } from "../../../core/utils";
import { useRouter } from "../../Provide/RouterContext";
import CurrencyChange from "../../Deposit/DepositForm/CurrencyChange/CurrencyChange";
import { openNavigation } from "@actions/navigation";
import { useDispatch } from "react-redux";
import { useAuth } from "../../Provide/AuthContext";
import { getCurrencies, getGamingAccounts } from "../../../data/model/wallet";
import { postCall } from "../../../core/softswiss";
import { getIpInfo } from "../../../data/model/session";
import { useQuery } from "@apollo/client";
import getProvidersQuery from "@components/GameBrowser/Templates/ProvidersList/get-providers.graphql";
import Icon from "../../Icon/Icon";
import { Transition } from "@headlessui/react";
import Button from "../../Button";
import { isBrowser } from "browser-or-node";
import payop from "../../../../public/img/logos/payop-payop_online_bank_transfer.svg";
import { getDocuments } from "../../../data/model/documents";
import { DOC_TYPE, DOCUMENT_STATUS } from "../../../data/constant";

const ENDPOINT = isBrowser
  ? `https://backend.${getDomainName(window.location.hostname)}`
  : "https://backend.theclubhousecasino.com";
const getIconCDNNameFallback = (currency, paymentMethod) => {
  console.log("paymentMethod", paymentMethod);
  console.log("paymentMethod?.icon_cdn_name", paymentMethod?.icon_cdn_name);
  if (paymentMethod?.icon_cdn_name) {
    return `https://cdn2.softswiss.net/logos/payments/white/${paymentMethod.icon_cdn_name}.svg`;
  }
  console.log("paymentMethod", paymentMethod);
  if (paymentMethod?.brand === "visa") {
    return `https://cdn2.softswiss.net/logos/payments/white/visa.svg`;
  }

  if (paymentMethod?.brand === "mastercard") {
    return `https://cdn2.softswiss.net/logos/payments/white/mastercard.svg`;
  }

  if (
    paymentMethod?.brand === "redirect_deposit" &&
    paymentMethod.provider === "finteqhub"
  ) {
    return payop;
  }

  if (paymentMethod?.provider === "payop") {
    return "https://cdn2.softswiss.net/logos/payments/white/bank_transfer.svg";
  }
  if (paymentMethod?.brand === "volt") {
    return "https://cdn2.softswiss.net/logos/payments/white/devcode_volt_white.svg";
  }
  if (paymentMethod.provider === paymentMethod?.brand) {
    paymentMethod.icon_cdn_name = paymentMethod.provider;
  } else {
    paymentMethod.icon_cdn_name =
      paymentMethod.provider + "-" + paymentMethod?.brand;
  }

  if (paymentMethod.provider === "coinspaid") {
    const split = paymentMethod.id.split("_");

    ("coinspaid_coinspaid-51_DOG_coinspaid");
    paymentMethod.icon_cdn_name = paymentMethod.currency
      ? paymentMethod.currency + "_" + paymentMethod.icon_cdn_name
      : `${split[2]}_${split[3]}`;

    return `https://cdn2.softswiss.net/logos/payments/white/${paymentMethod.icon_cdn_name}.svg`;
  }
  if (paymentMethod?.brand === "sticpay") {
    paymentMethod.icon_cdn_name =
      paymentMethod.provider + "_" + paymentMethod?.brand;
  }

  //   <a href="https://casino.com/payments" target="_blank"><img src="{{$root.CDN.SERVER}}/logos/payments/white/devcode_sticpay.svg
  //
  // <a href="https://casino.com/payments" target="_blank"><img src="{{$root.CDN.SERVER}}/logos/payments/white/rapid-transfer.svg

  if (paymentMethod?.brand === "rapidtransfer") {
    paymentMethod.icon_cdn_name = "rapid-transfer";
  }

  if (
    ["skrill", "eco_payz", "neosurf", "neteller", "paysafecard"].includes(
      paymentMethod?.brand
    )
  ) {
    paymentMethod.icon_cdn_name = paymentMethod?.brand.replace(/_/g, "");
  }
  if (["inpay", "netbanking"].includes(paymentMethod?.brand)) {
    paymentMethod.icon_cdn_name = "bank_transfer";
  }

  if (
    paymentMethod.provider === "accentpay" &&
    paymentMethod?.brand === "card"
  ) {
    //if (action && service.data[action].selectedCurrency) {
    paymentMethod.icon_cdn_name = currency + "_" + paymentMethod.icon_cdn_name;
    //}
  }

  return `https://cdn2.softswiss.net/logos/payments/white/${paymentMethod.icon_cdn_name}.svg`;
};

const WithdrawList = ({ setStatus, setFieldValue, setMenuItem }) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const { data: ipInfo } = useSWR("getIpInfo", getIpInfo);
  const [showConformation, setShowConformation] = useState("");
  const [remvoedCard, setRemvoedCard] = useState("");
  const { currency } = useWallet();
  const { data: methods } = useSWR(
    ["fetchPaymentMethods", currency, ipInfo?.code],
    (url, currency) =>
      window.PaymentsAPI.config({
        serverUrl: ENDPOINT,
        apiPrefix: "/api",
        customCdnUrl: "https://cdn2.softswiss.net/logos/payments/white/",
      }).then(() =>
        window.PaymentsAPI.getMethods({
          currency: currency,
          paymentAction: "cashout",
        }).then((e) => {
          setPaymentMethods(e);
        })
      )
  );
  // console.log('paymentMethods--paymentMethods-with',paymentMethods);
  const { data: paymentProviders } = useQuery(getProvidersQuery, {
    variables: { psection: "Payment Provider" },
  });

  const getIconCDNName = (softswissID, method, currency) => {
    return getIconCDNNameFallback(currency, method);
    let item = paymentProviders?.ProvidersBySection?.find(
      (x) => x.softswiss_id === softswissID
    );

    if (!item) {
      return getIconCDNNameFallback(currency, method);
    }
    return `${COCKPITCFG.url}${
      SetImagePath(item?.image?.path) ?? `/${softswissID}`
    }`;
  };

  const SetImagePath = (imagePath) => {
    if (imagePath !== undefined) {
      let start = imagePath.charAt(0);

      if (start !== "/") {
        return "/" + imagePath;
      }
    }

    return imagePath;
  };

  const getIconCDN = (softswissID) => {
    let item = paymentProviders?.ProvidersBySection?.find(
      (x) => x.softswiss_id === softswissID
    );
    return item ? `${COCKPITCFG.url}${SetImagePath(item?.image?.path)}` : false;
  };

  // const { data: savedChannels } = useSWR(['fetchSavedChannels', currency], (url, currency) => window.PaymentsAPI.savedChannels('cashout'));
  const { isAuthenticated } = useAuth();
  const { error, data, mutate } = useSWR("getDocuments", getDocuments);

  const { data: wallets } = useSWR(
    isAuthenticated ? "/api/player/accounts" : null,
    getGamingAccounts,
    { refreshInterval: 5000 }
  );
  const activeWallets = wallets?.filter((item) => item.active === true);
  const dispatch = useDispatch();
  const open = (section) => dispatch(openNavigation(section ?? "Root"));
  // console.log("paymentMethods", paymentMethods, currency);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { data: currencies } = useSWR("getCurrencies", getCurrencies);
  let currentWallet = currencies?.filter((cur) => cur?.code === currency);

  // let availableWallets = [];
  // const removeExistentWallets = (
  //   currencies?.forEach((item) => {
  //     activeWallets?.forEach((wallet) => {
  //       if(item.code === wallet.currency){
  //         availableWallets.push(wallet)
  //       }
  //     })
  //   })
  // );
  // let storedCards = [];
  // paymentMethods?.map((item) => {
  //   const card = window.PaymentsAPI.savedChannels('cashout', item);
  //
  //
  //   if (card.length > 0) {
  //     console.log('item',item);
  //     storedCards.push(item);
  //   }
  // });
  const handleCurrencyChange = (curr) => {
    setIsLoading(true);
    postCall(`/api/player/accounts?compatibility=false&currency=${curr}`)
      .then(() => {
        setIsLoading(false);
        console.log("currency change success");
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("currency change failed", err);
      });
  };
  const intl = useIntl();
  const router = useRouter();
  const cards = [];
  paymentMethods?.map((item) => {
    const savedCards = item.savedProfiles;
    if (savedCards.length > 0) {
      savedCards.map((savedItem) => {
        console.log("savedCards", savedCards);

        const card = (
          <>
            <div
              trackName={("Payment Method ", item?.brand)}
              trackParams={{ component: "WithdrawList" }}
              className="relative flex items-center justify-between w-auto p-3 text-lg border-solid rounded cursor-pointer bg-brand-600 "
            >
              <div
                className="flex items-center w-full space-x-2"
                onClick={() => {
                  setFieldValue("method", item);
                  setFieldValue("savedId", savedItem.id);
                  setStatus({ step: "form" });
                }}
              >
                {/*{item?.brand !== "creditcard" ? (*/}
                {/*  <img*/}
                {/*    src={*/}
                {/*      item?.brand === "combined"*/}
                {/*        ? `https://cdn2.softswiss.net/logos/payments/white/Interac_new_white.svg`*/}
                {/*        : getIconCDNName(item.id, item, currency)*/}
                {/*    }*/}
                {/*    className={cx("opacity-50", {*/}
                {/*      "md:h-6 h-6":*/}
                {/*        item.provider === "payop" ||*/}
                {/*        item.provider === "finteqhub" ||*/}
                {/*        item?.brand === "mifinity",*/}
                {/*      "h-6 md:h-6": item.provider !== "payop",*/}
                {/*    })}*/}
                {/*  />*/}
                {/*) : (*/}
                {/*  <Icon icon="card" className={`w-6 h-6 text-brand-400`} />*/}
                {/*)}*/}

                <div>
                  <p className={"leading-none"}>{savedItem?.title}</p>
                  <span className="block text-sm text-brand-400">
                    {intl.formatNumber(
                      item?.termsOfService?.restrictions?.minAmountValue,
                      {
                        style: "currency",
                        currency: currency,
                        minimumFractionDigits:
                          currentWallet?.[0]?.subunits_to_unit?.toString()
                            ?.length - 1 ?? 2,
                        maximumFractionDigits:
                          currentWallet?.[0]?.subunits_to_unit?.toString()
                            ?.length - 1 ?? 2,
                      }
                    )}{" "}
                    -{" "}
                    {intl.formatNumber(
                      item?.termsOfService?.restrictions?.maxAmountValue,
                      {
                        style: "currency",
                        currency: currency,
                        minimumFractionDigits:
                          currentWallet?.[0]?.subunits_to_unit?.toString()
                            ?.length - 1 ?? 2,
                        maximumFractionDigits:
                          currentWallet?.[0]?.subunits_to_unit?.toString()
                            ?.length - 1 ?? 2,
                      }
                    )}
                  </span>
                </div>
              </div>
              {savedItem?.isRemoveAvailable && (
                <Link
                  onClick={() => {
                    setShowConformation(savedItem?.id);
                  }}
                >
                  <Icon icon="trash" className={"w-5 h-5 text-brand-400"} />
                </Link>
              )}
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                className={
                  "fixed top-0 left-0 h-full w-full bg-black bg-opacity-70 z-50 origin-center"
                }
                show={showConformation === savedItem?.id}
              >
                <div className="absolute flex items-center w-full max-w-xs p-4 transform -translate-x-1/2 -translate-y-1/2 rounded-lg top-1/2 left-1/2 bg-brand-600">
                  <div className="flex items-center justify-between w-full">
                    <p> Are you sure?</p>
                    <div className="space-x-3">
                      <Button.Action
                        className={"h-8"}
                        onClick={(e) => {
                          window.PaymentsAPI.deleteSavedProfile({
                            id: item.id,
                            currency,
                            paymentAction: "cashout",
                            savedProfileId: savedItem?.id,
                          });
                          setRemvoedCard(savedItem?.id);
                        }}
                      >
                        Yes
                      </Button.Action>
                      <Button.Action
                        className={"bg-brand-400 h-8"}
                        onClick={() => setShowConformation("")}
                      >
                        No
                      </Button.Action>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </>
        );

        if (card) {
          cards.push(card);
        }
      });
    }
  });

  const isProofOfIdentityApproved = useMemo(() => {
    const documents = data?.filter(
      (x) => x.document_type === DOC_TYPE.PAYMENT_METHOD
    );
    if (documents && documents?.length > 0) {
      return !!documents?.every(
        (doc) => doc.status === DOCUMENT_STATUS.APPROVED
      );
    } else {
      return false;
    }
  }, [data]);

  console.log("isProofOfIdentityApproved", isProofOfIdentityApproved);
  console.log("cards", cards);
  return (
    <div className="flex flex-col w-full pb-4">
      <h4 className="mb-6 text-2xl font-light leading-5 text-center text-white">
        {intl.formatMessage({
          defaultMessage: "Withdraw",
          description: "header",
        })}
      </h4>
      {!isProofOfIdentityApproved && (
        <div className="py-3 border-y border-brand-500 bg-brand-600 px-2.5 -mx-3 mt-20 relative flex justify-end">
          <div className="absolute bottom-0 left-3 overflow-hidden max-h-[160px] max-w-52">
            <img src="/img/character.svg" alt="character" className="" />
          </div>
          <div className="max-w-56">
            <p className="mb-2.5 text-xs font-normal text-white leading-tight text-wrap">
              {intl.formatMessage({
                defaultMessage:
                  "To make a withdrawal you’ll need to quickly verify your identity.",
                description: "withdrawal verify identity message",
              })}
            </p>
            <Button.Action
              trackName="Action"
              trackParams={[router?.routename, `Verify now - ${currency}`]}
              arrow={"right"}
              onClick={() => {
                open("DocumentVerification");
              }}
              className={
                "p-0 text-gold bg-transparent h-auto px-0 text-base font-normal leading-tight"
              }
              arrowClassName={"!ml-1.5"}
            >
              {intl.formatMessage({
                defaultMessage: "Verify now",
                description: "Label",
              })}
            </Button.Action>
          </div>
        </div>
      )}
      <div className="relative flex flex-col items-stretch w-full pb-5 mt-14">
        <CurrencyChange
          open={open}
          setMenuItem={setMenuItem}
          onChange={(curr) => handleCurrencyChange(curr)}
          wallets={activeWallets}
          currency={currency}
          currencySymbol={currencySymbol}
          setCurrencySymbol={setCurrencySymbol}
        />
        {/* setNewCurrency={} currencySymbol={} setCurrencySymbol={} */}
      </div>
      <h3 className="mb-1">
        {intl.formatMessage({
          defaultMessage: "Choose your withdraw method",
          description: "Withdraw list header",
        })}
      </h3>
      {cards?.length > 0 && (
        <span>
          {intl.formatMessage({
            defaultMessage: "Your stored card(s)",
            description: "Withdraw card header",
          })}
        </span>
      )}
      {cards?.length > 0 && (
        <div className="flex flex-col mt-2 mb-4">{cards}</div>
      )}

      <div className="flex flex-col">
        {paymentMethods?.map(
          (item) =>
            !item.isForSavedProfilesOnly && (
              // window.PaymentsAPI.SUPPORTED_PROVIDERS.includes(item.provider) &&
              // window.PaymentsAPI.isPaymentMethodEnabled("cashout", item) &&
              // window.PaymentsAPI.isNewPaymentEnabled("cashout", item) && (
              <Link
                trackName={("Payment Method ", item?.brand)}
                trackParams={{ component: "WithdrawList" }}
                onClick={() => {
                  setFieldValue("method", item);
                  setStatus({ step: "form" });
                }}
                className="relative flex flex-col items-center justify-center w-full h-24 mt-4 rounded bg-brand-600"
              >
                <div className="flex items-center justify-center flex-1">
                  <img
                    src={
                      item?.brand === "interac_finteqhub"
                        ? `https://cdn2.softswiss.net/logos/payments/white/finteqhub-interac_finteqhub_cashout.svg`
                        : getIconCDNName(item.id, item, currency)
                    }
                    className="opacity-50 h-9"
                  />
                </div>
                <div className="flex flex-row items-end justify-between w-full px-4 pb-2">
                  <span className="font-bold text-brand-400">
                    {item?.brand === "einterac" ||
                    item?.brand === "interac_finteqhub"
                      ? "Interac® eTransfer"
                      : titleize(item?.brand)}
                  </span>
                  <span className="text-sm text-brand-400">
                    {item?.type === "crypto"
                      ? item?.termsOfService?.restrictions?.minAmountValue
                      : intl.formatNumber(
                          item?.termsOfService?.restrictions?.minAmountValue,
                          {
                            style: "currency",
                            currency:
                              item?.termsOfService?.restrictions
                                ?.amountCurrencyCode ?? currency,
                            minimumFractionDigits:
                              currentWallet?.[0]?.subunits_to_unit?.toString()
                                ?.length - 1 ?? 2,
                            maximumFractionDigits:
                              currentWallet?.[0]?.subunits_to_unit?.toString()
                                ?.length - 1 ?? 2,
                          }
                        )}{" "}
                    -{" "}
                    {item?.type === "crypto"
                      ? item?.termsOfService?.restrictions?.maxAmountValue
                      : intl.formatNumber(
                          item?.termsOfService?.restrictions?.maxAmountValue,
                          {
                            style: "currency",
                            currency:
                              item?.termsOfService?.restrictions
                                ?.amountCurrencyCode ?? currency,
                            minimumFractionDigits:
                              currentWallet?.[0]?.subunits_to_unit?.toString()
                                ?.length - 1 ?? 2,
                            maximumFractionDigits:
                              currentWallet?.[0]?.subunits_to_unit?.toString()
                                ?.length - 1 ?? 2,
                          }
                        )}
                  </span>
                </div>
              </Link>
            )
        )}
      </div>
    </div>
  );
};
export default WithdrawList;
