import React, { useEffect } from 'react';
import RootModal from '@components/Modal/RootModal';
import { Dialog, Transition } from '@headlessui/react';
import TopBar from '../../TopBar/TopBar';
import { useIntl } from 'react-intl';
import Button from '../../Button';
import { openNavigation } from '@actions/navigation';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { useWallet } from '../../Provide/WalletContext'
import useSWR from 'swr';
import { getCurrencies } from '../../../data/model/wallet';
import depositLimits from '../assets/depositLimits.json';

const DepositModal = ({ isActive, hideModal }) => {
const dispatch = useDispatch();
const intl = useIntl();
const open = (section = 'Root') => dispatch(openNavigation(section));
const cookies = new Cookies();
const { currency } = useWallet();
const {data: currencies} = useSWR('getCurrencies', getCurrencies);

const updateCookie = () => {
  let currentTime = new Date();
  let currentDateTime = new Date(currentTime.getTime());
  cookies.set('depositTrigger', currentDateTime, {path: '/'});
}

let currentWallet = currencies?.filter(cur => cur?.code === currency);
let currentWalletCurrency = currentWallet?.[0]?.code;
let depositLimit = depositLimits[currentWalletCurrency];

console.log("deposit limit", depositLimit)

useEffect(() => {
  console.log("modal isActive", isActive)
  if(!isActive){
    updateCookie();
  }
}, [isActive])

return (
<RootModal hideModal={hideModal} isActive={Boolean(isActive)}>
    <Transition.Child
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="flex flex-col  flex-shrink-0 w-screen h-full md:h-auto justify-start items-start md:items-center relative z-50 overflow-auto pb-24 md:pb-0"
    >
      <>
      {/* <TopBar/> */}
        <div id='deposit-notification' className="md:min-h-screen flex flex-shrink-0 flex-col items-start justify-start m-auto mx-5 md:mx-0">
          <div className="flex flex-1 flex-col md:flex-none md:grid md:grid-rows-12 md:grid-flow-col md:min-h-modal-reg overflow-auto md:overflow-visible my-auto bg-brand rounded-md">
            <div className="md:row-span-12 flex w-full md:order-last flex-col bg-gray-lightest md:w-modal py-10 px-2 md:px-10 md:p-9.5 md:rounded md:rounded-bl-none relative">
              <div className='w-full md:w-110 bg-brand text-white'>
                <h1 className='font-light mb-6 text-4xl text-center'>
                    {intl.formatMessage({
                    defaultMessage: 'Deposit now to keep playing',
                    description: 'Header for Deposit Notification Modal',
                    })}
                </h1>
                <p className='mb-6 text-base text-center'>
                    {intl.formatMessage({
                    defaultMessage: 'Your balance is under {amount}. To keep enjoying The Clubhouse Casino deposit now.',
                    description: 'Paragraph for Deposit Notification Modal',
                    },{amount: intl.formatNumber(depositLimit / (currentWallet?.[0]?.subunits_to_unit), {
                      style: 'currency',
                      currency: currency,
                      minimumFractionDigits: (currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1) ?? 2,
                      maximumFractionDigits: (currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1) ?? 2,
                    })})}
                </p>
                <div className='md:px-10 mx-4 md:mx-0'>
                    <Button.Action
                        trackName="Deposit" trackParams={{component: 'Deposit Modal'}}
                        className="inline-flex items-center justify-between w-full btn"
                        arrow="right"
                        onClick={ () => {updateCookie(); hideModal(); open('Deposit')}}
                    >
                        { intl.formatMessage({
                        defaultMessage: 'Deposit now',
                        description: 'Deposit Notification Modal button',
                        })}{' '}
                    </Button.Action>
                </div>
                <div className='w-full text-center mt-6' onClick={() => {updateCookie()}}>
                    <a style={{color:`#ddaf6c`}}
                    onClick={hideModal}
                    className='mt-4 text-brand text-base cursor-pointer'>
                        {intl.formatMessage({
                        defaultMessage: 'No thanks',
                        description: 'Close for Deposit Notification Modal',
                        })}
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </>
    </Transition.Child>
    <Transition.Child
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog.Overlay className="block absolute bg-black bg-opacity-70 h-screen w-screen top-0 l-0 z-40" />
    </Transition.Child>
  </RootModal>
  )}
;
DepositModal.displayName = 'DepositModal'
export default DepositModal;
