import AutobahnReact from '../../core/websocket';
import { getCall, patchCall } from '../../core/softswiss';

export const setBonusCode = (deposit_bonus_code) => patchCall('/api/player/set_bonus_code', { deposit_bonus_code });

export const getPaymentMethods = (currency) => getCall(`/api/payments/payment_methods/${currency}`);

export const getPaymentMethodCfg = (key, paymentMethodCode, payCardID = null) => AutobahnReact('everymatrix').call('/user/deposit#getPaymentMethodCfg', {
  paymentMethodCode,
  payCardID,
});
//
// export const prepare = (paymentMethodCode, fields) => AutobahnReact('everymatrix').call('/user/deposit#prepare', {
//   paymentMethodCode,
//   fields,
//   returnURL: 'https://dev.vindercasino.dk/',
// });

export const confirm = (pid) => AutobahnReact('everymatrix').call('/user/deposit#confirm', {
  pid,
});

export const subscribeDepositChanges = (callback) => AutobahnReact('everymatrix').subscribe('/deposit/statusChanged', (result) => callback(result.argsDict));

export const getDepositTransactionInfo = (key, pid) => AutobahnReact('everymatrix').call('/user/deposit#getTransactionInfo', {
  includeisfirstdeposit: true,
  pid,
});

export const getMaxAmount = () => AutobahnReact('everymatrix').call('/user/deposit#getMaxAmount');
