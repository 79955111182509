import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Icon from '../../Icon';
import SearchResults from '../../GameBrowser/GameBrowserNavigation/SearchResults';

const SearchGame = ({ onSelect }) => {
  const intl = useIntl();
  const [activeField, setActiveField] = useState(false);

  const handleFocus = () => {
    setActiveField(true);
  };

  const handleBlur = () => {
    setActiveField(false);
  };

  return (
    <div>
      <Formik initialValues={{ query: '' }}>
        {({
          values: { query, section },
          isSubmitting,
          status,
          isValid,
          setFieldValue,
        }) => (
          <Form className="relative">
            <Field
              name="query"
              placeholder={intl.formatMessage({
                defaultMessage: 'Search e.g. Starburst, Netent',
                description: 'Placeholder for the search input',
              })}
              type="text"
              className="w-72 pl-6 hidden md:block z-10 transition transition-all rounded-full font-bold text-blue-input h-10 border-gray-input placeholder-gray-light focus:outline-none focus:border-purple-dark focus:ring-offset-0 focus:ring-transparent focus:text-bold background-transparent"
              autoComplete="off"
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            <Icon
              icon="search"
              className="absolute ml-4 h-4 w-5 top-3 right-10 z-10 hidden md:block"
            />
            <SearchResults
              open={activeField}
              onSelect={onSelect}
              className="absolute"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SearchGame;
