/**
 * Layout Component
 *
 * This component is responsible for the whole page markup from the root. The wireframe for the site.
 *
 * @category   Layout
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MM
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */
import { LiveChatWidget } from "@livechat/widget-react";

import useStyles from "isomorphic-style-loader/useStyles";
import ConsentBanner from "@components/ConsentBanner";
import React, { useEffect } from "react";
import NoAccess from "@components/NoAccess";
import ErrorOccurred from "@components/ErrorOccurred";
import useSWR from "swr";
import { useIntl } from "react-intl";
import s from "./Layout.scss";
import TopBar from "../TopBar";
import Footer from "../Footer";
import PageMetaData from "../PageMetaData";
import Overlay from "./Overlay";
import { useRouter } from "../Provide/RouterContext";
import { getIpInfo } from "../../data/model/session";
import ErrorBoundary from "../ErrorBoundary";
import { patchCall } from "../../core/softswiss";
import Config from "./Config";
import { ToastContainer } from "react-toastify";
import { useAuth } from "../Provide/AuthContext";
import { BLOCKED_COUNTRY, redirectWithReturnURL } from "../../data/constant";
import { useTracking } from "../../hooks/useTracking";
import { isBrowser } from "browser-or-node";
import SsrCompatibleSuspense from "../SsrCompatibleSuspense/SsrCompatibleSuspense";
import { useDevice } from "../Provide/DeviceContext";

const AddToHomeScreen = React.lazy(() =>
  import("@ideasio/add-to-homescreen-react")
);

const Layout = ({
  children,
  footer = true,
  livechat = true,
  topbar = true,
  topbarLogo = true,
  topbarPosition = "fixed",
  initialTriggerMarketing = true,
}) => {
  useStyles(s);
  const intl = useIntl();
  const router = useRouter();
  const { page } = useTracking();

  const { isAuthenticated, isAuthLoading } = useAuth();
  const { isMobile } = useDevice();

  useEffect(() => {
    function savePreviousRoutes() {
      const currentPathname = window.location.pathname;
      let previousRoutes =
        JSON.parse(localStorage.getItem("previousRoutes")) || [];
      if (
        currentPathname.includes("my-clubhouse") &&
        previousRoutes?.length > 0
      ) {
        page("MC Page Journey", { routes: previousRoutes });
        localStorage.setItem("previousRoutes", JSON.stringify([]));
        return;
      }
      if (
        !currentPathname.includes("my-clubhouse") &&
        (previousRoutes.length === 0 || previousRoutes[0] !== currentPathname)
      ) {
        previousRoutes.unshift(currentPathname);
        previousRoutes = previousRoutes.slice(0, 3);
        localStorage.setItem("previousRoutes", JSON.stringify(previousRoutes));
      }
    }
    savePreviousRoutes();
  }, [router]);

  useEffect(() => {
    patchCall("/api/player/update_locale", {
      locale: intl.locale === "de-de" ? "de" : "en",
    });
  }, []);

  const { data: ipInfo } = useSWR("getIpInfo", getIpInfo);
  // const ipInfo = {country_code : "erwer"};
  useEffect(() => {
    if (
      (router.currentRoute?.isNeedAuthorization || false) &&
      !isAuthenticated &&
      !isAuthLoading
    ) {
      const queryParamsString = Object.keys(router.queryParams)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(
              router.queryParams[key]
            )}`
        )
        .join("&");
      const finalURL = `${router.pathname}${
        queryParamsString ? "?" : ""
      }${queryParamsString}`;
      redirectWithReturnURL(finalURL, intl.locale);
    }
  }, [isAuthenticated, isAuthLoading]);

  return (
    <>
      <ToastContainer className={""} />
      <ErrorBoundary fallback={<ErrorOccurred />}>
        <Config />
        <PageMetaData />
        {/* <SsrCompatibleSuspense fallback={<div />}>
          {isBrowser && (
            <AddToHomeScreen
              appId="theclubhousecasino-add-to-home"
              startAutomatically
              mustShowCustomPrompt={isMobile?.any}
              startDelay={0}
              customPromptElements={{
                containerAddOns:
                  "!fixed !bottom-2.5 max-w-[300px] left-0 right-0 m-auto rounded-xl !bg-brand-600 !text-white",
                logoAddOns: "!rounded-[10px]",
                cancelButtonAddOns:
                  "h-11 px-5 relative w-20 bg-brand-500 text-white rounded-lg font-bold",
                installButtonAddOns:
                  "btn h-11 px-5 bg-gold text-brand rounded-lg font-bold",
              }}
              customPromptContent={{
                title: "The Clubhouse Casino",
                src: "/android-chrome-192x192.png",
                cancelMsg: "No",
                installMsg: "Install App",
                guidanceCancelMsg: "Cancel",
              }}
              customPromptPlatformDependencies={{
                iphone: {
                  images: [
                    {
                      src: "/guide/iphone.jpeg",
                      alt: "Guide to install application on home screen.",
                    },
                  ],
                },
                ipad: {
                  images: [
                    {
                      src: "/guide/iphone.jpeg",
                      alt: "Guide to install application on home screen.",
                    },
                  ],
                },
              }}
            />
          )}
        </SsrCompatibleSuspense> */}
        {BLOCKED_COUNTRY.includes(ipInfo?.country_code) ? (
          <>
            <NoAccess router={router} />
          </>
        ) : (
          <>
            {topbar && (
              <TopBar topbarPosition={topbarPosition} logo={topbarLogo} />
            )}
            <Overlay />
            {children}
            {footer && <Footer />}
          </>
        )}
        <LiveChatWidget license="8370151" visibility="minimized" />
        <ConsentBanner />
      </ErrorBoundary>
    </>
  );
};
Layout.whyDidYouRender = true;
export default Layout;
