import useSWR from 'swr';
import { getGrantedBonuses } from '../../data/model/bonus';

const Bonusses = (props) => {
  const { mutate, data } = useSWR(
    '/user/bonus#getGrantedBonuses',
    (url) => getGrantedBonuses('register'),
  );
  const freeRoundBonuses = [];
  // const freeRoundBonuses = data?.filter(
  //   (item) => item?.type === 'freeRound' && item?.status === 'active',
  // );

  const grantedBonuses = [];
  // const grantedBonuses = data?.filter(
  //   (item) => item?.type === 'standard' && item?.status === 'active',
  // );

  const forfeit = (id) => {
    forfeit(id).then(mutate);
  };
  return props.children({
    freeRoundBonuses,
    grantedBonuses,
    forfeit,
    mutate,
  });
};
export default Bonusses;
