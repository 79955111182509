import React, { useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

const RootModal = ({ children, hideModal, isActive, animate = true }) => {
  const divRef = useRef();
  return (
    <Transition show={Boolean(isActive)} appear>
      <Dialog
        static
        open={Boolean(isActive)}
        onClose={hideModal}
        className="fixed w-screen h-screen top-0 l-0 z-50 pb-24  pb-26 md:pb-0"
        initialFocus={divRef}
      >
        {children}
        <div ref={divRef} />
      </Dialog>
    </Transition>
  );
};
export default RootModal;
