import React, { useState } from "react";
import SubmitFeedback from "@components/Formik/SubmitFeedback";
import Button from "@components/Button";
import { useIntl } from "react-intl";
import { confirmLimit } from "@data/model/responsible-gaming";
import useSWR from "swr";
import { useRouter } from "../../../Provide/RouterContext";

const ResponsibleGamingVerify = ({ setMenuItem }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const { queryParams } = useRouter();

  console.log("queryParams", queryParams);
  const { error, data } = useSWR(
    ["confirmLimit", queryParams.token],
    (url, token) => confirmLimit(token),
    { onSuccess: () => setLoading(false), onError: () => setLoading(false) }
  );

  console.log("ResponsibleGamingVerify data!", data);
  console.log("error!", error);

  if (loading) {
    return <div />;
  }

  return (
    <div>
      <SubmitFeedback
        status={error && !loading ? "failed" : "success"}
        header={
          error && !loading
            ? intl.formatMessage({
                defaultMessage: "Failed!",
                description: "The feedback header",
              })
            : intl.formatMessage({
                defaultMessage: "Confirmed",
                description: "The feedback header",
              })
        }
      >
        <div className="text-24 py-8 leading-7">
          {error &&
            !loading &&
            intl.formatMessage({
              defaultMessage:
                "Confirmation unsuccessful, please contact support for more information",
              description: "An error occured, please contact support",
            })}
          {!error &&
            !loading &&
            intl.formatMessage({
              defaultMessage: "Confirmation successful.",
              description:
                "The feedback after confirming the limits in the account section",
            })}
        </div>
        <div className="px-8">
          <Button.Action
            trackName="Back to account from Responsible Gaming Verify"
            trackParams={{ component: "ResponsibleGamingVerify" }}
            onClick={() => setMenuItem("Root")}
            className="w-full"
            arrow="right"
          >
            {intl.formatMessage({
              defaultMessage: "Back to my account",
              description: "Label",
            })}
          </Button.Action>
        </div>
      </SubmitFeedback>
    </div>
  );
};

export default ResponsibleGamingVerify;
