import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useRouter } from '../Provide/RouterContext';
import getMetaDataQuery from './get-routes.graphql';

const PageMetaData = () => {
  const intl = useIntl();

  const { loading, data } = useQuery(getMetaDataQuery, { variables: { locale: intl.locale } });
  //const [item, setItem] = useState();
  const { routename, currentRoute } = useRouter();
  // console.log('currentRoute--currentRoute',currentRoute);
  // console.log('data--data',data);
  //useEffect(() => {
    const landingPageData = data?.getRoutes?.find?.((item) => item.path === currentRoute?.params?.slug);
    const commonPageData = data?.getRoutes?.find?.((item) => item.route === routename);
    const item = routename === "landingPageDetail" ? landingPageData ? landingPageData : commonPageData : commonPageData;

    //setItem(entry);
  //}, [routename, data]);

  return (
    <Helmet>
      <title>{item?.title ?? 'Welcome to TheClubhouseCasino.com'}</title>
      <meta name={item?.title} content={item?.MetaData}></meta>
      <meta name="description" content={item?.metadescription ?? ''} />
      <meta property="og:description" content={item?.metadescription ?? ''} />
    </Helmet>
  );
};
export default PageMetaData;
