/**
 * TopBar Component
 *
 * This component is responsible for The public top menu login/ registration buttons
 *
 * @category   TopBarPublic
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */
import React from "react";

import Icon from "@components/Icon";
import Link from "@components/Link";
import { useRouter } from "../../../Provide/RouterContext";

const MenuItem = ({
  to,
  onClick,
  label,
  trackParams,
  trackName,
  isPendingWithdraw = false,
  hasPendingWithdraws = 0,
}) => {
  const router = useRouter();

  return (
    <Link
      to={to}
      trackName={trackName}
      trackParams={trackParams}
      onClick={onClick}
      className="relative flex justify-between w-full p-3 text-2xl font-light leading-5 text-left text-white"
    >
      <span className="relative flex flex-1 whitespace-nowrap">
        {label}
        {isPendingWithdraw && (
          <span className="top-0 w-5 h-5 ml-1 -mt-3 text-xs font-bold leading-5 text-center text-white rounded-full bg-gold">
            {hasPendingWithdraws}
          </span>
        )}
      </span>
      <Icon
        icon="arrow"
        className="w-4 h-4 ml-2 -mr-1 transform -rotate-90 text-blue-darkest"
      />
    </Link>
  );
};

export default MenuItem;
