// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".banner-root-j4Ma4{background:var(--color-darkgreen);color:var(--color-white);display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:justify;justify-content:space-between;line-height:1.3;padding:8px;position:relative}@media screen and (--screen-sm-min ){.banner-root-j4Ma4{-ms-flex-direction:row;flex-direction:row;height:80px;margin-bottom:0;padding:16px 40px 16px 100px}}.banner-content-YRfX7{font-family:var(--font-family-variant);font-size:12px;font-weight:700;margin-bottom:5px}@media screen and (--screen-sm-min ){.banner-content-YRfX7{font-size:16px;margin-bottom:10px}}.banner-subContent-nl73G{display:block;font-size:11.8px;margin-bottom:5px}@media screen and (--screen-sm-min ){.banner-subContent-nl73G{font-size:14px;margin-bottom:10px}}.banner-explainContainer-V3zEr{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;margin-bottom:10px}.banner-actionsContainer-HbpDo,.banner-buttonContainer-xaSZQ{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}.banner-buttonContainer-xaSZQ{-ms-flex-pack:justify;justify-content:space-between;margin-bottom:10px}@media screen and (--screen-sm-min ){.banner-buttonContainer-xaSZQ{-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;margin-bottom:0}}.banner-closingButton-FIPzp{background:none;border:none;color:inherit;cursor:pointer;font:inherit;font-size:36px;line-height:1}@media screen and (--screen-sm-min ){.banner-closingButton-FIPzp{margin-left:40px}}.banner-acceptButton-26ZjB{background-color:#fff!important;color:var(--color-darkgreen)!important;margin-right:10px;width:auto}.banner-settingsButton-y7bLe{width:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "banner-root-j4Ma4",
	"content": "banner-content-YRfX7",
	"subContent": "banner-subContent-nl73G",
	"explainContainer": "banner-explainContainer-V3zEr",
	"actionsContainer": "banner-actionsContainer-HbpDo",
	"buttonContainer": "banner-buttonContainer-xaSZQ",
	"closingButton": "banner-closingButton-FIPzp",
	"acceptButton": "banner-acceptButton-26ZjB",
	"settingsButton": "banner-settingsButton-y7bLe"
};
module.exports = ___CSS_LOADER_EXPORT___;
