import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { ToggleSwitch, TextField } from "@components/Formik";
import { useIntl } from "react-intl";
import Button from "@components/Button";
import cx from "classnames";
import {
  addDepositBonus,
  deleteDepositBonus,
  addCouponBonus,
} from "@data/model/bonus";
import { SelectBox } from "../../../Formik";
import { getPlayerDetails } from "../../../../data/model/profile";
import { getDepositBonuses } from "../../../../data/model/bonus";
import { updateBonusPreference } from "../../../../data/model/account";
import deleteIcon from "../../../../../public/delete-icon.png";
import { useAuth } from "../../../Provide/AuthContext";
import Icon from "../../../Icon";

const ActiveBonusSelector = ({
  setFieldValue,
  useBonusCode,
  bonusCode,
  values,
  type = "deposit",
  paymentType,
}) => {
  const intl = useIntl();
  const [] = useState(false);
  const { isAuthenticated } = useAuth();
  const { data: bonuses, mutate: refetchBonuses } = useSWR(
    "/user/bonus#getApplicableBonuses",
    getDepositBonuses
  );

  const { data: personal, mutate: refetchPersonalData } = useSWR(
    isAuthenticated ? "/api/player" : null,
    getPlayerDetails
  );

  const [isSubmittingBonus, setIsSubmittingBonus] = useState(false);
  const [bonusStatus, setBonusStatus] = useState("");

  const setUseBonus = async (hasBonus) => {
    await updateBonusPreference(hasBonus);
    refetchBonuses();
  };

  const submitAddingNewBonus = async () => {
    setIsSubmittingBonus(true);
    try {
      await addCouponBonus(values.fields.bonusCodeInput).then((response) => {
        refetchPersonalData();
        setIsSubmittingBonus(false);
        setBonusStatus(response.status.replaceAll("_", " "));
      });
    } catch (e) {
      setIsSubmittingBonus(false);
      return false;
    }
  };

  return (
    <div>
      <div className="ltr:ml-3 rtl:mr-3 text-gray rtl:text-right">
        {intl.formatMessage({
          defaultMessage: "Available bonuses:",
          description: "Label",
        })}
      </div>
      <div className="flex flex-row gap-2 mt-5 mb-4">
        <ToggleSwitch name="useBonusCode" onChange={setUseBonus} />
        <div className="">
          {intl.formatMessage({
            defaultMessage: "Use bonuses",
            description: "bonuses toggle switch",
          })}
        </div>
      </div>
      {useBonusCode && (
        <div className="relative mb-4">
          <div className="relative">
            <TextField
              id="bonusCodeInput"
              name="fields[bonusCodeInput]"
              type="bonusCodeInput"
              label={intl.formatMessage({
                defaultMessage: "Add Bonus Code",
                description: "Label of inputfield",
              })}
              className="w-full mt-4"
            />
            <Button.Action
              trackName="Submit Bonus"
              trackParams={{ component: "BonusSelector" }}
              className={cx(
                "absolute w-35 h-35 top-0 ltr:right-0 rtl:left-0  m-2.5 rounded-full p-0 text-center text-xl px-0 pl-3 pr-3 ",
                { "animate-pulse": isSubmittingBonus }
              )}
              disabled={
                !!isSubmittingBonus ||
                values?.fields?.bonusCodeInput?.length === 0
              }
              isSubmitting={isSubmittingBonus}
              onClick={() => submitAddingNewBonus()}
            >
              <svg
                className="w-8 h-8 text-white"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="4"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                {" "}
                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                <line x1="12" y1="5" x2="12" y2="19" />{" "}
                <line x1="5" y1="12" x2="19" y2="12" />
              </svg>
            </Button.Action>
          </div>

          {bonusStatus?.length > 0 && (
            <div
              className={cx(
                "pl-3 pb-1 text-sm capitalize",
                bonusStatus.includes("fail") ? "text-red" : "text-green"
              )}
            >
              {bonusStatus}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ActiveBonusSelector;
