/**
 * TopBar Component
 *
 * This component is responsible for The public top menu login/ registration buttons
 *
 * @category   TopBarPublic
 * @author     ggr <gerjo@madmenmt.com>
 * @copyright  2021 MocinoPlay
 * @version    Release: @package_version@
 * @since      Component available since Release 1.0.0
 */

import Icon from "../../Icon";

const TopBarPrivateLoading = () => (
  <>
    <div className="flex w-20 h-10 rounded md:w-24 animate-pulse bg-brand-600 rtl:ml-4 ltr:mr-4" />
    <div className="flex w-10 h-10 rounded animate-pulse bg-brand-600 rtl:ml-4 ltr:mr-4" />
    {/* <div className="relative flex items-center justify-center inline-block gap-1 text-left">
      <div className="w-10 h-10 rounded-full animate-pulse bg-brand-600" />
      <Icon icon="arrow" className="w-3 h-3 text-brand-600" />
    </div> */}
  </>
);

export default TopBarPrivateLoading;
