import React from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { Dialog } from '@headlessui/react';
import { useIntl } from 'react-intl';
import Button from '../../../Button';
import Icon from '../../../Icon';
import hero from '../../../LandingOffer/assets/img/hero.png';
import { PasswordField, SelectBox, TextField } from '../../../Formik';
import { useDispatch } from 'react-redux';
import { useRouter } from '../../../Provide/RouterContext';
import useSWR from 'swr';
import { getIpInfo } from '../../../../data/model/session';
import { useQuery } from '@apollo/client';
import getCurrenciesQuery from '../../../LandingOffer/get-currencies.graphql';
import getCountriesQuery from '../../../LandingOffer/get-countries.graphql';
import getPromotionBlockQuery from '../../../LandingOffer/get-promotion-block.graphql';
import { useDevice } from '../../../Provide/DeviceContext';
import { useWallet } from '../../../Provide/WalletContext';
import { useAuth } from '../../../Provide/AuthContext';



const Success = ({ handleClose, handleBonus }) => {
  const { isMobile } = useDevice();
  const intl = useIntl();
  const router = useRouter();
  const { isAuthenticated } = useAuth();
  const {
    currency,
  } = useWallet();
  const { loading, error, data: promotionBlocks } = useQuery(getPromotionBlockQuery, { variables: { locale: intl.locale, route: router.routename, isAuthenticated } });
  const promoBlock = promotionBlocks?.PromotionBlock?.find((item) => item.component === 'PromotionBlockRegistrationSuccess');


  console.log(' promotionBlocks',  promotionBlocks);

  let offer = promoBlock?.settings?.offer ?? "";
  const paramsPattern = /[^{\}]+(?=})/g;
  let extractParams = offer.match(paramsPattern);

  extractParams?.map((item) => {
    let replaceWith = '';
    const [func,value] = item.split('|');
    switch(func) {
      case 'currency':
        replaceWith = intl.formatNumber(value, {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
    }
    offer = offer.replace(`{${item}}`, replaceWith);
  });
  return (
    <>
      <div className="w-full flex items-center justify-center mb-8 ">
        <Icon icon="checkFeedback" className="h-10 w-10 text-gold mr-4" />
        <h4 className="text-center text-3xl font-light text-white">
          {intl.formatMessage({
            defaultMessage: 'Account created!',
            description: 'Header of the form section',
          })}
        </h4>
      </div>
      <div className="mt-5.5 flex-col flex w-full items-stretch px-4 md:px-0">

          <section className="relative flex flex-col justify-end items-center p-3 bg-center bg-cover h-80 bg-brand-500" style={{backgroundPosition: 'top right', backgroundImage: `url(https://cockpit-ch.madmenmt.com/${
              isMobile.any ? promoBlock?.settings?.backgroundMobile?.path : promoBlock?.settings?.backgroundDesktop?.path
            })`}}>
            <div className="z-20 w-full">
              {promoBlock?.settings?.header && <span className="mb-3 block font-bold text-white uppercase"> {promoBlock?.settings?.header}</span>}
              {promoBlock?.settings?.offer && <div className="mb-5 font-bold leading-none text-white text-3xl" dangerouslySetInnerHTML={{__html: offer}} />}

                {/*<h1 className="mb-5 font-bold leading-none text-white text-3xl">*/}
                {/*  $1000 deposit bonus and 200 free spins!*/}
                {/*</h1>*/}
                {/*<p className="mb-5 text-14 text-gray">More information</p>*/}

              {promoBlock?.settings?.ctaLine && <Button.Action trackName="Handle Bonus" trackParams={{ component: 'Success'}} type="button" className="w-full" arrow="right" onClick={handleBonus}>
                {promoBlock?.settings?.ctaLine}
              </Button.Action>}
            </div>

            <div className="z-10 absolute h-96 w-full bottom-0 left-0 bg-gradient-to-t from-black to-transparent" />
          </section>
        <a className="z-10 relative mt-8 w-full text-center text-gold max-w-xs mx-auto cursor-pointer mb-8" onClick={handleClose}>{intl.formatMessage({
          defaultMessage: 'Or look around and deposit later to claim your bonus',
          description: 'on success line',
        })}
        </a>
      </div>

      </>
  );
};

export default Success;
