import React, { useEffect, useState } from "react";
import { useAuth } from "../Provide/AuthContext";
import { getGrantedBonuses } from "../../data/model/bonus";
import useSWR from "swr";
import { getPlayerDetails } from "../../data/model/profile";
import { useCentrifuge } from "../Provide/CentrifugeContext";
import { checkif10DaysOld, is_cash_bonus } from "../../data/constant";
import UPDATE_MC_VISITED_DETAILS from "./update-mc-visited-details.graphql";
import GET_MC_VISITED_DETAILS from "./get-mc-visited-details.graphql";
import { useLazyQuery, useQuery } from "@apollo/client";

const GameAnalytics = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { subscribe } = useCentrifuge();
  const [isNeedsToReload, setNeedsToReload] = useState(true);

  const {
    error,
    data: grantedBonusesData,
    isValidating: grantedBonusesLoading,
    mutate,
  } = useSWR(
    isAuthenticated
      ? "getGrantedBonuses/unique"
      : "getGrantedBonuses/unique/null",
    getGrantedBonuses
  );

  const {
    data: playerDetails,
    isValidating: isLoadingPlayerDetails,
    mutate: reFetchPlayerDetail,
  } = useSWR(
    isAuthenticated ? "/api/player/unique" : "/api/player/unique/null",
    getPlayerDetails
  );

  const {
    loading: GET_MC_VISITED_DETAILS_LOADING,
    data: GET_MC_VISITED_DETAILS_DATA,
    error: GET_MC_VISITED_DETAILS_ERROR,
  } = useQuery(GET_MC_VISITED_DETAILS, {
    fetchPolicy: "no-cache",
    variables: {
      userId: playerDetails?.id?.toString(),
    },
  });

  const [updateUnvisitedBonusDetails] = useLazyQuery(
    UPDATE_MC_VISITED_DETAILS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        console.log(
          "updateUnvisitedBonusDetails--updateUnvisitedBonusDetails",
          data
        );
      },
    }
  );

  useEffect(() => {
    console.log("Effect triggered:", {
      isAuthenticated,
      grantedBonusesLoading,
      grantedBonusesData,
      playerDetails,
      isLoadingPlayerDetails,
    });
    if (
      isAuthenticated &&
      !grantedBonusesLoading &&
      grantedBonusesData &&
      playerDetails &&
      !isLoadingPlayerDetails &&
      !GET_MC_VISITED_DETAILS_LOADING &&
      isNeedsToReload
    ) {
      // const privateChannels = ["balance","$stats","game_limits", "comps_award", "bonuses_changes", "freespins_changes", "payments_changes"];
      console.log("----------in, balance#player_id subscribe");
      setNeedsToReload(false);
      // subscribe("balance", (message) => {
      //   console.log("balance#player_id", message);
      // });
      let sessionRounds = 0;
      subscribe("$analytics", (message) => {
        console.log("$analytics--message", message);
        if (
          message?.data?.category === "game" &&
          message?.data?.action === "finished"
        ) {
          //   console.log("balance#player_id REFETCH");
          sessionRounds++;
          console.log("balance#player_id sessionRounds", sessionRounds);
          //   console.log(
          //     "grantedBonusesData--grantedBonusesData",
          //     grantedBonusesData
          //   );
          if (sessionRounds === 5) {
            console.log(
              "grantedBonusesData--grantedBonusesData",
              grantedBonusesData
            );
            let visitedFreeSpinsArray;
            try {
              visitedFreeSpinsArray =
                GET_MC_VISITED_DETAILS_DATA?.getMcVisitedDetails?.data &&
                JSON.parse(
                  GET_MC_VISITED_DETAILS_DATA?.getMcVisitedDetails?.data?.[0]
                    ?.visitedFreeSpins
                );
            } catch (error) {
              visitedFreeSpinsArray = [];
            }
            // console.log(
            //   "visitedFreeSpinsArray--visitedFreeSpinsArray",
            //   visitedFreeSpinsArray
            // );
            const cashAndWagerBonus =
              grantedBonusesData
                ?.filter((item) => {
                  return (
                    (is_cash_bonus(item) &&
                      checkif10DaysOld(item) &&
                      (visitedFreeSpinsArray?.length > 0
                        ? !visitedFreeSpinsArray.includes(item?.id)
                        : true)) ||
                    (["handle_bets", "wait", "issued"].includes(item.stage) &&
                      (visitedFreeSpinsArray?.length > 0
                        ? !visitedFreeSpinsArray.includes(item?.id)
                        : true))
                  );
                })
                ?.map((item) => item.id) || [];
            // console.log("cashAndWagerBonus", cashAndWagerBonus);
            if (cashAndWagerBonus?.length > 0) {
              updateUnvisitedBonusDetails({
                variables: {
                  userId: playerDetails?.id?.toString(),
                  lastVisitedTime: new Date(),
                  visitedFreeSpins: cashAndWagerBonus,
                },
              });
            }
          }
        }
        if (message?.data?.category.indexOf("game_open") > -1) {
          sessionRounds = 0;
        }
      });
    }
  }, [
    isAuthenticated,
    grantedBonusesLoading,
    isLoadingPlayerDetails,
    isNeedsToReload,
    GET_MC_VISITED_DETAILS_LOADING,
  ]);
  return children;
};

export default GameAnalytics;
