import AutobahnReact from "../../core/websocket";
import {
  getCall,
  postCall,
  deleteCall,
  uploadCall,
} from "../../core/softswiss";

export const getDocuments = () => getCall("/api/player/documents");
export const saveDocument = (attachment, description, type = "identity") => {
  const formData = new FormData();

  formData.append("document[attachment]", attachment);
  formData.append("document[description]", description);
  formData.append("document[document_type]", type);

  return uploadCall("/api/player/documents", formData);
};
export const removeDocument = (id) => deleteCall(`/api/player/documents/${id}`);
