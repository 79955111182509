import React, { useEffect, useState } from "react";

import { ErrorMessage } from "formik";
import useSWR from "swr";
import { useIntl } from "react-intl";
import cx from "classnames";
import { isBrowser } from "browser-or-node";
import { getDomainName, titleize } from "../../../core/utils";
import Button from "../../Button";
import DynamicFields from "./DynamicFields";
import { useWallet } from "../../Provide/WalletContext";
import { defaultRequester } from "../../../core/default-requester";
import { useRouter } from "../../Provide/RouterContext";
import ProfileDynamicFields from "../../ProfileDynamicFields/DynamicFields";

const ENDPOINT = isBrowser
  ? `https://backend.${getDomainName(window.location.hostname)}`
  : "https://backend.theclubhousecasino.com";
export const WithdrawForm = ({
  isSubmitting,
  isValid,
  setFieldError,
  setFieldValue,
  values: { method, savedId },
  values,
  currencies,
  countries,
  setBackActions,
  setStatus,
  errors,
  initialValues,
}) => {
  const { currency } = useWallet();

  const [errorList, setErrorList] = useState([]);
  const [paymentPlanAPI, setPaymentPlanAPI] = useState(null);
  // console.log('paymentPlanAPI--paymentPlanAPI',paymentPlanAPI);
  useEffect(
    () =>
      setBackActions((prevState) => {
        prevState.push((x) => {
          // setSection('Main');
          setStatus({ step: "initial" });
        });
        return prevState;
      }),
    []
  );
  const router = useRouter();

  console.log(
    "method?.cashout_saved_methods?.[0]?.identifier",
    method?.cashout_saved_methods?.[0]?.identifier
  );
  console.log("savedId", savedId);
  // const { data: paymentPlan } = useSWR(['buildPaymentPlan', method], (url) => window.PaymentsAPI.buildPaymentPlan(currency, 'cashout', method, savedId?.name ?? method?.cashout_saved_methods?.[0]?.identifier, defaultRequester));

  const { data: paymentPlan } = useSWR(
    [
      "buildpaymentPlanAPI",
      currency,
      method,
      savedId?.name ?? method?.cashout_saved_methods?.[0]?.identifier,
      defaultRequester,
    ],
    (url) =>
      window.PaymentsAPI.config({
        serverUrl: ENDPOINT,
        apiPrefix: "/api",
        customCdnUrl: "https://cdn2.softswiss.net/logos/payments/white/",
      }).then(() =>
        window.PaymentsAPI.getMethodFields({
          id: method?.id,
          currency,
          paymentAction: "cashout",
          savedProfileId: savedId ?? null,
        }).then((e) => {
          console.log("Mandatory fields", e);
          setPaymentPlanAPI(e);
        })
      )
  );

  useEffect(() => {
    setFieldValue("paymentPlan", paymentPlanAPI);
    setFieldValue("accountId", savedId);
  }, [paymentPlanAPI, savedId]);
  const intl = useIntl();
  const [amountValue, setAmountValue] = useState(0);
  console.log("payment", paymentPlanAPI);

  const errorArray = [];
  const displayErrorFields = (item) => {
    console.log("errors item", item);
    try {
      if (item?.field === "account_identifier") {
        errorArray.push(
          `${"*" + "IBAN/Account number" + " - "}${intl.formatMessage(
            {
              defaultMessage: "{error}",
              description: "error message",
            },
            {
              error: String(
                Object.values(errors?.withdrawState?.errors?.[item.field])
              ),
            }
          )}`
        );
      } else {
        errorArray.push(
          `*${item?.field.replaceAll("_", " ")} - ${intl.formatMessage(
            {
              defaultMessage: "{error}",
              description: "error message",
            },
            {
              error: String(
                Object.values(errors?.withdrawState?.errors?.[item.field])
              ),
            }
          )}`
        );
      }
      console.log("errors item", item);
      // if(i === (paymentPlan?.fields?.length -1)){
      setErrorList(errorArray);
      // }
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    paymentPlanAPI?.fields?.map((item) => displayErrorFields(item));
  }, [errors]);

  console.log("errors array", paymentPlanAPI);
  console.log("errors array method", method);
  return (
    <div className="w-full flex flex-col pb-14">
      <h4 className="text-center text-2xl leading-5 font-light text-white mb-16  mx-12">
        {method?.brand === "einterac"
          ? "Interac® eTransfer"
          : intl.formatMessage(
              {
                defaultMessage: "Withdraw with {brand}",
                description: "header",
              },
              { brand: titleize(method?.brand) }
            )}
      </h4>
      <div className="mx-auto mt-5.5 flex-col flex w-full items-stretch">
        <ErrorMessage name="withdrawState" />

        {/* {method?.cashout_saved_methods?.length > 0 && ( */}
        {/*  <div className="mt-5.5 flex-col flex w-full items-stretch"> */}
        {/*    <SelectBox */}
        {/*      name="savedId" */}
        {/*      label="Please select a saved card" */}
        {/*      options={method?.cashout_saved_methods.map((items) => ({ */}
        {/*        text: items?.stored_title, */}
        {/*        name: items?.id, */}
        {/*      }))} */}
        {/*      className="mb-8 w-full mr-8" */}
        {/*    /> */}
        {/*  </div> */}
        {/* )} */}

        <div className="mt-5.5 flex-col flex w-full items-stretch">
          <DynamicFields
            setAmountValue={setAmountValue}
            paymentPlan={paymentPlanAPI}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            values={values}
            method={method}
            initialRequiredInputFields={paymentPlanAPI?.methodFields}
          />
        </div>

        <div className="flex-col flex w-full items-stretch -mt-3">
          <ProfileDynamicFields
            profileFieldsType="cashout"
            countries={countries}
            currencies={currencies}
            initialValues={initialValues}
            method={method}
            initialRequiredInputFields={paymentPlanAPI?.playerFields.map(
              (item) => ({ ...item, fieldName: item.field })
            )}
          />
        </div>

        <div className="text-red-error mt-4">
          {errorList?.map((item) => (
            <div>{item}</div>
          ))}
          {errors?.formState && (
            <div className="mt-8">
              <div className="w-full font-bold text-left">
                {intl.formatMessage({
                  defaultMessage: "Withdraw was not complete:",
                  description: "Button text",
                })}
              </div>
              <div className="my-4 text-red">
                {errors?.formState?.map((item) => (
                  <div className="w-full pb-2">{item}</div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-row my-8">
          <Button.Action
            trackName="Submit withdrawal"
            trackParams={{ component: "WithdrawForm" }}
            className={cx("w-full", { "animate-pulse": isSubmitting })}
            disabled={
              !!isSubmitting || !isValid || paymentPlanAPI === undefined
            }
            submittingLabel={intl.formatMessage({
              defaultMessage: "Submitting...",
              description:
                "When submitting a button this generic terms is used while waiting",
            })}
            isSubmitting={isSubmitting}
            type="submit"
            arrow="right"
          >
            {isSubmitting
              ? intl.formatMessage({
                  defaultMessage: "Submitting...",
                  description:
                    "When submitting a button this generic terms is used while waiting",
                })
              : intl.formatMessage({
                  defaultMessage: "Withdraw",
                  description: "The label on a button for saving",
                })}{" "}
            {`${intl
              .formatNumber(0, {
                style: "currency",
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
              .replace(/\d/g, "")
              .trim()}`}
            {amountValue === "" ? 0 : amountValue}
          </Button.Action>
        </div>
      </div>
    </div>
  );
};
export default WithdrawForm;
