import useStyles from 'isomorphic-style-loader/useStyles';
import Hamburger from 'hamburger-react';
import s from './ToggleNavigation.scss';

const ToggleNavigation = ({ open }) => {
  useStyles(s);

  // const dispatch = useDispatch();
  // const toggle = () => dispatch(toggleNavigation());
  // const isNavigationOpen = useSelector(
  //   state => state.navigation.isNavigationOpen
  // );

  return (
    <Hamburger
      toggled={open}
      color="#ffffff"
      easing="ease-in"
      size={24}
      toggle={() => {}}
    />
  );
};

export default ToggleNavigation;
