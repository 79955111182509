import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { useIntl } from "react-intl";
import usePrevious from "@hooks/usePrevious";
import { logOut } from "@data/model/account";
import ActiveBonuses from "@components/ActiveBonuses";
import Cookies from "universal-cookie";
import Link from "../../../Link";
import Button from "../../../Button";
import ChangeDetails from "../../../Account/Forms/ChangeDetails";

import ChangePassword from "../../../Account/Forms/ChangePassword";
import TransactionHistory from "../../../Account/Forms/TransactionHistory";
import MenuItem from "../MenuItem";
import SsrCompatibleSuspense from "../../../SsrCompatibleSuspense";
import { closeSession } from "../../../../data/model/session";
import { useAuth } from "../../../Provide/AuthContext";

const Items = {
  ChangeDetails,
  ChangePassword,
  Main: null,
  Root: null,
};

const Profile = ({
  setMenuItem,
  prevMenuItem,
  menuItem,
  setBackActions,
  fromModal = false,
}) => {
  const intl = useIntl();
  const [section, setSection] = useState("Main");
  const prevSection = usePrevious(section);
  const cookies = new Cookies();
  const { setAuthenticationStatus, setCmsSessionID } = useAuth();

  const logOutAccount = async () => {
    cookies.set("isAuthenticated", false);
    cookies.remove("depositTrigger");
    logOut(intl.locale, setAuthenticationStatus);
  };

  useEffect(() => {
    if (fromModal) {
      setSection("ChangeDetails");
    }
  }, []);

  const Slide = Items[section === "Main" ? prevSection : section];

  return (
    <div className="relative z-50 w-full h-full overflow-auto">
      <Transition
        show={section === "Main"}
        enter="transition-all duration-700"
        enterFrom="transform -translate-x-full"
        enterTo="transform translate-x-0"
        leave="transition-all duration-700"
        leaveFrom="transform-gpu translate-x-0"
        leaveTo="transform-gpu -translate-x-full"
        className="absolute top-0 left-0 flex flex-col w-full"
        // unmount={false}
      >
        <div className="flex flex-col w-full p4">
          <h4 className="mb-16 text-2xl font-light leading-5 text-center text-white pointer-events-none  sticky top-0 bg-[#13161d] max-w-full ms-auto me-auto pt-0 pb-[11px] z-[9] ml-0">
            {intl.formatMessage({
              defaultMessage: "Profile",
              description: "header",
            })}
          </h4>
          <div className="p-4 divide-y divide-white divide-opacity-25">
            <MenuItem
              trackName="Personal Details"
              trackParams={{ component: "Profile" }}
              onClick={() => setSection("ChangeDetails")}
              label={intl.formatMessage({
                defaultMessage: "Personal Details",
                description: "Link label",
              })}
            />
            <MenuItem
              trackName="Change Password"
              trackParams={{ component: "Profile" }}
              onClick={() => setSection("ChangePassword")}
              label={intl.formatMessage({
                defaultMessage: "Change Password",
                description: "Link label",
              })}
            />
            <MenuItem
              trackName="Log out"
              trackParams={{ component: "Profile" }}
              onClick={logOutAccount}
              label={intl.formatMessage({
                defaultMessage: "Log Out",
                description: "Link label",
              })}
            />
          </div>
        </div>
      </Transition>
      <Transition
        show={section !== "Main"}
        enter="transition-all duration-700"
        enterFrom="transform translate-x-full"
        enterTo="transform translate-x-0"
        leave="transition-all duration-700"
        leaveFrom="transform-gpu translate-x-0"
        leaveTo="transform-gpu translate-x-full"
        className="absolute top-0 left-0 z-0 flex flex-col w-full pt-0"
        // unmount={false}
      >
        <SsrCompatibleSuspense fallback={<div>loading</div>}>
          <Slide
            menuItem={menuItem}
            setMenuItem={setMenuItem}
            setSection={setSection}
            setBackActions={setBackActions}
          />
        </SsrCompatibleSuspense>
      </Transition>
    </div>
  );
};

export default Profile;
