import React, { useState, useEffect } from "react";
import useSWR from "swr";
import {
  getLoyaltyGroups,
  getLoyaltyUserPoints,
} from "../../../../data/model/loyalty";
import { useIntl } from "react-intl";
import Link from "@components/Link";
import { useDispatch, useSelector } from "react-redux";
import { closeNavigation } from "@actions/navigation";
import { useRouter } from "../../../Provide/RouterContext";

const LoyaltyProgramProgress = ({ hideLabel = false }) => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const close = () => dispatch(closeNavigation());
  const router = useRouter();
  const [userPoints, setUserPoints] = useState(0);
  const [userTotalPoints, setUserTotalPoints] = useState(0);
  const [userCurrentLevel, setUserCurrentLevel] = useState("");
  const [userNextLevel, setUserNextLevel] = useState("");

  const strings = {
    member: intl.formatMessage({
      defaultMessage: "Member",
      description: "Level",
    }),
    bronze: intl.formatMessage({
      defaultMessage: "Bronze",
      description: "Level",
    }),
    silver: intl.formatMessage({
      defaultMessage: "Silver",
      description: "Level",
    }),
    gold: intl.formatMessage({
      defaultMessage: "Gold",
      description: "Level",
    }),
    diamond: intl.formatMessage({
      defaultMessage: "Diamond",
      description: "Level",
    }),
    platinum: intl.formatMessage({
      defaultMessage: "Platinum",
      description: "Level",
    }),
    vip: intl.formatMessage({
      defaultMessage: "Vip",
      description: "Level",
    }),
  };
  const { data: loyaltyGroups } = useSWR(
    ["getLoyaltyGroupsCall"],
    getLoyaltyGroups
  );

  const { data: loyaltyUserPoints } = useSWR(
    ["getLoyaltyUserPointsCall"],
    getLoyaltyUserPoints
  );
  function getBackgroundColour(level) {
    let colour;
    switch (level) {
      case "member":
        colour = "#5b616e";
        break;
      case "bronze":
        colour =
          "linear-gradient(92deg, #4a261d 0%, #b28968 52%, #502c21 100%)";
        break;
      case "silver":
        colour = "linear-gradient(92deg, #4b4b4b 0%, #aaa 52%, #4b4b4b 100%)";
        break;
      case "gold":
        colour = "linear-gradient(92deg, #cb9e33 0%, #c8ad6c 52%, #cb9e33 99%)";
        break;
      case "diamond":
        colour = "linear-gradient(92deg, #515151 0%, #c0c0c0 52%, #818181 99%)";
        break;
      case "platinum":
        colour =
          "linear-gradient(92deg, #a4a4a4 0%, #b5b5b5 54%, #a4a4a4 100%)";
        break;
      case "vip":
        colour = "linear-gradient(92deg, #000 0%, #343434 52%, #000 100%)";
        break;
      default:
        colour = "#5b616e";
        break;
    }

    return colour;
  }

  function calculatePercentage() {
    let final = Math.round(
      ((loyaltyUserPoints?.persistent?.points || 0) * 100) / userTotalPoints
    );
    // console.log("width is :" + final)
    return final + "%";
  }

  function setUserLevel() {
    //setUserPoints(loyaltyUserPoints?.persistent?.points || 0);

    console.log("userPoints", loyaltyUserPoints?.persistent?.points);

    let userpnts = 0;
    if (!!loyaltyUserPoints?.persistent?.points) {
      userpnts = loyaltyUserPoints?.persistent?.points;
    }

    loyaltyGroups?.forEach((element) => {
      console.log("userPoints userpnts", userpnts);
      if (
        userpnts >= element?.conditions[0]?.persistent_comp_points?.min &&
        element.id === "platinum"
      ) {
        setUserCurrentLevel(element.id);
        setUserTotalPoints(element?.conditions[0]?.persistent_comp_points?.min);
        //this is because max is at 0 (platinum has no max)
        getUserNextLevel(element?.conditions[0]?.persistent_comp_points?.min);
        console.log("LOYALTY NOT VIP");
      } else if (
        userpnts >= element?.conditions[0]?.persistent_comp_points?.min &&
        userpnts <= element?.conditions[0]?.persistent_comp_points?.max
      ) {
        setUserCurrentLevel(element.id);
        setUserTotalPoints(element?.conditions[0]?.persistent_comp_points?.max);
        getUserNextLevel(element?.conditions[0]?.persistent_comp_points?.max);
        console.log("LOYALTY CURRENT " + element.id);
      }
    });
  }

  function getUserNextLevel(levelpoints) {
    loyaltyGroups?.forEach((element) => {
      let lvlpoints = Number(levelpoints) + 1;

      if (
        Number(lvlpoints) ===
          Number(element?.conditions[0]?.persistent_comp_points?.min) ||
        Number(levelpoints) ===
          Number(element?.conditions[0]?.persistent_comp_points?.min)
      ) {
        setUserNextLevel(element.id);
        console.log("LOYALTY NEXT" + element.id);
      }
    });
  }

  // console.log("LOYALTY ", strings?.[userCurrentLevel])

  useEffect(() => {
    if (
      typeof loyaltyUserPoints !== "undefined" &&
      typeof loyaltyGroups !== "undefined"
    ) {
      setUserLevel();
    }
  }, [loyaltyGroups, loyaltyUserPoints, userTotalPoints]);

  return (
    <div
      className={`px-8 py-2 ${
        hideLabel ? "" : "my-4 mb-10"
      }  rounded-xl bg-brand-600`}
    >
      {!hideLabel && (
        <h3 className="py-2 text-base font-normal text-center ">
          {intl.formatMessage({
            defaultMessage: "Your Club Membership Level",
            description: "Your Club Membership Level in Loyalty Page",
          })}
        </h3>
      )}

      <div className="relative py-2">
        <div
          className="w-full h-8 rounded-full"
          style={{ border: `solid 1px #5b616e` }}
        >
          <div className="flex flex-row items-center w-full h-8 px-4 m-auto z-1">
            <h3 className="z-10 w-1/2 ml-0 text-xs font-bold ">
              {loyaltyUserPoints?.persistent?.points === undefined
                ? 0
                : loyaltyUserPoints?.persistent?.points}{" "}
              {intl.formatMessage({
                defaultMessage: "points",
                description: "points",
              })}
            </h3>
            <h3 className="z-10 w-1/2 mr-0 text-xs text-right">
              {userTotalPoints}{" "}
              {intl.formatMessage({
                defaultMessage: "points required",
                description: "points required",
              })}
            </h3>
          </div>
        </div>
        <div
          className="absolute h-8 rounded-full "
          style={{
            background: `${getBackgroundColour()}`,
            width: `${calculatePercentage()}`,
            bottom: `8px`,
          }}
        ></div>
      </div>

      <div className="flex flex-row justify-between w-full px-4 text-xs">
        <div className="flex flex-col rtl:text-right">
          <p className="font-normal " style={{ color: `#657786` }}>
            {intl.formatMessage({
              defaultMessage: "Your level",
              description: "label",
            })}
          </p>
          <h3
            className="text-xs font-bold capitalize "
            style={{ color: `#ddaf6c`, fontSize: `14px` }}
          >
            {strings?.[userCurrentLevel] === undefined
              ? intl.formatMessage({
                  defaultMessage: "Yet to unlock",
                  description: "label",
                })
              : strings?.[userCurrentLevel]}
          </h3>
        </div>
        <div className="flex flex-col items-end ">
          <p className="font-normal " style={{ color: `#657786` }}>
            {intl.formatMessage({
              defaultMessage: "Next level",
              description: "label",
            })}
          </p>
          <h3
            className="text-xs font-bold capitalize "
            style={{ color: `#bababa` }}
          >
            {strings?.[userNextLevel]}
          </h3>
        </div>
      </div>
      <div className="w-full text-center">
        <Link
          trackName="Loyalty Program"
          trackParams={{ component: "LoyaltyProgramProgress" }}
          to="loyalty"
          onClick={close}
          className="w-full my-3 text-center cursor-pointer"
          style={{ color: `#ddaf6c`, fontSize: `10px` }}
        >
          {intl.formatMessage({
            defaultMessage: "More information about the Loyalty Programme",
            description: "link",
          })}
        </Link>
      </div>
    </div>
  );
};

export default LoyaltyProgramProgress;
