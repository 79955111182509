import { differenceInYears, isValid, parseISO } from 'date-fns';
import {
  getRofusStatus,
  isPersonalIDRegistered,
  validateCpr,
  validateEmail,
  validateUsername,
} from '../data/model/account';

export function YupValidateEmail(msg) {
  return this.test({
    name: 'validateEmail',
    message: msg,
    test: async (value) => {
      if (value?.includes('@')) {
        const check = await validateEmail(value);
        return check.isAvailable;
      }
      return false;
    },
  });
}

export function YupValidateUsername(msg) {
  return this.test({
    name: 'validateUsername',
    message: msg,
    test: async (value) => {
      const check = await validateUsername(value);
      return check.isAvailable;
    },
  });
}

export function YupIsPersonalIDRegistered(msg) {
  return this.test({
    name: 'isPersonalIDRegistered',
    message: msg,
    test: async (value) => {
      if (/^(\d{10,10})$/.test(value)) {
        const check = await isPersonalIDRegistered(value);
        return !check.isRegistered;
      }
      return false;
    },
  });
}
export function YupIsValidDate(msg) {
  return this.test({
    name: 'isValidDate',
    message: msg,
    test: (value) => { console.log('validation value', value); console.log('validation value', isValid(parseISO(value))); return isValid(parseISO(value)); },
  });
}

export function YupIsEighteen(msg) {
  return this.test({
    name: 'isEighteen',
    message: msg,
    test: (value) => { console.log('validation value', value); console.log('validation value', differenceInYears(new Date(), parseISO(value))); return differenceInYears(new Date(), parseISO(value)) >= 18; },
  });
}

export function YupValidateBirthdate(msg) {
  return this.test({
    name: "validateBirthdate",
    message: msg,
    test: async (value) => {
      if (!value) return false;
      const [year, month, date ] = value.split("-");
      if (
        !isNaN(date) &&
        date <= 31 &&
        !isNaN(month) &&
        month <= 12 &&
        !isNaN(year)
      ) {
        return true;
      }
      return false;
    },
  });
}

export function YupIsEighteenYearOld(msg) {
  return this.test({
    name: "YupIsEighteen",
    message: msg,
    test: (value) => {
      const values = value?.split("-");

      if (values?.length === 3) {
        return (
          differenceInYears(
            new Date(),
            parseISO(`${values[0]}-${values[1]}-${values[2]}`)
          ) >= 18
        );
      }
      return false;
    },
  });
}
